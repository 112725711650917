import React from 'react'
import anime from 'animejs'
import { ToastStateRecord, RemoveToast, ToastItem } from './ToastDriver'
import {
  ToastContainerStyled,
  ToastStyled,
  ToastCloseIconStyled,
  ToastTextStyled,
  ToastSpinnerStyled,
} from './Toast.styles'
import { Icon } from '@mobi/component-library/Common/Icon'

const ANIMATION_DURATION = 400

export const ToastComponent: React.FC<{
  items: ToastStateRecord['items']
  isHeaderVisible?: boolean
}> = ({ items, isHeaderVisible }) => {
  if (items.isEmpty()) return null

  const itemsOnTop = items.filter(item => item.position === 'top')
  const itemsOnBottom = items.filter(item => item.position === 'bottom')

  return (
    <>
      {!itemsOnTop.isEmpty() && (
        <ToastContainerStyled isHeaderVisible={isHeaderVisible}>
          {itemsOnTop.toArray().map(item => (
            <ToastMessage key={item.id} item={item} />
          ))}
        </ToastContainerStyled>
      )}

      {!itemsOnBottom.isEmpty() && (
        <ToastContainerStyled bottom={true}>
          {itemsOnBottom.toArray().map(item => (
            <ToastMessage key={item.id} item={item} />
          ))}
        </ToastContainerStyled>
      )}
    </>
  )
}

// =======
// Message
// =======

export const ToastMessage: React.FC<{ item: ToastItem }> = ({ item }) => {
  const toastRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const { position, timeout = 0.6 } = item

    const isPositionTop = position === 'top'
    const isClickable = timeout === 0
    const animateStartPoint = isPositionTop ? '-150%' : '100%'

    anime({
      targets: toastRef.current,
      opacity: [0, 1],
      translateY: [animateStartPoint, 0],
      duration: ANIMATION_DURATION,
      direction: isClickable ? 'normal' : 'alternate',
      delay: 200,
      endDelay: isClickable ? undefined : timeout * 500,
      complete: () => {
        if (!isClickable) RemoveToast(item.id)
      },
    })
  }, [item])

  const handleToastClick = () => {
    if (item.timeout !== 0) return

    const isPositionTop = item.position === 'top'
    const animateStartPoint = isPositionTop ? '-150%' : '100%'

    anime({
      targets: toastRef.current,
      opacity: [1, 0],
      translateY: [0, animateStartPoint],
      duration: ANIMATION_DURATION,
      direction: 'normal',
      complete: () => RemoveToast(item.id),
    })
  }

  const isClickable = item.timeout === 0

  return (
    <ToastStyled
      type={item.type || 'default'}
      ref={toastRef}
      onClick={handleToastClick}
      data-testid='toast-item'
    >
      <ToastTextStyled>
        {item.spinner ? <ToastSpinnerStyled /> : null}
        {item.message}
      </ToastTextStyled>
      {isClickable && (
        <ToastCloseIconStyled>
          <Icon type='cross' size='1.5rem' />
        </ToastCloseIconStyled>
      )}
    </ToastStyled>
  )
}
