import { useHistory } from 'react-router-dom'
import { routes } from '../Utils/routes'

export function useSignUpNavigation() {
  const history = useHistory()

  const navigateTo = (route: keyof typeof routes) => history.push(routes[route])

  return {
    navigateTo,
  }
}
