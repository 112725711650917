import React from 'react'
import { useDeposit } from '../../../Hooks/useDeposit'
import { useBraintreeClient } from '../../../Hooks'
import { GooglePayButton } from '@mobi/component-library/Deposit'
import type { PanelProps } from '../../types'
import { pay } from './googlePayNative'

export const GooglePayButtonNative = ({
  initialData: { clientToken, canDeposit, transactionId },
  accountNumber,
  onStart,
  onSuccess,
  onDepositing,
  onCancel,
  onFailure,
  depositAmount,
  isDepositAllowed,
}: PanelProps) => {
  const { client, isReady } = useBraintreeClient(clientToken)
  const { depositMutation } = useDeposit({
    braintreeClient: client,
    accountNumber,
  })

  const canUserStartDeposit = isDepositAllowed && canDeposit

  const onClick = async () => {
    if (canUserStartDeposit) {
      await pay({
        depositAmount,
        onStart,
        onFailure,
        onCancel,
        onDepositing,
        isReady,
        clientToken,
        transactionId,
        onSuccess,
        deposit: depositMutation.mutateAsync,
      })
    }
  }

  return <GooglePayButton onClick={onClick} disabled={!canUserStartDeposit} />
}

GooglePayButtonNative.displayName = 'GooglePayButtonNative'
