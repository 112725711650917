import React from 'react'
import { PromoBanner } from '@mobi/betslip/Components/Common/PromoBanner'
import type { Ticket } from '@core/Areas/AccountActivity/types'

export const SuperPick: React.FC<{ ticket: Ticket }> = ({ ticket }) => {
  const superPick =
    ticket.isSpecial &&
    ticket.specialInformation &&
    ticket.specialInformation.find(s => s.isPowerPlay)

  if (!superPick) return null

  return <PromoBanner promoType='SuperPick' text={superPick.title} />
}
