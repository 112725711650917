import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'
import { RacingTitle } from '@mobi/betslip/Components/Common/BetCard/Components/EventDetails/Components/RacingTitle'
import type { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'
import type { Ticket, TicketStatus } from '@core/Areas/AccountActivity/types'
import {
  getSingleTicketTitle,
  getTitleOdds,
  shouldUseFallbackIcon,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { getRaceLink, meetingIdToContestType } from '@core/Areas/Racing/helpers'
import { SilkFallback } from '@core/Areas/AccountActivity/Components/Ticket/Components'
import { SilkImageContained } from '@core/Components/SilkImage/SilkImageContained'
import { RunnerNumber } from '@core/Areas/RaceCard/Components'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import { FixedOddsMarketDisplayNames } from '@core/Areas/RaceCard/constants'
import { CountdownStatus } from '../Common/CountdownStatus'
import { getIconTypeFromMeetingCode } from '@core/Areas/Quickbet/helpers/IconTypeHelper'

export const SingleSingle: React.FC<{ ticket: Ticket; status: TicketStatus }> = ({
  ticket,
  status,
}) => {
  const ticketContext = React.useContext(TicketDisplayContext)
  const isPreviewMode = ticketContext.type === 'share-preview'

  const leg = ticket.legs[0]

  const runnerCode = meetingIdToContestType(leg.fixtureId, true)
  const contestType = runnerCode?.toLowerCase() as RaceCodesLowerCase

  const isStartingPrice = leg.betType === FixedOddsMarketDisplayNames.SP
  const shouldHideSilk = shouldUseFallbackIcon(leg, ticket.planSeq)

  const productName =
    (leg.betType === FixedOddsMarketDisplayNames.H2H && `${leg.betType} - ${leg.runners}`) ||
    (isStartingPrice && 'Starting Price') ||
    ticket.productName

  const { title, runnerNumber } = getSingleTicketTitle(
    leg,
    ticket.planSeq,
    !isPreviewMode && !!runnerCode
  )

  const shouldDisplayPrice =
    ticket.return !== null && (!isStartingPrice || (isStartingPrice && ticket.return > 0))

  const { priceWin, pricePlace } = shouldDisplayPrice
    ? getTitleOdds(ticket.investment.lines)
    : { priceWin: undefined, pricePlace: undefined }

  const runnerNameForBlackbook =
    runnerNumber && runnerCode && leg.selections[0]?.selectionDetails[0]?.acceptorDetails[0]?.name

  const isContextPendingBetsDrawer = ticketContext.type === 'pending-bets-drawer'
  const shouldHideEventDetails = isContextPendingBetsDrawer && ticketContext.data.isSkyRaceMatch

  const shouldProvideLink = !!(
    (ticketContext.type === 'account-activity' || ticketContext.type === 'pending-bets-drawer') &&
    leg.fixtureId &&
    leg.fixtureDate &&
    leg.raceNumber
  )

  const linkInfo = shouldProvideLink
    ? {
        pathname: getRaceLink({
          meetingDate: leg.fixtureDate,
          meetingId: leg.fixtureId,
          raceNumber: leg.raceNumber,
          isFOO: false,
        }),
      }
    : undefined

  return (
    <>
      {!shouldHideEventDetails && (
        <BetCard.EventDetails
          heading={
            leg.raceNumber && leg.meetingName ? (
              <RacingTitle
                races={[{ raceNumber: leg.raceNumber }]}
                meetingName={leg.meetingName}
                isMultipleRaceSelection={false}
              />
            ) : (
              ticket.legs[0].eventName
            )
          }
          statusComponent={<CountdownStatus ticket={ticket} status={status} />}
          iconComponent={
            <Icon
              size='1.6rem'
              type={
                getIconTypeFromMeetingCode(
                  leg.eventType || leg.fixtureId?.charAt(leg.fixtureId.length - 1)
                ) || 'tab'
              }
            />
          }
          linkInfoPrimary={linkInfo}
        />
      )}

      <BetCard.SelectionInfo
        heading={
          <>
            {runnerNumber && runnerCode && runnerNameForBlackbook && (
              <>
                <RunnerNumber
                  starterType={runnerCode}
                  starterName={runnerNameForBlackbook}
                  starterNumber={runnerNumber}
                />
                <span>&nbsp;-&nbsp;</span>
              </>
            )}
            {title}
          </>
        }
        subHeading={productName}
        priceWin={priceWin}
        pricePlace={pricePlace}
        iconComponent={
          shouldHideSilk ? (
            <SilkFallback contestType={contestType} />
          ) : (
            <SilkImageContained
              fixtureDate={leg.fixtureDate}
              fixtureId={leg.fixtureId}
              raceNumber={leg.selections[0].raceNumber || leg.raceNumber}
              acceptorNumber={leg.selections[0].selectionDetails[0].acceptorDetails[0].number}
            />
          )
        }
        linkInfo={linkInfo}
      />
    </>
  )
}
