export const SIGNUP_ROOT_ROUTE = 'SignUp'

export const routes = {
  contactDetails: `/${SIGNUP_ROOT_ROUTE}`,
  passwordForm: `/${SIGNUP_ROOT_ROUTE}/password`,
  biometrics: `/${SIGNUP_ROOT_ROUTE}/biometrics`,
  depositLimit: `/${SIGNUP_ROOT_ROUTE}/deposit-limit`,
  depositBonus: `/${SIGNUP_ROOT_ROUTE}/deposit-bonus`,
  verification: `/${SIGNUP_ROOT_ROUTE}/verification`,
  driversLicence: `/${SIGNUP_ROOT_ROUTE}/verification/drivers-licence`,
  passport: `/${SIGNUP_ROOT_ROUTE}/verification/passport`,
  medicare: `/${SIGNUP_ROOT_ROUTE}/verification/medicare`,
  verificationFailed: `/${SIGNUP_ROOT_ROUTE}/verification/failed`,
  signUpSuccess: `/${SIGNUP_ROOT_ROUTE}/success`,
  signUpSuccessUnverified: `/${SIGNUP_ROOT_ROUTE}/unverified`,
} as const
