import { ILegacyBetAdapter } from './ILegacyBetAdapter'
import { LegacyBetModelBuilder } from './LegacyBetModelBuilder'
import { BettingInformation } from './BettingInformation'
import { injectable, inject } from 'inversify'
import type { IBetEnquiryService } from '../Services/IBetEnquiryService'
import type { IErrorHandler } from '../Utils/IErrorHandler'
import type { IEventAggregator } from '../../AppUtils/Framework/Messaging/IEventAggregator'
import BetEnquiryResponseDataTransferObject from '../DataTransferObjects/BetEnquiryResponseDataTransferObject'
import { BetType } from './Betting/BetType'
import ObservableMeetingInformation from './Observables/ObservableMeetingInformation'

@injectable()
export class LegacyBetAdapter implements ILegacyBetAdapter {
  private betEnquiryService: IBetEnquiryService
  private errorHandler: IErrorHandler
  private eventAggregator: IEventAggregator

  constructor(
    @inject('IBetEnquiryService') betEnquiryService: IBetEnquiryService,
    @inject('IErrorHandler') errorHandler: IErrorHandler,
    @inject('IEventAggregator') eventAggregator: IEventAggregator
  ) {
    this.betEnquiryService = betEnquiryService
    this.errorHandler = errorHandler
    this.eventAggregator = eventAggregator
  }

  public doBetEnquiry(
    bettingInformation: BettingInformation,
    callback: (betEnquiryResponse: BetEnquiryResponseDataTransferObject) => void
  ): void {
    const betViewModelBuilder = new LegacyBetModelBuilder(bettingInformation, true, false)
    let betEnquiryPromise: Promise<BetEnquiryResponseDataTransferObject>
    if (bettingInformation.selectedBetType().betType() === BetType.AllUp) {
      betEnquiryPromise = this.betEnquiryService.enquireAllUpBet(betViewModelBuilder.getModel())
    } else {
      betEnquiryPromise = this.betEnquiryService.enquireBet(betViewModelBuilder.getModel())
    }

    betEnquiryPromise
      .then(success => {
        if (success.ExceptionMessage != undefined || success.Message != undefined) {
          const scratchedErrorCallback = () => {
            // If selected starter is scratched, refresh starters
            if (success.ExceptionMessage && success.ExceptionMessage.includes('scratched')) {
              let raceNumbersToRefresh = [
                (
                  bettingInformation.meetingInformation as ObservableMeetingInformation
                ).selectedRace.raceNumber(),
              ]
              this.eventAggregator.publish('race-refresh-command', raceNumbersToRefresh)
            }
          }

          this.errorHandler.showErrorMessage(
            success.Message,
            success.ExceptionMessage,
            scratchedErrorCallback
          )
        } else {
          callback(success)
        }
      })
      .catch(error => {
        if (error.status === 400) {
          const caughtException = jQuery.parseJSON(error.responseText)
          this.errorHandler.showErrorMessage(
            caughtException.Message,
            caughtException.ExceptionMessage
          )
        } else {
          this.eventAggregator.publish('bet.account')

          this.errorHandler.handleErrorAsIs(0, 'Unknown')
        }
      })
  }
}
