import { Client } from 'braintree-web'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import type { MutableRefObject } from 'react'
import { getCurrentEnvironment } from '@mobi/utils'
import { getDeviceData, deposit } from '../Utils'
import { reportErrorIfNeeded } from '../../../Utils/sentry'
import { selectDepositState } from '../Store'
import type { DepositRequest, DepositResponse } from '@mobi/api-types'

export type UseDepositProps = {
  braintreeClient: MutableRefObject<Client | undefined>
  accountNumber?: number
}

export type DepositRequestWithoutDeviceData<T extends DepositRequest> = Omit<T, 'deviceData'>

/**
 * Hook for calling the deposit function, ensuring initial data cache is invalidated
 */
export function useDeposit<T extends DepositRequest>({
  braintreeClient,
  accountNumber,
}: UseDepositProps) {
  const { selectedPaymentMethod, lastUsedPaymentMethod } = useSelector(selectDepositState)
  const lastUsedPaymentMethodForAccount = accountNumber
    ? lastUsedPaymentMethod?.[accountNumber]
    : null

  if (!braintreeClient) {
    const message = `Braintree client is uninitialized`
    reportErrorIfNeeded({ message })
    throw new Error(message)
  }

  const depositMutation = useMutation<DepositResponse, Error, DepositRequestWithoutDeviceData<T>>({
    mutationFn: async ({ amount, paymentMethodNonce, ...args }) => {
      const client = braintreeClient.current

      if (!client) {
        throw new Error('Braintree client is uninitialized')
      }

      if (
        getCurrentEnvironment() !== 'production' &&
        (selectedPaymentMethod || lastUsedPaymentMethodForAccount) == 'GooglePay'
      ) {
        paymentMethodNonce = 'fake-android-pay-visa-debit-nonce'
      }

      const deviceData = await getDeviceData(client)

      return deposit({ ...args, amount, paymentMethodNonce, deviceData })
    },
  })

  return {
    depositMutation,
  }
}
