import React, { type PropsWithChildren } from 'react'
import { state$ as routeState$ } from '@core/State/Navigation/driver'
import { AppHeaderLinkStyled } from '../../AppHeader.styles'
import { Icon } from '@mobi/component-library/Common/Icon'
import { showLogin } from '@core/Areas/Login/helpers/showLogin'
import { AppHeaderMaterialButtonStyled } from './Buttons.styles'
import { trackSignUpClick } from '@core/Areas/Account/SignUp/analytics'

export const AppHeaderBackButton: React.FC<PropsWithChildren<{ onClick?: VoidFunction }>> = ({
  onClick,
  children,
}) => {
  const handleBackButtonClick: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault()
    routeState$.take(1).subscribe(routeState => {
      window.location.href = `/${routeState.get('backUrl')}`
    })
  }

  return (
    <AppHeaderLinkStyled
      href='#'
      data-testid='header-back-btn'
      onClick={e => {
        onClick?.()
        handleBackButtonClick(e)
      }}
    >
      {children ?? <Icon type='arrowleft' title='Back' size='inherit' />}
    </AppHeaderLinkStyled>
  )
}

export const AppHeaderJoinButton = (): JSX.Element => {
  return (
    <AppHeaderMaterialButtonStyled
      isPrimary={false}
      data-testid='header-join-btn'
      href='/#SignUp'
      onClick={() => trackSignUpClick('header')}
    >
      Sign up
    </AppHeaderMaterialButtonStyled>
  )
}

export const AppHeaderLoginButton = (): JSX.Element => {
  return (
    <AppHeaderMaterialButtonStyled
      data-testid='header-login-btn'
      isPrimary={true}
      onClick={() => showLogin()}
    >
      Log in
    </AppHeaderMaterialButtonStyled>
  )
}
