import { useQuery } from 'react-query'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { getPendingTicketsCount } from '@core/Areas/AccountActivity/helpers/api'
import { queryClient } from '@core/Data/ReactQuery'

export const useCachedRacingPendingTicketsCount = ({ enabled }: { enabled: boolean }) => {
  const { data: pendingTicketsCount } = useQuery({
    queryKey: `${queryKeys.pendingTicketsCount}:cached`,
    queryFn: getPendingTicketsCount,
    enabled,
    staleTime: 1_000 * 60 * 30,
  })

  return pendingTicketsCount?.pendingTicketsCount ?? 0
}

export const clearCachedRacingPendingTicketsCount = () => {
  const queryKey = `${queryKeys.pendingTicketsCount}:cached`
  queryClient.removeQueries({ queryKey, exact: true })
}
