import React from 'react'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import { BetInfo } from '@core/Areas/AccountActivity/Components/Ticket/Components/BetInfo'
import { Investment } from '@core/Areas/AccountActivity/Components/Ticket/Components/Investment'
import {
  getTicketLayoutType,
  getTicketStatus,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'

export const RaceCardTicket: React.FC<{ ticket: Ticket; isPending: boolean }> = ({
  ticket,
  isPending,
}) => {
  const status = getTicketStatus(ticket, isPending ? 'pending' : 'results')
  const ticketTypeLayout = getTicketLayoutType(ticket)

  return (
    <BetCard.Container data-testid='race-card-ticket'>
      <BetInfo ticket={ticket} status={status} ticketLayoutType={ticketTypeLayout} />

      <Investment ticket={ticket} status={status} />
    </BetCard.Container>
  )
}
