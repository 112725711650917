import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { font, radius, spacing, colors } from '../../Theme/Common'

type StatusBadgeColor = 'gray' | 'green' | 'yellow' | 'red' | 'blue' | 'success' | 'neutral'
type StatusBadgeSize = 'S' | 'L'

/** A status badge that displays some text, and has a color to indicate status */
export const StatusBadge: React.FC<{
  children: ReactNode
  color?: StatusBadgeColor
  badgeSize?: StatusBadgeSize
}> = ({ color = 'gray', badgeSize = 'S', children }) => (
  <StatusBadgeStyled
    style={{
      borderRadius: badgeSize === 'S' ? radius.smx1 : radius.sm,
      ...badgeColors[color],
    }}
    data-testid='status-badge'
  >
    {children}
  </StatusBadgeStyled>
)

// ======
// Styles
// ======

const badgeColors: Record<StatusBadgeColor, { color: string; backgroundColor: string }> = {
  gray: {
    color: colors.neutral[900],
    backgroundColor: colors.neutral[100],
  },
  green: {
    color: colors.success[600],
    backgroundColor: colors.success[50],
  },
  yellow: {
    color: colors.warning[700],
    backgroundColor: colors.warning[50],
  },
  red: {
    color: colors.error[600],
    backgroundColor: colors.error[50],
  },
  blue: {
    color: colors.mariner[600],
    backgroundColor: colors.mariner[50],
  },
  success: {
    color: colors.white,
    backgroundColor: colors.success[500],
  },
  neutral: {
    color: colors.white,
    backgroundColor: colors.neutral[700],
  },
}

const StatusBadgeStyled = styled.span({
  boxSizing: 'border-box',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: spacing.smx2,

  fontFamily: font.family.primary,
  fontSize: font.size.sm.fontSize,
  lineHeight: font.size.sm.lineHeight,
  letterSpacing: font.size.sm.letterSpacing,
  fontWeight: font.weight.regular,
})
