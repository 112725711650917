import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import { RunnerNumber } from '@core/Areas/RaceCard/Components/RunnerNumber'
import { SilkImageContained } from '@core/Components/SilkImage/SilkImageContained'
import {
  sameRaceMultiPositionToText,
  sortSameRaceMultiTicketLegs,
} from '@core/Areas/AccountActivity/helpers'
import {
  getSingleTicketTitle,
  isRefundedStatus,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { BetStatusIcon } from '@core/Areas/AccountActivity/Components/Ticket/Components/Investment/Components/StatusSilks/BetStatusIcon'

export const SameRaceMulti: React.FC<{
  ticket: Ticket
}> = ({ ticket }) => {
  const sortedLegs = sortSameRaceMultiTicketLegs(ticket)
  const isRefunded = isRefundedStatus(ticket)

  return (
    <WrapperStyled data-testid='details-selection-info'>
      {sortedLegs.map(leg => {
        const { selectionDetails } = leg.selections[0]
        const srmPosition = sameRaceMultiPositionToText(
          selectionDetails[0].acceptorDetails[0].srmPosition
        )

        const { title, runnerNumber } = getSingleTicketTitle(leg, ticket.planSeq, true)

        const runnerNameForBlackbook =
          runnerNumber && leg.selections[0]?.selectionDetails[0]?.acceptorDetails[0]?.name
        const shouldRenderBlackbookNumber = runnerNameForBlackbook && runnerNumber

        return (
          <BetCard.SelectionInfo
            key={runnerNumber + title}
            heading={
              <>
                {shouldRenderBlackbookNumber ? (
                  <>
                    <RunnerNumber
                      starterType='Races'
                      starterName={runnerNameForBlackbook}
                      starterNumber={runnerNumber}
                    />
                    <span>&nbsp;-&nbsp;</span>
                  </>
                ) : runnerNumber ? (
                  `${runnerNumber} - `
                ) : (
                  ''
                )}
                {title}
              </>
            }
            subHeading={srmPosition}
            iconComponent={
              <SilkImageContained
                fixtureDate={leg.fixtureDate}
                fixtureId={leg.fixtureId}
                raceNumber={leg.selections[0].raceNumber || leg.raceNumber}
                acceptorNumber={leg.selections[0].selectionDetails[0].acceptorDetails[0].number}
              />
            }
            rightAlignedComponent={
              (leg.result === 'WIN' || leg.result === 'LOSE') && !isRefunded ? (
                <BetStatusIcon betStatus={leg.result === 'WIN' ? 'Won' : 'Lost'} />
              ) : leg.result === 'SCR' || leg.result === '-' ? (
                <span className='srm-leg-status'>
                  {leg.result === 'SCR' ? 'Scratched' : 'Pending'}
                </span>
              ) : null
            }
          />
        )
      })}
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.smx1,

  '.srm-leg-status': {
    fontSize: font.size.sm.fontSize,
    letterSpacing: font.size.sm.letterSpacing,
    fontWeight: font.weight.regular,
    lineHeight: font.size.sm.lineHeight,
    color: colors.neutral[800],
  },
})
