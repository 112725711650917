import { useEffect } from 'react'
import { startListening } from '@core/Store/listenerMiddleware'
import { clearAllBetSlipItems } from '@mobi/betslip/Store/Bets'
import { showBetSlipClearedToast } from '@core/Areas/BetSlipNew/helpers/toasts'

export const useToastOnClearBetSlipSuccess = (shouldEnable: boolean) => {
  useEffect(() => {
    if (!shouldEnable) return undefined

    const stopListener = startListening({
      actionCreator: clearAllBetSlipItems,
      effect: () => {
        showBetSlipClearedToast()
      },
    })

    return () => {
      stopListener()
    }
  }, [shouldEnable])
}
