import React, { forwardRef } from 'react'
import { Accordion } from '@mobi/component-library/Common/V2/Accordion'
import type { BetSlipItem } from '@mobi/betslip/types'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { selectActiveInvestment } from '@mobi/betslip/Store/Workflow/selectors'
import { calculateMultiProjectedPay } from '@mobi/betslip/helpers/calculator/multis'
import {
  getBetsInMulti,
  isSelectionAllowedInMulti,
  isValidMulti,
  isValidMultiInvestmentForLegs,
} from '@mobi/betslip/helpers/state'
import { MAX_LEGS_FOR_MULTI_FORMULA, MIN_LEGS_IN_MULTI } from '@mobi/betslip/helpers/constants'
import { Investment, InvestmentsWrapperStyled } from '../Common/Investment'
import { ShareMyBet } from '../Common/ShareMyBet'
import { InlineAlert } from '../Common/InlineAlert'
import { ReceiptNumber } from '../Common/ReceiptNumber'
import { BetCard } from '../Common/BetCard'
import { ComboMultis } from './Components'
import { MultiItems } from './Components/MultiItems'

export const Multi = forwardRef<HTMLDivElement, { items: BetSlipItem[] }>(({ items }, ref) => {
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)
  const isBusy = useAppSelector(state => state.betslip.workflow.isBusy)
  const activeInvestment = useAppSelector(selectActiveInvestment)
  const receipt = useAppSelector(state => state.betslip.bets.multiReceipt)
  const multiBetError = useAppSelector(state => state.betslip.bets.multiBetError)
  const multiInvestment = useAppSelector(state => state.betslip.bets.multiInvestment)

  const multiItems = items.filter(item => isSelectionAllowedInMulti(item.selection))
  const hasEnoughMultiItems = multiItems.length >= MIN_LEGS_IN_MULTI
  const selectedMultiItems = getBetsInMulti(items)

  const isMultiValid = isValidMulti(multiInvestment, multiBetError, selectedMultiItems)
  const hasValidMultiInvestment = isValidMultiInvestmentForLegs(
    multiInvestment,
    selectedMultiItems.length
  )

  const isEditable = workflowStatus === 'ready'

  if (!hasEnoughMultiItems || (!isEditable && !isMultiValid && !multiBetError)) return null

  const isDisabled = !isEditable || isBusy || receipt != null

  const hasAllowableMultiCountForCombos =
    selectedMultiItems.length >= MIN_LEGS_IN_MULTI &&
    selectedMultiItems.length <= MAX_LEGS_FOR_MULTI_FORMULA

  const shouldRenderMultiCombosInputs =
    isEditable && hasAllowableMultiCountForCombos && !multiInvestment.bonusBetId

  return (
    <Accordion title='Multi (1)' shouldStartExpanded shouldUseDefaultTheme={false} ref={ref}>
      <BetCard.Container
        topComponent={receipt && <InlineAlert type='success' text='Your bet has been placed' />}
      >
        <MultiItems
          multiItems={multiItems}
          isEditable={isEditable}
          hasValidMultiInvestment={hasValidMultiInvestment}
        >
          {isEditable && (
            <InvestmentsWrapperStyled data-testid='Multi.Investment'>
              <Investment
                isActive={activeInvestment?.investmentType === 'Multi'}
                itemId='Multi'
                investmentType='Multi'
                label='Cost'
                isEditable
                isDisabled={isDisabled}
                value={multiInvestment.value}
                // isBonusBet={multiInvestment.isBonusBet}
              />

              <Investment
                isActive={activeInvestment?.investmentType === 'MultiReverseStake'}
                itemId='MultiReverseStake'
                investmentType='MultiReverseStake'
                label='Potential Payout'
                isEditable
                isDisabled={isDisabled}
                value={calculateMultiProjectedPay(selectedMultiItems, multiInvestment, true)}
              />
            </InvestmentsWrapperStyled>
          )}
        </MultiItems>

        {workflowStatus !== 'proposed' && (
          <BetCard.Footer
            leftSection={receipt ? <ReceiptNumber ticketNumber={receipt.ticketNumber} /> : null}
            rightSection={<ShareMyBet items={selectedMultiItems} isBetPlaced={!!receipt} />}
            expandableText={shouldRenderMultiCombosInputs ? 'Combo Multis' : undefined}
            expandableContent={
              shouldRenderMultiCombosInputs ? (
                <ComboMultis isDisabled={isDisabled} selectedMultiItems={selectedMultiItems} />
              ) : null
            }
          />
        )}
      </BetCard.Container>
    </Accordion>
  )
})
