import React from 'react'
import styled from '@emotion/styled'
import { useRef, useState, useEffect } from 'react'
import { LoadingSpinner } from '@mobi/component-library/Feedback/LoadingSpinner'

export const PullToRefreshContainer = ({
  children,
  onRefresh,
}: {
  children: React.ReactNode
  onRefresh: () => void
}) => {
  const [isPulling, setIsPulling] = useState<boolean>(false)
  const pulledDistRef = useRef<number>(0)

  const startYRef = useRef<number | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const spinnerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const container = containerRef.current

    if (!container) return

    const handleTouchStart = (e: TouchEvent) => {
      if (window.scrollY === 0) {
        startYRef.current = e.touches[0].clientY
      }
    }

    const handleTouchMove = (e: TouchEvent) => {
      if (startYRef.current === null || !containerRef.current || !spinnerRef.current) return

      const currentY = e.touches[0].clientY
      const diff = currentY - startYRef.current
      pulledDistRef.current = diff
      setIsPulling(true)

      if (diff > 0) {
        spinnerRef.current.style.height = '5rem'

        const clampedDistance = Math.min(diff / 3, 105)
        containerRef.current.style.transform = `translateY(${clampedDistance}px)`

        if (diff > 125) {
          spinnerRef.current.style.opacity = '1'
        }
      }
    }

    const handleTouchEnd = () => {
      if (!containerRef.current || !spinnerRef.current) return

      containerRef.current.style.transform = 'unset'
      spinnerRef.current.style.transform = 'unset'
      spinnerRef.current.style.opacity = '0'
      spinnerRef.current.style.height = '0'

      if (pulledDistRef.current > 250) {
        onRefresh()
      }

      startYRef.current = null
      setIsPulling(false)
      pulledDistRef.current = 0
    }

    container.addEventListener('touchstart', handleTouchStart)
    container.addEventListener('touchmove', handleTouchMove)
    container.addEventListener('touchend', handleTouchEnd)

    return () => {
      container.removeEventListener('touchstart', handleTouchStart)
      container.removeEventListener('touchmove', handleTouchMove)
      container.removeEventListener('touchend', handleTouchEnd)
    }
  }, [onRefresh])

  return (
    <div ref={containerRef}>
      <SpinnerContianer ref={spinnerRef}>{isPulling && <LoadingSpinner />}</SpinnerContianer>
      {children}
    </div>
  )
}

const SpinnerContianer = styled.div({
  position: 'absolute',
  top: '-7rem',
  left: 0,
  right: 0,
  height: 0,
  display: 'flex',
  justifyContent: 'center',
  transition: 'opacity 0.25s ease-in',
  opacity: 0,
})
