import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Observable } from 'rx'
import Decimal from 'decimal.js'
import type {
  ToteSelection,
  AllUpSelection,
  FobSelection,
  BettingType,
  Selection,
} from '@mobi/betslip/types'
import {
  isAllUpSelection,
  isFobSelection,
  isFobSportsSelection,
  isNoveltyBetType,
  isSameRaceMultiSelection,
  isStartingPriceSelection,
  isToteSelection,
} from '@mobi/betslip/helpers/typeGuards'
import { handleBetSelection } from '@core/Utils/betting/handleBetSelection'
import { calculateBetCost, calculateFlexiAmount } from '../../helpers/calculator'
import { FobSingleRequest } from '@core/Data/betting'
import { isFavouriteNumbersSelection, isToteSportsSelection } from '@core/Data/Betting/selections'
import { Stake } from '@classic/Specials/Model/Stake'
import { BetSpecialOffer, IBetSpecialOffer } from '@classic/Specials/Model/BetSpecialOffer'
import { navigateToNextEvents } from '@classic/AppUtils/Framework/Intent/navigation'
import { trackBonusBetEvent } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { keys } from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import { BetslipItem } from '@core/Areas/Betslip/driver'
import { QuickbetState, state$ as quickbetState$ } from '../../driver'
import {
  Campaign,
  state$ as userAccountState$,
  UserAccountState,
} from '@core/State/UserAccount/userAccountDriver'
import {
  defaultInvestmentState,
  InvestmentState,
  state$ as investmentState$,
} from '../BetInvestment/betInvestmentDriver'
import { state$ as superPickState$, SuperPickState } from '@core/Components/SuperPick/driver'
import { FormulaState, state$ as formulaState$ } from '../Formula/driver'
import {
  AddingToBetslip,
  ChangeInvestment,
  DepositFundsDisplayed,
  InsufficientFundsForBet,
  SetInvalidInvestmentNotification,
} from '../../signals'
import { AddSingleToBetslip, UpdateItemInBetslip } from '@core/Areas/Betslip/signals'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { observeImmutable } from '@core/Components/HOCs'
import {
  AddToBetslipButtonComponent,
  BettingButtonComponent,
  BusyButtonComponent,
  CloseButtonComponent,
  DepositButtonComponent,
  EditBetButtonComponent,
  KeepBetsButtonComponent,
  LoginButtonComponent,
  NextEventButtonComponent,
  PendingBetsButtonComponent,
  UpdateBetslipItemButtonComponent,
} from './Buttons'
import { confirmBetAsync, proposeBetAsync } from '../../async-signals'
import { createBetRequest } from '../../helpers/request'
import { QUICKBET_MODAL_ID } from '../../constants'
import { NotificationType } from '../Notifications/NotificationTypes'
import { checkSuperPickEligible } from '@classic/Specials/Store/PyosRewardCalculator'
import { PyosExtensions } from '@classic/Specials/Store/PyosExtensions'
import { calculateCombinations as favouriteNumbersCombinations } from '@core/Areas/FavouriteNumbers/calculator'
import { calculateCombinations as tippingCombinations } from '@core/Areas/Tipping/helpers/calculator'
import { closeModal } from '@core/Components/Modal'
import { useFeature } from '@core/Utils/hooks'
import { calculateBetCostShortfall } from '@core/Areas/QuickDeposit/Utils/helpers'
import { openDepositModal } from '@core/Areas/Deposit'
import { selectIsBusy } from '@core/Areas/QuickDeposit/Store/selectors'

function setInvalidSuperPickSelectionNotification(selectedSuperPickOffer: BetSpecialOffer): void {
  SetInvalidInvestmentNotification({
    type: NotificationType.InvalidSuperPickSelection,
    subtitle: new PyosExtensions().getLegTypeIneligibilityDisplay(selectedSuperPickOffer) || '',
  })
}

export type ButtonsContainerComponentProps = Pick<
  QuickbetState,
  | 'id'
  | 'isBusy'
  | 'canProposeBet'
  | 'canConfirmBet'
  | 'canBet'
  | 'notificationType'
  | 'selection'
  | 'selectionDetails'
  | 'isEachWayAvailable'
  | 'isEachWay'
  | 'bettingType'
  | 'isBetslipItem'
  | 'shouldAllowPlaceInvestment'
  | 'promptForInvestment'
  | 'keepSelections'
  | 'tags'
  | 'betSource'
> & {
  investmentWin: number
  investmentPlace: number
  isSuperPickAvailable?: boolean
  isSuperPickSelectionInvalid?: boolean
  selectedSuperPickOffer?: BetSpecialOffer
  selectedCampaign?: Campaign
  specialOffers: BetSpecialOffer[]
  formulas: AllUpSelection['formulas']
  numberOfCombinationsSelected: number
  isLoggedIn: boolean
  accountNumber: number | null
  accountBalance: number | null
  bonusBetBalance: number | null
  bonusCashBalance: number | null
  isUsingBonusCash?: boolean
  isUsingBonusBet?: boolean
}

export function ButtonsContainerComponent({
  id,
  isBusy,
  canBet,
  canProposeBet,
  canConfirmBet,
  notificationType,
  isEachWayAvailable,
  isEachWay,
  selection,
  selectionDetails,
  bettingType,
  investmentWin,
  investmentPlace,
  isLoggedIn,
  selectedSuperPickOffer,
  selectedCampaign,
  isSuperPickAvailable,
  isSuperPickSelectionInvalid,
  isBetslipItem,
  formulas,
  numberOfCombinationsSelected,
  shouldAllowPlaceInvestment,
  specialOffers,
  promptForInvestment,
  accountNumber,
  accountBalance,
  bonusBetBalance,
  bonusCashBalance,
  isUsingBonusCash,
  keepSelections,
  isUsingBonusBet,
  tags,
  betSource,
}: ButtonsContainerComponentProps): JSX.Element {
  const isBonusCashImprovementsActive = useFeature('BONUS_CASH_IMPROVEMENTS')
  const isNewBetSlipFeatureActive = useFeature('NEW_BET_SLIP_DESIGN')

  const dispatch = useDispatch()

  const isTote = isToteSelection(selection)
  const isAllowedInMulti = !isTote && !isStartingPriceSelection(selection)

  const numberOfCombinations = isAllUpSelection(selection)
    ? numberOfCombinationsSelected
    : isTote
      ? (selection as ToteSelection).numberOfCombinations
      : isFavouriteNumbersSelection(selection)
        ? favouriteNumbersCombinations(selection.bets)
        : isToteSportsSelection(selection)
          ? tippingCombinations(selection.betSelections)
          : 1

  const betType = isTote ? (selection as ToteSelection).betType : undefined

  const betCost = calculateBetCost(
    bettingType as BettingType,
    investmentWin,
    investmentPlace,
    isEachWay,
    betType,
    numberOfCombinations
  )

  const isFlexiWithInsufficientInvestment =
    isTote &&
    isNoveltyBetType((selection as ToteSelection).betType) &&
    numberOfCombinations > 1 &&
    calculateFlexiAmount(investmentWin, numberOfCombinations) < 1

  const hasSuperPickOrFlexiError = (): boolean => {
    const stake: Stake | undefined = selectedSuperPickOffer
      ? Stake.normalise({
          Win: Decimal(investmentWin || 0),
          Place: Decimal(investmentPlace || 0),
        })
      : undefined
    if (isFlexiWithInsufficientInvestment && investmentWin > 0) {
      SetInvalidInvestmentNotification({
        type: NotificationType.InsufficientInvestmentForCombinations,
      })
      return true
    } else if (
      !!selectedSuperPickOffer &&
      !checkSuperPickEligible(selectedSuperPickOffer as IBetSpecialOffer, stake as Stake)
    ) {
      setInvalidSuperPickSelectionNotification(selectedSuperPickOffer)
      return true
    } else {
      return false
    }
  }

  const handleAddToBetslipClick = () => {
    if (hasSuperPickOrFlexiError()) return

    if (isNewBetSlipFeatureActive) {
      handleBetSelection({
        betFlow: { location: 'Betslip' },
        selection: {
          bettingType: bettingType as BettingType,
          selection: selection as Selection,
          selectionDetails,
          isEachWayAvailable,
          shouldAllowPlaceInvestment,
          tags,
          betSource: betSource as string,
        },
      })
    } else {
      const betslipItem = createBetSlipItem()
      if (tags) {
        betslipItem.tags = tags
      }
      if (!isEachWayAvailable && (betslipItem.selection as FobSelection)?.placePrice) {
        betslipItem.shouldAllowPlaceInvestment = true
      }
      AddingToBetslip()
      AddSingleToBetslip(betslipItem)
    }

    dispatch(
      closeModal({ id: QUICKBET_MODAL_ID as Lowercase<string>, exitAnimation: 'addToBetslip' })
    )

    trackBonusBet(keys.quickbetBonusBetAddedToBetslip)
  }

  const handleUpdateBetslipClick = () => {
    if (!hasSuperPickOrFlexiError()) {
      UpdateItemInBetslip(createBetSlipItem())
      dispatch(closeModal({ id: QUICKBET_MODAL_ID as Lowercase<string> }))
    }

    trackBonusBet(keys.betslipBonusBetChosen)
  }

  const trackBonusBet = (eventKey: Parameters<typeof trackBonusBetEvent>[0]) => {
    if (selectedCampaign?.rewardType === 'BonusBetReward') {
      trackBonusBetEvent(eventKey, {
        name: selectedCampaign.title,
        accountNumber: accountNumber ?? '',
        accountBalance,
        bonusBetBalance,
        bonusCashBalance,
      })
    }
  }

  const handleProposeBetClick = async () => {
    if (!hasSuperPickOrFlexiError()) {
      const betRequest = createBetRequest(
        selection,
        id as string,
        investmentWin,
        investmentPlace,
        isEachWay,
        selectedSuperPickOffer as BetSpecialOffer,
        selectedCampaign,
        formulas,
        // bonusCashBalance is also checked to workaround a bug/feature where isUsingBonusCash is true even for accounts that don't have any bonus cash available!
        isUsingBonusCash && !!bonusCashBalance && bonusCashBalance > 0,
        // TODO remove this when removing BONUS_CASH_IMPROVEMENTS/bonus-cash-improvements flag
        isBonusCashImprovementsActive
      )

      if (betRequest) {
        if (isFobSelection(selection) && !isSameRaceMultiSelection(selection) && tags) {
          ;(betRequest as FobSingleRequest).tags = tags
        }
        proposeBetAsync(betRequest).then(newAccountBalance => {
          // After proposing the bet and getting a successful response, we will try to
          // use the account balance returned from the API call. If the balance was
          // not returned, we will use the current known account balance instead.
          const shortfall = calculateBetCostShortfall(
            betCost,
            newAccountBalance ?? accountBalance ?? 0,
            isUsingBonusCash ?? false,
            bonusCashBalance ?? 0
          )
          if (shortfall > 0) {
            InsufficientFundsForBet({
              betCost,
              shortfall,
            })
          }
        })
      }
      trackBonusBet(keys.quickbetBonusBetProposed)
    }
  }

  const handleConfirmBetClick = () => {
    const betRequest = createBetRequest(
      selection,
      id as string,
      investmentWin,
      investmentPlace,
      isEachWay,
      selectedSuperPickOffer as BetSpecialOffer,
      selectedCampaign,
      formulas,
      // bonusCashBalance is also checked to workaround a bug/feature where isUsingBonusCash is true even for accounts that don't have any bonus cash available!
      isUsingBonusCash && !!bonusCashBalance && bonusCashBalance > 0,
      // TODO remove this when removing BONUS_CASH_IMPROVEMENTS/bonus-cash-improvements flag
      isBonusCashImprovementsActive
    )

    if (betRequest) {
      if (isFobSelection(selection) && tags) {
        ;(betRequest as FobSingleRequest).tags = tags
      }
      confirmBetAsync(betRequest)
    }

    trackBonusBet(keys.betslipBonusBetConfirmed)
  }

  const handleNextEventsClick = () => {
    dispatch(closeModal({ id: QUICKBET_MODAL_ID as Lowercase<string> }))
    navigateToNextEvents()
  }

  const handleKeepButtonClick = (keepSelections: (_: boolean) => void) => {
    keepSelections(true)
    dispatch(closeModal({ id: QUICKBET_MODAL_ID as Lowercase<string> }))
  }

  const createBetSlipItem: () => BetslipItem = () => ({
    id,
    bettingType,
    isEachWay,
    isEachWayAvailable,
    investment: {
      win: {
        ...defaultInvestmentState.win,
        isBonusBet:
          selectedCampaign && selectedCampaign.rewardType === 'BonusBetReward' && !!investmentWin,
        value: investmentWin,
      },
      place: {
        ...defaultInvestmentState.place,
        isBonusBet:
          selectedCampaign && selectedCampaign.rewardType === 'BonusBetReward' && !!investmentPlace,
        value: investmentPlace,
      },
      bonusBet:
        selectedCampaign && selectedCampaign.rewardType === 'BonusBetReward'
          ? { campaignId: selectedCampaign.id, value: selectedCampaign.remainingAmount }
          : undefined,
    },
    isSuperPickAvailable,
    selection: isAllUpSelection(selection) ? { ...selection, formulas } : selection,
    selectionDetails,
    shouldAllowPlaceInvestment,
    numberOfCombinations,
    errorMessage: '',
    hasNonHandledErrorOccurred: false,
    selectedSuperPickOffer,
    isInMulti: isAllowedInMulti,
    multiLegBetType: isAllowedInMulti ? 'W' : undefined,
    multiBetLegError: null,
    specialOffers,
    hasIotSubscription: false,
    campaign: selectedCampaign,
    isUsingBonusCash,
  })

  const isInvestmentError =
    NotificationType.InsufficientFunds === notificationType ||
    NotificationType.InvalidSuperPickSelection === notificationType ||
    NotificationType.InsufficientInvestmentForCombinations === notificationType
  const isMysteryQuickPick = bettingType === 'mystery-quick-pick'
  const isToteSports = bettingType === 'tote-sports-tipping'
  const isKambiSportsBet =
    isFobSportsSelection(selection) && selection.externalBetId?.startsWith('KAMBI_')

  const isSameRaceMulti = isSameRaceMultiSelection(selection)
  const showAddToBetslipButton =
    !isInvestmentError &&
    !canConfirmBet &&
    canProposeBet &&
    canBet &&
    !isBetslipItem &&
    !isMysteryQuickPick &&
    !isToteSports &&
    !isSameRaceMulti &&
    !isKambiSportsBet

  const showUpdateBetslipButton = isBetslipItem && !canConfirmBet && canBet
  const showBusyButton = (canProposeBet || canConfirmBet) && isBusy
  const showEditButton =
    ((isInvestmentError || canConfirmBet) && isLoggedIn && promptForInvestment) ||
    (isBetslipItem && isInvestmentError)
  const showProposeButton = !isBetslipItem && canProposeBet && isLoggedIn && !isBusy
  const showConfirmButton = canConfirmBet && isLoggedIn && !isBusy
  const showLoginButton =
    !isBetslipItem && !isLoggedIn && !isBusy && !(notificationType === NotificationType.RaceClosed)
  const showPendingBetsButton = !canBet && isLoggedIn
  const showDepositButton = notificationType === NotificationType.InsufficientFunds

  let shouldDisableBetButton =
    isInvestmentError ||
    betCost <= 0 ||
    (isBonusCashImprovementsActive && isMysteryQuickPick && !!isUsingBonusBet)

  const keepBetsRef = React.useRef<HTMLDivElement>(null)

  return (
    <Grid padding='0.5rem'>
      {showAddToBetslipButton && (
        <GridCell padding={'0.5rem'}>
          <AddToBetslipButtonComponent
            onClick={handleAddToBetslipClick}
            isDisabled={!!isSuperPickSelectionInvalid}
          />
        </GridCell>
      )}

      {showEditButton && (
        <GridCell>
          <EditBetButtonComponent isDisabled={isBusy} onClick={ChangeInvestment} />
        </GridCell>
      )}

      {showBusyButton && (
        <GridCell>
          <BusyButtonComponent />
        </GridCell>
      )}

      {showProposeButton && (
        <GridCell>
          <BettingButtonComponent
            onClick={handleProposeBetClick}
            betCost={betCost}
            isDisabled={shouldDisableBetButton}
            label='Bet'
          />
        </GridCell>
      )}

      {showConfirmButton && (
        <GridCell>
          <BettingButtonComponent
            onClick={handleConfirmBetClick}
            betCost={betCost}
            isDisabled={shouldDisableBetButton}
            label='Confirm'
          />
        </GridCell>
      )}

      {showLoginButton && (
        <GridCell>
          <LoginButtonComponent />
        </GridCell>
      )}
      {showDepositButton && (
        <GridCell>
          <DepositButtonComponent
            onClick={() => {
              openDepositModal('quick-deposit')
              DepositFundsDisplayed()
            }}
          >
            Deposit
          </DepositButtonComponent>
        </GridCell>
      )}
      {showPendingBetsButton && (
        <GridCell>
          <PendingBetsButtonComponent />
        </GridCell>
      )}
      {!canBet && (
        <GridCell>
          {keepSelections && keepSelections() === true ? (
            <KeepBetsButtonComponent
              theRef={keepBetsRef}
              onClick={() => handleKeepButtonClick(keepSelections)}
            />
          ) : (
            <NextEventButtonComponent onClick={handleNextEventsClick} />
          )}
        </GridCell>
      )}
      {!canBet && (
        <GridCell>
          <CloseButtonComponent />
        </GridCell>
      )}

      {showUpdateBetslipButton && (
        <GridCell padding={'0.5rem'}>
          <UpdateBetslipItemButtonComponent
            onClick={handleUpdateBetslipClick}
            isDisabled={!!isSuperPickSelectionInvalid || isInvestmentError}
          />
        </GridCell>
      )}
    </Grid>
  )
}

const buttonsState$ = Observable.combineLatest(
  quickbetState$,
  investmentState$,
  userAccountState$,
  superPickState$,
  formulaState$,
  (
    quickbetRecord,
    investmentRecord,
    userAccountRecord,
    superPickRecord,
    formulaRecord
  ): Omit<ButtonsContainerComponentProps, 'TEST_openQuickDeposit'> => {
    const quickbet: QuickbetState = quickbetRecord.toJS()
    const investment: InvestmentState = investmentRecord.toJS()
    const superPick: SuperPickState = superPickRecord.toJS()
    const formula: FormulaState = formulaRecord.toJS()
    const user: UserAccountState = userAccountRecord.toJS()
    const specialOffers = superPick.specialOffers
    const selectedSuperPickOffer = specialOffers
      ? specialOffers.find(offer => offer.specialSeq === superPick.selectedSuperPickSeq)
      : undefined
    if (quickbet.isEachWayAvailable) {
      investment.place.value = quickbet.isEachWay
        ? investment.win.value
        : quickbet.shouldAllowPlaceInvestment
          ? investment.place.value
          : 0
    }
    let campaign = user.campaigns?.find(x => x.id == investment.bonusBet?.campaignId)

    if (!quickbet.isUsingBonusCash && !campaign) {
      // To disable bonus cash, we must remove the ID
      const bonusCashCampaign = user.campaigns?.find(x => x.rewardType === 'BonusCashReward')
      campaign = bonusCashCampaign && { ...bonusCashCampaign, id: 0 }
    }

    return {
      id: quickbet.id,
      isBusy: quickbet.isBusy,
      canBet: quickbet.canBet,
      canProposeBet: quickbet.canProposeBet,
      canConfirmBet: quickbet.canConfirmBet,
      notificationType: quickbet.notificationType,
      isEachWayAvailable: quickbet.isEachWayAvailable,
      isEachWay: quickbet.isEachWay,
      bettingType: quickbet.bettingType,
      investmentWin: investment.win.value,
      investmentPlace: investment.place.value,
      isLoggedIn: !!userAccountRecord.isLoggedIn,
      selection: quickbet.selection,
      selectionDetails: quickbet.selectionDetails,
      isSuperPickSelectionInvalid: !!superPick.invalidSelectionMessage,
      selectedSuperPickOffer,
      isSuperPickAvailable: specialOffers && specialOffers.length > 0,
      isBetslipItem: quickbetRecord.isBetslipItem,
      formulas: formula.formulas,
      numberOfCombinationsSelected: formula.numberOfCombinationsSelected,
      shouldAllowPlaceInvestment: quickbet.shouldAllowPlaceInvestment,
      specialOffers,
      selectedCampaign: campaign,
      promptForInvestment: quickbet.promptForInvestment,
      accountNumber: user.accountNumber,
      accountBalance: user.accountBalance,
      bonusBetBalance: user.bonusBetBalance,
      bonusCashBalance: user.bonusCashBalance,
      isUsingBonusCash: quickbet.isUsingBonusCash,
      isUsingBonusBet: quickbet.isUsingBonusBet,
      keepSelections: quickbet.keepSelections,
      tags: quickbet.tags,
      betSource: quickbet.betSource,
    }
  }
)

export const ButtonsContainer: React.ComponentClass = observeImmutable(
  buttonsState$,
  ({ record }) => {
    const quickDepositIsBusy = useSelector(selectIsBusy)
    return <ButtonsContainerComponent {...record} isBusy={quickDepositIsBusy || record.isBusy} />
  }
)

ButtonsContainer.displayName = 'ButtonsContainer'
