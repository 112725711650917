import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { BetAccountKey, BetAccountRecord, BetAccountRepo } from '@core/Data/Account/betAccount'
import { buildAddress, getContactDetailsFields } from './data-transforms'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { connect } from '../../Components/HOCs/connect'
import { ContentHeader } from '../../Components/ContentHeader'
import { CustomerServiceTelephone } from '@mobi/component-library/Common/V2'
import { DesktopSpinner, Spinner } from '@mobi/component-library/Common/Spinner'
import { ErroredInstance, isErrored, isLoading, LoadingInstance } from 'rwwa-data-access'
import { ErrorMessage, SuccessMessage } from '../../Components/Messages'
import { getFromRepo } from '@core/Utils/repository'
import { MainContainer } from '../../Components/Containers'
import { observeImmutable } from '../../Components/HOCs/observe'
import { Reset } from '@core/Areas/ContactDetails/driver'
import { Text } from '@core/Components/Text'
import { useBetAccount } from '@mobi/account/Areas/Deposit/Hooks'
import { useLogon } from '@core/Utils/hooks'
import type { Address, BetAccountHolder } from '@mobi/api-types'
import {
  ContactDetails as ContactDetailsRepo,
  ContactDetailsKey,
} from '../../Data/Account/contactDetails'
import {
  ContactDetailsContainer,
  ContactDetailsItemStyled,
  ContactDetailsPreferredNameStyled,
  ContactDetailsViewItem,
  ContactDetailsViewLabel,
  NoticeBoxInternationalStyled,
  VerifyTextContainerStyled,
} from './ContactDetails.styles'
import { type ContactDetailsStateRecord, state$ as contactDetailsState$ } from './driver'

export interface ContactDetailsViewProps {
  betAccountHolderNumber?: number | null
  fullName?: string
  preferredName?: string
  dateOfBirth?: string
  email?: string
  phoneMobile?: string
  phoneHome?: string
  phoneWork?: string
  addressResidential?: Address | null
  addressPostal?: Address | null
  isDesktop?: boolean
  isIdVerified: boolean
  navigateToEditContactDetails: (accountNumber?: number | null) => void
  isInternationalCustomer: boolean
}

export interface ContactDetailsProps {
  isDesktop?: boolean
  navigateToEditContactDetails: (accountNumber?: number | null) => void
}

export function ContactDetailsMobiWrapper() {
  const history = useHistory()
  return (
    <ContactDetails
      navigateToEditContactDetails={accountNumber =>
        history.push('/account/contactdetails/edit/' + accountNumber)
      }
    />
  )
}

export function ContactDetails({ isDesktop, navigateToEditContactDetails }: ContactDetailsProps) {
  return (
    <ConnectContactDetails
      contactDetails={ContactDetailsKey}
      isDesktop={isDesktop}
      navigateToEditContactDetails={navigateToEditContactDetails}
    />
  )
}

const ConnectContactDetails = connect({ contactDetails: ContactDetailsRepo })<ContactDetailsProps>(
  ContactDetailsLoader
)

export function ContactDetailsLoader({
  contactDetails,
  isDesktop,
  navigateToEditContactDetails,
}: {
  contactDetails: typeof LoadingInstance | typeof ErroredInstance | BetAccountHolder
} & ContactDetailsProps) {
  const { accountNumber, isLoggedIn } = useLogon()
  const betAccountQuery = useBetAccount<BetAccountRecord>({
    enabled: accountNumber === null || isLoggedIn !== true,
    queryFn: () => getFromRepo(BetAccountRepo, BetAccountKey),
  })

  useEffect(() => {
    return () => Reset()
  }, [])

  if (isLoading(contactDetails) || betAccountQuery.isLoading) {
    return isDesktop ? <DesktopSpinner /> : <Spinner />
  }

  if (isErrored(contactDetails) || betAccountQuery.isError) {
    return <ErrorMessage>An error occurred while loading your contact details.</ErrorMessage>
  }

  const props = getContactDetailsFields(contactDetails)

  return (
    <ContactDetailsObserver
      {...props}
      isIdVerified={betAccountQuery.data?.IsIDVerified ?? false}
      isDesktop={isDesktop}
      navigateToEditContactDetails={navigateToEditContactDetails}
    />
  )
}

function NoticeBoxInternational() {
  return (
    <NoticeBoxInternationalStyled>
      If your name, address or date of birth are incorrect, please call us on{' '}
      <CustomerServiceTelephone isInternational={true} /> for assistance.
    </NoticeBoxInternationalStyled>
  )
}

const ContactDetailsObserver = observeImmutable<ContactDetailsStateRecord, ContactDetailsViewProps>(
  contactDetailsState$,
  ({ record, ...props }) => {
    const { updateSuccess } = record
    return <ContactDetailsView {...props} updateSuccess={updateSuccess} />
  }
)

export function ContactDetailsView({
  betAccountHolderNumber,
  fullName,
  preferredName,
  dateOfBirth,
  email,
  phoneMobile,
  phoneHome,
  phoneWork,
  addressResidential,
  addressPostal,
  updateSuccess,
  isDesktop,
  isIdVerified,
  isInternationalCustomer: isInternational,
  navigateToEditContactDetails,
}: ContactDetailsViewProps & { updateSuccess: boolean }) {
  const onEditDetailsClick = () => {
    if (isIdVerified) {
      navigateToEditContactDetails(betAccountHolderNumber)
    }
  }

  return (
    <MainContainer>
      {!isDesktop && <ContentHeader title='Contact Details' />}
      {updateSuccess ? (
        <SuccessMessage data-tid-success-message=''>Successfully updated</SuccessMessage>
      ) : null}
      <ContactDetailsContainer>
        {preferredName && (
          <ContactDetailsPreferredNameStyled data-tid-preferred-name=''>
            {preferredName}
          </ContactDetailsPreferredNameStyled>
        )}
        {fullName && (
          <ContactDetailsItemStyled data-tid-full-name=''>{fullName}</ContactDetailsItemStyled>
        )}

        <dl>
          {dateOfBirth && <ContactDetailsViewLabel>Date of Birth:</ContactDetailsViewLabel>}
          {dateOfBirth && (
            <ContactDetailsViewItem data-tid-dob=''>{dateOfBirth}</ContactDetailsViewItem>
          )}

          {email && <ContactDetailsViewLabel>Email: </ContactDetailsViewLabel>}
          {email && <ContactDetailsViewItem data-tid-email=''>{email}</ContactDetailsViewItem>}

          {phoneMobile && <ContactDetailsViewLabel>Mobile: </ContactDetailsViewLabel>}
          {phoneMobile && (
            <ContactDetailsViewItem data-tid-mobile-phone=''>{phoneMobile}</ContactDetailsViewItem>
          )}

          {isDesktop && <ContactDetailsViewLabel>Home: </ContactDetailsViewLabel>}
          {isDesktop && (
            <ContactDetailsViewItem data-tid-home-phone=''>
              {phoneHome || '-'}
            </ContactDetailsViewItem>
          )}

          {isDesktop && <ContactDetailsViewLabel>Work: </ContactDetailsViewLabel>}
          {isDesktop && (
            <ContactDetailsViewItem data-tid-work-phone=''>
              {phoneWork || '-'}
            </ContactDetailsViewItem>
          )}

          {addressResidential && (
            <ContactDetailsViewLabel>Address (Residential):</ContactDetailsViewLabel>
          )}
          {addressResidential && (
            <ContactDetailsViewItem data-tid-address-residential=''>
              {buildAddress(addressResidential)}
            </ContactDetailsViewItem>
          )}

          {addressPostal && <ContactDetailsViewLabel>Address (Postal):</ContactDetailsViewLabel>}
          {addressPostal && (
            <ContactDetailsViewItem data-tid-address-postal=''>
              {buildAddress(addressPostal)}
            </ContactDetailsViewItem>
          )}
        </dl>

        {isInternational ? (
          <NoticeBoxInternational />
        ) : (
          <ButtonBlock
            data-tid-edit-details-button=''
            onClick={onEditDetailsClick}
            disabled={!isIdVerified}
          >
            Edit Details
          </ButtonBlock>
        )}

        {!isIdVerified && (
          <VerifyTextContainerStyled>
            <Text>To edit your contact details, please verify now.</Text>
          </VerifyTextContainerStyled>
        )}
      </ContactDetailsContainer>
    </MainContainer>
  )
}
