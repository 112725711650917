import React from 'react'
import { createSignal, attachDriver, Signal } from 'rwwa-rx-state-machine'
import { recordify, TypedRecord } from 'typed-immutable-record'

/** @deprecated */
export const HidePopup = createSignal('HidePopup')
/** @deprecated */
export const ShowPopup = createSignal<{ content: React.ElementType; contentProps: unknown }>(
  'ShowPopup'
)

export interface PopupState {
  show: boolean
  content: React.ElementType | null
  contentProps: unknown
}

interface PopupStateRecord extends TypedRecord<PopupStateRecord>, PopupState {}
const defaultState = recordify<PopupState, PopupStateRecord>({
  show: false,
  content: null,
  contentProps: null,
})

const popupDriver = (state = defaultState, signal: Signal) => {
  switch (signal.tag) {
    case ShowPopup: {
      if (!signal.data.content) {
        return state
      }
      return state.merge({
        content: signal.data.content,
        contentProps: signal.data.contentProps,
        show: true,
      })
    }
    case HidePopup: {
      return state.merge({ show: false })
    }
    default:
      return state
  }
}
/** @deprecated */
export const state$ = attachDriver<PopupStateRecord>({ path: 'popup', driver: popupDriver })
