import React, { lazy, Suspense, StrictMode } from 'react'
import { Spinner } from '@mobi/component-library/Common/Spinner'
import { useDisableAppHeaderRender } from '@core/Areas/AppHeader/hooks/useDisableAppHeaderRender'
import { useFeature } from '@core/Utils/hooks'

type SignUpDesign = 'unknown' | 'old' | 'new'

const OldSignUpPage = lazy(() => import('./Old/SignUpPage'))
const NewSignUpPage = lazy(() => import('@mobi/account/Areas/SignUp/Components/SignUpPage'))

export const SignUp = () => {
  const signUpDesign = useFeature('SIGNUP_DESIGN') as SignUpDesign
  useDisableAppHeaderRender()

  if (signUpDesign === 'unknown') {
    return <Spinner key='signup-loader' />
  }

  const SignUpPage = signUpDesign === 'new' ? NewSignUpPage : OldSignUpPage

  return (
    <StrictMode>
      <Suspense fallback={<Spinner key='signup-loader' />}>
        <SignUpPage />
      </Suspense>
    </StrictMode>
  )
}
