export type Tabs = 'pending' | 'results' | 'transactions' | 'rebet'
export type SortOrder = 'eventStart' | 'betPlaced'
export type TicketStatus =
  | 'Won'
  | 'Cancelled'
  | 'Pending'
  | 'NoPayout'
  | 'Abandoned'
  | 'Refunded'
  | 'Scratched'
  | 'Partially Refunded'
  | null
export type BetStatus = TicketStatus | 'Lost'

// ========
// API DATA
// ========

interface ResponseDataShared {
  pageNumber: number
  isFinalPage: boolean
  sortedIds: string[]
  _nextPage: string | null
}

export interface TicketResponseData extends ResponseDataShared {
  sortedIds: `ticketsById.${string}`[]
  ticketsById: {
    [k: string]: Ticket | FauxTicketTransaction
  }
}

export interface TransactionsResponseData extends ResponseDataShared {
  sortedIds: `transactionsById.${string}`[]
  transactionsById: {
    [k: string]: Transaction | FauxTicketTransaction
  }
}

export interface FauxTicketTransaction {
  message: string
  timestamp: string
}

export interface Ticket {
  allUpStartTimes: string[] | null
  bettingInformation:
    | {
        title: string
        detail: string[]
      }[]
    | null
  combinedDividend: number | null
  detailHeader: string
  eventStartDateTime: string
  flexPercent: string
  formula: {
    formulaString: string
    lines: {
      key: string
      combos: number
      stake: number
      combinedStake: number
      combinedReturn: number
    }[]
    totalCombinedStake: number
    projectedPay: number
  } | null
  hideStartDateInHeader: boolean
  investment: {
    total: number
    bonusBet: number | null
    bonusType: BonusType | null
    lines: {
      key: string
      displayName: string
      totalValue: number
      projectedPay: number | null
      betValue: number
      count: number
    }[]
  }
  isExpandable: boolean
  isSpecial: boolean
  legs: TicketLeg[]
  numCombs: number | null
  numLegs: number | null
  payValue: number
  planSeq: number
  productName: string
  return: number | null
  shortProductName: string
  specialInformation:
    | {
        title: string
        description: string
        isPowerPlay: boolean
        projectedReward: number
        specialTypeCode: string
        insuranceReward: number | null
      }[]
    | null
  sportName: string | null
  status: 'Pending' | 'Cancelled' | 'Resolved'
  ticketSequence: number
  timestamp: string
  updatedEventStartDateTime: string
  externalBetId?: number | null
}

export interface TicketLeg {
  header: string | null
  eventName: string
  eventNameShowOnExpanded: boolean
  eventNameLine2: string | null
  runners: string | null
  advertisedStartTime: string
  meetingName: string
  raceNumber: number
  selections: {
    header: string | null
    expandedHeader: string | null
    expandedHeaderSecondLine: string | null
    favouriteCombination?: string | null
    oneLineHeader: boolean
    selectionDetails: {
      selectionString: string | null
      acceptorDetails: {
        isScratched?: boolean
        number: number
        name: string
        selectionNumber: number | null
        srmPosition: number | null
      }[]
    }[]
    price: number | null
    raceNumber: number
    raceResult?: string | null
    raceStatusSeq?: number
  }[]
  displayableEventDate: string | null
  return: string | null
  betType: string | null
  fixtureId: string
  fixtureDate: string
  sportName: string | null
  result: 'WIN' | 'LOSE' | 'SCR' | '-' | 'ABD' | 'Refund' | 'LINE' | undefined | null
  status: 'A' | 'S' | null
  price?: number
  selectionString?: string | null
  propositionSeq: number
  eventSeq: number
  isLive?: boolean
  odds?: number
  handicap?: number | null
  eventType?: string
  propositionExternalId?: string
}

export interface Transaction {
  bankAccountNumber: string
  bpayReferenceNumber: string
  description: string
  isCanceled: boolean
  location: string
  maskedCcNumber: string
  name: string
  timestamp: string
  transactionSequence: number
  tranTransSeq: number
  value: number
  withdrawalCancelable: boolean
  bonusType: BonusType | null
}

export type CancellationState = 'initial' | 'waiting' | 'error' | 'completed'

export type BonusType = 'BonusCash' | 'AccountCredit' | 'Other' | 'BonusBet'

export enum RaceStatusSequence {
  Created = 1,
  Open = 2,
  Closed = 3,
  ResultsLoaded = 4,
  Released = 5,
  AbandonedClosed = 6,
  AbandonedReleased = 7,
  ClosedDelayed = 8,
  ResultsLoadedDelayed = 9,
  PartiallyReleased = 10,
  PartialResultsLoaded = 11,
  PartialResultsLoadedDelayed = 12,
  Finalised = 13,
  AbandonedFinalised = 14,
}
