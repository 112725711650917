import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { font, spacing } from '@mobi/component-library/Theme/Common'
import { CopyField, CustomerServiceCentre, InfoBox } from '@mobi/component-library/Common/V2'
import type { PanelProps } from '../../types'
import { selectDepositFlow } from '../../../Store'
import { track } from '../../../../../Utils'

type Props = Pick<PanelProps, 'accountNumber' | 'initialData'> & {
  isPreCommitmentLimitSet: boolean
}

const minBPayDeposit: number = 20
const maxBPayDeposit: number = 500_000
const formattedMaxBPayDeposit: string = maxBPayDeposit.toLocaleString()

export const BPayPanel = ({ accountNumber, initialData, isPreCommitmentLimitSet }: Props) => {
  const flow = useSelector(selectDepositFlow)
  const containerRef = useRef<HTMLDivElement>(null)

  const isOldAccountNumbers = accountNumber && accountNumber <= 2176659

  return (
    <div ref={containerRef}>
      <BPayPanelStyled>
        <dt>Biller Code</dt>
        <dd>
          <CopyField
            value='1214'
            onCopied={() => {
              track('deposit_copy_content', {
                accountNumber,
                paymentMethod: 'BPay',
                copiedContent: 'Biller Code',
                copiedLocation: 'BPay',
                depositVariant: flow,
              })
            }}
          />
        </dd>
        <dt>Reference</dt>

        <dd>
          {isOldAccountNumbers ? (
            <CustomerServiceCentre suffixText='on your BPay reference.' />
          ) : (
            <CopyField
              value={accountNumber?.toString() ?? ''}
              onCopied={() => {
                track('deposit_copy_content', {
                  accountNumber,
                  paymentMethod: 'BPay',
                  copiedContent: 'Account Number',
                  copiedLocation: 'BPay',
                  depositVariant: flow,
                })
              }}
            />
          )}
        </dd>
        {initialData.depositLimits.maximum >= minBPayDeposit && (
          <>
            <dt>Minimum Deposit</dt>
            <dd style={{ fontVariant: 'tabular-nums' }}>${minBPayDeposit}</dd>
          </>
        )}
        {(!isPreCommitmentLimitSet || initialData.depositLimits.maximum > maxBPayDeposit) && (
          <>
            <dt>Daily Limit</dt>
            <dd style={{ fontVariant: 'tabular-nums' }}>${formattedMaxBPayDeposit}</dd>
          </>
        )}
      </BPayPanelStyled>

      <InfoBox role='status' color='grey' size='md'>
        BPay deposits are fee-free and usually reach your TABtouch account within 1-3 days.
      </InfoBox>
    </div>
  )
}

BPayPanel.displayName = 'BPayPanel'

const BPayPanelStyled = styled.dl({
  alignItems: 'center',
  display: 'grid',
  fontFamily: font.family.primary,
  gap: spacing.sm,
  gridAutoRows: '5rem',
  gridTemplateColumns: 'minmax(0, max-content) 1fr',
  margin: 0,
  marginBottom: spacing.md,
  ...font.size.lg,

  dt: {
    fontWeight: font.weight.semibold,
  },

  dd: {
    margin: 0,
  },
})
