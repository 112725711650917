export enum DepositUrl {
  CreatePaymentMethod = '/$_/accountDeposit/payment-methods/createPaymentMethod',
  SetDefaultPaymentMethod = '/$_/accountDeposit/payment-methods/setDefault',
  DeletePaymentMethod = '/$_/accountDeposit/payment-methods/deletePaymentMethod',
  InitialData = '/$_/accountDeposit/payment-methods/initialData',
  GeneratePaymentNonce = '/$_/accountDeposit/payment-methods/generatePaymentMethodNonce',
  Deposit = '/$_/accountDeposit/payment-methods/deposit',
}

export const WithdrawUrl = {
  withdrawDetails: (accountNumber: number) =>
    `/api/bet-account/accounts/${accountNumber}/withdraw-details`,
  withdraw: '/api/account/withdraw',
}

export enum SignUpUrl {
  SavePrecommitments = '/$_/api/account/updatePrecommitments',
  UpdateDepositLimitAnniversary = '/$_/api/account/updateDepositLimitAnniversary',
  GetContactDetails = '/api/contactDetails',
  CountryList = '/$_/api/SignUp/GetCountryList',
}

type AccountUrls = DepositUrl | keyof typeof WithdrawUrl | SignUpUrl

type Parser<TResponse> = (json: string) => TResponse

const defaultParser = <TResponse>(json: string) => json as TResponse

export const get = async <TResponse = string>(
  url: AccountUrls | string,
  parser: Parser<TResponse> = defaultParser
): Promise<TResponse> => {
  const response = await fetch(url, {
    method: 'GET',
  })
  if (response.ok) {
    const json = await response.json()
    return parser(json)
  } else {
    throw { status: response.status, statusText: response.statusText }
  }
}

export const post = async <TRequest, TResponse = string>(
  url: AccountUrls | string,
  body: TRequest,
  parser: Parser<TResponse> = defaultParser
) => {
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    credentials: 'include',
    headers: JSONContentHeaders,
  })
  if (response.ok) {
    if (response.status === 204) {
      return undefined as TResponse
    }

    const json = await response.json()
    return parser(json)
  } else {
    throw { status: response.status, statusText: response.statusText }
  }
}

export const put = async <TRequest, TResponse = string>(
  url: AccountUrls | string,
  body: TRequest,
  parser: Parser<TResponse> = defaultParser
) => {
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(body),
    credentials: 'include',
    headers: JSONContentHeaders,
  })

  if (response.ok) {
    if (response.status === 204) return null

    const json = await response.json()
    return parser(json)
  } else {
    throw { status: response.status, statusText: response.statusText }
  }
}

/**
 * Constants
 */

const JSONContentHeaders = {
  'Content-type': 'application/json; charset=UTF-8',
  Accept: 'application/json',
}
