import React from 'react'
import { fetchFromApi } from '@classic/Foundation/Services/ApiService'
import { RegisterToast } from '@core/Components/Toast/ToastDriver'
import { useAppDispatch, useAppSelector } from '@core/Store/hooks'
import { setEntitlementStatus, setCloseSkyVideoPlayer } from '@core/Areas/SkyVideoPlayer/Store'

interface VisionEntitlementCheckRes {
  CallToAction: null
  Condition: string
  Entitlement: { BamEntitlementId: string }
  HiddenReasonText: string | null
  IsEntitled: boolean
  ReasonText: string | null
}

export const useEntitlement = () => {
  const dispatch = useAppDispatch()
  const isEntitled = useAppSelector(state => state.skyVideoPlayer.isEntitled)

  React.useEffect(() => {
    if (typeof isEntitled === 'boolean') {
      return
    }

    fetchFromApi('/Vision/EntitlementCheck')
      .then(res => res.json())
      .then(({ IsEntitled, ReasonText }: VisionEntitlementCheckRes) => {
        const notEntitledMessage = ReasonText || 'Not authorised to view Vision'
        // Delay Close (Android 10 crashes if too soon)
        if (!IsEntitled) {
          setTimeout(() => {
            dispatch(setCloseSkyVideoPlayer())
            displayVisionNotEntitledMessage(notEntitledMessage)
          }, 2000)
        }

        // Update Vision State
        dispatch(
          setEntitlementStatus({
            isEntitled: IsEntitled,
            notEntitledMessage: !IsEntitled ? notEntitledMessage : undefined,
          })
        )
      })
  }, [dispatch, isEntitled])
}

export function displayVisionNotEntitledMessage(message: string) {
  RegisterToast({
    id: 'VisionNotEntitled',
    message: message,
    position: 'bottom',
    type: 'error',
    timeout: 0,
  })
}
