import React from 'react'
import { PopupContainerCenteredStyled, PopupCenteredStyled } from './Popup.styles'
import { observeImmutable } from '../HOCs/observe'
import { state$, HidePopup, PopupState } from './driver'

function PopupComponent({
  content,
  contentProps,
}: {
  content: React.ElementType
  contentProps: Record<string, unknown>
}) {
  const Content = content
  const closeHandler = () => {
    HidePopup()
  }
  const stopClickBubblingUp = (event: React.MouseEvent) => {
    event.stopPropagation()
  }
  return (
    <PopupContainerCenteredStyled onClick={closeHandler}>
      <PopupCenteredStyled onClick={stopClickBubblingUp}>
        <Content {...contentProps} />
      </PopupCenteredStyled>
    </PopupContainerCenteredStyled>
  )
}

/** @deprecated */
export const Popup: React.ComponentClass = observeImmutable(state$, ({ record }) => {
  const { show, content, contentProps } = record.toJS() as PopupState
  if (!show || !content) {
    return null
  }
  return <PopupComponent content={content} contentProps={contentProps as Record<string, unknown>} />
})
