import type { BetSelection, BetSlipItem } from '@mobi/betslip/types'
import { isToteSelection } from '@mobi/betslip/helpers/typeGuards'
import { trackLoadedBetBetslip } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { promptUserAndReturnDecision } from '@core/Utils/betting/loadBet/Components/PromptBetslipChange/helpers'
import { buildBaseBetSelection } from '../buildBetItem/buildBaseBetSelection'
import { LoadBetDetails, LoadBetErrors } from '../types'
import { store } from '@core/Store'
import { selectBetSlipItems } from '@mobi/betslip/Store/Bets/selectors'
import { addItemsToBetSlip, removeItemFromBetSlipById } from '@mobi/betslip/Store/Bets'
import { createBetSlipItem } from '@mobi/betslip/helpers/createBetSlipItem'
import { getErrorMessage } from './loadBet'
import { setBetSlipNewIsOpen } from '@core/Areas/AppHeader/Store'
import { zip } from '@mobi/utils/functions/zip'
import { QuickbetSelection } from '@core/Areas/Quickbet/signals'

const enum LocalConstants {
  PlaceBetType = 'Place - Fixed',
}

/** NEW BetSlip: handle non-empty betslip, build items and open (Multi ONLY) */
export async function addToBetSlip(
  bets: LoadBetDetails[],
  source?: LoadBetSource,
  // pass in existing bet selection to avoid loading from api again
  prebuiltBets?: QuickbetSelection[] | null
) {
  let whatToDoWithExisting = 'emptyBetslip'

  if (hasExistingBetSlipItems()) {
    whatToDoWithExisting = await promptUserAndReturnDecision()

    switch (whatToDoWithExisting) {
      case 'cancel':
        return { total: bets.length, success: 0 }
      case 'add':
        break
      case 'replace': {
        removeFixedOddsBetslipItems()
        break
      }
    }
  }

  const createBetslipItemResults = await Promise.allSettled<BetSlipItem>(
    zip(bets, prebuiltBets || []).map(async ([bet, qbSelection]) => {
      const item = qbSelection || (await buildBaseBetSelection(bet))
      const betslipItem = createBetSlipItem(item as BetSelection)
      if ('betType' in bet) {
        betslipItem.multiLegBetType = bet.betType === LocalConstants.PlaceBetType ? 'P' : 'W'
      }
      return betslipItem
    })
  )
  const betsReadyForBetslip = createBetslipItemResults
    .filter(isFulfilled)
    .map(result => result.value)

  if (betsReadyForBetslip.length === 0) {
    const isBettingClosedOnBets = createBetslipItemResults
      .filter(isRejected)
      .map(result => result.reason)
      .some(err => getErrorMessage(err) === LoadBetErrors.BettingClosed)
    throw Error(isBettingClosedOnBets ? LoadBetErrors.BettingClosed : LoadBetErrors.Default)
  }

  store.dispatch(addItemsToBetSlip(betsReadyForBetslip))
  store.dispatch(setBetSlipNewIsOpen(true))

  source &&
    trackLoadedBetBetslip({
      source,
      action: whatToDoWithExisting,
      betsToAdd: betsReadyForBetslip.length,
    })

  return { total: bets.length, success: betsReadyForBetslip.length }
}

// =============
// Local Helpers
// =============

function removeFixedOddsBetslipItems() {
  const betSlipItems = selectBetSlipItems(store.getState())
  const toRemove = betSlipItems.filter(item => !isToteSelection(item.selection))
  for (const item of toRemove) {
    store.dispatch(removeItemFromBetSlipById(item))
  }
}

function hasExistingBetSlipItems(): boolean {
  const betSlipItems = selectBetSlipItems(store.getState())
  return betSlipItems.some(item => !isToteSelection(item.selection))
}

function isFulfilled<T>(input: PromiseSettledResult<T>): input is PromiseFulfilledResult<T> {
  return input.status === 'fulfilled'
}
function isRejected<T>(input: PromiseSettledResult<T>): input is PromiseRejectedResult {
  return input.status === 'rejected'
}

type LoadBetSource = 'share-bet' | 'rebet' | 'blackbook'
