import React from 'react'
import { dayjs } from '@mobi/utils/date'
import { CountdownBadge } from '@mobi/component-library/Feedback/CountdownBadge'
import { StatusBadge } from '@mobi/component-library/Common/StatusBadge'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import type { BetStatus, Ticket } from '@core/Areas/AccountActivity/types'
import { getLegStatus, getStatusText } from '@core/Areas/AccountActivity/Components/Ticket/helpers'

export const CountdownStatus: React.FC<{ multiLeg: Ticket['legs'][0] }> = ({ multiLeg }) => {
  const isPreviewMode = React.useContext(TicketDisplayContext).type === 'share-preview'

  const legStatus = getLegStatus(multiLeg.result)

  if (legStatus === 'Pending') {
    const countdownDate = multiLeg.advertisedStartTime
    if (!countdownDate) return null

    if (isPreviewMode) {
      return (
        <StatusBadge color='gray' badgeSize='S'>
          {dayjs(countdownDate).format('D MMM | H:mm')}
        </StatusBadge>
      )
    }
    return <CountdownBadge badgeSize='S' advertisedStartTime={new Date(countdownDate)} />
  }

  const betLegStatus = getBetLegStatus(legStatus)
  return betLegStatus ? (
    <StatusBadge badgeSize='S' color={legStatus === 'Won' ? 'success' : 'neutral'}>
      {betLegStatus}
    </StatusBadge>
  ) : null
}

// =============
// Local Helpers
// =============

function getBetLegStatus(legStatus: BetStatus): string | null {
  if (legStatus === 'Lost') {
    return 'No Payout'
  }

  return getStatusText(legStatus)
}
