/**
 * Pattern to validate an amount
 *
 * This pattern can check for up to 8 digits and 2 decimal places if they are provided
 * Decimal places are optional unless a cent separator is given, from which the
 * decimal places become mandatory
 *
 * @example
 *
 * ```ts
 * amountInputValidationRegex.test('1') // true
 * amountInputValidationRegex.test('12') // true
 * amountInputValidationRegex.test('123') // true
 * amountInputValidationRegex.test('1.23') // true
 * amountInputValidationRegex.test('12.34') // true
 * amountInputValidationRegex.test('123.45') // true
 * amountInputValidationRegex.test('123.') // false
 * amountInputValidationRegex.test('123456789') // false
 * amountInputValidationRegex.test('123.1') // false
 * amountInputValidationRegex.test('$123') // false
 * amountInputValidationRegex.test('🦎') // false
 * amountInputValidationRegex.test('🍺') // false
 * ```
 */
export const amountInputValidationRegex = /^\d{1,8}(?:\.\d{2})?$/
