import React from 'react'
import styled from '@emotion/styled'
import { useMutation } from 'react-query'
import { Constants } from '@mobi/settings'
import type { BetSlipItem } from '@mobi/betslip/types'
import { Tray } from '@mobi/component-library/Overlays/Tray'
import { Button, Icon, InfoBox } from '@mobi/component-library/Common/V2'
import { Toggle } from '@mobi/component-library/Selection&Input/Toggle'
import { colors, font, radius, spacing } from '@mobi/component-library/Theme/Common'
import { getHostHelpers } from '@mobi/betslip/helpers/hostHelpers'
import { BetInfo } from '@mobi/betslip/Components/Common/BetInfo'
import { InlineAlert } from '@mobi/betslip/Components/Common/InlineAlert'
import { SvgLazy } from '@mobi/component-library/Common/Svg/SvgLazy'
import { BetInvestment } from '@mobi/betslip/Components/Singles/Components/SingleItem/Components/BetInvestment'
import { MultiItems } from '@mobi/betslip/Components/Multi/Components/MultiItems'
import tabtouchHandImage from './assets/tabtouch-hand.png'
import { ShareBetContext } from '../../context'

const enum LocalConstants {
  Title = 'My TABTouch Bet',
  MessageDefault = 'Have you got the touch? Check out this bet on TABtouch.\n',
  MessagePending = "Here's hoping I've got the touch! Check out this bet I placed on TABtouch.\n",
}

export const ShareMyBetTray: React.FC<{
  isOpen: boolean
  headerTitle: string
  onDismiss: () => void
  items: BetSlipItem[]
  isBetPlaced: boolean
}> = ({ isOpen, headerTitle, onDismiss, items, isBetPlaced }) => {
  const shareMyBetRef = React.useRef<HTMLDivElement>(null)

  const [shouldShowStakeInfo, setShouldShowStakeInfo] = React.useState(false)

  const handleShareClick = async () => {
    if (!shareMyBetRef.current) return
    const shareMyBetEl = shareMyBetRef.current
    const hostHelpers = getHostHelpers()
    const data = {
      title: LocalConstants.Title,
      message: isBetPlaced ? LocalConstants.MessagePending : LocalConstants.MessageDefault,
    }
    await hostHelpers.handleShareBet(items, data, shareMyBetEl)
  }

  const { mutate: onClick, isLoading, isError } = useMutation(handleShareClick)

  const toggleStakePayoutDetails = () => setShouldShowStakeInfo(curr => !curr)

  const isMultiBet = items.length > 1

  return (
    <Tray isOpen={isOpen} headerTitle={headerTitle} onDismiss={onDismiss}>
      <WrapperStyled>
        <ShareBetContext.Provider value={true}>
          <div ref={shareMyBetRef}>
            <div>
              <img width='205' height='138' src={tabtouchHandImage} />

              <SvgLazy name='TABtouchBrandLogo' color='currentColor' width='11.1rem' />
            </div>

            <div>
              {isBetPlaced && (
                <InlineAlert type='warning' text='PENDING' hasIcon={false} isCenterAlign={true} />
              )}

              <article>
                {isMultiBet ? (
                  <>
                    <MultiItems
                      multiItems={items}
                      isEditable={false}
                      hasValidMultiInvestment={shouldShowStakeInfo}
                    />
                  </>
                ) : (
                  <>
                    <BetInfo item={items[0]} />

                    {shouldShowStakeInfo && <BetInvestment item={items[0]} />}
                  </>
                )}
              </article>
            </div>

            <p>Gamble responsibly. Call Gambling Help on {Constants.GamblingHelpPhoneNumber}</p>
          </div>

          {isBetPlaced && (
            <div>
              Show stake information
              <Toggle
                id='share-bet-cost-toggle'
                isOn={shouldShowStakeInfo}
                onToggle={toggleStakePayoutDetails}
                disabled={false}
                size='md'
              />
            </div>
          )}

          {isError && (
            <InfoBox color='red' size='md'>
              Sorry, we&apos;re unable to create share. Please try again later.
            </InfoBox>
          )}

          <Button
            color='primary'
            size='md'
            isFullWidth
            onClick={() => onClick()}
            disabled={isLoading}
          >
            <Icon name='LineShare02' size='1.6rem' color={colors.white} /> Share Bet
          </Button>
        </ShareBetContext.Provider>
      </WrapperStyled>
    </Tray>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.sm,

  '& > div:nth-of-type(1)': {
    paddingBottom: spacing.big,
    background: colors.studio[600],
    borderRadius: radius.lgx1,

    // Branding Header
    '& > div:nth-of-type(1)': {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: spacing.smx1,

      svg: { maxWidth: '11.1rem' },

      img: {
        position: 'absolute',
        bottom: '100%',
        right: '50%',
        width: '205px',
        height: '138px',
        transform: 'translate(-3.5rem, 4.3rem)',
      },
    },

    // Bet Area
    '& > div:nth-of-type(2)': {
      backgroundColor: colors.white,
      borderRadius: radius.lgx1,
      fontFamily: font.family.primary,
      margin: spacing.md,
      overflow: 'hidden',

      article: {
        display: 'flex',
        flexDirection: 'column',
        gap: spacing.sm,
        padding: spacing.sm,
      },
    },

    p: {
      margin: 0,
      paddingBottom: spacing.md,
      fontFamily: font.family.primary,
      fontSize: font.size.base.fontSize,
      fontWeight: font.weight.regular,
      lineHeight: font.size.base.lineHeight,
      textAlign: 'center',
      color: colors.studio[200],
    },
  },

  //Stake Toggle Area
  '& > div:nth-of-type(2)': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: font.family.primary,
    fontSize: font.size.md.fontSize,
    fontWeight: font.weight.medium,
    lineHeight: font.size.md.lineHeight,
    letterSpacing: font.size.md.letterSpacing,
  },
})
