import { ObservableStarter } from '../Model/Observables/ObservableStarter'
import { IRaceCodeBuilder } from './IRaceCodeBuilder'
import MissingStarterException from '../Exceptions/MissingStarterException'
import ProvDivPoolMapper from './ProvDivPoolMapper'
import SilkImageMapper from '../Mapping/SilkImageMapper'
import { IObservableStarter } from '../Model/Observables/IObservableStarter'
import ObservableFixedFlucs from '../Model/Observables/ObservableFixedFlucs'
import { MarketMover } from '../Model/MarketMovers'
import ObservableSameRaceMultiPrices from '../Model/Observables/ObservableSameRaceMultiPrices'
import {
  FixedFlucsDataTransferObject,
  MarketMoversDataTransferObject,
  SameRaceMultiPrices,
  StarterDataTransferObject,
} from '@mobi/api-types'

export abstract class BaseRaceCodeBuilder implements IRaceCodeBuilder {
  addBaseInformation(model: ObservableStarter, dto: StarterDataTransferObject): ObservableStarter {
    if (!dto) throw new MissingStarterException('Missing data for an individual starter')

    model.type(dto.Type)
    model.name(dto.Name)
    model.number(dto.Number)
    model.isScratched(dto.IsScratched)
    model.isScratchedToteAndFob(dto.IsScratchedToteAndFob)
    model.isFixedOddsStarter(dto.IsFixedOddsStarter)
    model.isFavourite(dto.IsFavourite)
    model.starterPersonName(dto.StarterPersonName)
    model.trainerName(dto.TrainerName as string)
    model.isEmergency(dto.IsEmergency)
    model.emergencyNum(dto.EmergencyNumber)
    model.displayPlaceDividend(dto.DisplayPlaceDividend)
    model.displayWinDividend(dto.DisplayWinDividend)
    model.hasPlacePool(dto.HasPlacePool)
    model.isToteEnabled(dto.IsToteEnabled)
    model.scratchType(dto.ScratchType)
    model.lastFour(dto.LastFour)
    model.rating(dto.Rating)
    model.isRiderChanged(dto.IsRiderChanged)
    model.computeEmRes()
    model.hasFixedOdds(dto.HasFixedOdds)
    model.isFormAvailable(!dto.IsFormDataEmpty)

    if (dto.HasFixedOdds && model.fixedOddsStarterInfo) {
      const starter = model.fixedOddsStarterInfo

      // TODO: Remove FixedOddsStarterInfo after it's been removed from the API
      const starterDto = dto.FixedOddsInfo ?? dto.FixedOddsStarterInfo
      starter.hasWinDividend(starterDto.HasWinDividend)
      starter.hasPlaceDividend(starterDto.HasPlaceDividend)
      starter.displayPlaceDividend(starterDto.DisplayPlaceDividend)
      starter.displayWinDividend(starterDto.DisplayWinDividend)
      starter.isFavourite(starterDto.IsFavourite)
      starter.isScratched(starterDto.IsScratched)
      starter.isSuspended(starterDto.IsSuspended)
      starter.scratchedTime(starterDto.ScratchedTime)
      starter.winDividendDeduction(starterDto.WinDividendDeduction)
      starter.placeDividendDeduction(starterDto.PlaceDividendDeduction)
      starter.playerNo(starterDto.PlayerNo)
      starter.hasPlacePool(starterDto.HasPlacePool)
      starter.fixedFlucs = BaseRaceCodeBuilder.mapFixedFlucs(starterDto.FixedFlucs)
      starter.propositionSequence(starterDto.PropositionSequence)
      starter.sameRaceMultiPrices = BaseRaceCodeBuilder.mapSameRaceMultiPrices(
        starterDto.SameRaceMultiPrices
      )
    } else {
      model.hasFixedOdds(false)
      if (dto.IsScratched) {
        model.isScratchedToteAndFob(dto.IsScratched)
      }
    }

    ProvDivPoolMapper.map(dto.ProvDivPools, model)

    if (dto.SilkImages) {
      SilkImageMapper.map(dto.SilkImages, model)
    }

    model.marketMovers(BaseRaceCodeBuilder.mapMarketMovers(dto.MarketMovers))
    model.hasMarketMovers(dto.HasMarketMovers ? dto.HasMarketMovers : false)

    return model
  }

  abstract build(dto: StarterDataTransferObject): IObservableStarter

  private static mapSameRaceMultiPrices(dto?: SameRaceMultiPrices): ObservableSameRaceMultiPrices {
    const result = new ObservableSameRaceMultiPrices()
    if (dto) {
      result.win(dto.Win)
      result.top2(dto.Top2)
      result.top3(dto.Top3)
      result.top4(dto.Top4)
      result.isFavourite(dto.IsFavourite)
      result.isSuspended(!dto.Win)
      result.srmTopLimit(dto.SrmTopLimit)
    }
    return result
  }

  private static mapFixedFlucs(dto: FixedFlucsDataTransferObject): ObservableFixedFlucs {
    const result = new ObservableFixedFlucs()
    if (dto) {
      result.winOpeningDividend(dto.WinOpeningDividend)
      result.winHighDividend(dto.WinHighDividend)
      result.winLowDividend(dto.WinLowDividend)
      result.winDividends(dto.WinDividends)
    }
    return result
  }

  private static mapMarketMovers(dto: MarketMoversDataTransferObject[]): MarketMover[] {
    const result: MarketMover[] = []
    if (dto) {
      dto.map(record => {
        result.push({
          MinutesToAst: record.MinutesToAst,
          Dividend: record.Dividend,
        })
      })
    }
    return result
  }
}
