import React from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Currency, toMoney } from '@mobi/utils/money'
import { spacing } from '@mobi/component-library/Theme/Common'
import { Checkbox } from '@mobi/component-library/Common/V2/Inputs/Checkbox'
import type { BetSlipItem } from '@mobi/betslip/types'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import {
  isFobSelection,
  isFobSportsSelection,
  isStartingPriceSelection,
} from '@mobi/betslip/helpers/typeGuards'
import { selectActiveInvestment } from '@mobi/betslip/Store/Workflow/selectors'
import { toggleIsEachWay } from '@mobi/betslip/Store/Bets'
import { SummaryLine } from '@mobi/betslip/Components/Common/SummaryLine'
import { Investment, InvestmentsWrapperStyled } from '@mobi/betslip/Components/Common/Investment'
import { calculateEstReturnSingleItem } from '@mobi/betslip/helpers/calculator/singles'
import { calculateBoosts } from '@mobi/betslip/helpers/calculator/misc'
import { SuperPicks } from './Components/SuperPicks'

export const FobInvestment: React.FC<{ item: BetSlipItem }> = ({ item }) => {
  const dispatch = useDispatch()

  const activeInvestment = useAppSelector(selectActiveInvestment)
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)
  const isBusy = useAppSelector(state => state.betslip.workflow.isBusy)
  const isLoggedIn = useAppSelector(state => state.betslip.workflow.isHostUserLoggedIn)
  const canShowInducements = useAppSelector(state => state.betslip.workflow.canShowInducements)

  if (!isFobSelection(item.selection)) return null

  const { winPrice, placePrice } = item.selection
  const isItemInputActive = activeInvestment?.itemId === item.id

  const [winBoost, placeBoost] = calculateBoosts(item.selectedSuperPickOffer ?? null)

  const hasWinPrice = !!winPrice
  const hasPlacePrice = !!placePrice

  const isStartingPriceBet = isStartingPriceSelection(item.selection)
  const isSportsSelection = isFobSportsSelection(item.selection)
  const isEditable = !item.receipt && workflowStatus === 'ready'
  const shouldHideWinPlacePrice = workflowStatus == 'proposed' || workflowStatus == 'bets-placed'

  const isSuperPickAvailable =
    canShowInducements && isLoggedIn && !!item.specialOffers && item.specialOffers.length > 0

  if (isSportsSelection) {
    return (
      <InvestmentsWrapperStyled>
        <Investment
          value={item.investment.win.value}
          itemId={item.id}
          investmentType='Win'
          label='Cost'
          // isBonusBet={!!investment.win.isBonusBet}
          isEditable={isEditable}
          isDisabled={isBusy}
          isActive={isItemInputActive && activeInvestment.investmentType === 'Win'}
        />

        <Investment
          value={calculateEstReturnSingleItem(item)}
          itemId={item.id}
          label='Potential Payout'
          investmentType='ReverseStake'
          isEditable={isEditable}
          isDisabled={isBusy}
          isActive={isItemInputActive && activeInvestment.investmentType === 'ReverseStake'}
        />
      </InvestmentsWrapperStyled>
    )
  }

  return (
    <WrapperStyled>
      {item.isEachWayAvailable ? (
        <InvestmentsWrapperStyled>
          <Investment
            // TODO:
            // shouldHighlight={winBoost > 0}
            value={item.investment.win.value}
            itemId={item.id}
            label={
              item.isEachWayAvailable
                ? item.isEachWay && isEditable
                  ? 'Win & Place'
                  : 'Win'
                : 'Cost'
            }
            investmentType='Win'
            isEditable={isEditable}
            isDisabled={isBusy}
            // isBonusBet={!!investment.win.isBonusBet}
            isActive={isItemInputActive && activeInvestment.investmentType === 'Win'}
          />
          {!isEditable && item.isEachWay && (
            <Investment
              value={item.investment.place.value}
              itemId={item.id}
              label='Place'
              investmentType='Place'
              isEditable={isEditable}
              isDisabled={isBusy}
              // isBonusBet={!!investment.win.isBonusBet}
              isActive={isItemInputActive && activeInvestment.investmentType === 'Place'}
            />
          )}
          {isEditable && (
            <SummaryLine
              left='Each-Way'
              right={
                <Checkbox
                  label=''
                  name={item.id}
                  checked={item.isEachWay}
                  onChange={() =>
                    dispatch(toggleIsEachWay({ id: item.id, isEachWay: !item.isEachWay }))
                  }
                />
              }
            />
          )}
        </InvestmentsWrapperStyled>
      ) : (
        <InvestmentsWrapperStyled isStacked={isEditable}>
          {(hasWinPrice || isStartingPriceBet) && (
            <Investment
              // TODO:
              // shouldHighlight={winBoost > 0}
              key={`${item.id}.Win${winBoost ? '.Boost' : ''}`}
              value={item.investment.win.value}
              itemId={item.id}
              investmentType='Win'
              display='stacked'
              isEditable={isEditable}
              isDisabled={isBusy}
              // isBonusBet={!!investment.win.isBonusBet}
              isActive={isItemInputActive && activeInvestment.investmentType === 'Win'}
              price={
                !shouldHideWinPlacePrice ? new Currency(winPrice).add(winBoost).value : undefined
              }
              isBoostedPrice={winBoost > 0}
            />
          )}

          {hasPlacePrice && (
            <Investment
              // TODO:
              // shouldHighlight={winBoost > 0}
              key={`${item.id}.Place${placeBoost ? '.Boost' : ''}`}
              value={item.investment.place.value}
              itemId={item.id}
              investmentType='Place'
              display='stacked'
              isEditable={isEditable}
              isDisabled={isBusy}
              // isBonusBet={!!investment.place.isBonusBet}
              isActive={isItemInputActive && activeInvestment.investmentType === 'Place'}
              price={
                !shouldHideWinPlacePrice
                  ? new Currency(placePrice).add(placeBoost).value
                  : undefined
              }
              isBoostedPrice={placeBoost > 0}
            />
          )}
        </InvestmentsWrapperStyled>
      )}

      {!isStartingPriceSelection(item.selection) && (
        <SummaryLine left='Potential Payout' right={toMoney(calculateEstReturnSingleItem(item))} />
      )}

      {isSuperPickAvailable && (
        <SuperPicks
          id={item.id}
          specialOffers={item.specialOffers}
          selectedSuperPickOffer={item.selectedSuperPickOffer}
          winPrice={winPrice}
          placePrice={placePrice || 0}
          investment={item.investment}
        />
      )}
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.smx1,
})
