import React, { useRef } from 'react'
import { GridCell } from '@mobi/component-library/Common/Grid'
import { observeImmutable } from '@core/Components/HOCs/observe'
import { LoadingPlaceholder } from '@mobi/component-library/Common/LoadingPlaceholder'
import {
  state$ as userState$,
  Campaign,
  UserAccountState,
  UserAccountStateRecord,
} from '@core/State/UserAccount/userAccountDriver'
import { FaqContainerStyled, FaqLinkStyled, NothingToShowStyled } from '../BetOfferPage.styles'
import { BetOfferItem } from './BetOfferItem'
import { trackOptimoveEvent } from '@core/Services/Optimove/optimove'

type BetOffersProps = Pick<UserAccountState, 'isLoggedIn' | 'campaigns'> & {
  handleCampaignClick: (campaign: Campaign) => void
}

export const BetOffersInternal = (data: BetOffersProps): JSX.Element | null => {
  const [loadFailed, setLoadFailed] = React.useState(false)
  const timeoutRef = useRef<NodeJS.Timeout>()

  React.useEffect(() => {
    trackOptimoveEvent({
      eventName: 'visited_and_has_bonus_offer',
      data: { has_bonus_offer: !!(data.campaigns && data.campaigns.count() > 0) },
    })
  }, [data.campaigns])

  React.useEffect(() => {
    setLoadFailed(false)

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [data.campaigns])

  if (!data.isLoggedIn) {
    return null
  }

  if (!data.campaigns || data.campaigns.count() === 0) {
    if (loadFailed) {
      return <NothingToShowStyled data-tid-nooffers>No offers available.</NothingToShowStyled>
    }

    timeoutRef.current = setTimeout(() => {
      setLoadFailed(true)
    }, 5000)

    return (
      <GridCell padding='1rem' data-tid-loading>
        <LoadingPlaceholder width='100%' height='4rem' />
      </GridCell>
    )
  }

  const offers = data.campaigns
    .toArray()
    .map(campaign => (
      <BetOfferItem
        key={campaign.id}
        data-tid-campaign-entry
        campaign={campaign}
        handleCampaignClick={data.handleCampaignClick}
      ></BetOfferItem>
    ))

  return (
    <div data-tid-bonus-detail-list>
      {offers}
      {offers && (
        <FaqContainerStyled>
          <FaqLinkStyled>
            <a
              href='https://help.tabtouch.com.au/knowledge-base/bonus-offers-faq/'
              target='_blank'
              rel='noreferrer'
            >
              Frequently Asked Questions
            </a>
          </FaqLinkStyled>
        </FaqContainerStyled>
      )}
    </div>
  )
}

export const BetOffers = observeImmutable<
  UserAccountStateRecord,
  { handleCampaignClick: (campaign: Campaign) => void }
>(userState$, ({ record, handleCampaignClick }) => {
  return (
    <BetOffersInternal
      isLoggedIn={record.isLoggedIn}
      campaigns={record.campaigns}
      handleCampaignClick={handleCampaignClick}
    />
  )
})
