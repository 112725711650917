import * as ko from 'knockout'
import { inject, injectable } from 'inversify'
import ObservableDogStarter from '@classic/Betting-v2/Model/Observables/ObservableDogStarter'
import { BettingInformation } from '@classic/Betting-v2/Model/BettingInformation'
import ObservableRaceKey from '@classic/Betting-v2/Model/Observables/ObservableRaceKey'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import type { IDogStarterViewModel } from './IDogStarterViewModel'
import BaseStarterViewModel from './../BaseStarterViewModel'
import { MatchedFieldSummary } from '@core/Areas/Racing/Components/FieldSummary/MatchedFieldSummary'

@injectable()
export class DogStarterViewModel extends BaseStarterViewModel implements IDogStarterViewModel {
  public MatchedFieldSummary: typeof MatchedFieldSummary
  constructor(@inject('IEventAggregator') eventAggregator: IEventAggregator) {
    super(eventAggregator)
    this.MatchedFieldSummary = MatchedFieldSummary
  }

  init(params: {
    data: ObservableDogStarter
    raceNumber: ObservableRaceKey
    context: { bettingContext: BettingInformation }
    numberOfStartersInRace: ko.Computed<number>
    doubleProvDivAllowed: ko.Observable<boolean>
    quaddieProvDivAllowed: ko.Observable<boolean>
    isRaceClosed: ko.Observable<boolean>
  }) {
    super.initBase(
      params.data,
      params.raceNumber,
      params.context.bettingContext,
      'Dogs',
      params.numberOfStartersInRace,
      params.doubleProvDivAllowed,
      params.quaddieProvDivAllowed,
      params.isRaceClosed
    )

    this.stripes = ko.observableArray<number>([])
    for (let i = 1; i <= params.data.stripeCount(); ++i) {
      this.stripes.push(i)
    }
    this.stripeClass = stripe => 'stripe-' + stripe
  }

  public stripes!: ko.ObservableArray<number>
  public stripeClass!: (arg: number) => string
}
