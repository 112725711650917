import React from 'react'
import { PrecommitmentBetLimit } from './PrecommitmentBetLimit'
import { PrecommitmentCreditCardBlock } from './PrecommitmentCreditCardBlock'
import { PrecommitmentInfo } from './PrecommitmentInfo'
import type { PrecommitmentResponse } from '@mobi/account/Areas/SignUp/Utils/SignUpApi'
import { DEFAULT_COOLING_OFF_PERIOD_DAYS } from './constants'

export const PrecommitmentForm: React.FC<{
  data: PrecommitmentResponse | undefined
  hasDataFetchFailed: boolean
}> = ({ data, hasDataFetchFailed }): JSX.Element => (
  <>
    <PrecommitmentBetLimit data={data} hasDataFetchFailed={hasDataFetchFailed} />
    <PrecommitmentCreditCardBlock data={data} hasDataFetchFailed={hasDataFetchFailed} />
    <PrecommitmentInfo
      coolingOffPeriod={data?.CoolingOffPeriodDays || DEFAULT_COOLING_OFF_PERIOD_DAYS}
    />
  </>
)
