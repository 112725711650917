import React from 'react'
import { layering } from '@mobi/component-library/Theme/Common'
import { OverlayOpen } from '@core/Components/Overlay'
import { trackOptimoveEvent } from '@core/Services/Optimove/optimove'
import { Deposit, type DepositProps } from './Deposit'
import { type DepositFlow } from '@mobi/account/Areas/Deposit/typings/types'

export const openDepositModal = (flow: DepositFlow) =>
  openDeposit({
    asModal: true,
    flow,
  })

export function openDeposit(props?: DepositProps) {
  if (props?.asModal) {
    // `props` can no longer be undefined here as `asModal` must be `true`
    OverlayOpen({
      Component: () => <Deposit asModal={props.asModal} flow={props.flow} />,
      layer: layering.overlayHigh,
    })
  } else {
    if (window.location.hash !== '#deposit') {
      window.location.hash = '#deposit'
    }
  }

  trackOptimoveEvent({
    eventName: 'set_page_visit',
    data: { customURL: '/', pageTtile: 'DEPOSIT' },
  })
}
