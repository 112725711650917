import React from 'react'
import styled from '@emotion/styled'
import { callCenterPhoneNumber } from '@mobi/utils'
import { hexColors } from '@mobi/settings'
import { isReactNativeApp, AppVersion } from '@mobi/web-native-comms/web'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { Icon } from '@mobi/component-library/Common/Icon'
import { InputField } from '@mobi/component-library/Common/Input'
import { SpinnerInlineStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { NoticeBoxSingle, NoticeBoxTypes } from '@core/Components/NoticeBox'
import {
  trackLoginForgotAccountNumber,
  trackLoginForgotPassword,
} from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { LoginFieldOptionsStyled } from '../../Login.styles'
import type { useLoginState } from '../../hooks/useLoginState'
import { trackSignUpClick } from '@core/Areas/Account/SignUp/analytics'
import { BiometricsToggle } from '@mobi/account/Areas/Verification/Biometrics/Components/BiometricsToggle'
import { useBiometricsStatus } from '@mobi/account/Areas/Verification/Biometrics/Hooks/useBiometricsStatus'
import { spacing } from '@mobi/component-library/Theme/Common'

export const LoginMain = ({ state, handlers }: ReturnType<typeof useLoginState>) => {
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false)
  const { isBiometricsStatusReady, isBiometricsEnabled } = useBiometricsStatus()
  const [isBiometricsToggleOn, setIsBiometricsToggleOn] = React.useState(false)

  const isValid = !!state.accountNumber && !!state.password
  const shouldShowKeepMeLoggedIn = isReactNativeApp
  const shouldDisableSubmit =
    state.isBusy ||
    !!state.errorMessage ||
    !isValid ||
    (isReactNativeApp && !isBiometricsStatusReady)
  const reactNativeAppVersion = +(AppVersion?.split('.', 1)[0] ?? 0)

  React.useEffect(() => {
    if (isBiometricsStatusReady) {
      setIsBiometricsToggleOn(isBiometricsEnabled)
    }
  }, [isBiometricsStatusReady, isBiometricsEnabled])

  const handleRecoverAccountNumber = () => {
    handlers.setWorkflow('Account Number Recovery')
    trackLoginForgotAccountNumber()
  }
  const handleRecoverPassword = () => {
    handlers.setWorkflow('Password Reset')
    trackLoginForgotPassword(state.accountNumber)
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()
    if (!shouldDisableSubmit) handlers.login()
  }

  const handleEnterKey: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (!shouldDisableSubmit && (e.key === 'Enter' || e.keyCode === 13)) handlers.login()
  }

  return (
    <form onSubmit={handleSubmit}>
      <fieldset disabled={state.isBusy}>
        <InputField
          name='Account Number'
          autoComplete='account-number'
          value={state.accountNumber}
          onChange={handlers.onAccountNumberChange}
          onKeyUp={handleEnterKey}
          maxLength={8}
          type='tel'
          data-tid-login-account
        />

        <LoginFieldOptionsStyled>
          <label htmlFor='remember-input'>
            <input
              id='remember-input'
              type='checkbox'
              checked={state.rememberAccount}
              onChange={handlers.toggleRememberAccount}
            />
            <span>Remember Account</span>
          </label>

          <button
            type='button'
            onClick={handleRecoverAccountNumber}
            data-testid='login-forgot-account-number'
          >
            Forgot account number
          </button>
        </LoginFieldOptionsStyled>
      </fieldset>

      <fieldset disabled={state.isBusy}>
        <InputField
          name='Password'
          autoComplete='current-password'
          type='password'
          value={state.password}
          onChange={handlers.onPasswordChange}
          onKeyUp={handleEnterKey}
          addOnIcon={isPasswordVisible ? 'visibilityoff' : 'visibilityon'}
          addonAriaLabel={isPasswordVisible ? 'Hide password' : 'Show password'}
          handleAddOnAction={() => setIsPasswordVisible(isVisible => !isVisible)}
          data-tid-login-password
        />

        <LoginFieldOptionsStyled>
          {shouldShowKeepMeLoggedIn ? (
            <label htmlFor='keep-logged-in'>
              <input
                id='keep-logged-in'
                type='checkbox'
                checked={state.keepLoggedIn}
                onChange={handlers.toggleKeepLoggedIn}
                data-testid='login-keep-logged-in'
              />
              <span>Keep me logged in</span>
            </label>
          ) : (
            <div />
          )}

          <button type='button' onClick={handleRecoverPassword} data-testid='login-forgot-password'>
            Forgot password
          </button>
        </LoginFieldOptionsStyled>
      </fieldset>

      {isReactNativeApp && reactNativeAppVersion >= 239 && (
        <BiometricsToggleWrapper>
          <BiometricsToggle
            toggleState={isBiometricsToggleOn}
            text='Enable PIN / Biometrics'
            isDisabled={!isBiometricsStatusReady}
            onToggle={() => {
              setIsBiometricsToggleOn(!isBiometricsToggleOn)
            }}
            toggleTitle='Enable/Disable'
          />
        </BiometricsToggleWrapper>
      )}

      {state.errorMessage && (
        <NoticeBoxSingle
          title={state.errorMessage}
          noticeBoxType={NoticeBoxTypes.Error}
          hasBorder
        />
      )}

      <LoginMainFooterStyled>
        <ButtonBlock
          type='submit'
          color='primary'
          id='submit-login'
          data-testid='login-submit'
          data-tid-login-submit
          onClick={() => {
            handlers.login({
              userHasToggledBiometrics: isBiometricsToggleOn != isBiometricsEnabled,
            })
          }}
          disabled={shouldDisableSubmit}
        >
          {state.isBusy ? <SpinnerInlineStyled size={2} /> : 'Log In'}
        </ButtonBlock>

        <div role='separator'>OR</div>

        <ButtonBlock
          type='button'
          color='secondary'
          onClick={() => {
            trackSignUpClick('login-modal')
            handlers.signUp()
          }}
          data-tid-submit-signup
        >
          Join Now
        </ButtonBlock>

        <a href={callCenterPhoneNumber}>
          <Icon type='phone' size='2rem' /> For assistance, please call <u>1300 36 36 88</u>
        </a>
      </LoginMainFooterStyled>
    </form>
  )
}

// ======
// Styles
// ======

const LoginMainFooterStyled = styled('div')({
  marginBottom: '1.5rem',

  'div[role="separator"]': {
    display: 'flex',
    alignItems: 'center',
    margin: '2rem auto',
    maxWidth: '70%',
    fontSize: '1.3rem',
    color: hexColors.white,

    '&::before, &::after': {
      content: '""',
      flex: 1,
      height: 1,
      margin: '0 0.5rem',
      background: hexColors.white,
      opacity: 0.5,
    },
  },

  'a[href^="tel:"]': {
    display: 'block',
    margin: '2.5rem 0',
    fontSize: '1.2rem',
    textAlign: 'center',
    textDecoration: 'none',
    color: hexColors.white,

    svg: {
      marginRight: '0.5rem',
    },
  },
})

const BiometricsToggleWrapper = styled.div({
  '> div': {
    color: 'white',
    justifyContent: 'flex-end',
    margin: '3.9rem 0 1.4rem',
    gap: spacing.md,
  },
})
