import { BetSlipItem, FobPropositionSelection, ToteSelection } from '@mobi/betslip/types'
import { trackBetSlipAdd } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { isRaceDetails } from '@mobi/betslip/helpers/typeGuards'
import { isMysteryDetails } from '@core/Data/Betting/selectionDetails'

export const trackAddToBetslip = (addedItem: BetSlipItem) => {
  const { selection, selectionDetails, betSource } = addedItem

  if (betSource && selection && selectionDetails) {
    const betType =
      (selection as ToteSelection).betType || (selection as FobPropositionSelection).type

    const race = isRaceDetails(selectionDetails)
      ? selectionDetails.races[0]
      : isMysteryDetails(selectionDetails)
        ? selectionDetails.race
        : null

    const { meetingCode: raceCode, meetingName } = race || {
      meetingName: null,
      meetingCode: null,
    }

    trackBetSlipAdd({ source: betSource, raceCode, meetingName, betType })
  }
}
