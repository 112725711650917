// Temporary mock data
export const featuredTournamentsMock = {
  american_football: ['americanfootball_ncaaf', 'americanfootball_nfl'],
  baseball: ['baseball_mlb'],
  soccer: ['soccer_usa_mls', 'soccer_uefa_european_championship', 'soccer_epl'],
} as Record<string, string[]>

export const featuredSportsListMock = [
  'soccer',
  'baseball',
  'aussie_rules',
  'fake_sport_123',
  '1000449347',
]
