import { ACCOUNT_ACTIVITY_ROUTE } from './Areas/AccountActivity/constants'
import { ACTIVITY_STATEMENTS_ROUTE } from './Areas/Account/ActivityStatements/constants'
import { BankAccountRoute, BankAccountEditRoute } from './Areas/BankAccount/constants'
import { CHANGE_TEMP_PASSWORD_ROUTE } from './Areas/ChangePassword/constants'
import { KAMBI_ROOT_ROUTE } from './Areas/Kambi/constants'
import { MYSTERY_BET_ROUTE } from './Areas/MysteryBet/constants'
import { PRECOMMITMENT_ROUTE } from './Areas/Account/Precommitment/constants'
import { SIGNUP_COMPLETE_ROUTE } from '@core/Areas/Account/SignUp'
import { StructuredSearchRoute } from './Areas/StructuredSearch/constants'
import { TABLocatorWebRoute } from './Areas/TABLocatorWeb/constants'
import { TAKE_A_BREAK_ROUTE } from './Areas/Account/TakeABreak/constants'
import { WithdrawalRoute } from './Areas/Withdraw/constants'
import { SIGNUP_ROOT_ROUTE } from '@mobi/account/Areas/SignUp/Utils'

type RoutePath = `/${string}`
type AppRoutesPath = Readonly<RoutePath | RoutePath[]>

export const AppRoutes = {
  Home: '/',
  SignUp: `/${SIGNUP_ROOT_ROUTE}`,
  CompletedSignUp: `/${SIGNUP_COMPLETE_ROUTE}`,
  Jackpots: '/jackpots',
  NextEvents: ['/next-events', '/tote/nextevents'],
  /** Transitioning from Kambi to in-house solution @deprecated */
  SportsRoot: `/${KAMBI_ROOT_ROUTE}`,
  /** NEW in-house FOB Sports */
  Sports: '/sports',
  Meetings: '/tote',
  Account: '/account',
  AccountLogout: '/account/logout',
  AccountActivity: [`/${ACCOUNT_ACTIVITY_ROUTE}/:tab`, `/${ACCOUNT_ACTIVITY_ROUTE}`],
  ActivityStatements: `/${ACTIVITY_STATEMENTS_ROUTE}`,
  ChangePassword: '/account/change-password',
  ChangeTemporaryPassword: `/${CHANGE_TEMP_PASSWORD_ROUTE}/:accountNumber(\\d+)`,
  Settings: '/account/settings',
  ContactDetails: '/account/contactdetails',
  ContactUs: '/contactus',
  ContactDetailsEdit: '/account/contactdetails/edit/:accountNumber(\\d+)',
  MarketingCommunications: '/account/marketingcommunications',
  CloseAccount: '/account/close',
  FavouriteNumbers: '/favourite-numbers',
  StructuredSearch: `/${StructuredSearchRoute}`,
  Withdraw: `/${WithdrawalRoute}`,
  BankAccount: `/${BankAccountRoute}`,
  BankAccountEdit: `/${BankAccountEditRoute}`,
  Precommitment: `/${PRECOMMITMENT_ROUTE}`,
  TakeABreak: `/${TAKE_A_BREAK_ROUTE}`,
  RaceCard: '/tote/meetings/:meetingId/:raceNumber',
  RaceCardSky: '/live-sky-race',
  RaceList: ['/tote/meetings/:meetingId', '/tote/races'],
  RaceEvents: [
    '/fobracing/:product(FR|SR)/:code(Races|Trots|Dogs)/:date?',
    '/fobracing/:product(CLG)/:code(Races|Dogs)/:date?',
  ],
  RaceCardFixedOnly: [
    '/fobracing/propositions/:product(FR|CLG|FFF)/:code(Races|Dogs)/:raceId/:selectiondate?',
    '/fobracing/propositions/:product(FR|FFF)/:code(Trots)/:raceId/:selectiondate?',
    '/fobracing/propositions/:product(FOO)/:code(Races|Dogs|Trots)/:raceId/:selectiondate?',
  ],
  Specials: '/specials',
  MysteryBet: [`/${MYSTERY_BET_ROUTE}`, `/${MYSTERY_BET_ROUTE}/:meetingId/:raceNumber(\\d+)`],
  Tipping: '/totesports',
  Blackbook: '/account/blackbook',
  Offers: '/account/offer',
  GoodThings: '/goodthings',
  TABLocatorWeb: `/${TABLocatorWebRoute}`,
  Toolbox: '/toolbox',
  Biometrics: '/account/biometrics',
  Deposit: '/deposit',
  Verification: '/verification',
  DepositLimitsEdit: '/account/deposit-limits/edit/:depositLimitId',
  DepositLimits: '/account/deposit-limits',
} as const

export const RACES_ROUTES: AppRoutesPath[] = [
  AppRoutes.AccountActivity,
  AppRoutes.Blackbook,
  AppRoutes.RaceCardFixedOnly,
  AppRoutes.FavouriteNumbers,
  AppRoutes.Jackpots,
  AppRoutes.Meetings,
  AppRoutes.MysteryBet,
  AppRoutes.NextEvents,
  AppRoutes.Offers,
  AppRoutes.RaceEvents,
  AppRoutes.RaceList,
  AppRoutes.Specials,
  AppRoutes.StructuredSearch,
  AppRoutes.Tipping,
  // Non-react route
  '/goodthings',
]
