import { useQuery } from 'react-query'
import type { CountryList } from '@mobi/api-types'
import { get, queryKeys, SignUpUrl } from '../../../Utils'

export const useCountryList = () => {
  return useQuery<CountryList>({
    queryKey: queryKeys.countryList,
    queryFn: getCountryList,
    staleTime: 360000,
    refetchOnWindowFocus: false,
  })
}

export function getCountryList() {
  return get<CountryList>(SignUpUrl.CountryList)
}
