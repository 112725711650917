import React, { type MouseEventHandler } from 'react'
import styled from '@emotion/styled'
import { Button, Icon, InfoBox } from '@mobi/component-library/Common/V2'
import { spacing } from '@mobi/component-library/Theme/Common'
import { sendToNative } from '@mobi/web-native-comms/web'
import { track } from '../../../../../Utils'

export const ScanMyBetPanel = ({ accountNumber }: { accountNumber: number }) => {
  const onButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
    track('deposit_scan_my_bet', {
      accountNumber,
      paymentMethod: 'ScanMyBet',
    })
    sendToNative('SCAN_RETAIL_TICKET')
  }

  return (
    <ScanMyBetPanelStyled>
      <Button type='button' isFullWidth onClick={onButtonClick}>
        Scan My Bet <Icon name='CustomScanMyBet' size='2.4rem' />
      </Button>
      <InfoBox role='status' color='grey' size='md'>
        Use Scan My Bet to check the status of your ticket from any TAB retail store. Deposit
        winnings or your remaining balance directly into your TABtouch account. Simply point your
        phone's camera at the ticket to start.
      </InfoBox>
    </ScanMyBetPanelStyled>
  )
}

ScanMyBetPanel.displayName = 'ScanMyBetPanel'

const ScanMyBetPanelStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing.sm,
})
