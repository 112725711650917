import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { LinkWrapper } from '../Common/LinkWrapper'
import type { LinkInfo } from '../../types'

export const EventDetails: React.FC<{
  heading: React.ReactNode
  statusComponent: React.ReactNode
  iconComponent?: React.ReactNode
  subHeading?: string
  linkInfoPrimary?: LinkInfo
  linkInfoSecondary?: LinkInfo
}> = ({
  heading,
  subHeading,
  statusComponent,
  iconComponent = null,
  linkInfoPrimary,
  linkInfoSecondary,
}) => {
  return (
    <WrapperStyled data-testid='BetCard.EventDetails'>
      <div>
        <span>{iconComponent}</span>

        <span>
          <LinkWrapper linkInfo={linkInfoPrimary}>
            <span>{heading}</span>
          </LinkWrapper>

          {subHeading && <LinkWrapper linkInfo={linkInfoSecondary}> • {subHeading}</LinkWrapper>}
        </span>
      </div>

      <div>{statusComponent}</div>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: spacing.smx2,
  fontSize: font.size.md.fontSize,
  fontWeight: font.weight.regular,
  letterSpacing: font.size.md.letterSpacing,
  lineHeight: font.size.md.lineHeight,
  color: colors.neutral[800],

  // Left Content
  '> div:first-of-type': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    gap: spacing.smx1,
    overflow: 'hidden',

    // Icon
    '> span:first-of-type': { flexShrink: 0, ':empty': { display: 'none' } },

    // Text
    '> span:last-of-type': {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      gap: spacing.smx2,
      overflow: 'hidden',

      '> span:first-of-type': {
        flexBasis: '100%',
        maxWidth: 'fit-content',
      },

      '> *': {
        minWidth: 0,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
  },

  // Right Content
  '> div:last-of-type': {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    gap: spacing.smx2,
  },
})
