import React from 'react'
import { PlanSeq } from '@mobi/api-types'
import { Icon } from '@mobi/component-library/Common/Icon'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'
import { RacingTitle } from '@mobi/betslip/Components/Common/BetCard/Components/EventDetails/Components/RacingTitle'
import { meetingIdToContestType } from '@core/Areas/Racing/helpers/meetingIdToContestType'
import type { Ticket, TicketStatus } from '@core/Areas/AccountActivity/types'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import { getRaceLink } from '@core/Areas/Racing/helpers/getRaceLink'
import { SilkFallback } from '../../../Common/SilkFallback'
import { CountdownStatus } from '../Common/CountdownStatus'

export const SingleMultiple: React.FC<{ ticket: Ticket; status: TicketStatus }> = ({
  ticket,
  status,
}) => {
  const ticketContext = React.useContext(TicketDisplayContext)

  const contestType = meetingIdToContestType(ticket.legs[0].fixtureId)
  const isMultipleRaceSelection = shouldDisplayRaceNumbers(ticket.planSeq)
  const [title, productName] = getSelectionTitles(ticket)

  const leg = ticket.legs[0]

  const isContextPendingBetsDrawer = ticketContext.type === 'pending-bets-drawer'
  const shouldHideEventDetails = isContextPendingBetsDrawer && ticketContext.data.isSkyRaceMatch

  const shouldProvideLink =
    !isMultipleRaceSelection &&
    !!(
      (ticketContext.type === 'account-activity' || ticketContext.type === 'pending-bets-drawer') &&
      leg.fixtureId &&
      leg.fixtureDate &&
      leg.raceNumber
    )

  const linkInfo = shouldProvideLink
    ? {
        pathname: getRaceLink({
          meetingDate: leg.fixtureDate,
          meetingId: leg.fixtureId,
          raceNumber: leg.raceNumber,
          isFOO: false,
        }),
      }
    : undefined

  return (
    <>
      {!shouldHideEventDetails && (
        <BetCard.EventDetails
          heading={
            leg.meetingName ? (
              <RacingTitle
                races={
                  isMultipleRaceSelection
                    ? leg.selections.map(({ raceNumber }) => ({ raceNumber }))
                    : [{ raceNumber: leg.raceNumber }]
                }
                meetingName={leg.meetingName}
                isMultipleRaceSelection={isMultipleRaceSelection}
              />
            ) : (
              ticket.legs[0].eventName
            )
          }
          statusComponent={<CountdownStatus ticket={ticket} status={status} />}
          iconComponent={<Icon size='1.6rem' type={contestType || 'tab'} />}
          linkInfoPrimary={linkInfo}
        />
      )}

      <BetCard.SelectionInfo
        heading={<span>{title}</span>}
        subHeading={(() => {
          if (ticket.formula?.formulaString) {
            return ticket.formula.formulaString
          }

          if (productName) {
            return productName
          }

          if ([PlanSeq.Mystery$3Combo, PlanSeq.Mystery$10Combo].includes(ticket.planSeq)) {
            return ticket.legs[0].selections.map(selection => {
              const mysterySelectionTitle = `${selection.header} ${selection.selectionDetails[0].selectionString}`
              return (
                <React.Fragment key={mysterySelectionTitle}>
                  {mysterySelectionTitle}
                  <br />
                </React.Fragment>
              )
            })
          }
          return null
        })()}
        iconComponent={<SilkFallback contestType={contestType} />}
        linkInfo={linkInfo}
      />
    </>
  )
}

// =============
// Local Helpers
// =============

function getSelectionTitles(ticket: Ticket): [title: string, productName?: string] {
  const selectionString = ticket.legs[0].selectionString
  if (!selectionString || [PlanSeq.AllUp].includes(ticket.planSeq)) return [ticket.productName]
  return [buildSelectionString(ticket.legs[0], selectionString), ticket.productName]
}

function buildSelectionString(leg: Ticket['legs'][0], selectionString: string): string {
  // Instead of showing all acceptor numbers, use shorter selection string containing FD, S1, S2 etc
  if (
    leg.selections.length > 1 &&
    leg.selections.every(selection => !!selection.selectionDetails[0].selectionString)
  ) {
    return leg.selections
      .map(selection => selection.selectionDetails[0].selectionString)
      .join(selectionString.includes('/') ? '/' : '.')
  }
  return selectionString
}

function shouldDisplayRaceNumbers(planSeq: number): boolean {
  return [
    PlanSeq.Double,
    PlanSeq.Quaddie,
    PlanSeq.AllUp,
    PlanSeq.MysteryDouble1,
    PlanSeq.MysteryDouble2,
    PlanSeq.MysteryDouble3,
    PlanSeq.MysteryDouble4,
    PlanSeq.MysteryDouble5,
    PlanSeq.MysteryDouble6,
    PlanSeq.MysteryDouble7,
    PlanSeq.MysteryQuaddie1,
    PlanSeq.MysteryQuaddie2,
    PlanSeq.MysteryQuaddie3,
    PlanSeq.MysteryQuaddie4,
    PlanSeq.MysteryQuaddie5,
    PlanSeq.MysteryQuaddie6,
    PlanSeq.MysteryQuaddie7,
  ].includes(planSeq)
}
