/**
 * TABtouch Color Palette
 * =======================
 *
 * The colors below are taken from the TABtouch Style Guide as per 11/01/2018.
 * Color changes (additions/removals) need to be discussed with the UI/UX team.
 *
 * Color names are coming from here: http://www.color-blindness.com/color-name-hue/.
 *
 */
export const hexColors = {
  white: '#fff',
  black: '#000',

  // Purples
  /** As named by https://www.color-name.com/hex/281636 */
  darkPurple: '#281636',
  /** TAB Purple */
  blueDiamond: '#512d6d',
  /** Home Navigation Tiles */
  vividViolet: '#703B90',
  /** Secondary Version of TAB purple */
  studio: '#8246af',
  /** Progress indicator */
  deepLilac: '#9663b6',
  /** Search Input */
  affair: '#74578A',
  /** Selected bet informer */
  /** Content integration background */
  lavenderBlue: '#D8C6FF',
  /** Race selector buttons */
  amethyst: '#A573D1',
  /** Search Placeholders, Button Icons */
  lola: '#B9ABC4',
  /** Account dropdown links */
  jacarta: '#432660',
  /** 90 Lightness on blueDiamond */
  blueDiamond90L: '#E7DBF0',
  /** 97 Lightness on blueDiamond */
  blueDiamond97L: '#F8F4FB',

  // Greys
  /** Meeting dropdown background */
  grey1: '#f5f5f5',
  /** Page background */
  whiteSmoke: '#f4f4f4',
  /** Kambi container background */
  athensGrey: '#f2f1f3',
  /** Rating bar background */
  gainsboro: '#e8e8e8',
  /** Disabled button 1 */
  veryLightGrey: '#ccc',
  /** Unselected race button background and border */
  grey3: '#b5b5b5',
  lightGrey: '#BBBBBB',
  /** Disabled button 2 */
  darkGrey: '#aaa',
  /** Bet modifier, inactive */
  grey2: '#858585',
  /** Bet Cost background */
  grey: '#808080',
  /** Bet Cost background */
  dimGrey: '#666',
  /** Bet informers text */
  matterhorn: '#4f4f4f',
  /** Tabs style 1 (active) */
  charCoal: '#404040',
  /** 3rd level header / My Bets Menu */
  nightRider: '#333',
  /** 2nd level header / tabs style 1 (inactive) */
  nero: '#222',
  /** component library input field background colour */
  lightGreyCommon: '#F7F6F7',

  // Blues
  /** Information icon */
  summerSky: '#4f95dd',
  /** GiddyUp / Rating bar */
  cobalt: '#00509f',
  /** Info -> Price Changes */
  infoBlueLight: '#EBF4FF',
  mariner: '#2B75BF',
  americanExpressBlue: '#1434CB',
  /** Sky Tv -> CH2 */
  skyTvBlue: '#40B9EA',

  get infoBlueDark(): string {
    return this.mariner
  },
  /** Replay button */
  bondiBlue: '#02b0ae',
  /** Fixed icon */
  scooter: '#30878e',
  /** Replay Text */
  darkCyan: '#028D8B',
  /** Replay Overlay */
  pattensBlue: '#E6F7F7',
  /** Search Highlights */
  paleCornflowerBlue: '#BDDBF9',
  /** Sign up links */
  pacificBlue: '#0093dd',

  // Greens
  /** Race number button (betting) / Results: 1st to 4th tags */
  citrus: '#8dbb00',
  /** Countdown >10mins */
  yellowGreen: '#6ed234',
  /** Call to action / Primary button / Positive color */
  sushi: '#689f38',

  // Reds
  /** Quaddie icon */
  crimson: '#ca103f',
  /** Countdown <3mins */
  cinnabar: '#e84c3d',
  /** Error / Results */
  freeSpeechRed: '#cc0404',
  red: '#FF0000',
  /** component library input field validation background colour */
  lightRedCommonError: '#fdebea',
  /** Sky Tv -> CH1 */
  skyTvRed: '#EA1A43',

  // Oranges
  /** Countdown >3mins */
  tangerine: '#e38706',
  /** Content Toggle button */
  chocolate: '#dc6d18',
  /** SuperPick / Special / Star icon */
  orange: '#f2a900',
  /** SuperBoost */
  alizarin: '#e02b2b',
  /** SuperBoost price background / Special Icon*/
  lightOrange: '#FBB040',

  // Yellow
  capeHoney: '#FFECAA',
  lemon: '#ffe50b',
  paypalGold: '#ffc439',

  // Pinks
  /** Jockey change background */
  lilac: '#cd92c8',

  racingWa: '#DF2081',
} as const
