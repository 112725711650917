import React from 'react'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'

export const FormHistories: React.FC<{ children: React.ReactNode[] }> = ({ children }) => {
  const [isHistoriesTruncated, setIsHistoriesTruncated] = React.useState(() => children.length > 5)

  const historiesToRender = isHistoriesTruncated ? children.slice(0, 5) : children

  return (
    <section>
      {historiesToRender}

      {isHistoriesTruncated && (
        <ButtonBlock
          color='secondary'
          onClick={() => setIsHistoriesTruncated(curr => !curr)}
          data-testid='form-histories-show-more'
        >
          Show More
        </ButtonBlock>
      )}
    </section>
  )
}
