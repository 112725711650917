import React from 'react'
import styled from '@emotion/styled'
import { Button, Icon } from '@mobi/component-library/Common/V2'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'

const enum LocalConstants {
  ExpandedButtonClass = 'js-expandable-btn--expanded',
}

// ****
// ONLY add buttons here that are used in BOTH BetSlip + Transacted
// ****

export const ShareButton: React.FC<ButtonProps> = ({ onClick, disabled, testId }) => (
  <Button
    size='xs'
    color='tertiary_grey'
    isIconOnlyButton
    onClick={onClick}
    disabled={disabled}
    data-testid={testId}
  >
    <Icon name='LineShare02' size='1.6rem' color={colors.black} />
  </Button>
)

export const ExpandableContentButton: React.FC<
  Pick<ButtonProps, 'onClick' | 'disabled'> & { isExpanded: boolean; text?: string }
> = ({ onClick, disabled, isExpanded, text }) => (
  <ExpandableButtonStyled>
    <Button
      size='xs'
      color='tertiary_grey'
      onClick={onClick}
      isIconOnlyButton
      disabled={disabled}
      className={isExpanded ? LocalConstants.ExpandedButtonClass : ''}
    >
      <Icon size='1.6rem' name='SolidChevronDown' color={colors.black} />{' '}
      {text ? `${isExpanded ? 'Hide' : 'See'} ${text}` : `See ${isExpanded ? 'Less' : 'More'}`}
    </Button>
  </ExpandableButtonStyled>
)

// ======
// Styles
// ======

const ExpandableButtonStyled = styled.span({
  '> button': {
    columnGap: spacing.smx2,
    fontWeight: font.weight.regular,
    color: colors.black,
    paddingLeft: spacing.smx2,
    paddingRight: spacing.smx2,

    svg: { transition: 'transform ease 0.3s' },

    [`&.${LocalConstants.ExpandedButtonClass} svg`]: { transform: 'rotate(-180deg)' },
  },
})

// =====
// Types
// =====

type ButtonProps = Pick<React.ComponentProps<typeof Button>, 'onClick' | 'disabled'> & {
  testId?: string
}
