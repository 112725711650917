import React, { type MouseEventHandler } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { Button, InfoBox } from '@mobi/component-library/Common/V2'
import { SvgLazy } from '@mobi/component-library/Common/Svg/SvgLazy'
import { spacing } from '@mobi/component-library/Theme/Common'
import { useHostContext } from '../../../HostContext'
import { selectDepositFlow } from '../../../Store'
import { track } from '../../../../../Utils'
import type { PanelProps } from '../../types'

type Props = Pick<PanelProps, 'accountNumber'>

export const CashPanel = ({ accountNumber }: Props) => {
  const { events } = useHostContext()
  const flow = useSelector(selectDepositFlow)

  const onTabButtonClick: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault()

    if (!events) {
      return
    }

    events?.findTab()
    track('deposit_find_tab', {
      accountNumber,
      depositVariant: flow,
    })
  }

  return (
    <CashPanelStyled>
      <Button isFullWidth onClick={onTabButtonClick} aria-label='Find my nearest TAB'>
        Find my nearest <SvgLazy name='TABLogo' color='currentColor' width='5rem' />
      </Button>

      <InfoBox role='status' color='grey' size='md'>
        You can deposit cash into your TABtouch account at any TAB venue across Western Australia.
      </InfoBox>
    </CashPanelStyled>
  )
}

CashPanel.displayName = 'CashPanel'

const CashPanelStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing.sm,
})
