import React, { useEffect } from 'react'
import { PayPalButton } from '@mobi/component-library/Deposit/Buttons'
import { useBraintreeClient } from '../../../Hooks'
import { usePayPalDeposit } from '../../../Hooks/PayPal/usePayPalDeposit'
import { DepositError } from '../../DepositError'
import { sendToNative, subscribeToNative } from '@mobi/web-native-comms/web'
import { PanelProps } from '../../types'
import { PayPalButtonCaption } from './PayPalButton.styles'
import { payPalButtonCaption } from './PayPalButtonWeb'

export const PayPalButtonNative = ({
  depositAmount,
  isDepositAllowed,
  initialData: { transactionId, clientToken },
  onStart,
  onSuccess,
  onDepositing,
  onFailure,
  onCancel,
}: PanelProps) => {
  const { isReady, client } = useBraintreeClient(clientToken)
  const { deposit } = usePayPalDeposit({ braintreeClient: client })

  const onPayPalButtonClicked = async () => {
    onStart()

    if (await isReady()) {
      sendToNative('BEGIN_PAYPAL_TOKENIZE', {
        amount: depositAmount,
        clientToken,
      })
    } else {
      onCancel?.()
    }
  }

  useEffect(() => {
    const successSubscription = subscribeToNative(
      'PAYPAL_TOKENIZE_DID_SUCCEED',
      async ({ details, nonce }) => {
        try {
          onDepositing()

          const { billingAddress, lastName, firstName, payerId, email } = details

          const { isSuccess, ...errorDetails } = await deposit({
            amount: depositAmount,
            paymentMethodToken: clientToken,
            paymentMethodNonce: nonce,
            payPalAddress: billingAddress && {
              street: `${billingAddress.line1}${billingAddress.line2}`,
              city: billingAddress.city,
              state: billingAddress.state,
              postCode: billingAddress.state,
              countryCode: billingAddress.countryCode,
            },
            payPalEmail: email,
            payPalPayerId: payerId,
            lastName,
            firstName,
            depositSource: 'PayPal',
            transactionId,
          })

          if (isSuccess) {
            onSuccess(depositAmount)
          } else {
            onFailure(DepositError.fromErrorDetails(errorDetails))
          }
        } catch (e) {
          onFailure(DepositError.coerce(e, transactionId))
        }
      }
    )

    const failSubscription = subscribeToNative('PAYPAL_TOKENIZE_DID_FAIL', data => {
      const { message } = data

      onFailure(new DepositError('tokenization_failed', transactionId, undefined, message))
    })

    const cancelSubscription = subscribeToNative('PAYPAL_TOKENIZE_WAS_CANCELLED', () =>
      onCancel?.()
    )

    return () => {
      successSubscription.dispose()
      failSubscription.dispose()
      cancelSubscription.dispose()
    }
  }, [
    depositAmount,
    deposit,
    clientToken,
    transactionId,
    onCancel,
    onDepositing,
    onFailure,
    onSuccess,
  ])

  return (
    <>
      <PayPalButton
        onClick={onPayPalButtonClicked}
        aria-label='Deposit using PayPal'
        disabled={!isDepositAllowed}
      />
      <PayPalButtonCaption>{payPalButtonCaption}</PayPalButtonCaption>
    </>
  )
}
