import React from 'react'
import styled from '@emotion/styled'
import { hexColors, Constants } from '@mobi/settings'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'
import { Button } from '@mobi/component-library/Common/V2/Buttons'
import { useBodyScrollLock } from '@mobi/utils/hooks/useBodyScrollLock'
import { Details } from '@core/Areas/AccountActivity/Components/Ticket/Components/Details/Details'
import { isLoadBetSupported } from '@core/Utils/betting/loadBet'
import {
  getTicketLayoutType,
  getTicketStatus,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import type { Tabs, Ticket } from '@core/Areas/AccountActivity/types'
import { AppHeaderButtonStyled } from '@core/Areas/AppHeader/Components/Buttons/Buttons.styles'
import { BusyIndicator } from '@mobi/component-library/Feedback/BusyIndicator'
import { Icon } from '@mobi/component-library/Common/Icon'
import { TABtouchBrandLogo } from '@core/Components/Logos/TABtouchBrandLogo'
import { OverlayClose } from '@core/Components/Overlay'
import { useFeature } from '@core/Utils/hooks/useFeature'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import { BetInfo } from '@core/Areas/AccountActivity/Components/Ticket/Components/BetInfo'
import { Investment } from '@core/Areas/AccountActivity/Components/Ticket/Components/Investment'
import { handleShareBetClick } from './helpers/handleShareBetClick'
import { useCreateImageForBrowser } from './hooks/useCreateImageForBrowser'
import { useImageOutputDevTool } from './hooks/useImageOutputDevTool'
import {
  SharePreviewContentStyled as Content,
  SharePreviewContentWrapperStyled as Wrapper,
  SharePreviewOverlayHeaderStyled,
} from './SharePreview.styles'
import { setTemporaryWidthOfPreviewElement } from './helpers'

export const SharePreview: React.FC<{
  ticket: Ticket
  activeTab: Tabs
}> = ({ ticket, activeTab }) => {
  const previewImageElRef = React.useRef<HTMLDivElement>(null)
  const [shouldHideCost, setShouldHideCost] = React.useState(true)
  const [isBusy, setIsBusy] = React.useState(false)

  const scrollableContentRef = useBodyScrollLock()
  const imageFileBob = useCreateImageForBrowser(shouldHideCost, setIsBusy, previewImageElRef)
  const isShareBetLinkFeatureActive = useFeature('SHARE_BET_LINK')
  const [devOutputClickHandler, DevOutputImage] = useImageOutputDevTool(
    previewImageElRef,
    shouldHideCost
  )

  const ticketStatus = getTicketStatus(ticket, activeTab)
  const ticketTypeLayout = getTicketLayoutType(ticket)

  const shouldEnableLinkShare =
    activeTab === 'pending' && isShareBetLinkFeatureActive && isLoadBetSupported(ticket.planSeq)
  const isImageOnlyShare = activeTab === 'results' || !shouldEnableLinkShare

  const handleShareClick = () => {
    if (!previewImageElRef.current) return

    setIsBusy(true)
    const previewEl = previewImageElRef.current
    const resetTempWidth = setTemporaryWidthOfPreviewElement(previewEl)
    handleShareBetClick({
      ticket,
      ticketStatus,
      fileBlob: imageFileBob,
      previewEl,
      isImageOnlyShare,
      isCostShared: !shouldHideCost,
    }).finally(() => {
      setIsBusy(false)
      resetTempWidth()
    })
  }

  return (
    <>
      <SharePreviewOverlayHeaderStyled>
        <h2 data-testid='share-preview-heading'>
          Share My Bet <span onClick={devOutputClickHandler}>Preview image before sharing</span>
        </h2>

        <AppHeaderButtonStyled
          data-testid='share-preview-toggle-cost'
          onClick={() => setShouldHideCost(curr => !curr)}
        >
          <span>{shouldHideCost ? 'Show' : 'Hide'} Cost</span>{' '}
          <Icon type={shouldHideCost ? 'visibilityon' : 'visibilityoff'} />
        </AppHeaderButtonStyled>

        <AppHeaderButtonStyled onClick={OverlayClose} data-testid='share-preview-close'>
          <Icon type='cross' />
        </AppHeaderButtonStyled>
      </SharePreviewOverlayHeaderStyled>

      <Wrapper ref={scrollableContentRef} data-testid='share-preview-content'>
        <Content>
          <div ref={previewImageElRef}>
            <div>
              <TABtouchBrandLogo />
            </div>

            <BetCard.Container topComponent={null}>
              <TicketDisplayContext.Provider
                value={{ type: 'share-preview', data: { shouldHideCost } }}
              >
                <BetInfo
                  ticket={ticket}
                  status={ticketStatus}
                  ticketLayoutType={ticketTypeLayout}
                />

                <Details ticket={ticket} />

                {!shouldHideCost && <Investment status={ticketStatus} ticket={ticket} />}
              </TicketDisplayContext.Provider>
            </BetCard.Container>

            <div>
              <p>Gamble responsibly. Call Gambling Help on {Constants.GamblingHelpPhoneNumber}</p>
            </div>
          </div>
          {DevOutputImage && DevOutputImage}
        </Content>
      </Wrapper>

      <ButtonWrapperStyled data-testid='share-preview-options'>
        <Button
          color='secondary_color'
          onClick={OverlayClose}
          data-testid='share-preview-footer-cancel'
        >
          Cancel
        </Button>

        <Button
          color='primary'
          onClick={handleShareClick}
          disabled={isBusy}
          data-testid='share-preview-footer-confirm'
        >
          {isBusy ? <BusyIndicator isBusy /> : <>Share Bet</>}
        </Button>
      </ButtonWrapperStyled>
    </>
  )
}

const ButtonWrapperStyled = styled.div({
  display: 'flex',
  padding: '0.5rem',
  background: hexColors.white,

  button: {
    flex: 1,
    minHeight: '4rem',
    margin: 0,

    '&:first-of-type': {
      marginRight: '0.5rem',
    },
    '&:last-of-type': {
      marginLeft: '0.5rem',
    },

    'svg:last-of-type': {
      marginLeft: '0.2rem',
    },
    'svg:first-of-type': {
      marginLeft: '0.2rem',
    },
  },
})
