import { addToast, ToastContent } from '@mobi/component-library/Feedback/Toast'
import {
  AccountBalanceChangedPushEvent,
  AccountBalanceChangedExceptionPushEvent,
} from 'tabtouch-push-contract'
import { isSelfServePaymentMethod } from '@mobi/component-library/Deposit/helpers'
import React from 'react'

const FeedbackBalanceChangeSuccess = () =>
  addToast({
    id: 'deposit-success',
    isDismissable: true,
    content: <ToastContent title='Your balance has been updated.' icon='success' />,
  })

const FeedbackBalanceChangeError = (
  exception: AccountBalanceChangedExceptionPushEvent['exception']
) => {
  let message = ''
  let title = ''

  switch (exception) {
    case 'DepositPrecommLimit':
      title = 'Daily deposit limit exceeded'
      message =
        'Your deposit exceeded your daily limit. We have refunded the amount. Please try again with a value equal to or lower than your daily limit.'
      break
    case 'DepositMinNotMet':
      title = 'Minimum value not reached'
      message =
        'Your deposit was lower than the minimum deposit value. We have refunded the amount. Please try again.'
      break
    case 'DepositExceptionOthr':
    default:
      title = 'Your deposit could not be completed'
      message =
        'Please try again. If the issue persists, please contact your bank to check your account details or call us on 1300 36 36 88.'
      break
  }

  addToast({
    id: 'deposit-error',
    isDismissable: true,
    content: <ToastContent title={title} subtitle={message} icon='error' />,
    timeoutMs: 6_000, // 6s
  })
}

export const FeedbackBalanceChange = (
  balanceChangeEvent: AccountBalanceChangedPushEvent | AccountBalanceChangedExceptionPushEvent
) => {
  /*
   * We only want to show feedbacks for self serve deposits.
   * The feedback for internal deposits are already handled.
   */
  if (
    balanceChangeEvent.paymentMethod &&
    !isSelfServePaymentMethod(balanceChangeEvent.paymentMethod)
  )
    return

  if (
    'exception' in balanceChangeEvent &&
    (balanceChangeEvent.exception === 'DepositMinNotMet' ||
      balanceChangeEvent.exception === 'DepositPrecommLimit' ||
      balanceChangeEvent.exception === 'DepositExceptionOthr')
  ) {
    FeedbackBalanceChangeError(balanceChangeEvent.exception)
    return
  }

  FeedbackBalanceChangeSuccess()
}
