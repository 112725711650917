import React from 'react'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import type { RacesFormRaceResponse } from '@core/Areas/Racing/Hooks/useRaceFormData/api'
import { HistoryExpandable } from '../Common/HistoryExpandable'

export const RacesHistorySingle: React.FC<{
  history: RacesFormRaceResponse['Forms'][0]['RaceHistories'][0]
  StarterName?: string
}> = ({
  history: {
    Place,
    FieldSize,
    RaceNumber,
    TrackName,
    FixtureDate,
    Distance,
    StewardsComments,
    RaceClass,
    TrackCondition,
    RaceTime,
    StartPrice,
    Position800,
    JockeyName,
    Weight,
    Barrier,
    Margin,
    Position400,
    Placings,
    RaceReplay,
  },
  StarterName,
}) => {
  return (
    <HistoryExpandable
      Place={Place}
      FieldSize={FieldSize}
      RaceNumber={RaceNumber}
      TrackName={TrackName}
      FixtureDate={FixtureDate}
      Distance={Distance}
      RaceReplay={RaceReplay}
      StarterName={StarterName}
      RaceCode='races'
    >
      <>
        {StewardsComments && (
          <div data-testid='form-history-stewards-comments'>
            <strong>Stewards Comments</strong>
            <br />
            {StewardsComments}
          </div>
        )}

        <Grid>
          <GridCell width='50%'>
            <div data-testid='form-history-class'>
              <strong>Class</strong> {RaceClass}
            </div>
            <div data-testid='form-history-track-cond'>
              <strong>Cond</strong> {TrackCondition}
            </div>
            <div data-testid='form-history-racetime'>
              <strong>Time</strong> {RaceTime}
            </div>
            <div data-testid='form-history-startprice'>
              <strong>Start Price</strong> {StartPrice}
            </div>
            <div data-testid='form-history-800m'>
              <strong>800m</strong> {Position800}
            </div>
          </GridCell>

          <GridCell width='50%'>
            <div data-testid='form-history-jockey'>
              <strong>Jockey</strong> {JockeyName}
            </div>
            <div data-testid='form-history-weight'>
              <strong>Weight</strong> {Weight}
            </div>
            <div data-testid='form-history-barrier'>
              <strong>Barrier</strong> {Barrier}
            </div>
            <div data-testid='form-history-margin'>
              <strong>Margin</strong> {Margin}
            </div>
            <div data-testid='form-history-400m'>
              <strong>400m</strong> {Position400}
            </div>
          </GridCell>
        </Grid>

        {Placings.length && (
          <div>
            <ol>
              {Placings.map(({ Place, StarterName, JockeyName, Weight, Barrier, Margin }) => (
                <li key={Place}>
                  <strong>{Place}</strong>
                  <span>
                    {StarterName + ' / ' + JockeyName + ' (' + Weight + ', ' + Barrier + ')'}
                  </span>
                  {Margin && <div>{Margin}</div>}
                </li>
              ))}
            </ol>
          </div>
        )}
      </>
    </HistoryExpandable>
  )
}
