import { RootState } from '@core/Store'
import { LoginState } from '.'

export function getIsLoginShown(state: RootState): boolean {
  return state.login.isLoginShown
}

export function getLoginActions(
  state: RootState
): Pick<
  LoginState,
  'onLoginSuccessCallback' | 'onLoginSuccessRedirectUrl' | 'onLoginCancelledRedirectUrl'
> {
  return {
    onLoginSuccessCallback: state.login.onLoginSuccessCallback,
    onLoginSuccessRedirectUrl: state.login.onLoginSuccessRedirectUrl,
    onLoginCancelledRedirectUrl: state.login.onLoginCancelledRedirectUrl,
  }
}

export const getIsKeepMeLoggedIn = (state: RootState): boolean => state.login.isKeepMeLoggedIn
export const getIsBiometricsEnabledOnTempPasswordChange = (state: RootState): boolean =>
  state.login.isBiometricsEnabledOnTempPasswordChange
