import { useEffect } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import { store } from '@core/Store'
import { ensureBetErrorTypeLiteral } from '@core/Data/betting'
import {
  BetSlipItem,
  BetSlipResponse,
  EventDetails,
  FobBetReceiptResponse,
  Selection,
} from '@mobi/betslip/types'
import { confirmAllBets, proposeAllBets } from '@mobi/betslip/Store/Workflow/asyncActions'
import { startListening } from '@core/Store/listenerMiddleware'
import {
  FeatureFlagState,
  state$ as launchDarklyState$,
} from '@core/State/LaunchDarklyFeatures/driver'
import { trackBetConfirmation } from '@classic/Foundation/Analytics/Analytics.Bet'
import {
  keys as analyticsKeys,
  defaultData as analyticsDefaultData,
  AnalyticsBetData,
} from '@classic/Foundation/Analytics/AnalyticsDataLayer'
import * as Analytics from '@classic/Foundation/Analytics/Analytics'

export function useBetSlipAnalytics(shouldEnable: boolean): void {
  useEffect(() => {
    if (!shouldEnable) return

    const listeners = [
      startListening({
        actionCreator: proposeAllBets.fulfilled,
        effect: handleProposeAllBetsSuccessful,
      }),
      startListening({
        actionCreator: proposeAllBets.rejected,
        effect: handleProposeAllBetsFailed,
      }),
      startListening({
        actionCreator: confirmAllBets.fulfilled,
        effect: handleConfirmAllBetsSuccessful,
      }),
      startListening({
        actionCreator: confirmAllBets.rejected,
        effect: handleConfirmAllBetsFailed,
      }),
    ]

    return () => {
      listeners.forEach(stopListener => stopListener())
    }
  }, [shouldEnable])
}

// =====
// Helper
// =====

const handleProposeAllBetsSuccessful = (action: { payload: BetSlipResponse[] }) => {
  const trackData: AnalyticsBetData = {
    ...analyticsDefaultData.bet,
    construction: 'betslip',
  }

  action.payload.forEach(bet => {
    const errorType = getErrorType(bet)

    Analytics.track(analyticsKeys.betslipBetaSelected, {
      bet: {
        ...trackData,
        productType: bet.legs && bet.legs?.length > 1 ? 'multi' : 'single',
        errorMessage: mapBetErrorTypeIfNonNullable(errorType),
      },
    })
  })
}

const handleProposeAllBetsFailed = (action: AnyAction) => {
  const errorMessage = mapBetErrorTypeIfNonNullable(action.payload.code || 'Network Error')

  const step1BetData: AnalyticsBetData = {
    ...analyticsDefaultData.bet,
    construction: 'betslip',
    productType: 'N/A',
    errorMessage,
  }

  Analytics.track(analyticsKeys.betslipBetaSelected, { bet: step1BetData })
}

const handleConfirmAllBetsSuccessful = (action: { payload: BetSlipResponse[] }) => {
  launchDarklyState$.take(1).subscribe(launchDarklyState => {
    const betslipStateDataItems = store.getState().betslip.bets.items
    const featureFlags = launchDarklyState.toJS() as FeatureFlagState

    action.payload.forEach(bet => {
      const isMultiBet = bet.legs && bet.legs?.length > 1
      const betType = isMultiBet ? 'multi' : 'single'

      const step2BetData: AnalyticsBetData = {
        ...analyticsDefaultData.bet,
        construction: 'betslip',
        productType: betType,
        errorMessage:
          !bet.success && bet.error ? mapBetErrorTypeIfNonNullable(bet.error.type) : null,
      }

      Analytics.track(analyticsKeys.betslipBetaReview, { bet: step2BetData })

      if (bet.success) {
        if (isMultiBet) {
          trackMultiBetConfirmation(bet)
        } else {
          trackSingleBetConfirmation(bet, betslipStateDataItems, featureFlags)
        }
      }
    })
  })
}

const handleConfirmAllBetsFailed = (action: AnyAction) => {
  const errorMessage = mapBetErrorTypeIfNonNullable(action.payload.code || 'Network Error')

  const step2BetData: AnalyticsBetData = {
    ...analyticsDefaultData.bet,
    construction: 'betslip',
    productType: 'N/A',
    errorMessage,
  }

  Analytics.track(analyticsKeys.betslipBetaReview, { bet: step2BetData })
}

const getErrorType = (bet: BetSlipResponse) => {
  const betErrorType = bet.error?.type
  const betLegErrorType = bet.legs?.[0]?.error?.type
  return betErrorType || betLegErrorType
}

const trackMultiBetConfirmation = (bet: BetSlipResponse) => {
  const { ticketNumber, winInvestment, bonusBet } = bet.receipt as FobBetReceiptResponse
  const step3MultiBetData: AnalyticsBetData = {
    ...analyticsDefaultData.bet,
    specialOffers: null,
    id: ticketNumber,
    construction: 'betslip',
    productType: 'multi',
    placementMethod: 'account',
    mixed: {
      fixed: {
        cost: winInvestment,
        numberOfTickets: 1,
        bonusCashUsed: bonusBet > 0,
        bonusCashAmount: bonusBet,
      },
    },
  }

  Analytics.track(analyticsKeys.betAccountProcessed, { bet: step3MultiBetData })
}

const trackSingleBetConfirmation = (
  bet: BetSlipResponse,
  betslipStateDataItems: BetSlipItem[],
  featureFlags: FeatureFlagState
) => {
  const getSelection: GetSelection = (id: string) => {
    const item = betslipStateDataItems.find(item => item.id === id)
    return item?.selection && item?.selectionDetails
      ? [item.selection, item.selectionDetails]
      : null
  }

  const betItem = getSelection(bet.id)

  if (!betItem) return

  const [selection, selectionDetails] = betItem
  const { ticketNumber, winInvestment, placeInvestment, specialOffers, betCost, tags, bonusBet } =
    extractReceipt(bet.receipt)

  trackBetConfirmation(
    ticketNumber,
    selection,
    selectionDetails,
    specialOffers || [],
    'betslip',
    betCost,
    winInvestment,
    placeInvestment,
    featureFlags.features,
    null,
    tags || null,
    null,
    bonusBet
  )
}

const mapBetErrorTypeIfNonNullable = (betErrorType: unknown): Nullable<string> =>
  typeof betErrorType === 'string' || typeof betErrorType === 'number'
    ? ensureBetErrorTypeLiteral(betErrorType)
    : null

function extractReceipt(receipt: BetSlipResponse['receipt']) {
  if (!receipt) {
    return {
      ticketNumber: -1,
      winInvestment: 0,
      placeInvestment: 0,
      specialOffers: [],
      betCost: 0,
      tags: null,
      bonusBet: 0,
    }
  }

  if ('betCost' in receipt) {
    return {
      ticketNumber: receipt.ticketNumber,
      winInvestment: 0,
      placeInvestment: 0,
      specialOffers: [],
      betCost: receipt.betCost,
      tags: null,
      bonusBet: receipt.bonusBet,
    }
  }

  return {
    ticketNumber: receipt.ticketNumber,
    winInvestment: receipt.winInvestment,
    placeInvestment: receipt.placeInvestment,
    specialOffers: receipt.specialOffers ?? [],
    betCost: 0,
    tags: null,
    bonusBet: receipt.bonusBet,
  }
}

// =====
// Types
// =====

type GetSelection = (id: string) => [Selection, EventDetails] | null
