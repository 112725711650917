import React from 'react'
import type { NextSkyRace } from '@core/Areas/RaceCardSky/types'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import { BetInfo } from '@core/Areas/AccountActivity/Components/Ticket/Components/BetInfo'
import { Investment } from '@core/Areas/AccountActivity/Components/Ticket/Components/Investment'
import {
  getTicketLayoutType,
  getTicketStatus,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { SkyRaceHeader } from '../SkyRaceHeader'
import { getMatchedSkyRaceForTicketLeg } from '../../helpers/getMatchedSkyRaceForTicketLeg'

export const GeneralTicket: React.FC<{
  ticket: Ticket
  nextSkyRaces: NextSkyRace[]
  ticketType: ReturnType<typeof getTicketLayoutType>
}> = ({ ticket, nextSkyRaces, ticketType }) => {
  const isSingleRaceTicket =
    ticketType === 'single-single' ||
    ticketType === 'single-multiple' ||
    ticketType === 'same-race-multi'

  const matchedSkyRace =
    isSingleRaceTicket && getMatchedSkyRaceForTicketLeg(ticket.legs[0], nextSkyRaces)

  const isSkyRaceMatch = !!matchedSkyRace

  const status = getTicketStatus(ticket, 'pending')
  const ticketLayoutType = getTicketLayoutType(ticket)

  return (
    <TicketDisplayContext.Provider
      value={{ type: 'pending-bets-drawer', data: { isSkyRaceMatch } }}
    >
      {isSkyRaceMatch && isSingleRaceTicket && <SkyRaceHeader nextSkyRace={matchedSkyRace} />}

      <BetInfo ticket={ticket} status={status} ticketLayoutType={ticketLayoutType} />

      <Investment ticket={ticket} status='Pending' />
    </TicketDisplayContext.Provider>
  )
}
