import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { Icon as LegacyIcon } from '@mobi/component-library/Common/Icon'
import { Currency } from '@mobi/utils/money'
import { hexColors } from '@mobi/settings'
import { useRenderTimeoutControl } from '@mobi/utils/hooks/useRenderTimeoutControl'
import { roundDownDecimal } from '@mobi/betslip/helpers/calculator/misc'

const enum LocalConstants {
  PriceChangeUpClass = 'js-price--up',
  PriceChangeDownClass = 'js-price--down',
  PriceChangeActiveClass = 'js-price--changed',
  PriceShiftClass = 'js-price--shifted',
  SecondaryPriceClass = 'js-price--secondary',
  BoostedPriceClass = 'js-price--boosted',
}

interface PriceProps {
  value: number
  isIndicatorShifted?: boolean
  isSecondaryDisplay?: boolean
  roundDown?: boolean
  isBoostedPrice?: boolean
}

export const Price: React.FC<PriceProps> = ({
  value,
  isIndicatorShifted = false,
  isSecondaryDisplay = false,
  roundDown = false,
  isBoostedPrice = false,
}) => {
  const prevPriceRef = React.useRef(value)
  const priceChangeDirectionRef = React.useRef<PriceChangeDirection>(null)

  const [isChanging, setIsChanging] = React.useState<boolean>(false)

  const shouldRenderChange = useRenderTimeoutControl({ timeoutMs: 400, shouldRender: isChanging })

  React.useEffect(() => {
    const prevPrice = prevPriceRef.current
    prevPriceRef.current = value
    if (prevPrice === value) return
    priceChangeDirectionRef.current = prevPrice < value ? 'UP' : 'DOWN'
    setIsChanging(true)
  }, [value])

  React.useEffect(() => {
    if (!isChanging) return
    const timerId = setTimeout(() => setIsChanging(false), 6_000)
    return () => clearTimeout(timerId)
  }, [isChanging])

  const classNames = []
  if (isChanging) classNames.push(getPriceChangeClass(priceChangeDirectionRef))
  if (isIndicatorShifted) classNames.push(LocalConstants.PriceShiftClass)
  if (isSecondaryDisplay) classNames.push(LocalConstants.SecondaryPriceClass)
  if (isBoostedPrice) classNames.push(LocalConstants.BoostedPriceClass)

  let displayValue = new Currency(roundDown ? roundDownDecimal(value) : value).format({
    pattern: '#',
  })

  if (value % 1 === 0) {
    displayValue = displayValue.replace('.00', '')
  }

  return (
    <PriceStyled className={classNames.join(' ')} data-testid='Price'>
      <span>
        {isBoostedPrice ? (
          <>
            <LegacyIcon type='specials' size='1rem' />{' '}
          </>
        ) : null}

        {displayValue}
      </span>

      <span>
        <span>
          {shouldRenderChange && priceChangeDirectionRef.current === 'UP' && (
            <Icon name='SolidTriangle' size='0.8rem' color={colors.success[500]} />
          )}
        </span>
        <span>
          {shouldRenderChange && priceChangeDirectionRef.current === 'DOWN' && (
            <Icon name='SolidTriangle' size='0.8rem' color={colors.error[600]} />
          )}
        </span>
      </span>
    </PriceStyled>
  )
}

// =============
// Local Helpers
// =============

function getPriceChangeClass(directionRef: React.MutableRefObject<PriceChangeDirection>): string {
  if (directionRef.current === 'UP') return LocalConstants.PriceChangeUpClass
  if (directionRef.current === 'DOWN') return LocalConstants.PriceChangeDownClass
  return ''
}

// ======
// Styles
// ======

const PriceStyled = styled.span({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  fontSize: font.size.lg.fontSize,
  fontWeight: font.weight.medium,
  letterSpacing: font.size.lg.letterSpacing,
  lineHeight: 1,
  color: colors.black,

  // Price Value
  '> span:nth-of-type(1)': {
    transition: 'all ease 400ms',
  },

  // Change Indicators
  '> span:nth-of-type(2)': {
    position: 'absolute',
    right: 0,
    transition: 'opacity ease 400ms',
    opacity: 0,
    transform: 'none',

    '> span': { display: 'flex', alignItems: 'center' },
    '> span:last-of-type': { transform: 'rotate(180deg)' },
  },

  [`&.${LocalConstants.PriceChangeUpClass}`]: {
    '> span:nth-of-type(1)': {
      color: colors.success[500],
      transform: 'translateX(-1.2rem)',
    },
    '> span:nth-of-type(2)': { opacity: 1 },
  },

  [`&.${LocalConstants.PriceChangeDownClass}`]: {
    '> span:nth-of-type(1)': {
      color: colors.error[600],
      transform: 'translateX(-1.2rem)',
    },
    '> span:nth-of-type(2)': { opacity: 1 },
  },

  [`&.${LocalConstants.PriceShiftClass}`]: {
    '> span:nth-of-type(1)': { transform: 'none' },
    '> span:nth-of-type(2)': { transform: 'translateX(1.2rem)' },
  },

  [`&.${LocalConstants.SecondaryPriceClass}`]: {
    [`&.${LocalConstants.PriceChangeUpClass} > span:nth-of-type(1)`]: { paddingLeft: '1.2rem' },
    [`&.${LocalConstants.PriceChangeDownClass} > span:nth-of-type(1)`]: { paddingLeft: '1.2rem' },
  },

  [`&.${LocalConstants.BoostedPriceClass}`]: {
    display: 'flex',
    gap: spacing.smx2,
    padding: '0 0.2rem',
    borderRadius: spacing.smx2,
    background: hexColors.lightOrange,

    '> span:nth-of-type(1)': {
      display: 'flex',
      alignItems: 'baseline',
      gap: '0.4rem',
      color: colors.white,
      fontSize: font.size.md.fontSize,
      letterSpacing: font.size.md.letterSpacing,
      lineHeight: 1,
    },
  },
})

// =====
// Types
// =====

type PriceChangeDirection = 'UP' | 'DOWN' | null
