import React from 'react'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import type { TrotsFormRaceResponse } from '@core/Areas/Racing/Hooks/useRaceFormData/api'
import { FormDataStyled } from '../Common/Common.styles'
import { FormHistories } from '../Common/Histories'
import { TrotsHistorySingle } from './TrotsHistorySingle'

export const Trots: React.FC<{
  form?: TrotsFormRaceResponse['Forms'][0]
  starterName?: string
}> = ({ form, starterName }) => {
  if (!form) return null

  return (
    <>
      <FormDataStyled>
        {form.Comments && (
          <>
            <div />
            <div data-testid='runner-form-tips'>{form.Comments}</div>
          </>
        )}

        <Grid>
          <GridCell width='50%'>
            <div data-testid='runner-form-career'>
              <strong>Career</strong> {form.Career}
            </div>
            <div data-testid='runner-form-prize'>
              <strong>Prize</strong> {form.PrizeMoneyWon}
            </div>
          </GridCell>

          <GridCell width='50%'>
            <div data-testid='runner-form-last-starts'>
              <strong>Last Starts</strong> {form.LastTenStarts}
            </div>
            <div data-testid='runner-form-trainer'>
              <strong>Trainer</strong> {form.TrainerName}
            </div>
          </GridCell>
        </Grid>

        <Grid>
          <GridCell width='50%'>
            <div data-testid='runner-form-distance'>
              <strong>Distance</strong> {form.Distance}
            </div>
            <div data-testid='runner-form-trk-dist'>
              <strong>Trk/Dist</strong> {form.TrackDistance}
            </div>
          </GridCell>

          <GridCell width='50%'>
            <div data-testid='runner-form-track'>
              <strong>Track</strong> {form.TrackName}
            </div>
            <div data-testid='runner-form-starttype'>
              <strong>Start Type</strong> {form.StartType}
            </div>
          </GridCell>
        </Grid>

        <Grid>
          <GridCell width='50%'>
            <div data-testid='runner-form-age-sex'>
              <strong>Age / Sex</strong> {form.Age} / {form.Sex}
            </div>
          </GridCell>
          <GridCell width='50%'>
            <div data-testid='runner-form-colour'>
              <strong>Colour</strong> {form.Color}
            </div>
          </GridCell>
          <div data-testid='runner-form-sire-dam'>
            <strong>Sire / Dam</strong> {form.SireName} / {form.DamName}
          </div>
        </Grid>
      </FormDataStyled>

      <FormHistories>
        {form.TrotHistories.map(history => (
          <TrotsHistorySingle
            key={history.FixtureDate}
            history={history}
            StarterName={starterName}
          />
        ))}
      </FormHistories>
    </>
  )
}
