import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { colors, spacing } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { SummaryLine } from '@mobi/betslip/Components/Common/SummaryLine'
import type { Ticket, TicketStatus } from '@core/Areas/AccountActivity/types'
import { Money } from '@core/Components/Text/Money'
import { FixedOddsMarketDisplayNames } from '@core/Areas/RaceCard/constants'
import { StatusSilks } from './Components/StatusSilks'

export const Investment: React.FC<{
  status: TicketStatus
  ticket: Ticket
}> = ({ status, ticket }) => {
  const { investment, payValue: payout, return: projPayout, flexPercent } = ticket

  const hasPayoutFromScratchedOrAbandoned =
    payout > 0 &&
    (status === 'Abandoned' ||
      status === 'Refunded' ||
      status === 'Scratched' ||
      status === 'Partially Refunded')

  const isStartingPriceBet =
    ticket.legs?.length === 1 && ticket.legs[0].betType == FixedOddsMarketDisplayNames.SP

  const bonusType = ticket.investment.bonusType
  const hasBonusAmount = (ticket.investment.bonusBet || 0) > 0

  return (
    <WrapperStyled data-testid='Ticket.Investment'>
      <StatusSilks ticket={ticket} />

      <SummaryLine
        testId='Ticket.Cost'
        left='Cost'
        right={
          <span data-testid='bet-investment'>
            <Money amount={investment.total} />
            {investment.bonusBet && (
              <span
                style={{
                  color:
                    hasBonusAmount && bonusType
                      ? bonusType === 'BonusBet'
                        ? hexColors.tangerine
                        : hexColors.cobalt
                      : 'inherit',
                }}
              >
                {' '}
                {investment.bonusType === 'BonusBet'
                  ? 'Bonus Bet'
                  : investment.bonusBet === investment.total
                    ? 'Bonus Cash'
                    : '(incl. Bonus Cash)'}
              </span>
            )}
          </span>
        }
      />

      {flexPercent && <SummaryLine testId='Ticket.Flexi' left='Flexi' right={flexPercent} />}

      {(() => {
        if (status === 'NoPayout') return null

        if (status === 'Won' || hasPayoutFromScratchedOrAbandoned) {
          return (
            <SummaryLine
              testId='Ticket.Payout'
              left='Payout'
              right={
                <>
                  <PayoutStyled>
                    <Icon name='SolidCheckCircle' color={colors.success[400]} size='1.6rem' />
                    <Money amount={payout} prefix='+' />
                  </PayoutStyled>
                </>
              }
            />
          )
        }

        if (status === 'Cancelled') {
          return (
            <SummaryLine
              testId='Ticket.Payout'
              left='Payout'
              right={<Money amount={investment.total} />}
            />
          )
        }

        if (projPayout && !hasPayoutFromScratchedOrAbandoned && !isStartingPriceBet) {
          return (
            <SummaryLine
              testId='Ticket.PotentialPayout'
              left='Potential Payout'
              right={<Money amount={projPayout} />}
            />
          )
        }

        return null
      })()}
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.smx2,
})

const PayoutStyled = styled.span({
  display: 'flex',
  alignItems: 'center',
  gap: spacing.smx2,
  color: colors.success[400],
})
