import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { PlanSeq } from '@mobi/api-types'
import { colors, font, radius, spacing } from '@mobi/component-library/Theme/Common'
import { toTitleCase } from '@mobi/utils/string'
import { Ticket } from '@core/Areas/AccountActivity/types'
import { getSilk } from '@core/Areas/AccountActivity/helpers'
import { SilkImage } from '@core/Components/SilkImage/SilkImage'
import { BetStatusIcon } from '@core/Areas/AccountActivity/Components/Ticket/Components/Investment/Components/StatusSilks/BetStatusIcon'
import { getRaceLink } from '@core/Areas/Racing/helpers/getRaceLink'

export const SingleMultiple: React.FC<{
  leg: Ticket['legs'][0]
  planSeq: PlanSeq
}> = ({ leg, planSeq }) => {
  const labelElementsRef = React.useRef<HTMLDivElement[]>([])

  React.useLayoutEffect(() => {
    const widths = labelElementsRef.current.map(el => Math.ceil(el.getBoundingClientRect().width))
    const largestWidth = Math.max(...widths)
    labelElementsRef.current.forEach(el => (el.style.width = largestWidth + 'px'))
  }, [])

  const captureLabelEls = React.useCallback((el: HTMLDivElement) => {
    if (!el) return
    labelElementsRef.current.push(el)
  }, [])

  const isAllUp = planSeq === PlanSeq.AllUp
  const isDoubleOrQuaddie = [PlanSeq.Double, PlanSeq.Quaddie].includes(planSeq)

  const { selections, fixtureId, raceNumber: legRaceNumber, fixtureDate } = leg

  return (
    <WrapperStyled>
      {selections.map((selection, index) => {
        const {
          raceNumber,
          raceResult,
          header,
          expandedHeader,
          expandedHeaderSecondLine,
          selectionDetails,
        } = selection

        const { silkUrl, silkHeight, silkWidth, isDogsSilk } = getSilk({
          fixtureId,
          fixtureDate,
          raceNumber: raceNumber || legRaceNumber,
        })

        const subHeader =
          (selectionDetails[0].selectionString?.split(' ').some(a => a.toLowerCase() === 'fd') &&
            selectionDetails[0].selectionString) ||
          expandedHeaderSecondLine

        const shouldProvideLink =
          (isDoubleOrQuaddie || isAllUp) &&
          !!(fixtureId && fixtureDate && (raceNumber || legRaceNumber))

        const raceLink = shouldProvideLink
          ? getRaceLink({
              meetingDate: leg.fixtureDate,
              meetingId: leg.fixtureId,
              raceNumber: raceNumber || legRaceNumber,
              isFOO: false,
            })
          : null

        return (
          <div key={index}>
            <div ref={captureLabelEls}>
              {!!(expandedHeader || header) && (
                <span>
                  <LinkWrapper linkPath={raceLink}>
                    {isDoubleOrQuaddie || isAllUp ? `R${raceNumber}` : expandedHeader || header}

                    {subHeader && (
                      <>
                        {' '}
                        <br />
                        {subHeader}
                      </>
                    )}
                  </LinkWrapper>
                </span>
              )}
            </div>

            <ul>
              {selectionDetails[0].acceptorDetails.map(({ number, name }) => {
                const isWinner =
                  !!number && isDoubleOrQuaddie && !!(raceResult && raceResult[number - 1] === '1')
                return (
                  <li key={number + name}>
                    <span>
                      <SilkImage
                        url={silkUrl}
                        height={silkHeight}
                        width={silkWidth}
                        isDogsSilk={isDogsSilk}
                        xOffset={number - 1}
                        baseWidth={18}
                      />
                      {number} - {toTitleCase(name)}
                    </span>

                    {isWinner && (
                      <span>
                        <BetStatusIcon betStatus='Won' />
                      </span>
                    )}
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })}
    </WrapperStyled>
  )
}

// ===============
// Local Component
// ===============

const LinkWrapper: React.FC<{ linkPath: string | null; children: React.ReactNode }> = ({
  linkPath,
  children,
}) => {
  if (!linkPath) return <>{children}</>
  return <Link to={linkPath}>{children}</Link>
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.smx2,
  fontFamily: font.family.primary,

  ':empty': { display: 'none' },

  '> div': {
    display: 'flex',
    gap: spacing.sm,
    padding: spacing.smx1,
    backgroundColor: colors.surface[200],
    borderRadius: radius.md,

    // Label
    '> div': {
      display: 'flex',
      flexDirection: 'column',
      ':empty': { display: 'none' },

      '> span': {
        display: 'inline-flex',
        alignItems: 'center',
        paddingLeft: spacing.smx2,
        paddingRight: spacing.smx2,
        minHeight: '2rem',
        maxWidth: 'fit-content',
        fontWeight: font.weight.regular,
        fontSize: font.size.sm.fontSize,
        letterSpacing: font.size.sm.letterSpacing,
        lineHeight: font.size.sm.lineHeight,
        color: colors.neutral[900],
        borderRadius: radius.smx1,
        backgroundColor: colors.surface[300],

        a: { textDecoration: 'none', color: 'inherit' },
      },
    },

    // Runner List
    '> ul': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      margin: 0,
      listStyle: 'none',

      '> li': {
        display: 'flex',
        alignItems: 'center',
        gap: spacing.smx2,
        minHeight: '2rem',
        marginTop: spacing.smx2,
        paddingBottom: spacing.smx2,
        fontWeight: font.weight.regular,
        fontSize: font.size.md.fontSize,
        lineHeight: font.size.md.lineHeight,
        letterSpacing: font.size.md.letterSpacing,
        color: colors.black,
        borderBottom: `0.5px solid ${colors.neutral[200]}`,

        ':first-of-type': { marginTop: 0 },
        ':last-of-type': { border: 0, paddingBottom: 0 },

        '> span': { display: 'inline-flex', alignItems: 'center', gap: spacing.smx2 },
        '> span:nth-of-type(2)': { marginLeft: 'auto' },
      },
    },
  },
})
