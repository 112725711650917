import dayjs from '@mobi/utils/date/extendedDayjs'
import { SportItemId } from '../Data/types'

// TODO: Use dynamic method to allow for different sports routes in each env
const SPORTS_URL_PREFIX = 'sports'

export function buildTournamentsListPageURL(sportId: SportItemId) {
  return `/${SPORTS_URL_PREFIX}/${sportId}/tournaments`
}

export function buildEventsListPageURL(sportId: SportItemId, tournamentId: SportItemId) {
  return `/${SPORTS_URL_PREFIX}/${sportId}/${tournamentId}/events`
}

export function buildEventPageURL(
  sportId: SportItemId,
  tournamentId: SportItemId,
  eventId: SportItemId
) {
  return `/${SPORTS_URL_PREFIX}/${sportId}/${tournamentId}/${eventId}`
}

export const sportIDToTitlecase = (sportName: string) => {
  return sportName
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export function formatDateTime(date: string): string {
  return dayjs(date).format('dddd D MMM YYYY h:mm A')
}

export const friendlyMarketName = (marketName: string) => {
  if (marketName === 'h2h') return 'Head to Head'

  if (marketName.includes('_')) {
    return marketName
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  } else {
    return marketName
  }
}
