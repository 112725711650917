import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import React, { type FC } from 'react'
import { CreditCardBlockStyled, InfoHeaderStyled, NoticeBoxStyled } from './Precommitment.styles'
import { useMutation } from 'react-query'
import { Spinner } from '@mobi/component-library/Common/Spinner'
import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { CheckboxInput } from '@mobi/component-library/Common/Input'
import {
  PopupContainerStyled,
  PopupStyled,
  PopupTitleStyled,
} from '@core/Components/Popup/Popup.styles'
import type { PrecommitmentResponse } from '@mobi/account/Areas/SignUp/Utils/SignUpApi'
import { DEFAULT_COOLING_OFF_PERIOD_DAYS, PRECOMMITMENT_API_PATH_PREFIX } from './constants'
import { post } from '@classic/Foundation/Services/ApiService'
import dayjs from 'dayjs'

const BLOCK_CREDIT_CARD_URL = `${PRECOMMITMENT_API_PATH_PREFIX}UpdateCreditCardDeposits`

export const PrecommitmentCreditCardBlock = ({
  data,
  hasDataFetchFailed,
}: {
  data: PrecommitmentResponse | undefined
  hasDataFetchFailed: boolean
}): JSX.Element => {
  const [isPopupShowing, setIsPopupShowing] = React.useState(false)
  const [precommitmentDetails, setPrecommitmentDetails] = React.useState(data)

  React.useEffect(() => {
    setPrecommitmentDetails(data)
  }, [data])

  return (
    <form id='credit-card-block'>
      {isPopupShowing && (
        <CardBlockPopup
          setIsPopupShowing={setIsPopupShowing}
          setPrecommitmentDetails={setPrecommitmentDetails}
          precommitmentDetails={precommitmentDetails}
        />
      )}
      <InfoHeaderStyled level={3}>Block Debit Card</InfoHeaderStyled>
      <CreditCardBlockStyled>
        {hasDataFetchFailed && (
          <NoticeBox
            title={`Unable to display your debit card status. Please try again later.`}
            noticeBoxType={NoticeBoxTypes.Warning}
            hasBorder={true}
            data-tid-fetch-failed=''
          />
        )}
        {!hasDataFetchFailed && (
          <>
            <NoticeBox
              title={
                precommitmentDetails?.BlockCreditCardDeposits
                  ? precommitmentDetails?.ProposedCreditCardReleaseDate
                    ? `Your debit card block will be removed on ${precommitmentDetails?.ProposedCreditCardReleaseDate}.`
                    : 'Debit card deposits are currently blocked.'
                  : 'Debit card deposits are not currently blocked.'
              }
              noticeBoxType={
                precommitmentDetails?.BlockCreditCardDeposits
                  ? precommitmentDetails?.ProposedCreditCardReleaseDate
                    ? NoticeBoxTypes.Warning
                    : NoticeBoxTypes.Error
                  : NoticeBoxTypes.Info
              }
              hasBorder={true}
            />
            <ButtonBlock
              id='credit-card-block-button'
              type='button'
              onClick={() => setIsPopupShowing(true)}
            >
              {precommitmentDetails?.BlockCreditCardDeposits
                ? precommitmentDetails?.ProposedCreditCardReleaseDate
                  ? 'Cancel Unblock'
                  : 'Remove Block'
                : 'Block'}
            </ButtonBlock>
          </>
        )}
      </CreditCardBlockStyled>
    </form>
  )
}

type CardBlockPopupProps = {
  setIsPopupShowing: (x: boolean) => void
  setPrecommitmentDetails: (x: PrecommitmentResponse) => void
  precommitmentDetails?: PrecommitmentResponse
}

const CardBlockPopup: FC<CardBlockPopupProps> = ({
  setIsPopupShowing,
  setPrecommitmentDetails,
  precommitmentDetails,
}) => {
  const [hasAgreed, setHasAgreed] = React.useState(false)

  const willUnblock =
    precommitmentDetails?.BlockCreditCardDeposits &&
    !precommitmentDetails.ProposedCreditCardReleaseDate

  const mutation = useMutation(
    () =>
      post<PrecommitmentResponse>({
        url: BLOCK_CREDIT_CARD_URL,
        body: !willUnblock,
      }),
    {
      onSuccess: data => {
        setIsPopupShowing(false)
        setPrecommitmentDetails(data)
      },
    }
  )

  return (
    <PopupContainerStyled id='bet-limit-credit-card-block-popup'>
      <PopupStyled>
        {mutation.isLoading && <Spinner />}
        <Grid>
          <GridRow>
            <GridCell align='center'>
              <PopupTitleStyled id='credit-card-block-title'>
                {willUnblock ? 'Remove Debit Card Block' : 'Block Debit Card'}
              </PopupTitleStyled>
            </GridCell>
          </GridRow>
          {!willUnblock && (
            <>
              <GridRow>
                <GridCell align='left' padding='2em 0 0'>
                  <strong>By proceeding:</strong>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell align='left' data-tid-cooling-explanation padding='0 0 1em'>
                  I understand that debit card deposits to my account will be blocked effective
                  immediately. If I choose to remove this block in future, it will be subject to a{' '}
                  {precommitmentDetails?.CoolingOffPeriodDays || DEFAULT_COOLING_OFF_PERIOD_DAYS}{' '}
                  day cooling off period.
                </GridCell>
              </GridRow>
            </>
          )}
          {willUnblock && (
            <GridRow>
              <NoticeBoxStyled>
                <NoticeBox title='' noticeBoxType={NoticeBoxTypes.Warning} hasBorder={true}>
                  Your debit card block will be{' '}
                  <strong>
                    removed on{' '}
                    {dayjs()
                      .add(
                        precommitmentDetails?.CoolingOffPeriodDays ||
                          DEFAULT_COOLING_OFF_PERIOD_DAYS,
                        'days'
                      )
                      .format('DD/MM/YYYY')}
                  </strong>
                  , after the mandatory cooling off period.
                </NoticeBox>
              </NoticeBoxStyled>
            </GridRow>
          )}
          <GridRow>
            <CheckboxInput
              label='I understand and accept.'
              id='confirm-changes-checkbox'
              checked={hasAgreed}
              onChange={x => setHasAgreed(x.target.checked)}
              data-tid-confirm=''
            />
          </GridRow>
          <GridRow>
            <GridCell padding='2em 0.5em 0 0'>
              <ButtonBlock
                id='credit-card-block-cancel'
                color='secondary'
                type='button'
                onClick={() => setIsPopupShowing(false)}
              >
                Cancel
              </ButtonBlock>
            </GridCell>
            <GridCell padding='2em 0 0 0.5em'>
              <ButtonBlock
                id='credit-card-block-save'
                color='primary'
                type='button'
                disabled={mutation.isLoading || !hasAgreed}
                onClick={() => mutation.mutate()}
              >
                Save
              </ButtonBlock>
            </GridCell>
          </GridRow>
        </Grid>
      </PopupStyled>
    </PopupContainerStyled>
  )
}
