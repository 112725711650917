import React from 'react'
import { colors } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/Icon'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'
import { RacingTitle } from '@mobi/betslip/Components/Common/BetCard/Components/EventDetails/Components/RacingTitle'
import type { Ticket, TicketStatus } from '@core/Areas/AccountActivity/types'
import { getRaceLink } from '@core/Areas/Racing/helpers/getRaceLink'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import { CountdownStatus } from '../Common/CountdownStatus'

export const FavouriteNumbers: React.FC<{ ticket: Ticket; status: TicketStatus }> = ({
  ticket,
  status,
}) => {
  const ticketContext = React.useContext(TicketDisplayContext)

  const leg = ticket.legs[0]

  const shouldProvideLink = !!(
    (ticketContext.type === 'account-activity' || ticketContext.type === 'pending-bets-drawer') &&
    leg.fixtureId &&
    leg.fixtureDate &&
    leg.raceNumber
  )

  const linkInfo = shouldProvideLink
    ? {
        pathname: getRaceLink({
          meetingDate: leg.fixtureDate,
          meetingId: leg.fixtureId,
          raceNumber: leg.raceNumber,
          isFOO: false,
        }),
      }
    : undefined

  return (
    <>
      <BetCard.EventDetails
        heading={
          leg.meetingName ? (
            <RacingTitle
              races={leg.selections.map(({ raceNumber }) => ({ raceNumber }))}
              meetingName={leg.meetingName}
              isMultipleRaceSelection
            />
          ) : (
            leg.eventName
          )
        }
        statusComponent={<CountdownStatus ticket={ticket} status={status} />}
      />

      <BetCard.SelectionInfo
        heading={ticket.productName}
        subHeading={ticket.legs[0].selections.map(({ header, selectionDetails }) => (
          <React.Fragment key={header}>
            {header} - {selectionDetails[0].selectionString}
            <br />
          </React.Fragment>
        ))}
        linkInfo={linkInfo}
        iconComponent={<Icon size='2.4rem' type='favouriteNumbers' color={colors.neutral[800]} />}
      />
    </>
  )
}
