import React from 'react'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { colors, font } from '@mobi/component-library/Theme/Common'
import { LoadingSpinner } from '@mobi/component-library/Feedback/LoadingSpinner'

const enum LocalConstants {
  PreviousDateClass = 'acc-act__header__prev-date',
}

export const HeaderDate: React.FC<{ date: string; isOrderByEvent: boolean }> = React.memo(
  ({ date, isOrderByEvent }) => {
    const previousDisplayDateRef = React.useRef<string>('')

    React.useEffect(() => {
      previousDisplayDateRef.current = date
    }, [date])

    const prevDate = previousDisplayDateRef.current
    const shouldFindTimeDiff = date && prevDate && date !== prevDate

    const isNewDateBefore = shouldFindTimeDiff
      ? isOrderByEvent
        ? dayjs(date).isAfter(prevDate)
        : dayjs(date).isBefore(prevDate)
      : null

    return (
      <AccountActivityHeaderDateStyled data-tid-date-header>
        <AccountActivityDateStyled
          key={date}
          direction={shouldFindTimeDiff ? (isNewDateBefore ? 'up' : 'down') : 'neutral'}
        >
          {date ? (
            <>
              <span data-testid='account-activity-header-date'>{date}</span>
              <span aria-hidden className={LocalConstants.PreviousDateClass}>
                {prevDate}
              </span>
            </>
          ) : (
            <span data-testid='account-activity-header-loading'>
              <LoadingSpinner color='purple' size='1.8rem' />
            </span>
          )}
        </AccountActivityDateStyled>
      </AccountActivityHeaderDateStyled>
    )
  }
)

// ======
// Styles
// ======

const AccountActivityHeaderDateStyled = styled.div({
  flex: 1,
  position: 'relative',
  overflowY: 'hidden',
  marginLeft: '1rem',
})

type DateAnimationDirection = 'up' | 'down' | 'neutral'

const AccountActivityDateStyled = styled.div<{
  direction: DateAnimationDirection
}>(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    width: '100%',
    height: '4.8rem',
    color: colors.lavender[700],
    transition: 'transform 1s ease',
    fontFamily: font.family.primary,
    fontSize: font.size.lg.fontSize,
    fontWeight: font.weight.medium,
    lineHeight: font.size.md.lineHeight,

    '& > span': {
      display: 'flex',
      alignItems: 'center',
      minHeight: '4.8rem',
      position: 'absolute',
      top: 0,
      overflow: 'hidden',
    },

    '@keyframes animateUp': {
      '0%': {
        transform: 'translate3d(0, 100%, 0)',
        opacity: 0,
      },
      '90%': {
        opacity: 1,
      },
      '100%': {
        transform: 'translate3d(0, 0, 0)',
      },
    },

    '@keyframes animateDown': {
      '0%': {
        transform: 'translate3d(0, -100%, 0)',
        opacity: 0,
      },
      '90%': {
        opacity: 1,
      },
      '100%': {
        transform: 'translate3d(0, 0, 0)',
      },
    },
  },
  ({ direction }) => {
    if (direction === 'neutral') return undefined

    return {
      '& > span': {
        animation: `${direction === 'down' ? 'animateDown' : 'animateUp'} 0.4s ease-out`,
      },

      [`.${LocalConstants.PreviousDateClass}`]: {
        transform: `translateY(${direction === 'down' ? '100%' : '-100%'})`,
        animation: `${direction === 'down' ? 'animateUp' : 'animateDown'} 0.4s ease-in reverse`,
      },
    }
  }
)
