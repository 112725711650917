import {
  type ThreeDSecure,
  type ThreeDSecureVerifyPayload,
  type Client,
  threeDSecure,
} from 'braintree-web'
import type { ThreeDSecureVerifyOptions } from 'braintree-web/modules/three-d-secure'
import type { ContactDetails } from '@mobi/api-types'
import { coerceIntoError } from '@mobi/utils'
import { ThreeDSecureError, isBraintreeError } from '.'

type VerifyParams = {
  nonce: string
  depositAmount: number
  bin: string
  userData?: ContactDetails
}

export async function verifyThreeDSecure(
  client: Client,
  { bin, userData, nonce, depositAmount }: VerifyParams
): Promise<ThreeDSecureVerifyPayload> {
  const [threeDSClient, threeDSClientError] = await createThreeDSecureClient(client)

  if (threeDSClientError) {
    throw ThreeDSecureError.failedToInitialize(threeDSClientError)
  }

  let wasCancelled = false

  threeDSClient.on('lookup-complete', (_, next) => next?.())
  threeDSClient.on('customer-canceled', () => (wasCancelled = true))

  try {
    const data = await threeDSClient.verifyCard({
      nonce,
      bin,
      amount: depositAmount,
      ...formatAdditionalUserInformation(userData),
    })

    if (wasCancelled) {
      throw ThreeDSecureError.canceled()
    }

    if (!data) {
      throw ThreeDSecureError.noData()
    }

    return data
  } catch (error) {
    if (isBraintreeError(error)) {
      throw ThreeDSecureError.verificationFailed(error)
    }
    throw error
  } finally {
    threeDSClient.teardown()
  }
}

async function createThreeDSecureClient(
  client: Client
): Promise<[ThreeDSecure, undefined] | [undefined, Error]> {
  try {
    const threeDSClient = await threeDSecure.create({
      client,
      version: '2',
    })

    return [threeDSClient, undefined]
  } catch (error) {
    return [undefined, coerceIntoError(error)]
  }
}

type AdditionalInfo = Pick<
  ThreeDSecureVerifyOptions,
  'email' | 'mobilePhoneNumber' | 'billingAddress'
>

function formatAdditionalUserInformation(userData?: ContactDetails) {
  const additionalInfo: AdditionalInfo = {}

  if (!userData) {
    return additionalInfo
  }

  let streetAddress = [
    userData.PostalAddress.StreetNumber,
    userData.PostalAddress.StreetName,
    userData.PostalAddress.StreetType,
  ]
    .filter(part => null !== part)
    .join(' ')

  if (userData.PostalAddress.UnitNumber) {
    streetAddress = `${userData.PostalAddress.UnitNumber}/${streetAddress}`
  }

  additionalInfo.email = userData.Contact.Email ?? undefined
  additionalInfo.mobilePhoneNumber = userData.Contact.Mobile.PhoneNumber ?? undefined
  additionalInfo.billingAddress = {
    givenName: userData.NameDetails.FirstName ?? undefined,
    surname: userData.NameDetails.Surname ?? undefined,
    streetAddress,
    locality: userData.PostalAddress.Suburb ?? undefined,
    postalCode: userData.PostalAddress.Postcode ?? undefined,
    region:
      userData.PostalAddress.Country === 'Australia'
        ? `AU-${userData.PostalAddress.State}`
        : undefined,
    countryCodeAlpha2: userData.PostalAddress.Country === 'Australia' ? 'AU' : undefined,
  }

  return additionalInfo
}
