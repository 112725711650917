export type HostedFieldsErrorCode =
  | 'uninitialized'
  | 'validation_error'
  | 'tokenization_error'
  | 'network_error'
  | 'timeout'
  | 'unknown'

export class HostedFieldsError extends Error {
  /**
   * Don't call the constructor directly, use the static initialization methods
   *
   *  @private
   */
  private constructor(
    message: string,
    public readonly code: HostedFieldsErrorCode,
    public readonly fieldErrors: Record<string, string> = {}
  ) {
    super(message)
  }

  public hasFieldError(fieldName: string) {
    return fieldName in this.fieldErrors
  }

  public static uninitialized() {
    return new HostedFieldsError('Hosted fields are not initialized', 'uninitialized')
  }

  public static validationErrors(fields: Record<string, string>) {
    return new HostedFieldsError('One or more fields are invalid', 'validation_error', fields)
  }

  public static tokenizationFailure() {
    return new HostedFieldsError('Your card failed to verify', 'tokenization_error')
  }

  public static networkError() {
    return new HostedFieldsError('Card verification failed due to a network error', 'network_error')
  }

  public static unknown() {
    return new HostedFieldsError('An unknown error has occurred', 'unknown')
  }

  public static timeout() {
    return new HostedFieldsError('Hosted fields timed out trying to initialize', 'timeout')
  }
}
