import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'

export const SummaryLine: React.FC<{
  left: React.ReactNode
  right?: React.ReactNode
  testId?: string
}> = ({ left, right, testId }) => {
  return (
    <WrapperStyled data-testid={testId}>
      <span>{left}</span>
      {right && <span>{right}</span>}
    </WrapperStyled>
  )
}

const WrapperStyled = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: spacing.smx1,
  paddingTop: spacing.smx2,
  paddingBottom: spacing.smx2,
  fontSize: font.size.md.fontSize,
  letterSpacing: font.size.md.letterSpacing,
  lineHeight: font.size.md.lineHeight,
  fontWeight: font.weight.regular,
  color: colors.black,

  '> span:nth-of-type(2)': {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right',
    fontWeight: font.weight.medium,
    wordBreak: 'break-word',
  },
})
