import React from 'react'
import styled from '@emotion/styled'
import { SkyTvSvg } from '@mobi/component-library/Common/Svg'
import { spacing } from '@mobi/component-library/Theme/Common'
import { CountdownBadge } from '@mobi/component-library/Feedback/CountdownBadge'
import { StatusBadge } from '@mobi/component-library/Common/StatusBadge'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'
import { RacingTitle } from '@mobi/betslip/Components/Common/BetCard/Components/EventDetails/Components/RacingTitle'
import { getVisionChannelForDisplay } from '@core/Areas/SkyVideoPlayer/helpers'
import { isThoroughbredNextRaceInWA } from '@core/Areas/Racing/helpers/liveVideoFeedHelpers'
import type { NextSkyRace } from '@core/Areas/RaceCardSky/types'
import { useFeature } from '@core/Utils/hooks'

export const SkyRaceHeader: React.FC<{ nextSkyRace: NextSkyRace }> = ({ nextSkyRace }) => {
  const isRacingWAFeatureActive = useFeature('RACING_WA_VISION_CHANNEL')

  const { RaceNumber, MeetingName, SkyTvChannelId, AdvertisedStartTime } = nextSkyRace

  const shouldDisplayRacingWA = isRacingWAFeatureActive && isThoroughbredNextRaceInWA(nextSkyRace)

  return (
    <BetCard.EventDetails
      heading={
        <RacingTitle
          races={[{ raceNumber: RaceNumber }]}
          meetingName={MeetingName}
          isMultipleRaceSelection={false}
        />
      }
      statusComponent={
        <StatusStyled>
          <StatusBadge>
            <SkyTvSvg
              channel={getVisionChannelForDisplay(SkyTvChannelId as string, shouldDisplayRacingWA)}
              width='3rem'
            />
          </StatusBadge>
          <CountdownBadge advertisedStartTime={new Date(AdvertisedStartTime)} />
        </StatusStyled>
      }
    />
  )
}

// ======
// Styles
// ======

const StatusStyled = styled.header({
  display: 'flex',
  gap: spacing.smx2,
})
