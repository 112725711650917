import React from 'react'
import { toTitleCase } from '@mobi/utils'
import { StatusBadge } from '@mobi/component-library/Common/StatusBadge/StatusBadge'
import { Race } from '@mobi/betslip/types'

export const RacingTitle: React.FC<{
  races: Pick<Race, 'raceNumber'>[]
  meetingName: string
  isMultipleRaceSelection: boolean
}> = ({ races, meetingName, isMultipleRaceSelection }) => {
  const racesToRender = isMultipleRaceSelection ? races : races.slice(0, 1)

  return (
    <>
      {racesToRender.map(({ raceNumber }) => (
        <React.Fragment key={raceNumber}>
          <StatusBadge badgeSize='S'>R{raceNumber}</StatusBadge>{' '}
        </React.Fragment>
      ))}{' '}
      {toTitleCase(meetingName)}
    </>
  )
}
