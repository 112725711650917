import React from 'react'
import styled from '@emotion/styled'
import { Button } from '../../Common/V2'
import { defer } from '@mobi/utils'
import { gapX, gapY } from '../../Utils/gap'
import { MoneyInput } from './MoneyInput'

type ShortcutAmount = { amount: number; label: string }
const defaultShortcutAmounts: ShortcutAmount[] = [
  { amount: 5, label: '$5' },
  { amount: 10, label: '$10' },
  { amount: 20, label: '$20' },
  { amount: 50, label: '$50' },
  { amount: 100, label: '$100' },
]

export type PaymentAmountProps = {
  helpText: string
  isDisabled: boolean
  maximumAmount: number
  inputValue: string
  shortcutAmounts?: ShortcutAmount[]
  onAmountChange: (value: string) => void
}

export const fiveDigitMoneyInputRegex = /^(\d{0,5}(\.\d{0,2})?)?.*/
export function sanitizeInput(input: string, regex: RegExp) {
  return input.replace(regex, '$1')
}

export const PaymentAmount = ({
  helpText,
  isDisabled,
  inputValue,
  shortcutAmounts = defaultShortcutAmounts,
  onAmountChange,
}: PaymentAmountProps) => {
  const onInputValueChanged = (inputValue: string) => {
    const sanitizedValue = sanitizeInput(inputValue, fiveDigitMoneyInputRegex)
    onAmountChange(sanitizedValue)
  }

  const onAddDefinedAmount = (amount: number) => {
    const currentAmount = (+inputValue || 0) * 100
    const amountToAdd = amount * 100
    const newAmount = (currentAmount + amountToAdd) / 100
    onAmountChange(newAmount.toFixed(2))
  }

  // Tack on cents if not entered by user
  const onBlur = (inputValue: string) => {
    if (inputValue.length > 0 && !inputValue.includes('.')) {
      onAmountChange(inputValue.concat('.00'))
    }
  }

  return (
    <PaymentAmountContainerStyled>
      <InputContainerStyled>
        <MoneyInput
          id='amount-input'
          label=''
          name='amount-input'
          inputValue={inputValue}
          helpText={helpText}
          onClearButtonPress={() => onAmountChange('')}
          onInputValueChange={e => {
            onInputValueChanged(e.currentTarget.value)
          }}
          onBlur={e => {
            // ensures onBlur callback is queued only after onClearButtonPress
            defer(() => {
              onBlur(e.target.value)
            })
          }}
          isDisabled={isDisabled}
        />
      </InputContainerStyled>

      <AmountButtonsContainerStyled>
        {shortcutAmounts.map(({ amount, label }) => (
          <AmountButtonStyled
            key={label}
            color='secondary_outline'
            onClick={() => {
              onAddDefinedAmount(amount)
            }}
            disabled={isDisabled}
          >
            {label}
          </AmountButtonStyled>
        ))}
      </AmountButtonsContainerStyled>
    </PaymentAmountContainerStyled>
  )
}

const PaymentAmountContainerStyled = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gapY('3rem')
)

const InputContainerStyled = styled.div({
  width: '32rem',
})

const AmountButtonsContainerStyled = styled.div(
  {
    width: '100%',
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
  },
  gapX('1rem')
)

const AmountButtonStyled = styled(Button)({
  width: '0',
  flex: '1 1 0px',
})
