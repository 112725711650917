import { debounceFn } from '@mobi/utils'
import { state$ as userAccountState$ } from '@core/State/UserAccount/userAccountDriver'
import { toggleIsHamburgerMenuOpen } from '@core/Areas/AppHeader/Store'
import { showLogin } from '@core/Areas/Login/helpers/showLogin'
import { store } from '@core/Store'
import { navigateToSkyRacePage } from '@core/Areas/RaceCardSky/helpers/navigateToSkyRacePage'
import { setCloseSkyVideoPlayer, setSkyVideoPlayerStatus, SkyVideoPlayerState } from '../Store'
import { trackVisionOpened } from '../analytics'
import { selectIsSkyVideoPlayerOpen } from '../Store/selectors'
import { queryClient } from '@core/Data/ReactQuery'
import { getEntitlementUrlFromApi } from '@core/Areas/Racing/Components/RaceReplay/api'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { logError } from '@classic/Foundation/Services/LoggingService'
import { displayVisionNotEntitledMessage } from '../Components/VideoPlayer/helpers/useEntitlement'

export function toggleSkyVideoPlayer({
  selectedChannel,
  playerStatus,
  isPlayerOpen,
  analyticsData,
  raceReplayKey,
  raceReplayUrl,
  raceReplayTitle,
  raceReplayMeetingDate,
}: StartSkyVideoPlayerOptions = {}): void {
  if (
    isPlayerOpen === false ||
    (isPlayerOpen === undefined && selectIsSkyVideoPlayerOpen(store.getState()))
  ) {
    store.dispatch(setCloseSkyVideoPlayer())
    return
  }

  store.dispatch(toggleIsHamburgerMenuOpen(false))

  userAccountState$.take(1).subscribe(({ isLoggedIn }) => {
    const openSkyVideoPlayer = async () => {
      if (raceReplayKey && selectedChannel && selectedChannel === 'Replay') {
        try {
          const entitlementData = await queryClient.fetchQuery({
            queryKey: queryKeys.videoEntitlementRequest(raceReplayKey),
            queryFn: () => getEntitlementUrlFromApi(raceReplayKey),
            staleTime: 30_000,
          })
          if (!entitlementData.IsEntitled) {
            store.dispatch(setCloseSkyVideoPlayer())

            const notEntitledMessage = entitlementData.ReasonText
              ? entitlementData.ReasonText
              : raceReplayKey
                ? 'You are not authorised to view this race replay'
                : 'You are not authorised to view SKY Racing vision'

            displayVisionNotEntitledMessage(notEntitledMessage)
            return
          }
          if (!entitlementData.VideoKey) {
            logError(
              `getEntitlementUrlFromApi: url is empty: raceReplayKey=${raceReplayKey}, entitlementData=${JSON.stringify(entitlementData)}`
            )
            return
          }
          raceReplayUrl = entitlementData.VideoKey
        } catch (e) {
          logError(
            e as Error,
            `getEntitlementUrlFromApi: failed to retrieve: raceReplayKey=${raceReplayKey}`
          )
          return
        }
      }

      store.dispatch(
        setSkyVideoPlayerStatus({
          playerStatus: playerStatus || 'floating',
          selectedChannel,
          raceReplayKey,
          raceReplayUrl,
          raceReplayTitle,
          raceReplayMeetingDate,
        })
      )
    }

    if (isLoggedIn) {
      openSkyVideoPlayer()
      analyticsData && trackVisionOpened(analyticsData)
    } else {
      const handleOnLoginSuccess = () => {
        if (analyticsData?.openLocation === 'bottom-navigation') {
          navigateToSkyRacePage()
        } else openSkyVideoPlayer()
      }
      showLogin({ onLoginSuccessCallback: debounceFn(handleOnLoginSuccess, 500) })
    }
  })
}

// =====
// Types
// =====

type StartSkyVideoPlayerOptions = {
  analyticsData?: Parameters<typeof trackVisionOpened>[0]
} & Partial<
  Pick<
    SkyVideoPlayerState,
    | 'selectedChannel'
    | 'playerStatus'
    | 'isPlayerOpen'
    | 'raceReplayKey'
    | 'raceReplayUrl'
    | 'raceReplayTitle'
    | 'raceReplayMeetingDate'
  >
>
