import React, { type FC, type HTMLAttributes } from 'react'
import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { Icon } from '@mobi/component-library/Common/Icon'
import { colors } from '@core/Settings'
import {
  NoticeBoxTitleStyled,
  NoticeBoxStyled,
  NoticeBoxTypes,
  NoticeBoxIconStyled,
  NoticeBoxChildContentWrapperStyled,
  NoticeBoxSingleStyled,
} from './NoticeBox.styles'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'

type Props = {
  title?: string | null
  subtitle?: string | null
  noticeBoxType?: NoticeBoxTypes
  hideIcon?: boolean
  hasBorder?: boolean
  className?: string
  testId?: string | null
  buttonClick?: () => void
  buttonText?: string
  children?: React.ReactNode
} & Pick<HTMLAttributes<HTMLDivElement>, 'role' | 'aria-label'>

export const NoticeBox: FC<Props> = ({
  title,
  subtitle,
  noticeBoxType,
  hideIcon = false,
  hasBorder,
  children,
  className,
  testId = title,
  buttonClick,
  buttonText = 'OK',
  ...htmlAttributes
}) => {
  const titleTestId = { 'data-tid-notice-box-title': title }

  return (
    <NoticeBoxStyled
      className={className}
      noticeBoxType={noticeBoxType}
      hasBorder={hasBorder}
      data-tid-notice-box={testId}
      data-testid={testId}
      {...htmlAttributes}
    >
      <Grid>
        {!hideIcon && (
          <NoticeBoxIconStyled>
            <NoticeBoxIcon type={noticeBoxType} />
          </NoticeBoxIconStyled>
        )}
        <GridCell valign='middle'>
          {title && (
            <NoticeBoxTitleStyled {...titleTestId}>
              <strong>{title}</strong>
            </NoticeBoxTitleStyled>
          )}

          {(subtitle || children) && (
            <NoticeBoxChildContentWrapperStyled data-tid-notice-box-children={title}>
              {subtitle ? <p>{subtitle}</p> : null}
              {children}
            </NoticeBoxChildContentWrapperStyled>
          )}
        </GridCell>

        {buttonClick && (
          <GridRow padding='2rem 0 0 0'>
            <GridCell width='100%' valign='middle'>
              <ButtonBlock onClick={buttonClick} color='primary'>
                {buttonText}
              </ButtonBlock>
            </GridCell>
          </GridRow>
        )}
      </Grid>
    </NoticeBoxStyled>
  )
}

export function NoticeBoxSingle({
  title,
  noticeBoxType,
  hasBorder,
  testId,
  isDesktop,
}: {
  title: React.ReactNode
  noticeBoxType?: NoticeBoxTypes
  hasBorder?: boolean
  testId?: string
  isDesktop?: boolean
}): JSX.Element {
  const testAutomationValue = testId || ''
  const testAutomationId = { 'data-tid-notice-box-single': testAutomationValue }

  return (
    <NoticeBoxSingleStyled
      noticeBoxType={noticeBoxType}
      hasBorder={hasBorder}
      {...testAutomationId}
      isDesktop={isDesktop}
    >
      <Grid>
        <NoticeBoxIconStyled>
          <NoticeBoxIcon type={noticeBoxType} />
        </NoticeBoxIconStyled>
        <GridCell valign='middle'>{title}</GridCell>
      </Grid>
    </NoticeBoxSingleStyled>
  )
}

const NoticeBoxIcon = ({ type }: { type?: NoticeBoxTypes }) => {
  if (type === undefined)
    return <Icon type='infoCircled' size='3.2rem' color={colors.messages.info.text} />

  switch (type) {
    case NoticeBoxTypes.Error:
      return <Icon type='errorCircled' size='3.2rem' color={colors.messages.error.text} />
    case NoticeBoxTypes.Success:
      return <Icon type='tickCircled' size='3.2rem' color={colors.messages.success.text} />
    case NoticeBoxTypes.Warning:
      return <Icon type='warningCircled' size='3.2rem' color={colors.messages.warning.text} />
    default:
    case NoticeBoxTypes.Info:
      return <Icon type='infoCircled' size='3.2rem' color={colors.messages.info.text} />
  }
}
