import React, { useEffect } from 'react'
import { QueryClient, QueryClientProvider, setLogger, type Logger } from 'react-query'

export const createTestQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        cacheTime: Infinity,
      },
    },
  })

type WrapperProps = {
  client?: QueryClient
  logger?: Partial<Logger>
}

const defaultLogger: Logger = {
  // eslint-disable-next-line no-console
  warn: console.warn,
  // eslint-disable-next-line no-console
  log: console.log,
  // eslint-disable-next-line no-console
  error: console.error,
}

export function createWrapper<TProps>(props?: WrapperProps) {
  const Wrapper: React.FC<TProps & { children?: React.ReactNode }> = ({
    children,
  }: {
    children?: React.ReactNode
  }) => {
    useEffect(() => {
      setLogger({
        ...defaultLogger,
        ...(props?.logger ?? {}),
      })

      return () => {
        setLogger(defaultLogger)
      }
    }, [])

    return (
      <QueryClientProvider client={props?.client ?? createTestQueryClient()}>
        {children}
      </QueryClientProvider>
    )
  }
  return Wrapper
}
