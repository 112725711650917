import {
  isAccountBalanceChangedPushEvent,
  isAccountBalanceChangedExceptionPushEvent,
  isPayIdStatusChangedPushEvent,
  PushEvent,
} from 'tabtouch-push-contract'
import { FeedbackBalanceChange, FeedbackPayIdStatusChange } from '../Areas/Deposit/Utils'
import { QueryClient } from 'react-query'
import { queryKeys } from './constants'

export const balanceUpdate = (
  payload: PushEvent,
  queryClient: QueryClient,
  onSuccess: () => void
) => {
  const isBalanceChangedSuccess = isAccountBalanceChangedPushEvent(payload)
  const isBalanceChangedError = isAccountBalanceChangedExceptionPushEvent(payload)
  if (!isBalanceChangedSuccess && !isBalanceChangedError) return

  FeedbackBalanceChange(payload)
  if (isBalanceChangedSuccess) {
    onSuccess()
    queryClient.invalidateQueries({
      queryKey: [queryKeys.depositInitialData],
    })
  }
}

export const payIdUpdate = (
  payload: PushEvent,
  accountNumber: number,
  queryClient: QueryClient
) => {
  /*
   * We are using a random number to track if the activation
   * event is getting fired over multiple tabs.
   */
  const processId = Math.round(Math.random() * 1e6)
  if (isPayIdStatusChangedPushEvent(payload)) {
    FeedbackPayIdStatusChange(accountNumber, payload.status, processId)
    // Refetch the PayId details
    queryClient.invalidateQueries({
      queryKey: [queryKeys.depositBetAccount],
    })
  }
}
