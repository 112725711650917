import * as ko from 'knockout'
import React from 'react'
import { createRoot, Root } from 'react-dom/client'
import { Provider } from 'react-redux'
import { QueryClientProvider } from 'react-query'
import { store } from '@core/Store'
import { queryClient } from '@core/Data/ReactQuery/queryClient'

const mapOfReactRoots = new WeakMap<Element, Root>()

ko.bindingHandlers.react = {
  init: (element: Element) => {
    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      const root = mapOfReactRoots.get(element)
      setTimeout(() => {
        root?.unmount()
        mapOfReactRoots.delete(element)
      })
    })
    return { controlsDescendantBindings: true }
  },

  update: (
    element: Element,
    valueAccessor: () => ko.MaybeSubscribable<React.FunctionComponent<unknown>>,
    allBindingsAccessor?
  ) => {
    const componentType = ko.unwrap(valueAccessor())
    const options = ko.unwrap(allBindingsAccessor?.())

    if (options && componentType) {
      const props = ko.toJS(options.props)
      const nonObservables = options.nonObservables ?? {}

      let wrappedComponent = React.createElement(componentType, { ...props, ...nonObservables })

      if (options.shouldWrapWithRedux) {
        // @ts-expect-error Typing issue
        wrappedComponent = React.createElement(Provider, { store }, wrappedComponent)
      }

      if (options.shouldWrapWithReactQuery) {
        // @ts-expect-error Typing issue
        wrappedComponent = React.createElement(
          QueryClientProvider,
          { client: queryClient },
          wrappedComponent
        )
      }

      let root: Root
      if (mapOfReactRoots.has(element)) {
        root = mapOfReactRoots.get(element) as Root
      } else {
        root = createRoot(element)
        mapOfReactRoots.set(element, root)
      }
      root.render(wrappedComponent)
    }
  },
}
