import React from 'react'
import styled from '@emotion/styled'
import { colors, radius } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2'

const enum LocalConstants {
  MediumSizeClass = 'js-radio--md',
  TickTypeClass = 'js-radio--tick',
}

export const RadioButton: React.FC<
  {
    id: string
    type: 'Tick' | 'Radio'
    isChecked: boolean
    onClick?(): void
    size?: 'sm' | 'md'
    isDisabled?: boolean
  } & ({ text?: string; supportingText?: never } | { text: string; supportingText?: string })
> = ({
  id,
  type,
  size = 'sm',
  isChecked,
  onClick,
  isDisabled = false,
  // TODO: Extend RadioButton to match Figma text labels
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  text,
  // TODO: Extend RadioButton to match Figma text labels
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  supportingText,
}) => {
  const classNames: string[] = []
  if (type === 'Tick') classNames.push(LocalConstants.TickTypeClass)
  if (size === 'md') classNames.push(LocalConstants.MediumSizeClass)

  return (
    <WrapperStyled className={classNames.join(' ')}>
      <span>
        <input
          id={id}
          type='checkbox'
          checked={isChecked}
          onChange={() => !isDisabled && onClick?.()}
          disabled={isDisabled}
          aria-checked={isChecked}
          role='radio'
        />

        <label htmlFor={id}>
          {isChecked &&
            (type === 'Radio' ? (
              <span />
            ) : (
              <Icon
                name='LineCheck'
                size={size === 'sm' ? '1rem' : '1.4rem'}
                color={colors.white}
              />
            ))}
        </label>
      </span>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  '--radioSize': '1.6rem',
  '--radioDarkColor': colors.neutral[300],
  '--radioLightColor': colors.white,

  position: 'relative',
  display: 'inline-flex',

  [`&.${LocalConstants.MediumSizeClass}`]: { '--radioSize': '2rem' },

  // Radio Button
  '> span:first-of-type': {
    '> label': {
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'var(--radioSize)',
      height: 'var(--radioSize)',
      borderRadius: radius.full,
      touchAction: 'manipulation',
      cursor: 'pointer',
      transition: 'all 150ms linear',
    },

    '> input[type=checkbox]': {
      height: 0,
      width: 0,
      visibility: 'hidden',
      appearance: 'none',
      position: 'absolute',

      ':checked + label': {
        '--radioDarkColor': colors.lavender[500],
      },

      ':disabled + label': {
        '--radioDarkColor': colors.neutral[300],
        '--radioLightColor': colors.neutral[200],
        cursor: 'not-allowed',
      },

      ':checked:disabled + label': {
        '--radioDarkColor': colors.neutral[300],
        '--radioLightColor': colors.neutral[200],
      },

      '+ label': {
        border: '0.1rem solid var(--radioDarkColor)',
        backgroundColor: 'var(--radioLightColor)',
      },
    },
  },

  [`&.${LocalConstants.TickTypeClass}`]: {
    'input[type=checkbox]:checked + label': {
      backgroundColor: 'var(--radioDarkColor)',
    },
  },

  [`&:not(.${LocalConstants.TickTypeClass})`]: {
    'input[type=checkbox]:checked + label > span': {
      width: 'calc(var(--radioSize) / 2)',
      height: 'calc(var(--radioSize) / 2)',
      borderRadius: radius.full,
      backgroundColor: 'var(--radioDarkColor)',
    },
  },
})
