import React from 'react'
import styled from '@emotion/styled'
import { colors, font, radius, spacing } from '@mobi/component-library/Theme/Common'
import { Button } from '@mobi/component-library/Common/V2/Buttons'
import { Icon } from '@mobi/component-library/Common/V2/Icon'

export const Content: React.FC<
  {
    icon?: ToastType
    button?: { text: string; onClick: () => void }
  } & ({ title: string; subtitle?: string } | { title?: never; subtitle?: never })
> = ({ icon, title, subtitle, button }) => {
  return (
    <WrapperStyled>
      <div data-icon-type={icon}>
        {icon && <Icon color='inherit' name={iconPathDict[icon]} size='1.6rem' />}
      </div>

      <div>
        {title && (
          <>
            <span>{title}</span>
            {subtitle && <span>{subtitle}</span>}
          </>
        )}
      </div>

      <div>
        {button && (
          <Button color='secondary_color' size='xs' onClick={button.onClick}>
            {button.text}
          </Button>
        )}
      </div>
    </WrapperStyled>
  )
}

// =============
// Local Helpers
// =============

const iconPathDict: Record<ToastType, React.ComponentProps<typeof Icon>['name']> = {
  success: 'LineCheckCircle',
  warning: 'LineAlertTriangle',
  error: 'LineAlertCircle',
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: spacing.smx1,

  '> div:empty': { display: 'none' },

  // Icon
  '> div:nth-of-type(1)': {
    alignItems: 'center',
    borderRadius: radius.full,
    display: 'flex',
    height: '3.2rem',
    justifyContent: 'center',
    minWidth: '3.2rem',
    width: '3.2rem',

    '&[data-icon-type="success"]': {
      color: colors.success[500],
      backgroundColor: colors.success[50],
    },

    '&[data-icon-type="warning"]': {
      color: colors.warning[500],
      backgroundColor: colors.warning[50],
    },

    '&[data-icon-type="error"]': {
      color: colors.error[500],
      backgroundColor: colors.error[50],
    },
  },

  // Titles
  '> div:nth-of-type(2)': {
    display: 'flex',
    flexDirection: 'column',

    '> span:nth-of-type(2)': {
      fontWeight: font.weight.regular,
      color: colors.neutral[800],
    },
  },
})

// =====
// Types
// =====

type ToastType = 'success' | 'warning' | 'error'
