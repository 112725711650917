import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { InfoBox } from '@mobi/component-library/Common/V2/Display/InfoBox'
import { toMoney } from '@mobi/utils/money'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { calculateEstReturn, calculateTotalStake } from '@mobi/betslip/helpers/calculator/combined'
import {
  getBetsToPlace,
  getBetsInMulti,
  isValidMulti,
  isFatalErrorType,
} from '@mobi/betslip/helpers/state'
import { BetSlipContext } from '@mobi/betslip/context'
import { ErrorMessage } from './Components/Messages/ErrorMessage'
import { InvalidBetsMessage } from './Components/Messages/InvalidBetsMessage'
import { ReviewChangesMessage } from './Components/Messages/ReviewChangesMessage'
import { BetsPlacedSuccessToast } from './Components/BetsPlacedSuccessToast'

export const SummarySection: React.FC = () => {
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)
  const apiErrorMessage = useAppSelector(state => state.betslip.workflow.apiErrorMessage)
  const items = useAppSelector(state => state.betslip.bets.items)
  const multiBetError = useAppSelector(state => state.betslip.bets.multiBetError)
  const multiInvestment = useAppSelector(state => state.betslip.bets.multiInvestment)
  const multiReceipt = useAppSelector(state => state.betslip.bets.multiReceipt)
  const accountBalance = useAppSelector(state => state.betslip.workflow.hostAccountBalance)

  const toastEmitter = React.useContext(BetSlipContext)?.toastEmitter

  React.useEffect(() => {
    if (workflowStatus !== 'bets-placed' || !toastEmitter) return
    toastEmitter.emit('addToast', {
      id: 'success',
      content: <BetsPlacedSuccessToast />,
      isDismissable: true,
      timeoutMs: Infinity,
    })
    return () => {
      toastEmitter.emit('removeToast', { id: 'success' })
    }
  }, [workflowStatus, toastEmitter])

  const isValidMultiPresent =
    !multiReceipt && isValidMulti(multiInvestment, multiBetError, getBetsInMulti(items))

  const singlesLeftToPlace = getBetsToPlace(items).length

  const hasProposed = workflowStatus === 'proposed'
  const totalStake = calculateTotalStake(items, multiInvestment, multiBetError)
  const isInsufficientFunds = accountBalance && accountBalance < totalStake

  const hasInsufficientFundsError =
    isInsufficientFunds ||
    (hasProposed && !!multiBetError && multiBetError.betErrorType === 'InsufficientFunds') ||
    items.some(x => x.betErrorType === 'InsufficientFunds')

  const hasSeriousMultiError =
    !!multiBetError &&
    (isFatalErrorType(multiBetError.betErrorType) ||
      multiBetError.betErrorType === 'BetPlacementFault' ||
      multiBetError.betErrorType === 'DuplicateBonusBet' ||
      multiBetError.betErrorType === 'Unspecified')

  const hasMultiLeftToPlace = !multiReceipt && !hasSeriousMultiError && isValidMultiPresent
  const hasBetsLeftToPlace = singlesLeftToPlace > 0 || hasMultiLeftToPlace

  const hasErrors =
    !!multiBetError || items.some(x => x.betErrorType !== undefined || !!x.multiBetLegError)
  const hasPlacedBets = items.some(x => !!x.receipt) || !!multiReceipt

  const multiItems = getBetsInMulti(items)
  const placeableItems = getBetsToPlace(items)
  const placeableMultis =
    !multiReceipt && isValidMulti(multiInvestment, null, multiItems) ? multiItems : []
  const estimatedReturn = calculateEstReturn(placeableItems, placeableMultis, multiInvestment)

  const displayValue = Number.isFinite(estimatedReturn) && estimatedReturn > 0

  return (
    <WrapperStyled>
      {hasInsufficientFundsError && (
        <InfoBox size='md' color='red'>
          <span>Low balance</span>
          <div>Please make a deposit or adjust stake to place a bet.</div>
        </InfoBox>
      )}

      {(hasProposed || !!apiErrorMessage) && (
        // TODO: Determine where error messaging will go, refer to logic
        <span style={{ background: 'white ', display: 'none' }}>
          {!!apiErrorMessage && <ErrorMessage errorMessage={apiErrorMessage} />}

          {!hasBetsLeftToPlace && !hasPlacedBets && <InvalidBetsMessage />}

          {hasBetsLeftToPlace && hasErrors && (
            <ReviewChangesMessage includeCancellationWarning={hasProposed} />
          )}

          {hasInsufficientFundsError && (
            <ErrorMessage errorMessage='Insufficient funds to place bet' />
          )}
        </span>
      )}

      <div>
        <span>Total Cost</span>{' '}
        <span>{toMoney(calculateTotalStake(items, multiInvestment, multiBetError))}</span>
      </div>

      <div>
        <span>Potential Returns</span>{' '}
        <span>{displayValue ? toMoney(estimatedReturn) : 'N/A'}</span>
      </div>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.sm,

  '> div': {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: font.size.lg.fontSize,
    letterSpacing: font.size.lg.letterSpacing,
    lineHeight: font.size.lg.lineHeight,
    fontWeight: font.weight.regular,

    '> span': {
      color: colors.white,
    },

    '> span:last-of-type': {
      fontWeight: font.weight.semibold,
    },
  },
})
