import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export const initialState: DeepReadonly<SettingsState> = {
  hapticFeedback: {
    enabled: true,
  },
  enhancedBetslip: {
    enabled: true,
    touched: false,
  },
  newSports: {
    enabled: true,
  },
  newBetSlip: {
    enabled: true,
  },
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setHapticFeedbackSetting(state, { payload }: PayloadAction<boolean>) {
      state.hapticFeedback.enabled = payload
    },
    setNewSportsSetting(state, { payload }: PayloadAction<boolean>) {
      state.newSports.enabled = payload
    },
    setNewBetSlipSetting(state, { payload }: PayloadAction<boolean>) {
      state.newBetSlip.enabled = payload
    },
    setEnhancedBetslipSetting(state, { payload }: PayloadAction<boolean>) {
      state.enhancedBetslip.enabled = payload
      state.enhancedBetslip.touched = true
    },
  },
})

export const {
  setHapticFeedbackSetting,
  setEnhancedBetslipSetting,
  setNewSportsSetting,
  setNewBetSlipSetting,
} = settingsSlice.actions

const whitelist: Array<keyof SettingsState> = [
  'hapticFeedback',
  'enhancedBetslip',
  'newSports',
  'newBetSlip',
]
export default persistReducer(
  { key: 'settings', storage, whitelist, debug: !PRODUCTION },
  settingsSlice.reducer
)

// =====
// Types
// =====

export interface SettingsState {
  hapticFeedback: Setting
  enhancedBetslip: TouchedSetting
  newSports: Setting
  newBetSlip: Setting
}

type Setting = { enabled: boolean }

type TouchedSetting = Setting & { touched: boolean }
