import { logError } from '@core/Utils'
import React from 'react'

// TODO move somewhere higher up the filesystem
export class TrendingBetTileErrorBoundary<
  TP extends { children?: React.ReactNode },
> extends React.Component<TP, { hasError: boolean }> {
  constructor(props: TP) {
    super(props)
    this.state = { hasError: false }
  }

  public componentDidCatch(error: { toString: () => string } /*, info*/) {
    logError(`TrendingBetTile render error: ${error.toString()}`, true)
    this.setState({
      hasError: true,
    })
  }

  public render() {
    if (this.state.hasError) {
      return <></>
    }

    return this.props.children
  }
}
