import React, { type FC } from 'react'
import { Accordion } from '@mobi/component-library/Common/V2'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { track } from '../../../../../Utils'

type Props = {
  accountNumber: number
}

const PayIDInstructions: FC<Props> = ({ accountNumber }) => {
  return (
    <ContainerStyled>
      <Accordion
        title='How to use PayID'
        onToggle={isExpanded => {
          if (!isExpanded) {
            track('deposit_payid_help', {
              accountNumber,
            })
          }
        }}
      >
        <ContentStyled>
          <ol>
            <li>Generate your unique TABtouch PayID</li>
            <li>Copy your PayID</li>
            <li>Log in to your banking app</li>
            <li>Select PayID or payee via email address</li>
            <li>Paste your TABtouch PayID and finalise the payment</li>
          </ol>
          <p>
            Your PayID is unique to your account. Please ensure you copy the PayID field accurately
            before entering it into your banking app.
          </p>
          <p>
            If your banking app doesn’t explicitly state PayID, look for the option to transfer via
            email address to complete the transfer.
          </p>
          <p>
            PayID is an instant transaction, however your bank may delay the first deposit from your
            bank account due to their own security controls.
          </p>
        </ContentStyled>
      </Accordion>
    </ContainerStyled>
  )
}

PayIDInstructions.displayName = 'PayIDInstructions'

// ======
// Styles
// ======

const ContainerStyled = styled.div({
  marginTop: spacing.md,
})

const ContentStyled = styled.div({
  paddingBottom: spacing.smx2,
  color: colors.neutral[900],
  ...font.size.md,

  ol: {
    marginTop: 0,
    paddingLeft: spacing.none,
    marginLeft: '2ch',
    lineHeight: '2.5rem',
  },
  p: {
    marginTop: spacing.big,
  },
})

export default PayIDInstructions
