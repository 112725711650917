import type { PanelProps } from '../../types'
import { DepositError } from '../../DepositError'
import { useDeposit } from '../../../Hooks'
import { sendToNative, subscribeToNative } from '@mobi/web-native-comms/web'

type PayParams = Pick<
  PanelProps,
  'onSuccess' | 'onStart' | 'onFailure' | 'onCancel' | 'onDepositing'
> & {
  clientToken: string
  transactionId: string
  depositAmount: number
  isReady: () => Promise<boolean>
  deposit: ReturnType<typeof useDeposit>['depositMutation']['mutateAsync']
}

/**
 * Initiate a native Google Pay payment
 */
export async function pay({
  onFailure,
  onSuccess,
  onStart,
  onCancel,
  onDepositing,
  isReady,
  depositAmount,
  clientToken,
  transactionId,
  deposit,
}: PayParams) {
  isReady()
    .then(canDeposit => {
      if (canDeposit) {
        onStart()
        return Promise.resolve()
      }

      return Promise.reject()
    })
    .then(() => {
      const abortController = new AbortController()

      const promise = Promise.race([
        waitForGooglePaySuccess(abortController.signal),
        waitForGooglePayFailure(abortController.signal, transactionId),
        waitForGooglePayCanceled(abortController.signal, transactionId),
      ]).finally(() => {
        // When any of the `Promise`s has settled we need to prevent any other promise from
        // calling back, as these are now NOOP. By having passed the abort signal to all
        // other subscriptions, we can call abortController.abort() to clean them up.
        abortController.abort()
      })

      sendToNative('REQUEST_GOOGLE_PAY_BEGIN_PAYMENT', {
        token: clientToken,
        price: depositAmount,
      })

      return promise
    })
    .then(token => {
      onDepositing()

      return deposit({
        amount: depositAmount,
        paymentMethodNonce: token,
        depositSource: 'GooglePay',
        transactionId,
      })
    })
    .then(({ isSuccess, ...errorDetails }) => {
      if (!isSuccess) {
        errorDetails.transactionId ??= transactionId
        return Promise.reject(DepositError.fromErrorDetails(errorDetails))
      }

      return onSuccess(depositAmount)
    })
    .catch(error => {
      if (error instanceof DepositError && error.wasCanceled) {
        return onCancel?.()
      }

      return onFailure(DepositError.coerce(error, transactionId))
    })
}

function waitForGooglePaySuccess(abort: AbortSignal) {
  return new Promise<string>((resolve, reject) => {
    abort.addEventListener('abort', reject, {
      once: true,
    })

    subscribeToNative(
      'RESPONSE_GOOGLE_PAY_SUCCESS',
      ({ token }) => {
        resolve(token)
      },
      {
        cleanupSignal: abort,
      }
    )
  })
}

function waitForGooglePayFailure(abort: AbortSignal, transactionId: string) {
  return new Promise<never>((_, reject) => {
    abort.addEventListener('abort', reject, {
      once: true,
    })

    subscribeToNative(
      'RESPONSE_GOOGLE_PAY_FAILURE',
      ({ error }) => {
        // TODO: Can we extrapolate why it failed?
        reject(new DepositError('generic_failure', transactionId, undefined, error))
      },
      {
        cleanupSignal: abort,
      }
    )
  })
}

function waitForGooglePayCanceled(abort: AbortSignal, transactionId: string) {
  return new Promise<never>((_, reject) => {
    abort.addEventListener('abort', reject, {
      once: true,
    })

    subscribeToNative(
      'GOOGLE_PAY_CANCELLED',
      () => {
        reject(DepositError.canceled(transactionId))
      },
      {
        cleanupSignal: abort,
      }
    )
  })
}
