import React from 'react'
import { useMutation } from 'react-query'
import dayjs from 'dayjs'

import { post } from '@classic/Foundation/Services/ApiService'

import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { CheckboxInput } from '@mobi/component-library/Common/Input'
import { Grid, GridCell, GridRow } from '@mobi/component-library/Common/Grid'
import { Icon } from '@mobi/component-library/Common/Icon'
import { NoticeBox, NoticeBoxTypes } from '@core/Components/NoticeBox'
import {
  PopupContainerStyled,
  PopupStyled,
  PopupTitleStyled,
} from '@core/Components/Popup/Popup.styles'
import { Spinner } from '@mobi/component-library/Common/Spinner'

import { hexColors } from '@mobi/settings'

import {
  InfoHeaderStyled,
  FieldHeaderStyled,
  ReadOnlyFieldStyled,
  BoldedInputBoxStyled,
  PaddingStyled,
} from './Precommitment.styles'
import type { PrecommitmentResponse } from '@mobi/account/Areas/SignUp/Utils/SignUpApi'
import { DEFAULT_COOLING_OFF_PERIOD_DAYS, PRECOMMITMENT_API_PATH_PREFIX } from './constants'
import { toMoney } from '@mobi/utils/money'

const DEFAULT_MAX_BET_LIMIT = 999999
const DEFAULT_MIN_BET_LIMIT = 1

const CONFIRM_BET_LIMIT_URL = `${PRECOMMITMENT_API_PATH_PREFIX}UpdateBetLimit`
const OPT_OUT_BET_LIMIT_URL = `${PRECOMMITMENT_API_PATH_PREFIX}OptOutBetLimit`

const scrollToInfo = (evt: React.MouseEvent<HTMLAnchorElement>) => {
  document.getElementById('#bet-limit-info')?.scrollIntoView()
  evt.preventDefault()
}

export const PrecommitmentBetLimit = ({
  data,
  hasDataFetchFailed,
}: {
  data: PrecommitmentResponse | undefined
  hasDataFetchFailed: boolean
}): JSX.Element => {
  const [isEditPopupShowing, setIsEditPopupShowing] = React.useState(false)
  const [isRemovalPopupShowing, setIsRemovalPopupShowing] = React.useState(false)
  const [precommitmentDetails, setPrecommitmentDetails] = React.useState(data)
  const [hasBetLimitBeenSuccessfullyChanged, setHasBetLimitBeenSuccessfullyChanged] =
    React.useState(false)

  React.useEffect(() => {
    setPrecommitmentDetails(data)
  }, [data])

  React.useLayoutEffect(() => {
    if (isEditPopupShowing || isRemovalPopupShowing) setHasBetLimitBeenSuccessfullyChanged(false)
  }, [isEditPopupShowing, isRemovalPopupShowing, setHasBetLimitBeenSuccessfullyChanged])

  const isBetLimitPendingRemoval = React.useCallback(
    () =>
      precommitmentDetails?.ProposedBetLimit?.ActiveDate &&
      !precommitmentDetails?.ProposedBetLimit?.Amount,
    [precommitmentDetails]
  )

  const canRemoveBetLimit = React.useCallback(
    () => precommitmentDetails?.BetLimit && !isBetLimitPendingRemoval(),
    [precommitmentDetails?.BetLimit, isBetLimitPendingRemoval]
  )

  return (
    <form id='bet-limits-form'>
      {isEditPopupShowing && (
        <EditPopup
          existingBetLimit={precommitmentDetails?.BetLimit}
          maxBetLimit={precommitmentDetails?.BetLimitRange?.Max || DEFAULT_MAX_BET_LIMIT}
          minBetLimit={precommitmentDetails?.BetLimitRange?.Min || DEFAULT_MIN_BET_LIMIT}
          coolingOffDate={precommitmentDetails?.CoolingOffDate}
          coolingOffPeriodDays={
            precommitmentDetails?.CoolingOffPeriodDays || DEFAULT_COOLING_OFF_PERIOD_DAYS
          }
          hidePopup={() => setIsEditPopupShowing(false)}
          setPrecommitmentDetails={setPrecommitmentDetails}
          setHasBetLimitBeenSuccessfullyChanged={setHasBetLimitBeenSuccessfullyChanged}
        />
      )}
      {isRemovalPopupShowing && (
        <RemovalPopup
          coolingOffDate={precommitmentDetails?.CoolingOffDate}
          coolingOffPeriodDays={
            precommitmentDetails?.CoolingOffPeriodDays || DEFAULT_COOLING_OFF_PERIOD_DAYS
          }
          hidePopup={() => setIsRemovalPopupShowing(false)}
          setPrecommitmentDetails={setPrecommitmentDetails}
        />
      )}
      <InfoHeaderStyled level={3}>
        <span>Single Bet Limit</span>
        <a href='#bet-limit-info' onClick={scrollToInfo}>
          <Icon type='infoCircledInvert' title='Get more information' color={hexColors.summerSky} />
        </a>
      </InfoHeaderStyled>
      <PaddingStyled>
        {hasDataFetchFailed && (
          <NoticeBox
            title='Unable to display your current bet limit. Please try again later.'
            noticeBoxType={NoticeBoxTypes.Warning}
            hasBorder={true}
            data-tid-fetch-failed=''
          />
        )}
        {!hasDataFetchFailed && (
          <>
            {hasBetLimitBeenSuccessfullyChanged &&
              !precommitmentDetails?.ProposedBetLimit?.ActiveDate && (
                <NoticeBox
                  title='Your single bet limit has been set'
                  noticeBoxType={NoticeBoxTypes.Success}
                  hasBorder={true}
                />
              )}
            {precommitmentDetails?.ProposedBetLimit?.ActiveDate && (
              <NoticeBox title='' noticeBoxType={NoticeBoxTypes.Warning} hasBorder={true}>
                Your single bet limit{' '}
                {isBetLimitPendingRemoval() ? (
                  <>
                    will be{' '}
                    <strong>removed on {precommitmentDetails.ProposedBetLimit.ActiveDate}</strong>
                  </>
                ) : (
                  <>
                    increase to ${precommitmentDetails.ProposedBetLimit.Amount} will be{' '}
                    <strong>effective on {precommitmentDetails.ProposedBetLimit.ActiveDate}</strong>
                  </>
                )}
                , after the mandatory cooling off period.
              </NoticeBox>
            )}
            <FieldHeaderStyled>Single Bet Limit</FieldHeaderStyled>
            <ReadOnlyFieldStyled id='bet-limit'>
              {precommitmentDetails?.BetLimit ? `$${precommitmentDetails.BetLimit}` : 'No Limit'}
            </ReadOnlyFieldStyled>
            <Grid>
              <GridRow>
                {canRemoveBetLimit() && (
                  <GridCell padding='0 0.5em 0 0'>
                    <ButtonBlock
                      id='bet-limit-optout'
                      type='button'
                      color='secondary'
                      onClick={() => setIsRemovalPopupShowing(true)}
                    >
                      Remove Limit
                    </ButtonBlock>
                  </GridCell>
                )}
                <GridCell padding={canRemoveBetLimit() ? '0 0 0 0.5em' : '0'}>
                  <ButtonBlock
                    id='bet-limit-edit'
                    type='button'
                    color='primary'
                    onClick={() => setIsEditPopupShowing(true)}
                  >
                    Edit Limit
                  </ButtonBlock>
                </GridCell>
              </GridRow>
            </Grid>
          </>
        )}
      </PaddingStyled>
    </form>
  )
}

const EditPopup = ({
  existingBetLimit,
  maxBetLimit,
  minBetLimit,
  coolingOffPeriodDays,
  coolingOffDate,
  hidePopup,
  setPrecommitmentDetails,
  setHasBetLimitBeenSuccessfullyChanged,
}: {
  existingBetLimit?: number
  maxBetLimit: number
  minBetLimit: number
  coolingOffPeriodDays: number
  coolingOffDate?: string
  hidePopup: () => void
  setPrecommitmentDetails: (x: PrecommitmentResponse) => void
  setHasBetLimitBeenSuccessfullyChanged: (x: boolean) => void
}) => {
  const [newBetLimit, setNewBetLimit] = React.useState('')
  const [hasAgreed, setHasAgreed] = React.useState(false)

  const newBetLimitRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (newBetLimitRef.current) {
      newBetLimitRef.current.focus()
    }
  }, [])

  const editNewBetLimit = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      // Remove all non-number characters.
      const attemptedNewValue = parseFloat(evt.target.value.replace(/[^0-9.-]/g, ''))
      if (isNaN(attemptedNewValue) || attemptedNewValue < 1) {
        setNewBetLimit('')
        return
      }

      if (attemptedNewValue < minBetLimit) {
        setNewBetLimit(minBetLimit.toString())
        return
      }

      if (attemptedNewValue > maxBetLimit) {
        setNewBetLimit(maxBetLimit.toString())
        return
      }

      setNewBetLimit(Math.trunc(attemptedNewValue).toString())
    },
    [maxBetLimit, minBetLimit]
  )

  const mutation = useMutation(
    () =>
      post<PrecommitmentResponse>({
        url: CONFIRM_BET_LIMIT_URL,
        body: newBetLimit,
      }),
    {
      onSuccess: data => {
        hidePopup()
        setPrecommitmentDetails(data)
        setHasBetLimitBeenSuccessfullyChanged(true)
      },
    }
  )

  return (
    <PopupContainerStyled id='bet-limit-edit-container'>
      <PopupStyled>
        {mutation.isLoading && <Spinner />}
        <Grid>
          <GridRow>
            <GridCell align='center'>
              <PopupTitleStyled id='edit-bet-limit-title'>Edit Single Bet Limit</PopupTitleStyled>
            </GridCell>
          </GridRow>
          {mutation.isError && (
            <GridRow>
              <NoticeBox
                title='Unable to update your bet limit at this time. Please try again later.'
                noticeBoxType={NoticeBoxTypes.Error}
                hasBorder={true}
                data-tid-bet-limit-update-failure
              />
            </GridRow>
          )}
          <GridRow>
            <GridCell align='left'>
              <strong>Set the dollar amount you wish to be the limit of a single bet:</strong>
            </GridCell>
          </GridRow>
          <GridRow>
            <BoldedInputBoxStyled
              ref={newBetLimitRef}
              name='new-bet-limit'
              id='new-bet-limit'
              type='text'
              inputMode='numeric'
              min={1}
              max={999999}
              title='Whole dollars only'
              required={true}
              placeholder='Please enter an amount'
              value={toMoney(parseInt(newBetLimit), { decimalPlaces: 0 })}
              onChange={editNewBetLimit}
            />
          </GridRow>
          <GridRow>
            <GridCell align='left'>
              <strong>By proceeding:</strong>
            </GridCell>
          </GridRow>
          <GridRow>
            <GridCell align='left' data-tid-cooling-explanation>
              I understand that once my bet limit is set, there will be a {coolingOffPeriodDays} day
              cooling off period should I choose to increase the amount or frequency of the limit.
              Any limit decrease will be effective immediately.
            </GridCell>
          </GridRow>
          {existingBetLimit && newBetLimit && parseInt(newBetLimit) > existingBetLimit && (
            <GridRow>
              <GridCell padding='1rem 0 0 0'>
                <NoticeBox
                  title=''
                  noticeBoxType={NoticeBoxTypes.Warning}
                  hasBorder={true}
                  data-tid-increase-warning
                >
                  Your single bet limit increase will be{' '}
                  <strong>
                    effective on{' '}
                    {coolingOffDate ||
                      dayjs().add(coolingOffPeriodDays, 'days').format('DD/MM/YYYY')}
                  </strong>
                  , after the mandatory cooling off period.
                </NoticeBox>
              </GridCell>
            </GridRow>
          )}
          <GridRow>
            <CheckboxInput
              label='I understand and accept.'
              id='confirm-changes-checkbox'
              checked={hasAgreed}
              onChange={x => setHasAgreed(x.target.checked)}
              data-tid-confirm=''
            />
          </GridRow>
          <GridRow>
            <GridCell padding='0 0.5em 0 0'>
              <ButtonBlock
                id='bet-limit-cancel'
                color='secondary'
                type='button'
                onClick={hidePopup}
              >
                Cancel
              </ButtonBlock>
            </GridCell>
            <GridCell padding='0 0 0 0.5em'>
              <ButtonBlock
                id='bet-limit-save'
                color='primary'
                type='button'
                disabled={mutation.isLoading || !hasAgreed || !newBetLimit}
                onClick={() => mutation.mutate()}
              >
                Save
              </ButtonBlock>
            </GridCell>
          </GridRow>
        </Grid>
      </PopupStyled>
    </PopupContainerStyled>
  )
}

const RemovalPopup = ({
  coolingOffPeriodDays,
  coolingOffDate,
  hidePopup,
  setPrecommitmentDetails,
}: {
  existingBetLimit?: number
  coolingOffPeriodDays: number
  coolingOffDate?: string
  hidePopup: () => void
  setPrecommitmentDetails: (x: PrecommitmentResponse) => void
}) => {
  const [hasAgreed, setHasAgreed] = React.useState(false)

  const mutation = useMutation(
    () =>
      post<PrecommitmentResponse>({
        url: OPT_OUT_BET_LIMIT_URL,
      }),
    {
      onSuccess: data => {
        hidePopup()
        setPrecommitmentDetails(data)
      },
    }
  )

  return (
    <PopupContainerStyled id='bet-limit-remove-container'>
      <PopupStyled>
        {mutation.isLoading && <Spinner />}
        <Grid>
          <GridRow>
            <GridCell align='center' padding='0 0 1rem 0'>
              <PopupTitleStyled id='remove-bet-limit-title'>
                Remove Single Bet Limit
              </PopupTitleStyled>
            </GridCell>
          </GridRow>
          {mutation.isError && (
            <GridRow>
              <NoticeBox
                title='Unable to remove your bet limit at this time. Please try again later.'
                noticeBoxType={NoticeBoxTypes.Error}
                hasBorder={true}
                data-tid-bet-limit-removal-failure
              />
            </GridRow>
          )}
          <GridRow>
            <NoticeBox
              title=''
              noticeBoxType={NoticeBoxTypes.Warning}
              hasBorder={true}
              data-tid-remove-warning
            >
              Your single bet limit will be{' '}
              <strong>
                removed on{' '}
                {coolingOffDate || dayjs().add(coolingOffPeriodDays, 'days').format('DD/MM/YYYY')}
              </strong>
              , after the mandatory cooling off period.
            </NoticeBox>
          </GridRow>
          <GridRow>
            <CheckboxInput
              label='I understand and accept.'
              id='confirm-changes-checkbox'
              checked={hasAgreed}
              onChange={x => setHasAgreed(x.target.checked)}
              data-tid-confirm=''
            />
          </GridRow>
          <GridRow>
            <GridCell padding='0 0.5em 0 0'>
              <ButtonBlock
                id='bet-limit-cancel'
                color='secondary'
                type='button'
                onClick={hidePopup}
              >
                Cancel
              </ButtonBlock>
            </GridCell>
            <GridCell padding='0 0 0 0.5em'>
              <ButtonBlock
                id='bet-limit-save'
                color='primary'
                type='button'
                disabled={mutation.isLoading || !hasAgreed}
                onClick={() => mutation.mutate()}
              >
                Save
              </ButtonBlock>
            </GridCell>
          </GridRow>
        </Grid>
      </PopupStyled>
    </PopupContainerStyled>
  )
}
