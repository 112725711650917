import React from 'react'
import styled from '@emotion/styled'
import { List } from 'immutable'
import { useQuery } from 'react-query'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { SpinnerStyled } from '@mobi/component-library/Common/Spinner/Spinner.styles'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { Button } from '@mobi/component-library/Common/V2/Buttons/Button'
import { zip } from '@mobi/utils/functions/zip'
import { useFeature } from '@core/Utils/hooks'
import type { Tabs, Ticket } from '@core/Areas/AccountActivity/types'
import { QuickbetSelection } from '@core/Areas/Quickbet/signals'
import { convertToBetSelection } from '@core/Utils/betting/handleBetSelection/helpers/convertToBetSelection'
import { isFobSelection } from '@mobi/betslip/helpers/typeGuards'
import { calculateMultiReturn } from '@core/Areas/Betslip/helpers/calculator'
import { createItemForLegacyBetslip } from '@core/Areas/Betslip/helpers/addToBetslip'
import { getLegStatus } from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import {
  buildBaseBetSelection,
  createLoadBetDetails,
  loadBet,
  LoadBetDetails,
} from '@core/Utils/betting/loadBet'
import { trackRebet } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { canAllowReBetForTicket } from './helpers/canAllowReBetForTicket'

export const ReBetButton: React.FC<{ ticket: Ticket; activeTab: Tabs }> = ({
  ticket,
  activeTab,
}) => {
  const isReBetFeatureActive = useFeature('REBET_PENDING_TICKET')

  if (!isReBetFeatureActive || !canAllowReBetForTicket(activeTab, ticket)) {
    return null
  }

  return <ReBetButtonLocal ticket={ticket} source={activeTab} />
}

// ===============
// Local Component
// ===============

const ReBetButtonLocal: React.FC<{ ticket: Ticket; source: Tabs }> = ({ ticket, source }) => {
  const pendingLegs = ticket.legs.filter(leg => getLegStatus(leg.result) === 'Pending')
  const loadDetails = createLoadBetDetails({ ...ticket, legs: pendingLegs })
  const [loading, setLoading] = React.useState(false)

  // show a spinner while fetching current odds. if no odds found then just show 'Bet Now'
  const { data, isLoading, isError } = useQuery({
    queryKey: ['ticketodds', ticket.ticketSequence],
    cacheTime: 3 * 60 * 1000,
    staleTime: 3 * 60 * 1000,
    queryFn: () => Promise.all(loadDetails.map(x => buildBaseBetSelection(x))),
  })

  const { win, place } = calcOdds(loadDetails, data)

  return (
    <WrapperStyled>
      <h4>
        <Icon name='LineRepeat04' size='1.6rem' /> Re-Bet
      </h4>

      <Button
        size='xs'
        data-testid='rebet-button'
        color='secondary_grey'
        onClick={() => {
          setLoading(true)
          loadBet(loadDetails, 'rebet', data).finally(() => setLoading(false))

          const resultedCount = ticket.legs.length - pendingLegs.length
          trackRebet({ source, resultedCount, pendingCount: pendingLegs.length })
        }}
      >
        {isLoading || loading ? (
          <SpinnerStyled
            width='auto'
            height='auto'
            title='Loading...'
            role='progressbar'
            aria-label='Loading'
          />
        ) : isError || !data ? (
          'Bet Now'
        ) : (
          <>
            <div>{win.toFixed(2)}</div>
            <div>{place?.toFixed(2)}</div>
          </>
        )}
      </Button>
    </WrapperStyled>
  )
}

function calcOdds(loadDetails: LoadBetDetails[], data?: QuickbetSelection[]) {
  if (!data) {
    return { win: 0, place: 0 }
  }

  if (data.length === 1 && isFobSelection(data[0].selection)) {
    return { win: data[0].selection.winPrice, place: data[0].selection.placePrice }
  }

  const betSlipItems = zip(data, loadDetails).map(([bet, loadDetail]) => {
    const res = createItemForLegacyBetslip(convertToBetSelection(bet))
    if ('betType' in loadDetail) {
      res.multiLegBetType = loadDetail.betType === LocalConstants.PlaceBetType ? 'P' : 'W'
    }
    return res
  })

  const win = calculateMultiReturn(List(betSlipItems), { shouldRound: true })
  return { win, place: null }
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  h4: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.smx2,
    margin: 0,
    padding: `${spacing.smx1} ${spacing.smx2}`,
    textAlign: 'unset',
    fontSize: font.size.md.fontSize,
    letterSpacing: font.size.md.letterSpacing,
    lineHeight: font.size.md.lineHeight,
    fontWeight: font.weight.regular,
    color: colors.black,
  },

  button: {
    minWidth: '10rem',
    minHeight: '4.8rem',
    fontSize: font.size.lg.fontSize,
    lineHeight: '1.8rem',
    letterSpacing: font.size.lg.letterSpacing,
    color: colors.studio[900],
    fontWeight: font.weight.semibold,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const enum LocalConstants {
  PlaceBetType = 'Place - Fixed',
}
