import React from 'react'
import { BetStatus } from '@core/Areas/AccountActivity/types'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { colors } from '@mobi/component-library/Theme/Common'

export const BetStatusIcon: React.FC<{ betStatus: BetStatus }> = ({ betStatus }) => {
  switch (betStatus) {
    case 'Won':
      return (
        <div data-testid='status-silk-win'>
          <Icon name='SolidCheckCircle' size='2rem' color={colors.success[400]} />
        </div>
      )
    case 'Lost':
      return (
        <div data-testid='status-silk-lost'>
          <Icon name='SolidXCircle' size='2rem' color={colors.error[600]} />
        </div>
      )
    case 'Cancelled':
    case 'Pending':
    default:
      return null
  }
}
