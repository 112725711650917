import React from 'react'
import { Breakpoint } from '../Utils/media'
import {
  AppHeaderLogoLinkStyled,
  AppHeaderLogoFullStyled,
  AppHeaderLogoCompactStyled,
} from './AppHeaderLogo.styles'
import { TABtouchLogo } from './tabtouch-logo'
import { TABtouchLogoCompact } from './tabtouch-logo-compact'

type AppHeaderLogoProps = {
  /** Breakpoint width of when compact icon is used instead. */
  compactBreakpoint: keyof typeof Breakpoint
  onClick?: VoidFunction
}

export const AppHeaderLogo = ({ compactBreakpoint, onClick }: AppHeaderLogoProps): JSX.Element => {
  return (
    <AppHeaderLogoLinkStyled id='home-page' href='/#' onClick={onClick} aria-label='TABtouch Logo'>
      <AppHeaderLogoFullStyled compactBreakpoint={compactBreakpoint}>
        <TABtouchLogo />
      </AppHeaderLogoFullStyled>
      <AppHeaderLogoCompactStyled compactBreakpoint={compactBreakpoint}>
        <TABtouchLogoCompact size='inherit' />
      </AppHeaderLogoCompactStyled>
    </AppHeaderLogoLinkStyled>
  )
}
