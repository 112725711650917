import React from 'react'
import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { colors, font, radius, shadow, spacing } from '@mobi/component-library/Theme/Common'
import { LoadingSpinner } from '@mobi/component-library/Feedback/LoadingSpinner'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { SkyTvSvg } from '@mobi/component-library/Common/Svg'
import { ChannelType } from '@mobi/component-library/Common/Svg/SkyTv/SkyTvSvg'

export const SkyTvButton: React.FC<{
  channel: ChannelType
  isLive: boolean
  isPlaying: boolean
  onClick: () => void
  isLoading?: boolean
}> = ({ channel, isLive, isPlaying, onClick, isLoading = false }) => {
  const displayType = isLive ? 'live' : channel

  return (
    <SkyButtonStyled onClick={onClick} display={displayType} isPlaying={isPlaying}>
      <span>
        {isLive ? (
          'Live'
        ) : (
          <SkyTvSvg
            channel={channel}
            colorOverride={isPlaying ? colors.white : undefined}
            width='3.2rem'
          />
        )}
      </span>

      {isLoading ? (
        <LoadingSpinner size='1.4rem' />
      ) : (
        <Icon color='inherit' name={isPlaying ? 'SolidXCircle' : 'SolidPlayCircle'} size='1.4rem' />
      )}
    </SkyButtonStyled>
  )
}

// ======
// Styles
// ======

const SkyButtonStyled = styled.button<{ display: ChannelType | 'live'; isPlaying: boolean }>(
  {
    '@keyframes pulseAnimation': {
      '0%': { opacity: 1, transform: 'scale(1)' },
      to: { opacity: 0, transform: 'scale(1.7)', transformOrigin: 'center' },
    },

    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.smx2,
    margin: 0,
    minWidth: '6.4rem',
    padding: `0 ${spacing.smx1}`,
    height: '3.2rem',
    border: 0,
    borderRadius: radius.sm,
    cursor: 'pointer',

    '> span': {
      display: 'inline-flex',
      alignItems: 'center',
    },

    '*': { flexShrink: 0 },
  },
  ({ display, isPlaying }) => {
    if (display === 'live') {
      return {
        backgroundColor: colors.error[600],
        color: colors.white,
        fontFamily: font.family.primary,
        fontSize: font.size.lg.fontSize,
        lineHeight: font.size.lg.lineHeight,
        letterSpacing: font.size.lg.letterSpacing,
        fontWeight: font.weight.bold,

        '> span:nth-of-type(2)': {
          svg: { color: colors.white },

          '&::before': !isPlaying
            ? {
                content: '""',
                display: 'block',
                boxSizing: 'border-box',
                border: '0.2rem solid white',
                borderRadius: '100%',
                animation: 'pulseAnimation 1.5s infinite',
                height: '1.2rem',
                width: '1.2rem',
                position: 'absolute',
              }
            : {},
        },
      }
    }

    const channelColor =
      display === 'Sky1'
        ? hexColors.skyTvRed
        : display === 'Sky2'
          ? hexColors.skyTvBlue
          : display === 'Replay'
            ? hexColors.bondiBlue
            : hexColors.racingWa
    const colorInvert = isPlaying ? channelColor : colors.white

    return {
      backgroundColor: colorInvert,
      border: isPlaying ? 0 : `0.05rem solid ${colors.neutral[200]}`,
      boxShadow: isPlaying ? undefined : shadow.sm,

      '> span > svg': {
        color: isPlaying ? colors.white : channelColor,
      },
    }
  }
)
