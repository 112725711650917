import React, { type FC } from 'react'
import { type PaymentMethodsAll } from '@mobi/component-library/Deposit/types'
import { CustomerServiceCentre, InfoBox } from '@mobi/component-library/Common/V2'
import { paymentMethodTypeNameMap } from '../Utils/schema'

type Props = {
  paymentMethod: PaymentMethodsAll
}

export const PaymentMethodBlocked: FC<Props> = ({ paymentMethod }) => {
  return (
    <InfoBox size='md' color={paymentMethod === 'PayID' ? 'red' : 'orange'}>
      <BlockedPaymentMethodText paymentMethod={paymentMethod} />
    </InfoBox>
  )
}

PaymentMethodBlocked.displayName = 'PaymentMethodBlocked'

const BlockedPaymentMethodText: FC<Props> = ({ paymentMethod }) => {
  return (
    <div>
      <strong>
        Deposits are not enabled for {paymentMethodTypeNameMap[paymentMethod]} on this account.
        {paymentMethod === 'PayID' &&
          ' Any deposits to this PayID will be automatically refunded to your bank account.'}
      </strong>
      <br />
      <CustomerServiceCentre />
    </div>
  )
}
