import React from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { toMoney } from '@mobi/utils/money'
import { colors, font, radius, shadow, spacing } from '@mobi/component-library/Theme/Common'
import type { InvestmentType } from '@mobi/betslip/types'
import { setActiveInvestment } from '@mobi/betslip/Store/Workflow'
import {
  selectIsBettingDisabled,
  selectKeypadActiveValue,
} from '@mobi/betslip/Store/Workflow/selectors'
import { useRegisterInvestmentInput } from './hooks/useRegisterInvestmentInput'
import { useCaptureValueOnActiveInput } from './hooks/useCaptureValueOnActiveInput'
import { InlineError } from '@mobi/betslip/Components/Common/InlineError'
import { MIN_INVESTMENT_VALUE } from '@mobi/betslip/helpers/constants'
import { Price } from '../Price'

const enum LocalConstants {
  StackedDisplayClass = 'js-investment--stacked',
  InputActiveClass = 'js-investment__input--active',
  InputHasValueClass = 'js-investment__input--has-value',
  InputEditableClass = 'js-investment__input--editable',
  InputErrorClass = 'js-investment__input--error',
  InputDisabledClass = 'js-investment__input--disabled',
}

export const Investment: React.FC<{
  itemId: string
  investmentType: InvestmentType
  isActive: boolean
  value: number
  isEditable: boolean
  isDisabled?: boolean
  label?: string
  shouldHideLabel?: boolean
  display?: 'inline' | 'stacked'
  price?: number
  isBoostedPrice?: boolean
}> = ({
  itemId,
  investmentType,
  isActive,
  value,
  isEditable,
  label,
  shouldHideLabel,
  isDisabled = false,
  display = 'inline',
  price,
  isBoostedPrice,
}) => {
  const dispatch = useDispatch()
  const investmentElementRef = React.useRef<HTMLDivElement>(null)

  const activeValue = useSelector(selectKeypadActiveValue)
  const isBettingDisabled = useSelector(selectIsBettingDisabled)

  useRegisterInvestmentInput({ itemId, investmentType })
  useCaptureValueOnActiveInput({ isActive, value })

  React.useEffect(() => {
    if (isActive) investmentElementRef.current?.scrollIntoView({ block: 'center' })
  }, [isActive])

  const handleInvestmentClick = () => {
    if (!isActive && isEditable) dispatch(setActiveInvestment({ investmentType, itemId }))
  }

  const valueToRender = isActive ? activeValue ?? value : value
  const hasValue = valueToRender && Number(valueToRender) > 0

  const isDecimalActive = isActive && !!activeValue?.includes('.')

  const wrapperClassNames = []
  if (display === 'stacked' && isEditable)
    wrapperClassNames.push(LocalConstants.StackedDisplayClass)

  const activeValueNumber = Number(activeValue)
  const isInvalidInvestmentError =
    isActive && activeValueNumber !== 0 && activeValueNumber < MIN_INVESTMENT_VALUE

  const inputClassNames = getInputClassNames({
    isActive,
    hasValue: hasValue || isDecimalActive,
    isEditable,
    isError: isInvalidInvestmentError,
    isDisabled: isBettingDisabled || isDisabled,
  })

  if (!isEditable && !hasValue) return null

  return (
    <WrapperStyled ref={investmentElementRef} className={wrapperClassNames.join(' ')}>
      <span>
        <span>{shouldHideLabel ? null : label || investmentType}</span>

        {price && <Price value={price} isIndicatorShifted isBoostedPrice={isBoostedPrice} />}
      </span>

      <span onClick={handleInvestmentClick} className={inputClassNames.join(' ')}>
        {!isActive ? toMoney(value, { decimalPlaces: 2 }) : null}

        {isActive &&
          (!hasValue && !isDecimalActive ? (
            toMoney(0, { decimalPlaces: 2 })
          ) : (
            <>
              {`$${valueToRender || 0}`}
              <span />
            </>
          ))}
      </span>

      {isInvalidInvestmentError && (
        <div>
          <InlineError text={`Minimum stake is ${toMoney(MIN_INVESTMENT_VALUE)}`} />
        </div>
      )}
    </WrapperStyled>
  )
}

// =============
// Local Helpers
// =============

const getInputClassNames = ({
  isActive,
  hasValue,
  isEditable,
  isError,
  isDisabled,
}: {
  isActive: boolean
  hasValue: boolean
  isEditable: boolean
  isDisabled: boolean
  isError: boolean
}): string[] => {
  const classNames: string[] = []

  if (hasValue) classNames.push(LocalConstants.InputHasValueClass)
  if (isEditable) {
    classNames.push(LocalConstants.InputEditableClass)
  } else {
    return classNames
  }
  if (isActive) classNames.push(LocalConstants.InputActiveClass)
  if (isError) classNames.push(LocalConstants.InputErrorClass)
  if (isDisabled) classNames.push(LocalConstants.InputDisabledClass)

  return classNames
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: spacing.smx2,
  scrollMargin: spacing.sm,
  minHeight: '2.4rem',

  // Label and Price Change Wrapper
  '> span:first-of-type': {
    display: 'inline-flex',
    gap: spacing.smx1,

    // Label
    '> span:first-of-type': {
      fontSize: font.size.md.fontSize,
      letterSpacing: font.size.md.letterSpacing,
      lineHeight: font.size.md.lineHeight,
      fontWeight: font.weight.regular,
      color: colors.black,

      ':empty': { display: 'none' },
    },

    '> span:nth-of-type(2)': {
      fontSize: font.size.md.fontSize,
      letterSpacing: font.size.md.letterSpacing,
      lineHeight: font.size.md.lineHeight,
      fontWeight: font.weight.medium,
      color: colors.neutral[900],
    },
  },

  // Input Box
  '> span:last-of-type': {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    maxWidth: '12rem',
    minWidth: '12rem',

    textAlign: 'end',
    color: colors.neutral[700],
    fontSize: font.size.md.fontSize,
    letterSpacing: font.size.md.letterSpacing,
    lineHeight: font.size.md.lineHeight,
    fontWeight: font.weight.regular,

    [`&.${LocalConstants.InputEditableClass}`]: {
      padding: spacing.sm + ' ' + spacing.md,
      border: '1px solid ' + colors.neutral[300],
      borderRadius: radius.md,
      backgroundColor: colors.white,
      boxShadow: shadow.xs,
      touchAction: 'manipulation',
    },

    [`&.${LocalConstants.InputActiveClass}`]: {
      outline: `${colors.lavender[50]} solid 0.4rem`,
      borderColor: colors.lavender[200],
      fontWeight: font.weight.medium,
    },

    [`&.${LocalConstants.InputHasValueClass}`]: {
      fontWeight: font.weight.medium,
      color: colors.black,
    },

    [`&.${LocalConstants.InputErrorClass}`]: {
      outline: `${colors.error[50]} solid 0.4rem`,
      background: colors.error[50],
      borderColor: colors.error[600],
    },

    [`&.${LocalConstants.InputDisabledClass}`]: {
      background: colors.surface[100],
      color: colors.neutral[400],
      fontWeight: font.weight.regular,
      border: `1px solid ${colors.neutral[300]}`,
      boxShadow: shadow.xs,
      pointerEvents: 'none',
      cursor: 'not-allowed',
    },

    '> span': {
      display: 'inline-block',
      width: '2px',
      height: font.size.md.lineHeight,
      background: colors.lavender[200],
      opacity: 0,
      animation: 'blinking 1s infinite',

      '@keyframes blinking': {
        '0%': { opacity: 0 },
        '50%': { opacity: 1 },
        '100%': { opacity: 0 },
      },
    },
  },

  '> div': {
    flexBasis: '100%',
  },

  [`&.${LocalConstants.StackedDisplayClass}`]: {
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'start',
    justifyContent: 'start',

    '> span:last-of-type': {
      maxWidth: 'unset',

      [`&.${LocalConstants.InputEditableClass}`]: {
        justifyContent: 'flex-start',
      },
    },
  },
})
