import React from 'react'
import styled from '@emotion/styled'
import { Currency } from '@mobi/utils/money'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { SummaryLine } from '@mobi/betslip/Components/Common/SummaryLine'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import type { BetSlipItem, MultiInvestmentKey } from '@mobi/betslip/types'
import { MULTI_FORMULA_COMBINATIONS } from '@mobi/betslip/helpers/constants'
import { calculateMultiProjectedPay } from '@mobi/betslip/helpers/calculator/multis'
import { isValidMultiInvestmentForLegs } from '@mobi/betslip/helpers/state'

export const InvestmentSummary: React.FC<{ selectedMultiItems: BetSlipItem[] }> = ({
  selectedMultiItems,
}) => {
  const multiInvestment = useAppSelector(state => state.betslip.bets.multiInvestment)

  const hasStandardMultiInvestment = !!multiInvestment.value
  const hasComboMultisInvestment = isValidMultiInvestmentForLegs(
    multiInvestment,
    selectedMultiItems.length,
    { shouldCheckOnlyFormulas: true }
  )
  const multiPotentialPayout = hasStandardMultiInvestment
    ? calculateMultiProjectedPay(selectedMultiItems, multiInvestment, true)
    : 0
  const totalProjPay = calculateMultiProjectedPay(selectedMultiItems, multiInvestment)

  const legCount = selectedMultiItems.length

  return (
    <WrapperStyled>
      {hasStandardMultiInvestment && (
        <>
          <SummaryLine left='Cost' right={new Currency(multiInvestment.value).format()} />
          <SummaryLine
            left='Potential Payout'
            right={new Currency(multiPotentialPayout).format()}
          />
        </>
      )}

      {hasComboMultisInvestment && (
        <>
          {hasStandardMultiInvestment && <hr />}

          <h4>Combo Multi</h4>

          <ComboSummaryLine
            comboKey='f1'
            label='Singles'
            cost={multiInvestment.f1}
            legCount={legCount}
          />

          {legCount > 2 && (
            <ComboSummaryLine
              comboKey='f2'
              label='Doubles'
              cost={multiInvestment.f2}
              legCount={legCount}
            />
          )}

          {legCount > 3 && (
            <ComboSummaryLine
              comboKey='f3'
              label='Trebles'
              cost={multiInvestment.f3}
              legCount={legCount}
            />
          )}

          {legCount > 4 && (
            <ComboSummaryLine
              comboKey='f4'
              label='Pick 4'
              cost={multiInvestment.f4}
              legCount={legCount}
            />
          )}

          {legCount > 5 && (
            <ComboSummaryLine
              comboKey='f5'
              label='Pick 5'
              cost={multiInvestment.f5}
              legCount={legCount}
            />
          )}

          <SummaryLine left='Total Projected Payout' right={new Currency(totalProjPay).format()} />
        </>
      )}
    </WrapperStyled>
  )
}

// ===============
// Local Component
// ===============

const ComboSummaryLine: React.FC<{
  comboKey: MultiInvestmentKey
  legCount: number
  label: string
  cost: number
}> = ({ comboKey, legCount, label, cost }) => {
  const combos = MULTI_FORMULA_COMBINATIONS[comboKey]
  const comboCount = combos[`${legCount}`]

  if (!cost) return null

  return (
    <SummaryLine
      left={`${label} ${new Currency(cost).format()} (Combo x ${comboCount})`}
      right={new Currency(cost).multiply(comboCount).format()}
    />
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.smx1,

  '> hr': {
    margin: 0,
    border: 0,
    height: '0.5px',
    background: colors.neutral[200],
  },

  '> h4': {
    margin: 0,
    padding: 0,
    fontWeight: font.weight.medium,
    fontSize: font.size.lg.fontSize,
    letterSpacing: font.size.lg.letterSpacing,
    lineHeight: font.size.lg.lineHeight,
    color: colors.black,
    textAlign: 'unset',
  },
})
