import * as ko from 'knockout'
import { v4 } from 'uuid'
import { inject, injectable } from 'inversify'
import { RaceStatus } from '@core/Areas/Racing/Types'
import { RacePanels } from '@core/Areas/Racing/Components/Panels'
import Guard from '@classic/AppUtils/Framework/Guard'
import { BetInformers } from '@core/Areas/Racing/Components/BetInformers/BetInformers'
import { Disposable } from '@classic/AppUtils/Framework/Disposable/Disposable'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import ObservableResultsPage from '@classic/Betting-v2/Model/Observables/ObservableResultsPage'
import { FormGiddyUpRaceInformation } from '@classic/Betting-v2/Components/Form/FormGiddyUpRaceInformation'
import { state$ as bettingV2CommandDriver$ } from '@classic/Betting-v2/Components/Commands/driver'
import type { IObservableFinisher } from '@classic/Betting-v2/Model/Observables/IObservableFinisher'
import type { IResultsPageViewModel } from './IResultsPageViewModel'

type ViewName = 'View Field' | 'View Results'

@injectable()
export class ResultsPageViewModel extends Disposable implements IResultsPageViewModel {
  private giddyUp: FormGiddyUpRaceInformation

  public showProtest!: ko.PureComputed<boolean>
  public raceInformation!: ObservableResultsPage
  public simplePlacings!: ko.Observable<string>
  public showField!: ko.PureComputed<boolean>
  public isRaceClosed!: ko.PureComputed<boolean>
  public viewName!: ko.Observable<ViewName>
  public isRaceReplayOn!: ko.Observable<boolean>
  public hasGiddyUpRaceKey!: ko.PureComputed<boolean>
  public raceReplayId!: string
  public BetInformers = BetInformers
  public RacePanels = RacePanels
  private ldSubscription!: Rx.IDisposable
  private displayTypeSubscription!: Rx.IDisposable
  public fieldVisibleState!: ko.Observable<boolean>
  public status!: ko.PureComputed<RaceStatus>
  public finishers!: ko.ObservableArray<IObservableFinisher>
  public hasVideoReplay!: ko.PureComputed<boolean>

  constructor(
    @inject('IEventAggregator') eventAggregator: IEventAggregator,
    @inject('FormGiddyUpRaceInformation') giddyUp: FormGiddyUpRaceInformation
  ) {
    super(eventAggregator)
    this.giddyUp = giddyUp
  }

  public init(params: { raceInformation: ObservableResultsPage }) {
    Guard.notNull(params)
    Guard.notNull(params.raceInformation)

    this.raceReplayId = `id-${v4()}`

    this.raceInformation = params.raceInformation

    this.simplePlacings = this.raceInformation.simplePlacings as ko.Observable<string>
    this.viewName = ko.observable<ViewName>('View Field')
    this.showProtest = ko.pureComputed<boolean>(() => {
      const protestStatus = this.raceInformation.protestStatus()

      return protestStatus !== undefined && protestStatus.length > 0
    })
    this.isRaceClosed = ko.pureComputed<boolean>(() => {
      return this.status() === RaceStatus.Closed
    })
    this.showField = ko.pureComputed<boolean>(() => {
      return this.isRaceClosed() || this.fieldVisibleState()
    })
    this.status = ko.pureComputed<RaceStatus>(() => {
      return this.raceInformation.raceStatus() as RaceStatus
    })

    this.isRaceReplayOn = ko.observable(false)

    this.hasVideoReplay = ko.pureComputed<boolean>(
      () =>
        this.raceInformation.raceReplay.hasVideo() &&
        [RaceStatus.Released, RaceStatus.Interim].includes(this.status())
    )

    this.fieldVisibleState = ko.observable(false)

    this.registerDisposals(() => {
      this.showProtest.dispose()
      this.isRaceClosed.dispose()
      this.showField.dispose()
    })

    this.registerHandlers()

    this.hasGiddyUpRaceKey = ko.pureComputed<boolean>(() => !!this.raceInformation.giddyUpRaceKey())

    this.displayTypeSubscription = bettingV2CommandDriver$
      .map(record => {
        return {
          fieldVisible: record.fieldVisible,
        }
      })
      .distinctUntilChanged()
      .subscribe(result => {
        this.fieldVisibleState(result.fieldVisible)
      })

    this.registerDisposals(() => {
      this.ldSubscription?.dispose()
      this.displayTypeSubscription?.dispose()
    })
  }

  private registerHandlers() {
    if (this.raceInformation.raceReplay.hasVideo()) {
      this.subscribeToRaceReplay(this.raceReplayId)
    }
  }

  private subscribeToRaceReplay(id: string): void {
    this.safeSubscribe(`race-replay-started-for-${id}`, () => {
      this.isRaceReplayOn(true)
    })

    this.safeSubscribe(`race-replay-stopped-for-${id}`, () => {
      this.isRaceReplayOn(false)
    })
  }

  public changeView() {
    this.evtAggregator.publish(`stop-all-race-replay`)

    if (this.viewName() === 'View Results') {
      this.viewName('View Field')
    } else {
      this.viewName('View Results')
    }
  }

  public toggleRaceReplay() {
    this.evtAggregator.publish(`toggle-race-replay-for-${this.raceReplayId}`)
  }

  public openGiddyUp() {
    this.giddyUp.openGiddyUp(
      this.raceInformation.giddyUpRaceKey() as string,
      this.raceInformation.giddyUpFixtureKey() as string,
      finisherNumber => {
        return this.raceInformation.raceFinishers.getFinisher(finisherNumber)
      },
      () => {
        return {
          raceCourse: this.raceInformation.meetingInformation.meetingName(),
          raceNumber: this.raceInformation.raceNumber(),
          distance: this.raceInformation.meetingInformation.selectedRace.distance(),
          trackCondition: null,
          weatherClass: null,
          raceStatus: this.raceInformation.raceStatus(),
          raceTimeLocal: null,
          raceResults: this.simplePlacings(),
        }
      },
      // placeSelection is n/a for results,
      () => {
        //
      },
      () => {
        //
      },
      () => {
        //
      }
    )
  }
}
