import { FeatureFlags } from '@mobi/settings'
import { createBetSlipItem } from '@mobi/betslip/helpers/createBetSlipItem'
import { isFobSportsSelection } from '@mobi/betslip/helpers/typeGuards'
import { addItemsToBetSlip } from '@mobi/betslip/Store/Bets'
import { store } from '@core/Store'
import { state$ as featureState$ } from '@core/State/LaunchDarklyFeatures/driver'
import { createItemForLegacyBetslip } from '@core/Areas/Betslip/helpers/addToBetslip'
import { AddSingleToBetslip } from '@core/Areas/Betslip/signals'
import { QUICKBET_MODAL_ID } from '@core/Areas/Quickbet/constants'
import { Quickbet, type QuickbetExternalProps } from '@core/Areas/Quickbet/Quickbet'
import {
  QuickbetClosed,
  QuickbetLoadSelection,
  QuickbetSelection,
} from '@core/Areas/Quickbet/signals'
import { openModal } from '@core/Components/Modal/store'
import { triggerHapticFeedback } from '@core/Utils/hapticFeedback/hapticFeedback'
import { setBetSlipNewIsOpen } from '@core/Areas/AppHeader/Store'
import { convertToBetSelection } from './helpers/convertToBetSelection'
import { selectBetSlipMultiLegCount } from '@mobi/betslip/Store/Bets/selectors'
import { animateSelectionButtonToBetslip } from './helpers/animateSelectionButtonToBetslip'
import {
  showAddedToBetSlipToast,
  showMultiBuilderToast,
} from '@core/Areas/BetSlipNew/helpers/toasts'
import { trackAddToBetslip } from '@core/Areas/BetSlipNew/analytics'
import { getNewBetSlipSetting } from '@core/Areas/Settings/Store/selectors'

export async function handleBetSelection({
  betFlow,
  selection,
}: { betFlow: BetFlow } & { selection: QuickbetSelection }) {
  featureState$.take(1).subscribe(featureState => {
    const isNewBetSlipActive =
      featureState.features.get(FeatureFlags.NEW_BET_SLIP_DESIGN.key) &&
      getNewBetSlipSetting(store.getState())
    // ============
    // New Bet Slip
    // ============
    if (
      isNewBetSlipActive &&
      (betFlow.location === 'Betslip' || betFlow.location === 'BetSlipNew')
    ) {
      const isDirectToBetSlip = betFlow.options?.isDirectToBetSlip
      const clickedElement = betFlow.options?.clickedElement

      const currentMultiLegCount = selectBetSlipMultiLegCount(store.getState())

      const item = createBetSlipItem(convertToBetSelection(selection))

      trackAddToBetslip(item)

      store.dispatch(addItemsToBetSlip([item]))

      triggerHapticFeedback('impact-light')

      if (clickedElement && (currentMultiLegCount > 0 || isDirectToBetSlip)) {
        animateSelectionButtonToBetslip(clickedElement)
      }

      if (currentMultiLegCount > 0 && isFobSportsSelection(item.selection)) {
        showMultiBuilderToast(item)
        return
      }

      if (!isDirectToBetSlip) {
        store.dispatch(setBetSlipNewIsOpen(true))
      }
      showAddedToBetSlipToast()
      return
    }

    // ==============
    // Legacy Betslip
    // ==============
    if (betFlow.location === 'Betslip') {
      AddSingleToBetslip(createItemForLegacyBetslip(selection))
      return
    }

    // ========
    // Quickbet
    // ========
    // Note: isFobSportsSelection only used to allow new sports w/ kambi data
    if (betFlow.location === 'Quickbet') {
      QuickbetLoadSelection(selection)

      if (betFlow.options?.setUp) betFlow.options.setUp()

      store.dispatch(
        openModal({
          id: QUICKBET_MODAL_ID,
          modalComponent: Quickbet,
          color: 'dark',
          onCloseCallback: QuickbetClosed,
          modalComponentProps: betFlow.options?.quickbetProps as Record<string, unknown>,
        })
      )
    }
  })
}

// =====
// Types
// =====

type BetFlow =
  | { location: 'Betslip'; options?: never }
  | {
      location: 'BetSlipNew'
      options?: { isDirectToBetSlip?: boolean; clickedElement?: HTMLButtonElement }
    }
  | { location: 'Quickbet'; options?: { setUp?(): void; quickbetProps?: QuickbetExternalProps } }
