import React from 'react'
import { PlanSeq } from '@mobi/api-types'
import { dayjs } from '@mobi/utils/date'
import { CountdownBadge } from '@mobi/component-library/Feedback/CountdownBadge'
import { StatusBadge } from '@mobi/component-library/Common/StatusBadge'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import type { Ticket, TicketStatus } from '@core/Areas/AccountActivity/types'
import {
  getStatusText,
  isTippingTicket,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'

export const CountdownStatus: React.FC<{ ticket: Ticket; status: TicketStatus }> = ({
  ticket,
  status,
}) => {
  const isPreviewMode = React.useContext(TicketDisplayContext).type === 'share-preview'

  const isPendingTab = status === 'Pending'
  const isTipping = isTippingTicket(ticket)
  const isMulti = !isTipping && ticket.legs.length > 1
  const isFavouriteNumbers = ticket.planSeq === PlanSeq.FavouriteNumbers

  const countdownDate =
    (isPendingTab || isPreviewMode) &&
    !isFavouriteNumbers &&
    !isTipping &&
    getCountdownDate(ticket, isMulti)

  return (
    <>
      {countdownDate &&
        (isPreviewMode ? (
          !isMulti && (
            <StatusBadge color='gray' badgeSize='S'>
              {dayjs(countdownDate).format('D MMM | H:mm')}
            </StatusBadge>
          )
        ) : (
          <CountdownBadge badgeSize='S' advertisedStartTime={new Date(countdownDate)} />
        ))}

      {status !== 'Pending' && (
        <StatusBadge badgeSize='S' color={status === 'Won' ? 'success' : 'neutral'}>
          {getStatusText(status)}
        </StatusBadge>
      )}
    </>
  )
}

// =======
// Helpers
// =======

function getCountdownDate(ticket: Ticket, isMulti: boolean): string {
  if (!isMulti) return ticket.legs[0].advertisedStartTime

  const currentTime = dayjs()
  const timesFromNow = ticket.legs.map(leg => {
    const diff = currentTime.diff(leg.advertisedStartTime)
    // Allow time if within 2min
    if (diff < 1000 * 60 * 2) return diff
    return -Infinity
  })

  const closestTime = Math.max(...timesFromNow)
  const indexOfClosest = timesFromNow.findIndex(time => time === closestTime)

  if (indexOfClosest === -1) return ticket.legs[0].advertisedStartTime

  return ticket.legs[indexOfClosest].advertisedStartTime
}
