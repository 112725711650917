import React from 'react'
import Rx from 'rx'
import { useQuery } from 'react-query'
import { useFeature, useLogon } from '@core/Utils/hooks'
import { ConfirmBetslipBetsSucceeded, ConfirmBetSucceeded } from '@core/Areas/Quickbet/signals'
import { ConfirmAllBetsSuccessful } from '@core/Areas/Betslip/signals'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import { queryKeys } from '@core/Data/ReactQuery/constants'
import { RaceTicketsAccordion } from './Components/RaceTicketsAccordion'
import { getPendingTicketsForRaceFromApi } from './api'
import { clearCachedRacingPendingTicketsCount, useCachedRacingPendingTicketsCount } from './hooks'
import { addBetForRaceKey, hasBetForRaceKey } from './utils'

const enum LocalConstants {
  pendingTTL = 1000 * 60 * 30,
}

export const AccountTicketsForRace = ({
  isPending,
  date,
  id,
  raceNumber,
  raceKey,
  hasFixedResulted,
}: Props) => {
  const { isLoggedIn } = useLogon()
  const isRaceTicketsFeatureActive = useFeature('PENDING_BETS_FOR_RACE')
  const shouldEnableRaceTickets = !!(isLoggedIn && isRaceTicketsFeatureActive)
  const pendingBetsCount = useCachedRacingPendingTicketsCount({
    enabled: isPending && shouldEnableRaceTickets,
  })

  // MOBI-3069: disabled bets on the resulted page for perf reasons
  const shouldQueryForTickets =
    shouldEnableRaceTickets && isPending && (pendingBetsCount > 0 || hasBetForRaceKey(raceKey))

  const queryKey = isPending
    ? queryKeys.racePendingTickets(raceKey)
    : queryKeys.raceResultedTickets(id, date, raceNumber, hasFixedResulted)

  const { isLoading, isError, data, refetch } = useQuery(
    queryKey,
    () => getPendingTicketsForRaceFromApi(raceKey),
    {
      staleTime: isPending ? LocalConstants.pendingTTL : Infinity,
      refetchOnWindowFocus: false,
      enabled: shouldQueryForTickets,
      retry: 1,
    }
  )

  React.useEffect(() => {
    if (!shouldEnableRaceTickets || !isPending) return
    // Subscription to refresh pending tickets on bet placement
    const subscription = Rx.Observable.merge(
      ConfirmBetSucceeded.signal$,
      ConfirmAllBetsSuccessful.signal$,
      ConfirmBetslipBetsSucceeded.signal$
    )
      .debounce(500)
      .subscribe(a => {
        refetch()
        if (a.name === 'ConfirmBetSucceeded') {
          // for a single bet from quickbet, we know that it is for the current
          // race, so we don't need to refresh the pending count, we can just
          // whitelist the current race to query the list of pending
          addBetForRaceKey(raceKey)
        } else {
          clearCachedRacingPendingTicketsCount()
        }
      })
    return () => subscription.dispose()
  }, [refetch, shouldEnableRaceTickets, isPending, raceKey])

  if (!shouldEnableRaceTickets || isError || !data || !data.tickets?.length) return null

  return (
    <TicketDisplayContext.Provider value={{ type: 'race-card' }}>
      <RaceTicketsAccordion data={data} isLoading={isLoading} isPending={isPending} />
    </TicketDisplayContext.Provider>
  )
}

// =====
// Types
// =====

type Props = {
  isPending: boolean
  /** meetingDate / fixtureDate */
  date: string
  /** meetingId / fixtureId */
  id: string
  raceNumber: string
  raceKey: string
  hasFixedResulted: boolean
}
