import styled from '@emotion/styled'
import { greaterOrEqualThan, type Breakpoint } from '../Utils/media'
import { colors, measurements } from '../Theme/Common'

export const AppHeaderLogoLinkStyled = styled.a({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  height: measurements.mobi.headerHeight,
  fontSize: '3rem',
  textDecoration: 'none',
  userSelect: 'none',

  padding: 0,
  width: 'auto',
  color: colors.white,
})

type logoProps = {
  compactBreakpoint: keyof typeof Breakpoint
}

export const AppHeaderLogoFullStyled = styled('span')(
  ({ compactBreakpoint = 'px350' }: logoProps) => ({
    display: 'none',

    [greaterOrEqualThan(compactBreakpoint)]: {
      display: 'flex',
      width: '7rem',
    },

    [greaterOrEqualThan('px400')]: {
      width: '9rem',
    },

    [greaterOrEqualThan('medium')]: {
      width: '11rem',
    },
  })
)

export const AppHeaderLogoCompactStyled = styled('span')(
  ({ compactBreakpoint = 'px350' }: logoProps) => ({
    display: 'block',
    minWidth: '4rem',

    [greaterOrEqualThan(compactBreakpoint)]: {
      display: 'none',
    },
  })
)
