import React from 'react'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import { SummaryLine } from '@mobi/betslip/Components/Common/SummaryLine'

export const FavouriteNumbers: React.FC<{ ticket: Ticket }> = ({ ticket }) => {
  return (
    <div>
      {ticket.legs[0].selections.map(({ header, selectionDetails }) => (
        <SummaryLine key={header} left={header} right={selectionDetails[0].selectionString} />
      ))}
    </div>
  )
}
