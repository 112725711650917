import { type MutableRefObject } from 'react'
import type { Client } from 'braintree-web'
import { GooglePaymentDetails } from 'braintree-web/modules/google-payment'
import { useSelector } from 'react-redux'
import type { PaymentMethods } from '@mobi/component-library/Deposit/types'
import { type ContactDetails, type GooglePayDepositRequest } from '@mobi/api-types'
import { getGoogleMerchantId, getGooglePaymentsClient, verifyThreeDSecure } from '../../Utils'
import { tokenize } from './initGooglePayWeb'
import { DepositRequestWithoutDeviceData, useDeposit } from '../../Hooks/useDeposit'
import { type HostApi, useHostContext } from '../../HostContext'
import { useUserContactDetails } from '../../Hooks/useUserContactDetails'
import type { OnFailureParams } from '../../Components/types'
import { addCrumb, coerceIntoError, unwrapErrorMessage } from '@mobi/utils'
import {
  logLiabilityShiftError,
  logLiabilityShiftRejected,
  logLiabilityShiftSuccess,
} from '../../Utils/logging'
import type { DepositFlow, UserCancelable } from '../../typings/types'
import { selectDepositFlow } from '../../Store'
import { DepositError } from '../../Components'
import { ThreeDSecureError } from '../../Utils'

export function useGooglePayDeposit({
  braintreeClient,
  accountNumber,
  transactionId,
}: UseGooglePayDepositParams) {
  const { depositMutation } = useDeposit<GooglePayDepositRequest>({
    braintreeClient,
    accountNumber,
  })
  const hostApi = useHostContext()
  const flow = useSelector(selectDepositFlow)

  const { data: userData } = useUserContactDetails({
    accountNumber,
    flow,
  })

  const verify = async ({ depositAmount, onSuccess, onFailure, onCancel }: TokenizeParams) => {
    const client = braintreeClient.current

    if (!client) {
      throw new Error('Braintree client is uninitialized')
    }

    const googlePayClient = await getGooglePaymentsClient()

    const { nonce, details } = await tokenize({
      depositAmount,
      braintreeClient: client,
      merchantId: getGoogleMerchantId(),
      googlePayClient,
    })

    const paymentTokenResult = await getPaymentTokens({
      accountNumber,
      client,
      nonce,
      details,
      depositAmount,
      transactionId,
      userData,
      hostApi,
      flow,
    })

    if (paymentTokenResult.wasUserCanceled) {
      onCancel?.()
      return
    }

    const { nonce: paymentMethodNonce, liabilityShiftStatus } = paymentTokenResult

    if (liabilityShiftStatus === 'did_not_ask') {
      onSuccess?.({
        paymentMethodNonce,
        amount: depositAmount,
        transactionId,
        forceThreeDSecureClient: false,
        depositSource: 'GooglePay',
      })
      return
    }

    if (liabilityShiftStatus !== 'shifted') {
      onFailure?.(
        new DepositError(
          'card_verification_failed',
          transactionId,
          undefined,
          'Card failed verification'
        )
      )
      return
    }

    onSuccess?.({
      paymentMethodNonce,
      amount: depositAmount,
      transactionId,
      forceThreeDSecureClient: true,
      depositSource: 'GooglePay',
    })
  }

  return {
    verify,
    deposit: depositMutation.mutateAsync,
  }
}

const getPaymentTokens = async ({
  accountNumber,
  nonce,
  client,
  details,
  transactionId,
  depositAmount,
  userData,
  flow,
}: PaymentTokenParams): Promise<PaymentTokenResult> => {
  const paymentMethod: PaymentMethods = 'GooglePay'

  if (details.isNetworkTokenized) {
    return {
      wasUserCanceled: false,
      nonce,
      liabilityShiftStatus: 'did_not_ask',
    }
  }

  try {
    const threeDSecure = await verifyThreeDSecure(client, {
      nonce,
      bin: details.bin,
      depositAmount,
      userData,
    })

    if (threeDSecure.threeDSecureInfo.liabilityShifted) {
      logLiabilityShiftSuccess({
        accountNumber,
        transactionId,
        paymentMethod,
        flow,
      })

      return {
        wasUserCanceled: false,
        nonce: threeDSecure.nonce,
        liabilityShiftStatus: 'shifted',
      }
    }
    logLiabilityShiftRejected({
      accountNumber,
      transactionId,
      response: {
        wasUserCanceled: false,
        ...threeDSecure,
      },
      paymentMethod,
      flow,
    })
    return {
      wasUserCanceled: false,
      nonce: threeDSecure.nonce,
      liabilityShiftStatus: 'not_shifted',
    }
  } catch (error) {
    if (error instanceof ThreeDSecureError && error.code === 'user_canceled') {
      addCrumb(
        'deposit',
        'user has cancelled 3ds GPay verification step therefore deposit was not made'
      )

      return {
        wasUserCanceled: true,
      }
    }

    logLiabilityShiftError({
      accountNumber,
      transactionId,
      errorMessage: unwrapErrorMessage(coerceIntoError(error)),
      paymentMethod,
      flow,
    })
    return {
      wasUserCanceled: false,
      nonce: '',
      liabilityShiftStatus: 'verification_failed',
    }
  }
}

// region Types
type UseGooglePayDepositParams = {
  braintreeClient: MutableRefObject<Client | undefined>
  accountNumber: number
  transactionId: string
}

type TokenizeParams = {
  depositAmount: number
  onSuccess?: (data: DepositRequestWithoutDeviceData<GooglePayDepositRequest>) => void
  onFailure?: (result: OnFailureParams) => void
  onCancel?: VoidFunction
}

type PaymentTokenParams = {
  accountNumber: number
  client: Client
  nonce: string
  details: GooglePaymentDetails
  depositAmount: number
  transactionId: string
  userData?: ContactDetails
  hostApi: HostApi
  flow: DepositFlow
}

type PaymentTokenResult = UserCancelable<{
  nonce: string
  liabilityShiftStatus: 'shifted' | 'not_shifted' | 'did_not_ask' | 'verification_failed'
}>
// endregion
