import { featuredTournamentsMock, featuredSportsListMock } from './mocks'
import type {
  GetTournamentsForSportResponse,
  SingleUpcomingEventResponse,
  SportItemId,
  SportsListResponse,
  UpcomingEventsResponse,
} from './types'

export function getTournamentsForSport(sportId: SportItemId) {
  return get<GetTournamentsForSportResponse>({
    url: `/api/sports/v2/sports/${sportId}/tournaments`,
  })
}

export function getFeaturedTournamentsForSport(sportId: SportItemId) {
  // TODO: Make backend mock data match the API definition
  return resolveWithDelay(randomIntInRange(50, 200), featuredTournamentsMock[sportId] ?? [])
}

export function getFuturesForTournament(tournamentId: SportItemId) {
  return get<UpcomingEventsResponse>({
    url: `/api/sports/v2/tournaments/${tournamentId}/futures`,
  }).then(response => response.data)
}

export function getUpcomingEventsForTournament(sportId: SportItemId, tournamentId: SportItemId) {
  /**
   * @TODO disable direct call to tournaments endpoint, because this doesn't exist on rwwa API
   * but it might in the future, so don't delete completely. For now get all events for a sport
   * and then filter
   * */
  //return get<UpcomingEventsResponse>({
  //  url: `/api/sports/v2/tournaments/${tournamentId}/upcoming`,
  //}).then(response => response.data)

  return getUpcomingEventsForSport(sportId).then(data =>
    data.filter(e => e.tournamentId.toString() === tournamentId.toString())
  )
}

export function getUpcomingEventsForSport(sportId: SportItemId) {
  return get<UpcomingEventsResponse>({
    url: `/api/sports/v2/sports/${sportId}/upcoming`,
  }).then(response => response.data)
}

export function getUpcomingEventsForAllSports() {
  return get<UpcomingEventsResponse>({
    url: `/api/sports/v2/sports/upcoming`,
  }).then(response => response.data)
}

export function getSingleEventForTournament(tournamentId: SportItemId, eventId: number) {
  return get<SingleUpcomingEventResponse>({
    url: `/api/sports/v2/events/${tournamentId}/${eventId}`,
  }).then(response => response.data)
}

export function getSportsList() {
  return get<SportsListResponse>({ url: '/api/sports/v2/sports' })
}

export function getFeaturedSports() {
  return resolveWithDelay(randomIntInRange(50, 200), featuredSportsListMock)
}

// =============
// Local Helpers
// =============

// TODO: Create a formal fetch wrapper to use
// * Add better error handling

async function get<T>({ url, opts }: { url: string; opts?: RequestInit }): Promise<T> {
  const response = await fetch(url, opts)
  return response.json() as Promise<T>
}

function resolveWithDelay<T>(delayInMs: number, data: T) {
  return new Promise<T>(resolve => {
    setTimeout(() => resolve(data), delayInMs)
  })
}

function randomIntInRange(from: number, to: number) {
  return Math.floor(Math.random() * (to - from) + from)
}
