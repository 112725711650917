import * as ko from 'knockout'
import { injectable, inject } from 'inversify'
import { IDogFinisherViewModel } from './IDogFinisherViewModel'
import BaseFinisherViewModel from '../BaseFinisherViewModel'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import ObservableDogFinisher from '@classic/Betting-v2/Model/Observables/ObservableDogFinisher'
import ObservableResultsPage from '@classic/Betting-v2/Model/Observables/ObservableResultsPage'
import { MatchedFieldSummaryResults } from '@core/Areas/Racing/Components/FieldSummary/MatchedFieldSummaryResults'
import { MatchedFieldSummary } from '@core/Areas/Racing/Components/FieldSummary/MatchedFieldSummary'

@injectable()
export class DogFinisherViewModel extends BaseFinisherViewModel implements IDogFinisherViewModel {
  public stripes!: ko.ObservableArray<number>
  public stripeClass!: (arg: number) => string
  public numberOfFinishersInRace!: ko.PureComputed<number>
  public MatchedFieldSummaryResults: typeof MatchedFieldSummaryResults
  public MatchedFieldSummary: typeof MatchedFieldSummary

  constructor(@inject('IEventAggregator') eventAggregator: IEventAggregator) {
    super(eventAggregator)
    this.MatchedFieldSummaryResults = MatchedFieldSummaryResults
    this.MatchedFieldSummary = MatchedFieldSummary
  }

  init(params: {
    raceInformation: ObservableResultsPage
    finisher: ObservableDogFinisher
    showField: boolean
  }) {
    super.initBase(params)

    this.stripes = ko.observableArray<number>([])
    for (let i = 1; i <= params.finisher.stripeCount(); ++i) {
      this.stripes.push(i)
    }
    this.stripeClass = stripe => 'stripe-' + stripe
    this.numberOfFinishersInRace = ko.pureComputed(
      () => params.raceInformation.raceFinishers.finishers().length
    )
  }
}
