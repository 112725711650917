import { BamAccountHolder, BetAccount, DepositLimitFrequency } from '@mobi/api-types'
import { post, SignUpUrl } from '../../../Utils'

export type PrecommitmentModel = {
  BetAccountNumber: number
  OptInIndicator: boolean
  MaxBetAmount: string
  MaxWeeklyDepositLimit: string
  OptInMarketingIndicator: boolean
  UserCreditCardBlockedIndicator: boolean
  BlockPayPalIndicator: boolean
  AccountHolderId: number
  PreCommitmentMaxDepositAmount: number | null
  PreCommitmentMaxDepositFrequency: DepositLimitFrequency
}

export async function savePrecommitmentDetails(
  model: PrecommitmentModel,
  onCompletion?: () => void
): Promise<void> {
  const body = { PreCommitmentDetails: model }
  await post<typeof body, void>(SignUpUrl.SavePrecommitments, body)
  if (onCompletion) {
    onCompletion()
  }
}

export type PrecommitmentResponse = {
  BetLimit?: number
  ProposedBetLimit: { Amount: number; ActiveDate: string }
  ProposedCreditCardReleaseDate: string | null
  BlockCreditCardDeposits: boolean
  BlockCreditCardDepositsEnable: boolean
  CoolingOffPeriodDays: number
  CoolingOffDate: string
  BetLimitRange: { Min: number; Max: number }
  RwwaAccountsContactTel: string
}

export type UpdateDepositLimitAnniversaryRequest = Pick<BetAccount, 'BetAccountNumber'> &
  Pick<BamAccountHolder, 'AccountHolderId'> & {
    Location: number
    Keep: boolean
  }

export async function updateDepositLimitAnniversary(
  model: UpdateDepositLimitAnniversaryRequest
): Promise<void> {
  await post<UpdateDepositLimitAnniversaryRequest, void>(
    SignUpUrl.UpdateDepositLimitAnniversary,
    model
  )
}
