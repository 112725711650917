import React, { type InputHTMLAttributes } from 'react'
import styled from '@emotion/styled'
import { colors, font, radius, spacing } from '@mobi/component-library/Theme/Common'
import { type SharedProps } from './types'

type Props = SharedProps & {
  /**
   * Setting this property will turn the input into a controlled input, doing so will require an `onChange` or `onInput` handler.
   * This property is mutually exclusive with `defaultChecked`.
   */
  checked?: boolean
  /**
   * Setting this property will turn the input into an uncontrolled input.
   * This property is mutually exclusive with `checked`.
   */
  defaultChecked?: boolean
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'disabled'>

export const Checkbox = ({ id, name, label, helpText, isDisabled, ...inputAttributes }: Props) => {
  id ||= name

  return (
    <CheckboxStyled>
      <input id={id} type='checkbox' disabled={isDisabled} {...inputAttributes} />

      <label htmlFor={id}>
        <span role='checkbox'>
          <svg
            width='1.4rem'
            height='1.4rem'
            viewBox='0 0 12 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M10 3L4.5 8.5L2 6'
              stroke='currentColor'
              strokeWidth='1.6666'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </span>

        {label && (
          <span>
            <span>{label}</span>
            <span>{helpText}</span>
          </span>
        )}
      </label>
    </CheckboxStyled>
  )
}

// ======
// Styles
// ======

const CheckboxStyled = styled.div({
  '--checkbox-size': '2rem',

  fontFamily: font.family.primary,
  fontSize: font.size.xl2.fontSize,
  letterSpacing: font.size.xl2.letterSpacing,
  lineHeight: font.size.xl2.lineHeight,

  input: { display: 'none' },

  label: {
    display: 'flex',
    alignItems: 'center',

    // Label Text Container
    '> span:last-of-type': {
      display: 'block',

      span: {
        display: 'flex',
        alignItems: 'center',
        margin: '0.1rem 0',

        // Label
        ':first-of-type': { color: colors.neutral[900] },

        // Help
        ':last-of-type': { color: colors.neutral[600] },
        ':last-of-type:empty': { display: 'none' },
      },
    },

    // Handling Checkbox alignment when there is no label
    '> span:only-child': { marginRight: 0, display: 'flex' },
  },

  '[role="checkbox"]': {
    boxSizing: 'border-box',
    flex: '0 0 auto',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'var(--checkbox-size)',
    height: 'var(--checkbox-size)',
    marginRight: spacing.sm,
    backgroundColor: colors.white,
    border: `1px solid ${colors.neutral[300]}`,
    borderRadius: radius.sm,
    color: colors.transparent,
  },

  'input:checked + label [role="checkbox"]': {
    backgroundColor: colors.lavender[500],
    borderColor: colors.lavender[500],
    color: colors.lavender[50],
  },

  'input:disabled + label [role="checkbox"]': {
    backgroundColor: colors.neutral[200],
    borderColor: colors.neutral[300],
  },

  'input:disabled:checked + label [role="checkbox"]': {
    color: colors.neutral[300],
  },
})
