import React from 'react'
import { store } from '@core/Store'
import { setBetSlipNewIsOpen } from '@core/Areas/AppHeader/Store'
import {
  MULTI_BUILDER_TOAST_ID,
  MultiBuilderToastContent,
} from '@mobi/betslip/Components/MultiBuilderToastContent/MultiBuilderToastContent'
import { addToast, removeToast, ToastContent } from '@mobi/component-library/Feedback/Toast'
import type { BetSlipItem } from '@mobi/betslip/types'

export const showMultiBuilderToast = (item: BetSlipItem) => {
  addToast({
    theme: 'none',
    isFullWidth: true,
    timeoutMs: 4000,
    id: MULTI_BUILDER_TOAST_ID,
    content: <MultiBuilderToastContent item={item} />,
  })
}

export const showBetSlipClearedToast = () => {
  const toastText = 'Bet Slip Cleared'
  addToast({
    id: toastText,
    content: <ToastContent icon='success' title={toastText} />,
  })
}

export const showAddedToBetSlipToast = () => {
  const toastText = 'Added To Bet Slip'

  const onBetNowClick = () => {
    removeToast({ id: toastText })
    store.dispatch(setBetSlipNewIsOpen(true))
  }

  addToast({
    id: toastText,
    content: (
      <ToastContent
        icon='success'
        title={toastText}
        button={{ text: 'Bet Now', onClick: onBetNowClick }}
      />
    ),
  })
}
