import type { PaymentMethods } from '@mobi/component-library/Deposit/types'
import { track } from '../../../Utils'
import type { DepositFlow, VerifyThreeDSecureResponse } from '../typings/types'
import { addCrumb } from '@mobi/utils'
import { reportErrorIfNeeded } from '../../../Utils/sentry'

/**
 * Asynchronously report deposit failure details and deposit events.
 *
 * This promise can safely be ignored
 *
 * @param {String} logType categorise the log entry (for reporting purposes)
 * @param details Any additional information that needs to be sent
 */
export function logClient(logType: string, details?: Record<string, unknown>) {
  return fetch('/api/client-log/log', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ logType, ...(details ?? {}) }),
  }).catch(() => {
    // best effort - ignore serialization, network errors, etc.
  })
}

type LiabilityShiftParams = {
  accountNumber: number
  transactionId: string
  paymentMethod: PaymentMethods
  flow: DepositFlow
}

type LiabilityShiftErrorParams = LiabilityShiftParams & {
  errorMessage: string
}

type LiabilityShiftRejectedParams = LiabilityShiftParams & {
  response: VerifyThreeDSecureResponse
}

export function logLiabilityShiftError({
  accountNumber,
  transactionId,
  paymentMethod,
  errorMessage,
  flow,
}: LiabilityShiftErrorParams) {
  track('deposit-3d-secure-error', {
    accountNumber,
    paymentMethod,
    message: errorMessage,
    depositVariant: flow,
  })
  reportErrorIfNeeded({
    message: `user 3ds verification error: ${errorMessage} trx:${transactionId}`,
  })
}

export function logLiabilityShiftRejected({
  accountNumber,
  transactionId,
  response,
  paymentMethod,
  flow,
}: LiabilityShiftRejectedParams) {
  track('deposit-3d-secure-not-shifted', {
    accountNumber,
    paymentMethod,
    depositVariant: flow,
  })

  addCrumb(
    'deposit',
    `user has not successfully verified with 3ds: liability shift possible ${response.threeDSecureInfo.liabilityShiftPossible} trx:${transactionId}`
  )
}

export function logLiabilityShiftSuccess({
  accountNumber,
  transactionId,
  paymentMethod,
  flow,
}: LiabilityShiftParams) {
  track('deposit-3d-secure', {
    accountNumber,
    paymentMethod,
    depositVariant: flow,
  })
  addCrumb('deposit', `user has successfully verified with 3ds trx:${transactionId}`)
}
