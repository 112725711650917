import React from 'react'
import styled from '@emotion/styled'
import { colors, font, radius, shadow, spacing } from '@mobi/component-library/Theme/Common'

export const Container: React.FC<{ children: React.ReactNode; topComponent?: React.ReactNode }> = ({
  children,
  topComponent,
}) => {
  return (
    <CardStyled data-testid='BetCard'>
      <div>{topComponent}</div>

      <div>{children}</div>
    </CardStyled>
  )
}

// ======
// Styles
// ======

const CardStyled = styled.div({
  backgroundColor: colors.white,
  border: '1px solid ' + colors.neutral[200],
  borderRadius: radius.lgx1,
  boxShadow: shadow.xs,
  fontFamily: font.family.primary,

  '> div:first-of-type': {
    overflow: 'hidden',
    borderTopLeftRadius: radius.lgx1,
    borderTopRightRadius: radius.lgx1,

    ':empty': { display: 'none' },
  },

  '> div:last-of-type': {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.sm,
    padding: spacing.sm,
  },
})
