/**
 * Lazily zip together any number of same length arrays and return a tuple for each step
 *
 * This is more performant then `zip` if you chain another array fluent api call
 * e.g. `.map(...)` immediately after this call, because it only allocates once
 * when you call `.toArray()`, at the end of the call chain
 * @param arrs Any number of arrays. They must all be the same length
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* zipLazy<T extends any[]>(...arrs: T): Generator<FlatTuple<T>> {
  const size = arrs.length
  const steps = Math.max(...arrs.map(x => x.length))
  for (let index = 0; index < steps; index++) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const step: FlatTuple<T> = new Array(size) as any
    for (let i = 0; i < size; i++) {
      const arr = arrs[i]
      step[i] = arr[index]
    }
    yield step
  }
}

/**
 * Zip together any number of same length arrays and return a tuple for each step
 *
 * Use `zipLazy` for more memory performance if this is a sequence in fluent api calls
 * and it makes sense to lazily evaluate the expressions
 * @param arrs Any number of arrays. They must all be the same length
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function zip<T extends any[]>(...arrs: T): FlatTuple<T>[] {
  return Array.from(zipLazy(...arrs))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FlatTuple<T extends any[][]> = { [K in keyof T]: T[K] extends (infer U)[] ? U : never } // black magic to staticly infer typs from array spread
