import styled from '@emotion/styled'
import { font, colors, spacing } from '@mobi/component-library/Theme/Common'

export const DetailsSecondaryInfoStyled = styled.div({
  fontSize: font.size.sm.fontSize,
  letterSpacing: font.size.sm.letterSpacing,
  lineHeight: font.size.sm.lineHeight,
  color: colors.neutral[800],

  dl: {
    margin: 0,

    dt: { fontWeight: font.weight.medium },

    dd: {
      margin: 0,
      padding: 0,
      fontWeight: font.weight.regular,
    },
  },

  'dl > div': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: spacing.smx2,
    paddingTop: spacing.smx2,
    paddingBottom: spacing.smx2,
  },

  'dl > span': {
    display: 'block',
    paddingTop: spacing.smx2,
    paddingBottom: spacing.smx2,

    'dt, dd': { display: 'inline' },
    dd: { marginLeft: spacing.smx2 },
  },
})
