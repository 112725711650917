import React from 'react'
import type { WithdrawResponse } from '@mobi/account/Areas/Withdraw/Utils/types'
import { trackOptimoveEvent } from '../optimove'

export const useTrackOptimoveWithdrawalPercentage = (
  withdrawalResponse?: WithdrawResponse | null
) => {
  const hasTrackedSuccessfulWithdrawalRef = React.useRef<boolean>(false)

  React.useEffect(() => {
    if (!withdrawalResponse?.isSuccessful || hasTrackedSuccessfulWithdrawalRef.current) return
    const { balance, withdrawalAmount } = withdrawalResponse
    const fraction = withdrawalAmount / (balance + withdrawalAmount)
    const percentage = Math.floor(fraction * 100)

    trackOptimoveEvent({ eventName: 'withdrawal', data: { percentage } })
    hasTrackedSuccessfulWithdrawalRef.current = true
  }, [withdrawalResponse])
}
