import React from 'react'
import { ExpandableSectionStyled } from './ExpandablePanelContent.styles'

interface ExpandableSectionProps {
  expanded: boolean
  transitionTime?: number
  transitionCloseTime?: number
  easing?: 'ease-in' | 'ease-out' | 'ease-in-out' | 'none'
  scrollIntoViewWhenOpened: boolean
  useSmartScroll?: boolean
  children?: React.ReactNode
}
interface ExpandableSectionState {
  expanded: boolean
  height: string | number
  transition: string
  hasBeenOpened: boolean
  shouldSwitchAutoOnNextCycle: boolean
  shouldOpenOnNextCycle: boolean
  overflow: React.CSSProperties['overflow']
}

export class ExpandableSection extends React.Component<
  ExpandableSectionProps,
  ExpandableSectionState
> {
  public static defaultProps = {
    transitionTime: 200,
    transitionCloseTime: 100,
    easing: 'ease-in-out',
  }

  private innerRef: HTMLElement | undefined
  private childrenRenderedTimeoutHandle: number | undefined
  private scrollIntoViewWhenOpened
  private useSmartScroll

  constructor(props: ExpandableSectionProps) {
    super(props)

    if (props.expanded) {
      this.state = {
        expanded: true,
        height: 'auto',
        transition: 'none',
        hasBeenOpened: true,
        shouldSwitchAutoOnNextCycle: false,
        shouldOpenOnNextCycle: false,
        overflow: 'visible',
      }
    } else {
      this.state = {
        expanded: false,
        height: 0,
        transition: `height ${props.transitionTime}ms ${props.easing}`,
        hasBeenOpened: false,
        shouldSwitchAutoOnNextCycle: false,
        shouldOpenOnNextCycle: false,
        overflow: 'hidden',
      }

      this.scrollIntoViewWhenOpened = props.scrollIntoViewWhenOpened
      this.useSmartScroll = props.useSmartScroll
    }
  }

  public render() {
    const styles = {
      height: this.state.height,
      WebkitTransition: this.state.transition,
      msTransition: this.state.transition,
      transition: this.state.transition,
      overflow: this.state.overflow,
    }
    return (
      <ExpandableSectionStyled style={styles} onTransitionEnd={this.handleTransitionEnd}>
        <div ref={this.setInnerRef}>{this.props.children}</div>
      </ExpandableSectionStyled>
    )
  }

  public componentDidUpdate(prevProps: ExpandableSectionProps, prevState: ExpandableSectionState) {
    if (this.state.shouldOpenOnNextCycle) {
      this.continueOpenCollapsible()
    }

    if (prevState.height !== 0 && this.state.shouldSwitchAutoOnNextCycle) {
      this.cleanUpTimeout()
      this.childrenRenderedTimeoutHandle = window.setTimeout(() => {
        // Set timeout to ensure children have rendered
        this.setState({
          height: 0,
          overflow: 'hidden',
          expanded: false,
          shouldSwitchAutoOnNextCycle: false,
        })
      }, 10)
    } else if (this.scrollIntoViewWhenOpened && this.state.shouldOpenOnNextCycle) {
      this.cleanUpTimeout()
      this.childrenRenderedTimeoutHandle = window.setTimeout(() => {
        // Set timeout to ensure children have rendered
        if (this.useSmartScroll) {
          // @ts-expect-error This component needs work
          var currentElementTop = this.innerRef.offsetParent.offsetParent.offsetTop
          // @ts-expect-error This component needs work
          var elementContentHeight = this.innerRef.offsetParent.offsetParent.offsetHeight
          if (currentElementTop && elementContentHeight) {
            // if the height of content panel is more than window height, then scroll to top to show both header panel and content
            // if the current offset position plus content panel height is greater than window height, then scrollIntoView(false) to show the content (for better user experience)
            // if the current view can show the content of the panel or can't determine the Top position and Height of content then no need to use scrollIntoView
            if (elementContentHeight > window.innerHeight) {
              this.innerRef?.scrollIntoView(true)
            } else if (
              currentElementTop + elementContentHeight >
              window.innerHeight + document.documentElement.scrollTop
            ) {
              this.innerRef?.scrollIntoView(false)
            }
          }
        } else {
          this.innerRef?.scrollIntoView()
        }
      }, this.props.transitionTime)
    }

    if (prevProps.expanded !== this.props.expanded) {
      if (this.props.expanded) {
        this.openCollapsible()
      } else {
        this.closeCollapsible()
      }
    }
  }

  public componentWillUnmount() {
    this.cleanUpTimeout()
  }

  private cleanUpTimeout() {
    if (this.childrenRenderedTimeoutHandle) {
      clearTimeout(this.childrenRenderedTimeoutHandle)
    }
  }

  private closeCollapsible() {
    this.setState({
      height: this.innerRef?.offsetHeight as number,
    })

    // Set timeout so that height is applied before applying transition,
    // some browser implementations are not able to handle transition with height: auto resulting
    this.cleanUpTimeout()
    this.childrenRenderedTimeoutHandle = window.setTimeout(() => {
      this.setState({
        shouldSwitchAutoOnNextCycle: true,
        transition: `height ${
          this.props.transitionCloseTime
            ? this.props.transitionCloseTime
            : this.props.transitionTime
        }ms ${this.props.easing}`,
      })
    }, 10)
  }

  private openCollapsible() {
    this.setState({
      shouldOpenOnNextCycle: true,
    })
  }

  private continueOpenCollapsible = () => {
    this.setState({
      height: this.innerRef?.offsetHeight as number,
      transition: `height ${this.props.transitionTime}ms ${this.props.easing}`,
      expanded: true,
      hasBeenOpened: true,
      shouldOpenOnNextCycle: false,
    })
  }

  private setInnerRef: React.LegacyRef<HTMLDivElement> = (ref: HTMLDivElement) => {
    this.innerRef = ref
  }

  private handleTransitionEnd = () => {
    if (this.state.expanded) {
      // set transition to none to cater for browsers that do not handle transitions for height 'auto'
      this.setState({ height: 'auto', overflow: this.state.overflow, transition: 'none' })
    }
  }
}
