import styled, { CSSObject } from '@emotion/styled'
import { hexColors } from '@mobi/settings'
import { measurements, layering } from '@mobi/component-library/Theme/Common'

export const AppHeaderContainerStyled = styled('div')({
  backgroundColor: hexColors.blueDiamond,
  position: 'relative',
  height: '6em',
  maxWidth: '80em',
  margin: '0 auto',
})

export const AppHeaderStyled = styled.header<{
  isStickyEnabled: boolean
  shouldLowerZIndex: boolean
}>(
  {
    top: 0,
    left: 0,
    right: 0,
    boxSizing: 'border-box',
    backgroundColor: hexColors.blueDiamond,
    color: hexColors.white,
    height: measurements.mobi.headerHeight,
    width: '100%',
    margin: '0 auto',
    maxWidth: measurements.mobi.maxWidth,
    minWidth: '30em',
    transform: 'translateY(0)',
  },
  ({ isStickyEnabled, shouldLowerZIndex }) => ({
    position: isStickyEnabled ? 'fixed' : 'relative',
    zIndex: shouldLowerZIndex ? layering.baseLayer : layering.appHeader,
  })
)

export const AppHeaderInnerStyled = styled('div')({
  position: 'relative',
  zIndex: 1,
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  height: measurements.mobi.headerHeight,
  backgroundColor: hexColors.blueDiamond,
})

export const baseAppHeaderButtonStyle: CSSObject = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  width: '4rem',
  height: measurements.mobi.headerHeight,
  padding: '0.5rem',
  fontSize: '3rem',
  textDecoration: 'none',
  color: hexColors.lilac,
  userSelect: 'none',
}

export const AppHeaderLinkStyled = styled.a(baseAppHeaderButtonStyle)

export const AppHeaderLogoLinkStyled = styled(AppHeaderLinkStyled)({
  padding: 0,
  width: 'auto',
  color: hexColors.white,
})

export const AppHeaderRightAlignedContainerStyled = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flex: '1',
})

export const HamburgerStyled = styled.button(baseAppHeaderButtonStyle, {
  cursor: 'pointer',
  color: hexColors.white,
  background: 0,
  border: 0,
})
