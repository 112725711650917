import { injectable, inject } from 'inversify'
import type { IEventAggregator } from '@classic/AppUtils/Framework/Messaging/IEventAggregator'
import ObservableRaceFinisher from '@classic/Betting-v2/Model/Observables/ObservableRaceFinisher'
import ObservableResultsPage from '@classic/Betting-v2/Model/Observables/ObservableResultsPage'
import BaseFinisherViewModel from '../BaseFinisherViewModel'
import { IRaceFinisherViewModel } from './IRaceFinisherViewModel'
import { MatchedFieldSummaryResults } from '@core/Areas/Racing/Components/FieldSummary/MatchedFieldSummaryResults'
import { MatchedFieldSummary } from '@core/Areas/Racing/Components/FieldSummary/MatchedFieldSummary'
import { JockeyName } from '@core/Areas/RaceCard/Components/JockeyName'

@injectable()
export class RaceFinisherViewModel extends BaseFinisherViewModel implements IRaceFinisherViewModel {
  public MatchedFieldSummaryResults: typeof MatchedFieldSummaryResults
  public MatchedFieldSummary: typeof MatchedFieldSummary
  public JockeyName: typeof JockeyName

  constructor(@inject('IEventAggregator') eventAggregator: IEventAggregator) {
    super(eventAggregator)
    this.MatchedFieldSummaryResults = MatchedFieldSummaryResults
    this.MatchedFieldSummary = MatchedFieldSummary
    this.JockeyName = JockeyName
  }

  init(params: {
    raceInformation: ObservableResultsPage
    finisher: ObservableRaceFinisher
    showField: boolean
  }) {
    super.initBase(params)
  }
}
