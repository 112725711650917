/* eslint-disable no-console */
import { createSignal, attachDriver, Signal } from 'rwwa-rx-state-machine'
import { TypedRecord, makeTypedFactory } from 'typed-immutable-record'
import { List as ImmutableList } from 'immutable'
import { hasValue } from '@mobi/utils'
import { List as ImmutableListExtended } from 'typings/immutable'
import {
  DEFAULT_SUPERPICK_STATUS,
  FobSpecialToken,
} from '@core/Areas/AppHeader/Components/AccountDropDown/Components/helpers/useGetSpecialTokens'
import { trackOptimoveEvent } from '@core/Services/Optimove/optimove'

export type CampaignTriggerType =
  | 'AccountDefaultTrigger'
  | 'AccountBetTrigger'
  | 'AccountDepositTrigger'
  | 'AccountTrigger'
  | 'AccountWinningBetTrigger'

export type CampaignRewardType = 'BonusBetReward' | 'BonusCashReward' | 'CashReward' | 'Reward'

export interface Campaign {
  id: number
  triggerType: CampaignTriggerType
  rewardType: CampaignRewardType
  title: string
  description: string
  expiry: Date
  rewardExpiry: Date
  minimumAmount: number
  initialAmount: number
  remainingAmount: number
  isActivated: boolean
  isActivationPending: boolean
  isExpired: boolean
  isLive: boolean
  isNotActivated: boolean
  isRedeemed: boolean
  redeemableBy: string
  redemptionDetails: null | {
    race: {
      fixtureId: number
      raceNumber: number
      fixtureDate: Date
    }
  }
}

export interface SuperpickStatus {
  isLoading: boolean
  tokens: FobSpecialToken[]
  errorMessage: string
  errorCode: number | null
}

export interface UserAccountState {
  isLoggedIn: boolean | null
  hasEvaluatedUserAuth: boolean
  accountBalance: number | null
  accountBalanceUpdatedTime: number | null
  bonusBetBalance: number | null
  bonusCashBalance: number | null
  campaigns: ImmutableList<Campaign> | null // All campaigns, regardless of state
  activeCampaigns: ImmutableList<Campaign> | null // Active only
  availableCampaigns: ImmutableList<Campaign> | null // Available i.e. on the home screen
  accountNumber: number | null
  residentialDetail: ImmutableListExtended<ResidentialDetail> | null
  superpicks: SuperpickStatus
}

export const defaultUserAccountState: UserAccountState = {
  isLoggedIn: null,
  accountBalance: null,
  hasEvaluatedUserAuth: false,
  accountBalanceUpdatedTime: null,
  bonusBetBalance: null,
  bonusCashBalance: null,
  campaigns: null,
  activeCampaigns: null,
  availableCampaigns: null,
  accountNumber: null,
  residentialDetail: null,
  superpicks: {
    isLoading: false,
    tokens: [],
    errorMessage: '',
    errorCode: null,
  },
}

export const UpdateAccountBalance = createSignal<{ balance: number | string }>(
  'UpdateAccountBalance'
)
export const UpdateBonusBalance = createSignal<{
  bonusBetBalance: number | string
  bonusCashBalance: number | string
}>('UpdateBonusBalance')
export const HasLoggedIn = createSignal<{ accountNumber: number | string }>('HasLoggedIn')
export const HasLoggedOut = createSignal('HasLoggedOut')
export const UpdateCampaigns = createSignal<Campaign[]>('UpdateCampaigns')
export type ResidentialDetail = [country: string, state: string]
export const SetResidentialDetail = createSignal<ResidentialDetail>('SetResidentialDetail')
export const SetSuperpickStatus = createSignal<SuperpickStatus>('SetSuperpickStatus')

export interface UserAccountStateRecord
  extends TypedRecord<UserAccountStateRecord>,
    UserAccountState {}

export const UserAccountStateFactory = makeTypedFactory<UserAccountState, UserAccountStateRecord>(
  defaultUserAccountState
)

export function userAccountDriver(
  state = UserAccountStateFactory(),
  signal: Signal
): UserAccountStateRecord {
  switch (signal.tag) {
    case HasLoggedIn: {
      const { accountNumber } = signal.data
      return state.merge({
        isLoggedIn: true,
        hasEvaluatedUserAuth: true,
        accountNumber,
      })
    }

    case HasLoggedOut: {
      return state.merge({
        ...defaultUserAccountState,
        hasEvaluatedUserAuth: true,
        isLoggedIn: false,
      })
    }

    case UpdateAccountBalance: {
      const { balance } = signal.data
      const timestamp = Date.now()
      // handle null, undefined, ''
      if (hasValue(balance)) {
        const newAccountBalance = +balance
        if (Number.isFinite(newAccountBalance) && newAccountBalance !== state.accountBalance) {
          trackOptimoveEvent({
            eventName: 'account_balance_updated',
            data: { balance: newAccountBalance },
          })

          return state.merge({
            accountBalance: newAccountBalance,
            accountBalanceUpdatedTime: timestamp,
          })
        }
      }
      return state
    }

    case UpdateBonusBalance: {
      const { bonusBetBalance, bonusCashBalance } = signal.data

      return state.merge({
        bonusBetBalance,
        bonusCashBalance,
      })
    }

    case UpdateCampaigns: {
      const campaigns = ImmutableList(signal.data as Campaign[])
      const activeCampaigns = ImmutableList(
        signal.data.filter((x: Campaign) => x.isActivated) as Campaign[]
      )
      const availableCampaigns = ImmutableList(
        signal.data.filter((x: Campaign) => !(x.isRedeemed || x.isExpired))
      )

      return state.merge({
        campaigns,
        activeCampaigns,
        availableCampaigns,
      })
    }

    case SetResidentialDetail: {
      return state.merge({
        residentialDetail: signal.data,
      })
    }

    case SetSuperpickStatus: {
      const newState = state.merge({
        superpicks: signal.data || DEFAULT_SUPERPICK_STATUS,
      })
      newState.superpicks.tokens = signal.data?.isLoading
        ? state.superpicks.tokens
        : signal.data?.tokens || []
      return newState
    }

    default: {
      return state
    }
  }
}

export const state$ = attachDriver<UserAccountStateRecord>({
  path: 'user-account',
  driver: userAccountDriver,
})
