import { getSingleEventForTournament } from '@mobi/sports/Data/api'
import { SportsProposition } from '@mobi/sports/Data/types'
import { buildEventPageURL } from '@mobi/sports/Utils'
import { SportDetails } from '../../types'

export async function buildSportsBetProposition({
  tournamentId,
  eventId,
  marketId,
  externalBetId,
}: SportDetails): Promise<SportsProposition> {
  const event = await getSingleEventForTournament(tournamentId, eventId)

  const selectedMarket = event?.markets?.find(market => market.marketId === marketId)
  const selectOutcome = selectedMarket?.outcomes?.find(
    outcome => outcome.externalBetId === externalBetId
  )

  return {
    sportName: event.sportName,
    sportId: Number(event.sportId),
    tournamentName: event.tournamentName,
    tournamentId: Number(event.tournamentId),
    eventName: event.eventName,
    eventId: event.eventId,
    advertisedStartTime: event.advertisedStartTime,
    marketId: marketId,
    marketName: selectedMarket?.marketName || '',
    bettingClosingTime: selectedMarket?.bettingClosingTime || '',
    outcomeId: selectOutcome?.outcomeId ?? 0,
    outcomeName: selectOutcome?.outcomeName || '',
    price: selectOutcome?.price ?? 0,
    externalBetId: externalBetId,
    urlPath: buildEventPageURL(event.sportId, tournamentId, eventId),
    marketTypeCode: selectOutcome?.marketTypeCode || '',
  }
}
