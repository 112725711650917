import { type RefObject, useEffect, useRef } from 'react'
import {
  type HostedFields,
  hostedFields,
  type HostedFieldsTokenizePayload,
  type BraintreeError,
} from 'braintree-web'
import { font } from '@mobi/component-library/Theme/Common'
import { HostedFieldsError } from '../Utils/HostedFieldsError'
import { useBraintreeClient } from './useBraintreeClient'

type HostedFieldRef = RefObject<HTMLElement | null>

export type TokenizeSuccessParams = Pick<HostedFieldsTokenizePayload, 'nonce' | 'details'>

type Params = {
  clientToken: string
  fields: {
    number: HostedFieldRef
    expiry: HostedFieldRef
    cvc: HostedFieldRef
  }
  onInitError: (error: HostedFieldsError) => void
}

export function useHostedFields({
  clientToken,
  fields: { number, expiry, cvc },
  onInitError,
}: Params) {
  const hostedFieldsRef = useRef<HostedFields | undefined>(undefined)
  const { client, isReady } = useBraintreeClient(clientToken)

  const tokenize = async (): Promise<TokenizeSuccessParams> => {
    const hostedFields = hostedFieldsRef.current

    if (!hostedFields) {
      throw HostedFieldsError.uninitialized()
    }

    const { fields } = hostedFields.getState()
    const { number, expirationDate, cvv } = fields

    const errors = [number, expirationDate, cvv]
      .filter(field => !field.isValid)
      .reduce<Record<string, string>>((errors, field) => {
        const fieldName = field.container.dataset.field

        if (fieldName) {
          errors[fieldName] = 'This field is invalid'
        }

        return errors
      }, {})

    if (Object.keys(errors).length > 0) {
      throw HostedFieldsError.validationErrors(errors)
    }

    try {
      const { nonce, details } = await hostedFields.tokenize()

      return {
        nonce,
        details,
      }
    } catch (err) {
      const error = err as BraintreeError

      switch (error.code) {
        case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
          throw HostedFieldsError.tokenizationFailure()
        case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
          throw HostedFieldsError.networkError()
        default:
          throw HostedFieldsError.unknown()
      }
    }
  }

  useEffect(() => {
    isReady().then(isReady => {
      if (!isReady) {
        return
      }

      hostedFields
        .create({
          client: client.current,
          styles: {
            input: {
              'font-family': font.family.primary,
              // Braintree has the root font size set to 16px
              'font-size': `calc(${font.size.md.fontSize} / 1.6)`,
              'letter-spacing': `calc(${font.size.md.letterSpacing} / 1.6)`,
              'line-height': `calc(${font.size.md.lineHeight} / 1.6)`,
            },
          },
          fields: {
            number: {
              container: number.current ?? undefined,
              placeholder: 'Card Number',
            },
            expirationDate: {
              container: expiry.current ?? undefined,
              placeholder: 'MM / YY',
            },
            cvv: {
              container: cvc.current ?? undefined,
              placeholder: 'CVV',
            },
          },
        })
        .then(fields => {
          hostedFieldsRef.current = fields
        })
        .catch((err: BraintreeError) => {
          const hostedFieldsError =
            err.code === 'HOSTED_FIELDS_TIMEOUT'
              ? HostedFieldsError.timeout()
              : HostedFieldsError.unknown()

          onInitError(hostedFieldsError)
        })
    })

    return () => {
      hostedFieldsRef.current?.teardown()
      hostedFieldsRef.current = undefined
    }
  }, [client, cvc, expiry, isReady, number, onInitError])

  return { tokenize }
}
