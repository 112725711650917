import React from 'react'
import { dayjs } from '@mobi/utils/date'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { colors } from '@mobi/component-library/Theme/Common'
import type { Ticket, TicketStatus } from '@core/Areas/AccountActivity/types'
import { CountdownStatus } from '../Common/CountdownStatus'
import { Currency } from '@mobi/utils/money'

export const Multi: React.FC<{ ticket: Ticket; status: TicketStatus }> = ({ ticket, status }) => {
  const formulaText = ticket.formula?.formulaString ? ` · ${ticket.formula.formulaString}` : ''

  const isPending = status === 'Pending'
  const finishedLegAst = !isPending ? getMultiFinishedLegAst(ticket, status) : null
  const headingText = isPending
    ? 'Next leg begins in:'
    : finishedLegAst
      ? `Finished on ${dayjs(finishedLegAst).format('ddd Do MMM')}`
      : undefined

  return (
    <>
      <BetCard.EventDetails
        heading={headingText}
        statusComponent={<CountdownStatus ticket={ticket} status={status} />}
        iconComponent={null}
      />

      <BetCard.SelectionInfo
        heading={`${ticket.legs.length} Leg Multi${formulaText}`}
        subHeading={
          <>
            {ticket.legs.map(leg => {
              const { name, number } = leg.selections[0].selectionDetails[0].acceptorDetails[0]
              return (
                <React.Fragment key={number + name + leg.advertisedStartTime}>
                  {`${number ? number + ' - ' : ''}${name}`}
                  <br />
                </React.Fragment>
              )
            })}
          </>
        }
        priceWin={ticket.combinedDividend ? new Currency(ticket.combinedDividend).format() : ''}
        iconComponent={<Icon size='2.4rem' name='CustomBetslip' color={colors.neutral[800]} />}
      />
    </>
  )
}

// =============
// Local Helpers
// =============

function getMultiFinishedLegAst(ticket: Ticket, status: TicketStatus): string | null {
  if (status === 'Won') {
    const latestAst = ticket.legs.reduce<string>(reduceCallbackToGetLatestAst, '')
    return latestAst
  }

  if (status === 'NoPayout') {
    const latestAst = ticket.legs
      .filter(leg => leg.result === 'LOSE')
      .reduce<string>(reduceCallbackToGetLatestAst, '')
    return latestAst
  }

  return null
}

function reduceCallbackToGetLatestAst(latestAst: string, curr: Ticket['legs'][0]) {
  if (!latestAst) return curr.advertisedStartTime
  if (dayjs(latestAst).isBefore(curr.advertisedStartTime)) return curr.advertisedStartTime
  return latestAst
}
