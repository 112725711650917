import { z } from 'zod'

export const serverConfigurationSchema = z.object({
  serverVersion: z.string(),
  machineIdentifier: z.string(),
  serverEnvironment: z.string(),
  enableSentryIo: z.boolean(),
  enableJsConsoleLogging: z.boolean(),
  sentryIoUrl: z.string(),
  enableAnalytics: z.boolean(),
  isInternalRequest: z.boolean(),
  launchDarklyClientID: z.string(),
  eventNotificationApi: z.object({
    url: z.string(),
    region: z.string(),
    identityPoolId: z.string(),
  }),
  racingWAStreamUrl: z.string(),
})

export type ServerConfiguration = z.infer<typeof serverConfigurationSchema>
