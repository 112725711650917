import type {
  StartingPriceFOOSelection,
  FobPropositionSelection,
  FobDetails,
} from '@mobi/betslip/types'
import { PlanSeq } from '@mobi/api-types'
import { QuickbetSelection } from '@core/Areas/Quickbet/signals'
import { RaceProduct } from '@core/Areas/RaceCardFixedOnly'
import { getFixedOddsEventDetails } from '@core/Areas/RaceCardFixedOnly/Data/api'
import { FixedOddsMarketDisplayNames } from '@core/Areas/RaceCard/constants'
import {
  parseSelectionsString,
  getToteSelection,
  getFOBSelection,
} from './getSelectionInfo/getSelection'
import { getSelectionDetails } from './getSelectionInfo/getSelectionDetails'
import { BetDetails, FOOBetDetails, LoadBetDetails, SportDetails } from '../types'
import { createSportsQuickbetSelection } from '@core/Areas/Sports/helpers/createSportsBetSelection'
import { buildSportsBetProposition } from './getSelectionInfo/buildSportsBetProposition'

export async function buildBaseBetSelection(bet: LoadBetDetails): Promise<QuickbetSelection> {
  if (isFOOBetDetails(bet)) {
    return await buildFixedOddsOnlyBet(bet)
  }
  if (isSportBetDetails(bet)) {
    return createSportsQuickbetSelection(await buildSportsBetProposition(bet))
  }
  return await buildStandardBet(bet)
}

export const isFOOBetDetails = (bet: LoadBetDetails): bet is FOOBetDetails =>
  bet.hasOwnProperty('propositionSeq')

export const isSportBetDetails = (bet: LoadBetDetails): bet is SportDetails =>
  bet.hasOwnProperty('externalBetId')

export function getFOOEventType(sportName: string | null): RaceProduct {
  const product = (sportName || '').toLowerCase()
  if (product.includes('feature')) return RaceProduct.FeatureRace
  if (product.includes('challenge')) return RaceProduct.Challenge
  if (product.includes('future')) return RaceProduct.FutureFinalField

  return RaceProduct.FixedOddsOnly
}

// =============
// Local Helpers
// =============

const buildFixedOddsOnlyBet = async (betDetails: FOOBetDetails) => {
  const { competitionSeq, propositionSeq, competitionName, sportName, betType } = betDetails

  const event = await getFixedOddsEventDetails(getFOOEventType(sportName), 'races', competitionSeq)

  const proposition = event.propositions.find(
    proposition => proposition.sequenceNumber === propositionSeq
  )
  if (!proposition) throw new Error('Failed to match provided proposition')

  const isStartingPrice = betType === FixedOddsMarketDisplayNames.SP
  const marketName = isStartingPrice ? 'Starting Price' : event.marketName
  const shouldAllowPlaceInvestment = !isStartingPrice && event.isPlaceAvailable

  const selection: StartingPriceFOOSelection | FobPropositionSelection = isStartingPrice
    ? {
        type: 'fob-proposition',
        propositionSeq: propositionSeq.toString(),
        winPrice: null,
        placePrice: null,
        marketCode: 'SP',
        marketName: 'StartingPrice',
      }
    : {
        type: 'fob-proposition',
        propositionSeq: propositionSeq.toString(),
        winPrice: proposition.winReturn,
        winPriceLastSeen: proposition.winReturn,
        placePrice: proposition.placeReturn,
        placePriceLastSeen: proposition.placeReturn,
        priceSource: 'selection',
      }

  const details: Partial<FobDetails> = {
    competitionName: competitionName,
    event: competitionSeq,
    marketName,
    eventName: event.eventName,
    eventStartTime: event.eventStartTime,
    propositionName: proposition.name,
    sportIconCode: 'tab',
    sportName: '',
    tournamentName: '',
    handicapName: null,
  }

  const isEachWayAvailable = Boolean(
    !event.isPlaceAvailable && selection.winPrice && selection.placePrice
  )
  const quickbetSelection: QuickbetSelection = {
    bettingType: 'fixed-odds-racing',
    isEachWayAvailable,
    shouldAllowPlaceInvestment,
    selection,
    selectionDetails: details as FobDetails,
  }

  return quickbetSelection
}

const buildStandardBet = async (betDetails: BetDetails) => {
  const { fixtureId, fixtureDate, races, planSeq, selectionString } = betDetails

  const selections = parseSelectionsString(selectionString)

  // Multi Race bets (Double, Quaddie & All Up) should have a selection string
  // with the same # of divisions (eg. Quaddie => 1.2/3/2/1.2.3)
  if (races.length > 1 && selections.length !== races.length)
    throw new Error('Provided selection details invalid')

  const shouldForceWinOnly =
    !!(betDetails.srmSelections && betDetails.srmSelections.length >= 2) ||
    betDetails.betType === FixedOddsMarketDisplayNames.SP

  const { selectionDetails, shouldAllowPlaceInvestment } = await getSelectionDetails(
    fixtureId,
    fixtureDate,
    races,
    selections,
    planSeq,
    shouldForceWinOnly
  )

  const selection =
    betDetails.planSeq === PlanSeq.FOBRacing
      ? await getFOBSelection(betDetails)
      : await getToteSelection(betDetails)

  const quickbetSelection: QuickbetSelection = {
    bettingType: PlanSeq.FOBRacing === planSeq ? 'fixed-odds-racing' : 'tote-racing',
    selection: selection,
    selectionDetails,
    isEachWayAvailable: false,
    shouldAllowPlaceInvestment,
  }

  return quickbetSelection
}
