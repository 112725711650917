import React from 'react'
import { SelectField, SelectFieldProps, OptionStyled } from '@mobi/component-library/Common/Select'
import { useCountryList } from '@mobi/account/Areas/SignUp/Hooks'
import type { CountryList } from '@mobi/api-types'

export function PopularCountryList({ countries }: { countries: CountryList }) {
  const popularCountries = ['Australia', 'New Zealand', 'United Kingdom'] // Top three countries, selected based on country of origin of customers
  const popularOptions = countries.filter(c => popularCountries.find(p => p === c.description))

  return (
    <optgroup label='Popular selections'>
      {popularOptions.map(opt => (
        <OptionStyled key={opt.code} value={opt.code}>
          {opt.description}
        </OptionStyled>
      ))}
    </optgroup>
  )
}

export function CountrySelect({ ...props }: SelectFieldProps) {
  const { isLoading, isError, data: countries } = useCountryList()

  return (
    <SelectField {...props}>
      {isLoading && <OptionStyled value=''>Loading ...</OptionStyled>}

      {isError && <OptionStyled value=''>Could not load countries</OptionStyled>}

      {!isLoading && !isError && countries && (
        <>
          <OptionStyled value=''>Select a country</OptionStyled>
          <PopularCountryList countries={countries} />
          <AlphabeticalCountryList countries={countries} />
        </>
      )}
    </SelectField>
  )
}

const AlphabeticalCountryList = ({ countries }: { countries: CountryList }) => (
  <optgroup label='Alphabetical'>
    {countries.map(opt => (
      <OptionStyled key={opt.code} value={opt.code}>
        {opt.description}
      </OptionStyled>
    ))}
  </optgroup>
)
