import React from 'react'
import styled from '@emotion/styled'
import { Icon } from '@mobi/component-library/Common/V2'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { useRenderTimeoutControl } from '@mobi/utils/hooks/useRenderTimeoutControl'
import { trackKey } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { useAccountActivityData } from '@core/Areas/AccountActivity/Hooks'
import { useAppSelector } from '@core/Store/hooks'
import { useShrinkSkyOnLocationChange } from './Hooks/useShrinkSkyOnLocationChange'
import { PendingTicketsPage } from './Components/PendingTicketsPage'

const enum LocalConstants {
  AnimationTimingMs = 400,
  PendingBetsDrawerEmptyClassname = 'js-pending-bets-drawer__empty',
}

export const PendingBetsDrawer = React.forwardRef<HTMLDivElement, {}>((...args) => {
  const isPendingBetsOpen = useAppSelector(state => state.skyVideoPlayer.isPendingBetsDrawerOpen)
  const shouldRenderPendingBetsDrawer = useRenderTimeoutControl({
    shouldRender: isPendingBetsOpen,
    timeoutMs: LocalConstants.AnimationTimingMs as number,
  })

  return (
    <DrawerWrapStyled ref={args[1]}>
      {shouldRenderPendingBetsDrawer && <PendingBetsDrawerContent />}
    </DrawerWrapStyled>
  )
})

// ===============
// Local Component
// ===============

const PendingBetsDrawerContent = () => {
  const { data, isLoading, LoadMoreButton, SkeletonLoader, isFinalPage, isEmptyState } =
    useAccountActivityData({ activeTab: 'pending', isOrderByEventStart: true })

  useShrinkSkyOnLocationChange()

  React.useEffect(() => {
    trackKey('sky_pending_bets_open')
    return () => trackKey('sky_pending_bets_closed')
  }, [])

  return (
    <ContentStyled>
      <header>
        <h2>Pending Bets</h2>
      </header>

      <div>
        {isLoading ? (
          <SkeletonLoader />
        ) : (
          <>
            {data?.pages.map(pageData => (
              <PendingTicketsPage key={pageData.pageNumber} pageData={pageData} />
            ))}

            {isFinalPage ? (
              isEmptyState ? (
                <div className={`${LocalConstants.PendingBetsDrawerEmptyClassname}`}>
                  <div>
                    <Icon name='CustomBetslip' size='7.2rem' color={colors.neutral[600]} />
                  </div>
                  <span>No Pending Bets</span>
                </div>
              ) : (
                <p>End of Pending Bets</p>
              )
            ) : (
              <LoadMoreButton />
            )}
          </>
        )}
      </div>
    </ContentStyled>
  )
}

// ======
// Styles
// ======

const DrawerWrapStyled = styled.div({
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
  boxSizing: 'border-box',
  width: '25rem',
  minWidth: '25rem',
  padding: '0 ' + spacing.sm,

  overflowY: 'scroll',
  scrollbarWidth: 'thin',
  scrollBehavior: 'smooth',
  backgroundColor: colors.surface[50],
})

const ContentStyled = styled.div({
  '> header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    h2: {
      margin: 0,
      padding: `${spacing.sm} 0 ${spacing.smx1} 0`,
      fontFamily: font.family.primary,
      fontWeight: font.weight.semibold,
      color: colors.black,
      textTransform: 'unset',
      ...font.size.md,
      textShadow: 'none',
    },
  },

  '> div': {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.smx1,
    position: 'relative',
    minHeight: '80vh',

    ['> div.' + LocalConstants.PendingBetsDrawerEmptyClassname]: {
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '80vh',

      '> div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.neutral[100],
        borderRadius: '50%',
        width: '11.6rem',
        height: '11.6rem',
      },

      '> span': {
        color: colors.black,
        marginTop: spacing.sm,
        fontSize: font.size.lg.fontSize,
        letterSpacing: font.size.lg.letterSpacing,
        lineHeight: font.size.lg.lineHeight,
        fontWeight: font.weight.semibold,
      },
    },
  },
})
