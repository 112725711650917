import React from 'react'
import { Grid, GridCell } from '@mobi/component-library/Common/Grid'
import { DogsFormRaceResponse } from '@core/Areas/Racing/Hooks/useRaceFormData/api'
import { HistoryExpandable } from '../Common/HistoryExpandable'

export const DogsHistorySingle: React.FC<{
  history: DogsFormRaceResponse['Forms'][0]['Histories'][0]
  StarterName?: string
}> = ({
  history: {
    Place,
    RaceNumber,
    BarrierBox,
    TrackName,
    FixtureDate,
    Distance,
    StewardsComments,
    RaceClass,
    StartingPrice,
    Margin,
    RaceTime,
    WinnerTime,
    BestOfNight,
    BodyWeight,
    Placings,
    RaceReplay,
  },
  StarterName,
}) => {
  return (
    <HistoryExpandable
      Place={Place}
      RaceNumber={RaceNumber}
      TrackName={TrackName}
      FixtureDate={FixtureDate}
      Distance={Distance}
      RaceReplay={RaceReplay}
      StarterName={StarterName}
      StarterNumber={Number(BarrierBox)}
      RaceCode='dogs'
    >
      <>
        {StewardsComments && (
          <div data-testid='form-history-stewards-comments'>
            <strong>Stewards Comments</strong>
            <br />
            {StewardsComments}
          </div>
        )}

        <Grid>
          <GridCell width='50%'>
            <div data-testid='form-history-class'>
              <strong>Class</strong> {RaceClass}
            </div>
            <div data-testid='form-history-box'>
              <strong>Box</strong> {BarrierBox}
            </div>
            <div data-testid='form-history-dist'>
              <strong>Dist</strong> {Distance}
            </div>
            <div data-testid='form-history-racetime'>
              <strong>RT</strong> {RaceTime}
            </div>
            <div data-testid='form-history-startprice'>
              <strong>Start Price</strong> {StartingPrice}
            </div>
          </GridCell>

          <GridCell width='50%'>
            <div data-testid='form-history-winnertime'>
              <strong>WT</strong> {WinnerTime}
            </div>
            <div data-testid='form-history-winnertime'>
              <strong>Best</strong> {BestOfNight}
            </div>
            <div data-testid='form-history-winnertime'>
              <strong>Weight</strong> {BodyWeight}
            </div>
            <div data-testid='form-history-margin'>
              <strong>Margin</strong> {Margin}
            </div>
          </GridCell>
        </Grid>

        {Placings.length && (
          <div>
            <ol>
              {Placings.map(({ Place, StarterName, Margin }) => (
                <li key={Place}>
                  <strong>{Place}</strong>
                  <span>{StarterName}</span>
                  {Margin && <div>{Margin}</div>}
                </li>
              ))}
            </ol>
          </div>
        )}
      </>
    </HistoryExpandable>
  )
}
