import styled from '@emotion/styled'
import { hexColors } from '@mobi/settings'

export const StatusSilkStyled = styled.div<{ isFallback: boolean }>(
  {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '2rem',
    height: '2rem',
    color: hexColors.dimGrey,
  },
  ({ isFallback }) =>
    isFallback && {
      backgroundColor: hexColors.gainsboro,
      borderRadius: '50%',
    }
)
