import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectLastUsedPaymentMethod,
  setLastUsedPaymentMethod,
  resetLastUsedPaymentMethod,
} from '@mobi/account/Areas/Deposit/Store'
import { useLogon } from '@core/Utils/hooks/useLogon'
import { DepositSource } from '@mobi/component-library/Deposit/types'

export const LastPaymentMethod = () => {
  const dispatch = useDispatch()
  const { isLoggedIn, accountNumber } = useLogon()
  const lastUsedPaymentMethod = useSelector(selectLastUsedPaymentMethod(accountNumber))

  const savePaymentMethod = (paymentMethod: DepositSource | '') => {
    if (!isLoggedIn || !accountNumber) {
      throw new Error('Log in required to update stored deposit method')
    }

    if (paymentMethod === '') {
      dispatch(resetLastUsedPaymentMethod(accountNumber))
    } else {
      dispatch(
        setLastUsedPaymentMethod({
          accountNumber,
          paymentMethod,
        })
      )
    }
  }

  return (
    <>
      <h3>Last used payment method</h3>
      <div style={{ padding: '1rem', fontSize: '1.4rem' }}>
        {isLoggedIn ? (
          <form>
            <select
              style={{ display: 'block', width: '100%', padding: '0.5rem' }}
              name='payment-method'
              value={lastUsedPaymentMethod ?? ''}
              onChange={e => savePaymentMethod(e.target.value as DepositSource)}
            >
              <option value=''>None</option>
              <option value='CreditCard'>Credit Card</option>
              <option value='PayPal'>PayPal</option>
              <option value='ApplePay'>ApplePay</option>
              <option value='GooglePay'>GooglePay</option>
            </select>
          </form>
        ) : (
          <>Last deposit payment method is recorded per-user in local storage. Log in to edit.</>
        )}
      </div>
    </>
  )
}
