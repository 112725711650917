import React from 'react'
import { PlanSeq } from '@mobi/api-types'
import { SingleSingle, SingleMultiple, Multi, FavouriteNumbers, SameRaceMulti } from './Components'
import { getTicketLayoutType } from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { Ticket } from '@core/Areas/AccountActivity/types'

export const SelectionInfo: React.FC<{ ticket: Ticket }> = React.memo(
  ({ ticket }) => {
    if (PlanSeq.FavouriteNumbers === ticket.planSeq) return <FavouriteNumbers ticket={ticket} />

    const ticketLayoutType = getTicketLayoutType(ticket)

    if (ticketLayoutType === 'same-race-multi') return <SameRaceMulti ticket={ticket} />

    if (ticketLayoutType === 'single-single') return <SingleSingle leg={ticket.legs[0]} />

    if (ticketLayoutType === 'single-multiple') {
      return <SingleMultiple leg={ticket.legs[0]} planSeq={ticket.planSeq} />
    }

    if (ticketLayoutType === 'multi' || ticketLayoutType === 'tipping')
      return <Multi ticket={ticket} ticketLayoutType={ticketLayoutType} />

    return null
  },
  (prev, next) => prev.ticket.ticketSequence === next.ticket.ticketSequence
)
