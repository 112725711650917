import React from 'react'
import { dayjs } from '@mobi/utils/date'
import styled from '@emotion/styled'
import { Currency } from '@mobi/utils/money'
import { FixedOddsMarketCodes } from '@mobi/api-types'
import { Icon as IconLegacy, type IconType } from '@mobi/component-library/Common/Icon'
import { BetCard, LinkInfo } from '@mobi/betslip/Components/Common/BetCard'
import { CountdownBadge } from '@mobi/component-library/Feedback/CountdownBadge'
import { StatusBadge } from '@mobi/component-library/Common/StatusBadge/StatusBadge'
import type {
  Race,
  Acceptor,
  RaceDetails,
  BetSlipItem,
  BettingType,
  Selection,
  FobPropositionSelection,
  ToteSelection,
  RacingBetType,
} from '@mobi/betslip/types'
import {
  isFobPropositionSelection,
  isNoveltyBetType,
  isToteSelection,
  isFobMatchedSelection,
  isSameRaceMultiSelection,
  isStartingPriceMatchedSelection,
} from '@mobi/betslip/helpers/typeGuards'
import { BetCardTitle } from '@mobi/betslip/Components/Common/BetCardTitle'
import { calculateBoosts } from '@mobi/betslip/helpers/calculator/misc'
import { SelectionInfoMultiple } from '@mobi/betslip/Components/Common/BetCard/Components/SelectionInfoMultiple'
import { RacingTitle } from '@mobi/betslip/Components/Common/BetCard/Components/EventDetails/Components/RacingTitle'
import {
  addAcceptorNameToSingleSelection,
  getAcceptorTextForSingleSelection,
  createNoveltyMultipleSelectionMap,
  createMultipleRaceMultipleSelectionMap,
} from './helpers'
import { SilkImage } from '@mobi/betslip/Components/Common/SilkImage/SilkImage'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { ShareBetContext } from '@mobi/betslip/Components/Common/ShareMyBet/context'
import { MultiLegTypeToggle } from '@mobi/betslip/Components/Multi/Components/MultiLegTypeToggle'
import { MultiLegCheckbox } from '@mobi/betslip/Components/Multi/Components/MultiLegCheckbox'
import { isFatalErrorType } from '@mobi/betslip/helpers/state'
import type { BetInfoDisplayType } from '../../types'

const FixedOddsMarketDisplayNames = {
  [FixedOddsMarketCodes.HeadToHead]: 'Head to Head',
  [FixedOddsMarketCodes.RunnerVsField]: 'Runner vs Field',
  [FixedOddsMarketCodes.StartingPrice]: 'SP - Fixed',
} as const

const mapCodeToIcon: Record<string, IconType> = {
  Races: 'races',
  Trots: 'trot',
  Dogs: 'dog',
}

export const RaceBetInfo: React.FC<{
  item: BetSlipItem
  isMultiItem?: boolean
  displayMode?: BetInfoDisplayType
}> = ({ item, isMultiItem, displayMode }) => {
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)

  const isShareDisplay = React.useContext(ShareBetContext)

  const { selection } = item
  const bettingType = item.bettingType === null ? 'tote-racing' : item.bettingType
  const { races = [], acceptors = [] } = (item.selectionDetails || {}) as RaceDetails

  const hasFatalError = isFatalErrorType(item.betErrorType)

  const { meetingName, raceTime, meetingCode } = races[0] as Race
  const raceStartTime = raceTime
  const racingIconCode = meetingCode

  const iconType = mapCodeToIcon[racingIconCode || 'Trots']

  const shouldDisplayCountdown =
    !hasFatalError &&
    raceStartTime &&
    (!isMultiItem ||
      (isMultiItem && dayjs(raceStartTime).isSameOrBefore(dayjs().add(5, 'minutes'))))
  const isTote = isToteSelection(selection)
  const isMultipleRaceSelection = isTote && isMultiRaceBetType(selection.betType)

  const shouldShowPriceInSelectionInfo =
    workflowStatus == 'proposed' || workflowStatus == 'bets-placed'

  const urlPath = item.selectionDetails.urlPath
  const linkInfo: LinkInfo | undefined =
    !isMultiItem && workflowStatus === 'ready' && urlPath ? { pathname: urlPath } : undefined

  return (
    <>
      {(displayMode === 'full' || displayMode === 'event') && (
        <>
          {isTote && <BetCardTitle title={selection.betType} />}

          <BetCard.EventDetails
            linkInfoPrimary={linkInfo}
            heading={
              <RacingTitle
                races={races}
                meetingName={meetingName}
                isMultipleRaceSelection={isMultipleRaceSelection}
              />
            }
            subHeading={getFixedOddsMarketName(selection)}
            statusComponent={
              <>
                {isShareDisplay ? (
                  <StatusBadge color='gray' badgeSize='S'>
                    {dayjs(raceStartTime).format('D MMM | H:mm')}
                  </StatusBadge>
                ) : (
                  <>
                    {shouldDisplayCountdown && raceStartTime && (
                      <CountdownBadge advertisedStartTime={new Date(raceStartTime)} />
                    )}
                  </>
                )}

                {isMultiItem && !hasFatalError && item.shouldAllowPlaceInvestment && (
                  <MultiLegTypeToggle id={item.id} multiLegBetType={item.multiLegBetType} />
                )}
              </>
            }
            iconComponent={<IconLegacy size='2rem' type={iconType} />}
          />
        </>
      )}

      {(() => {
        if (!selection || displayMode === 'event') return null

        const fixedOrToteDisplay = isMultiItem ? '' : getFixedOrToteDisplay(selection, bettingType)

        const betTypeOverride = isMultiItem
          ? item.multiLegBetType
          : getBetTypeOverrideFromInvestment(item.investment)
        const selectionType = `${getMarketTitle(selection, betTypeOverride)} ${fixedOrToteDisplay}`

        if (isTote) {
          if (isSingleRaceNoveltyBetType(selection.betType)) {
            const noveltyPlacedMap = createNoveltyMultipleSelectionMap(selection, acceptors)
            return <SelectionInfoMultiple selections={noveltyPlacedMap} />
          }

          if (isMultipleRaceSelection) {
            const multipleRaceSelectionMap = createMultipleRaceMultipleSelectionMap(
              selection,
              races,
              acceptors
            )
            return <SelectionInfoMultiple selections={multipleRaceSelectionMap} />
          }

          return (
            <BetCard.SelectionInfo
              iconComponent={
                <SilkImage
                  acceptor={extractSelectedAcceptor(selection.selectionString, acceptors)}
                />
              }
              heading={addAcceptorNameToSingleSelection(selection.selectionString, acceptors)}
              subHeading={selectionType}
              linkInfo={linkInfo}
            />
          )
        }

        if (isSameRaceMultiSelection(selection)) {
          const sorted = selection.acceptors
            .map(a => ({
              ...a,
              legName: a.legNumber === 0 ? 'WIN' : `TOP ${a.legNumber + 1}`,
            }))
            .sort(sameRaceMultiSortCallback)
          const result = sorted.map((selectionAcceptor, index) => {
            const number = selectionAcceptor.acceptorNumber.toString()
            return (
              <SingleSameRaceMultiSelectionStyled key={index}>
                <div>
                  {<SilkImage acceptor={extractSelectedAcceptor(number, acceptors)} />}{' '}
                  <span>{addAcceptorNameToSingleSelection(number, acceptors)}</span>
                </div>
                <div>{selectionAcceptor.legName}</div>
              </SingleSameRaceMultiSelectionStyled>
            )
          })
          return <>{result}</>
        }

        if (isFobMatchedSelection(selection)) {
          const [winBoost, placeBoost] = calculateBoosts(item.selectedSuperPickOffer ?? null)
          const winPrice = isMultiItem
            ? item.multiLegBetType === 'W'
              ? selection.winPrice
              : selection.placePrice
            : item.investment.win.value
              ? new Currency(selection.winPrice).add(winBoost).value
              : undefined

          return (
            <BetCard.SelectionInfo
              key={`${item.id}${isMultiItem ? item.multiLegBetType : ''}`}
              iconComponent={
                <SilkImage
                  acceptor={extractSelectedAcceptor(selection.acceptorNumber.toString(), acceptors)}
                />
              }
              heading={addAcceptorNameToSingleSelection(
                selection.acceptorNumber.toString(),
                acceptors
              )}
              subHeading={selectionType}
              priceWin={
                ((shouldShowPriceInSelectionInfo || (isMultiItem && !hasFatalError)) && winPrice) ||
                null
              }
              pricePlace={
                (shouldShowPriceInSelectionInfo &&
                  !!item.investment.place.value &&
                  new Currency(selection.placePrice || 0).add(placeBoost).value) ||
                null
              }
              rightAlignedComponent={isMultiItem ? <MultiLegCheckbox item={item} /> : null}
              linkInfo={linkInfo}
            />
          )
        }

        if (isStartingPriceMatchedSelection(selection)) {
          const acceptor = selection.selectedAcceptor
          if (acceptor) {
            return (
              <BetCard.SelectionInfo
                iconComponent={<SilkImage acceptor={acceptor} />}
                heading={getAcceptorTextForSingleSelection(acceptor) || ''}
                subHeading={selectionType}
                linkInfo={linkInfo}
              />
            )
          }
        }

        if (isFobPropositionSelection(selection)) {
          const acceptor = selection.selectedAcceptors && selection.selectedAcceptors[0]
          if (acceptor) {
            const winPrice = isMultiItem
              ? item.multiLegBetType === 'W'
                ? selection.winPrice
                : selection.placePrice
              : item.investment.win.value
                ? selection.winPrice
                : undefined

            return (
              <BetCard.SelectionInfo
                iconComponent={<SilkImage acceptor={acceptor} />}
                heading={getAcceptorTextForSingleSelection(acceptor) || ''}
                subHeading={selectionType}
                priceWin={((shouldShowPriceInSelectionInfo || isMultiItem) && winPrice) || null}
                pricePlace={
                  (shouldShowPriceInSelectionInfo &&
                    !!item.investment.place.value &&
                    selection.placePrice) ||
                  null
                }
                rightAlignedComponent={isMultiItem && <MultiLegCheckbox item={item} />}
                linkInfo={linkInfo}
              />
            )
          } else {
            return (
              <BetCard.SelectionInfo
                heading={selection.displayName || ''}
                subHeading=''
                linkInfo={linkInfo}
              />
            )
          }
        }

        return null
      })()}
    </>
  )
}

// =============
// Local Helpers
// =============

function getFixedOddsMarketName(selection: Selection | null, isMultiDisplay: boolean = false) {
  const isFobProposition = isFobPropositionSelection(selection)
  // Only display market name to add clarity where starter can appear in multiple markets
  const shouldDisplay =
    isFobProposition &&
    ((isMultiDisplay && selection.marketCode === FixedOddsMarketCodes.Concession) ||
      selection.marketCode === FixedOddsMarketCodes.RunnerVsField ||
      selection.marketCode === FixedOddsMarketCodes.HeadToHead)

  const marketName =
    shouldDisplay &&
    (FixedOddsMarketDisplayNames[
      selection.marketCode as keyof typeof FixedOddsMarketDisplayNames
    ] ||
      selection.marketName)

  return shouldDisplay ? `${isMultiDisplay ? ' - ' : ''}${marketName}` : ''
}

function getMarketTitle(selection: Selection, betTypeOverride?: BetSlipItem['multiLegBetType']) {
  if ((selection as ToteSelection).betType) return (selection as ToteSelection).betType
  if ((selection as FobPropositionSelection).marketCode) {
    return (selection as FobPropositionSelection).marketName
  }
  if (betTypeOverride === 'W') {
    return 'Win'
  }
  if (betTypeOverride === 'P') {
    return 'Place'
  }
  return 'Win & Place'
}

function getBetTypeOverrideFromInvestment(
  investment: BetSlipItem['investment']
): BetSlipItem['multiLegBetType'] {
  if (investment.win.value && !investment.place.value) return 'W'
  if (!investment.win.value && investment.place.value) return 'P'
  return undefined
}

function getFixedOrToteDisplay(selection: Selection, bettingType: BettingType) {
  if (isNoveltyBetType((selection as ToteSelection).betType)) {
    return ''
  }
  if (bettingType === 'fixed-odds-racing' && !(selection as FobPropositionSelection).marketCode) {
    return ' - Fixed'
  }
  if (bettingType === 'tote-racing') {
    return ' - Tote'
  }
  return ''
}

function sameRaceMultiSortCallback(
  a: { legNumber: number; acceptorNumber: number },
  b: { legNumber: number; acceptorNumber: number }
) {
  const diff = a.legNumber - b.legNumber
  if (diff != 0) return diff
  return a.acceptorNumber - b.acceptorNumber
}

function extractSelectedAcceptor(
  selectionNumber: string,
  acceptors: Acceptor[]
): Acceptor | undefined {
  return acceptors.find(a => `${a.number}` === selectionNumber)
}

function isSingleRaceNoveltyBetType(value: RacingBetType): boolean {
  const noveltyTypes: RacingBetType[] = ['Exacta', 'Quinella', 'Trifecta', 'First 4']
  return noveltyTypes.includes(value)
}

function isMultiRaceBetType(value: RacingBetType): boolean {
  return value === 'Double' || value === 'Quaddie' || value === 'All Up'
}

// ======
// Styles
// ======

const SingleSameRaceMultiSelectionStyled = styled.div({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  fontSize: '1.6rem',
  fontWeight: 'bold',
  lineHeight: 'normal',
  padding: '1rem 0 0',
  wordBreak: 'break-all',
  wordWrap: 'normal',
})
