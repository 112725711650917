import type { MutableRefObject } from 'react'
import { Client } from 'braintree-web'
import type { PayPalDepositRequest } from '@mobi/api-types'
import { useDeposit } from '../../Hooks/useDeposit'
import { init } from './initPayPal'

export type UsePayPalDepositParams = {
  braintreeClient: MutableRefObject<Client | undefined>
}

export function usePayPalDeposit({ braintreeClient }: UsePayPalDepositParams) {
  const { depositMutation } = useDeposit<PayPalDepositRequest>({ braintreeClient })
  return {
    init,
    deposit: depositMutation.mutateAsync,
  }
}
