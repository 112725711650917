import React from 'react'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import type { BetSlipItem, FobSportsSelection, SportsDetails } from '@mobi/betslip/types'
import { Icon } from '@mobi/component-library/Common/Icon'
import { CountdownBadge } from '@mobi/component-library/Feedback/CountdownBadge'
import { StatusBadge } from '@mobi/component-library/Common/StatusBadge/StatusBadge'
import { BetCard, type LinkInfo } from '@mobi/betslip/Components/Common/BetCard'
import { ShareBetContext } from '@mobi/betslip/Components/Common/ShareMyBet/context'
import { selectWorkflowStatus } from '@mobi/betslip/Store/Workflow/selectors'
import { MultiLegCheckbox } from '@mobi/betslip/Components/Multi/Components/MultiLegCheckbox'
import type { BetInfoDisplayType } from '../../types'

export const SportBetInfo: React.FC<{
  item: BetSlipItem
  isMultiItem?: boolean
  displayMode?: BetInfoDisplayType
}> = ({ item, isMultiItem, displayMode }) => {
  const isShareDisplay = React.useContext(ShareBetContext)

  const workflowStatus = useSelector(selectWorkflowStatus)

  const { tournamentName, eventName, marketName, advertisedStartTime, marketId, urlPath } =
    item.selectionDetails as SportsDetails

  const { propositionName, winPrice } = item.selection as FobSportsSelection

  const linkInfo: LinkInfo | undefined =
    !isMultiItem && workflowStatus === 'ready' && urlPath
      ? {
          pathname: urlPath,
          state: marketId ? { market: marketId.toString() } : undefined,
        }
      : undefined

  return (
    <>
      {(displayMode === 'full' || displayMode === 'event') && (
        <BetCard.EventDetails
          heading={tournamentName}
          subHeading={eventName && eventName !== marketName ? eventName : ''}
          statusComponent={
            isShareDisplay ? (
              <StatusBadge color='gray' badgeSize='S'>
                {dayjs(advertisedStartTime).format('D MMM | H:mm')}
              </StatusBadge>
            ) : (
              <CountdownBadge advertisedStartTime={dayjs(advertisedStartTime).toDate()} />
            )
          }
          iconComponent={<Icon size='2rem' type='tab' />}
          linkInfoSecondary={linkInfo}
        />
      )}

      {(displayMode === 'full' || displayMode === 'selection') && (
        <BetCard.SelectionInfo
          heading={propositionName}
          subHeading={marketName}
          priceWin={winPrice}
          // TODO: Use real sports/team logo
          iconComponent={
            <div
              style={{ width: '2.8rem', height: '2.8rem', borderRadius: '50%', background: 'red' }}
            />
          }
          linkInfo={linkInfo}
          rightAlignedComponent={isMultiItem && <MultiLegCheckbox item={item} />}
        />
      )}
    </>
  )
}
