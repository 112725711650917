import styled from '@emotion/styled'
import { spacing } from '@mobi/component-library/Theme/Common'

export const InvestmentsWrapperStyled = styled.div<{ isStacked?: boolean }>(
  {
    display: 'flex',
    gap: spacing.smx1,
  },
  ({ isStacked }) => ({
    flexDirection: isStacked ? 'row' : 'column',
    flexWrap: isStacked ? 'wrap' : 'initial',
  })
)
