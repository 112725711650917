import React from 'react'
import { Icon } from '@mobi/component-library/Common/Icon'
import { colors } from '@mobi/component-library/Theme/Common'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'
import type { Ticket, TicketStatus } from '@core/Areas/AccountActivity/types'
import { CountdownStatus } from '../Common/CountdownStatus'

export const Tipping: React.FC<{ ticket: Ticket; status: TicketStatus }> = ({ ticket, status }) => {
  const numberOfGames = ticket.legs.length
  return (
    <>
      <BetCard.EventDetails
        heading={`${numberOfGames} Game${numberOfGames > 1 ? 's' : ''}`}
        statusComponent={<CountdownStatus ticket={ticket} status={status} />}
        iconComponent={<Icon size='1.6rem' type='TIPPING' />}
      />

      <BetCard.SelectionInfo
        heading={ticket.shortProductName}
        subHeading={ticket.detailHeader}
        iconComponent={<Icon size='2.4rem' type='TIPPING' color={colors.neutral[800]} />}
      />
    </>
  )
}
