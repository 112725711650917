import { PlanSeq } from '@mobi/api-types'
import {
  getLegStatus,
  getTicketLayoutType,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { Tabs, Ticket } from '@core/Areas/AccountActivity/types'
import { FixedOddsMarketDisplayNames } from '@core/Areas/RaceCard/constants'

export const canAllowReBetForTicket = (activeTab: Tabs, ticket: Ticket) => {
  if (activeTab === 'rebet') return true

  if (
    ticket.planSeq !== PlanSeq.FOBRacing ||
    !ticket.legs.some(leg => leg.status === 'A') ||
    ticket.legs.some(x => x.betType === FixedOddsMarketDisplayNames.SP)
  ) {
    return false
  }

  if (activeTab === 'pending') return true

  if (
    activeTab === 'results' &&
    getTicketLayoutType(ticket) === 'multi' &&
    ticket.legs.some(leg => getLegStatus(leg.result) === 'Pending')
  ) {
    return true
  }

  return false
}
