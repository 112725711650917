import React from 'react'
import { colors } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/V2/Icon'
import { Icon as IconLegacy } from '@mobi/component-library/Common/Icon'
import { RacingTitle } from '@mobi/betslip/Components/Common/BetCard/Components/EventDetails/Components/RacingTitle'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'
import { getRaceLink } from '@core/Areas/Racing/helpers/getRaceLink'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'
import type { Ticket, TicketStatus } from '@core/Areas/AccountActivity/types'
import {
  sameRaceMultiPositionToText,
  sortSameRaceMultiTicketLegs,
} from '@core/Areas/AccountActivity/helpers'
import { CountdownStatus } from '../Common/CountdownStatus'

export const SameRaceMulti: React.FC<{ ticket: Ticket; status: TicketStatus }> = ({
  ticket,
  status,
}) => {
  const ticketContext = React.useContext(TicketDisplayContext)

  const sortedLegs = sortSameRaceMultiTicketLegs(ticket)

  const leg = sortedLegs[0]

  const isContextPendingBetsDrawer = ticketContext.type === 'pending-bets-drawer'
  const shouldHideEventDetails = isContextPendingBetsDrawer && ticketContext.data.isSkyRaceMatch

  const shouldProvideLink = !!(
    (ticketContext.type === 'account-activity' || isContextPendingBetsDrawer) &&
    leg.fixtureId &&
    leg.fixtureDate &&
    leg.raceNumber
  )
  const linkInfo = shouldProvideLink
    ? {
        pathname: getRaceLink({
          meetingDate: leg.fixtureDate,
          meetingId: leg.fixtureId,
          raceNumber: leg.raceNumber,
          isFOO: false,
        }),
      }
    : undefined

  return (
    <>
      {!shouldHideEventDetails && (
        <BetCard.EventDetails
          heading={
            leg.raceNumber && leg.meetingName ? (
              <RacingTitle
                races={[{ raceNumber: leg.raceNumber }]}
                meetingName={leg.meetingName}
                isMultipleRaceSelection={false}
              />
            ) : (
              ticket.legs[0].eventName
            )
          }
          statusComponent={<CountdownStatus ticket={ticket} status={status} />}
          iconComponent={<IconLegacy size='1.6rem' type='races' />}
          linkInfoPrimary={linkInfo}
        />
      )}

      <BetCard.SelectionInfo
        heading={ticket.productName}
        subHeading={sortedLegs.map(srmLeg => {
          const { selectionDetails } = srmLeg.selections[0]
          const { name, srmPosition, number } = selectionDetails[0].acceptorDetails[0]
          const srmPositionText = sameRaceMultiPositionToText(srmPosition)
          return (
            <React.Fragment key={srmPositionText + number}>
              {srmPositionText}: {number} - {name}
              <br />
            </React.Fragment>
          )
        })}
        priceWin={ticket.combinedDividend?.toFixed(2)}
        iconComponent={<Icon size='2.4rem' name='CustomBetslip' color={colors.neutral[800]} />}
        linkInfo={linkInfo}
      />
    </>
  )
}
