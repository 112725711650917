import React, { type FC, type ReactNode, useRef, useEffect } from 'react'
import { BusyPanel, SuccessPanel, FailPanel } from '@mobi/component-library/Deposit/Workflow'
import { Tray } from '@mobi/component-library/Overlays/Tray'
import { Modal } from '@mobi/component-library/Overlays/Modal'
import { type DepositStatus } from '../Store/deposit'
import { isReactNativeApp } from '@mobi/web-native-comms/web'
import styled from '@emotion/styled'
import { spacing } from '@mobi/component-library/Theme/Common'
import { useHostContext } from '../HostContext'

type Props = {
  isOpen: boolean
  onClose: () => void
  depositAmount: number
  depositStatus: DepositStatus
  failureMessage?: string
}

export const DepositProgress: FC<Props> = ({
  depositStatus,
  failureMessage,
  depositAmount,
  onClose,
  isOpen,
}) => {
  const previousDepositStatus = useRef<DepositStatus | undefined>()
  const shouldRenderPreviousStatus = depositStatus === 'idle' || !isOpen
  const renderableDepositStatus =
    shouldRenderPreviousStatus && previousDepositStatus.current !== undefined
      ? previousDepositStatus.current
      : depositStatus

  useEffect(() => {
    if (isOpen && depositStatus !== 'idle') {
      previousDepositStatus.current = depositStatus
    }
  }, [depositStatus, isOpen])

  if (renderableDepositStatus === 'idle') {
    return null
  }

  const renderPanel = (depositStatus: Exclude<DepositStatus, 'idle'>) => {
    switch (depositStatus) {
      case 'verifying':
        return <BusyPanel message='Verifying your payment method.' />
      case 'depositing':
        return <BusyPanel message='Depositing funds. Please wait a few seconds.' />
      case 'success':
        return <SuccessPanel amount={depositAmount} onDone={onClose} />
      case 'unsuccessful':
        return <FailPanel message={failureMessage} onClose={onClose} />
    }
  }

  return <Overlay isOpen={isOpen}>{renderPanel(renderableDepositStatus)}</Overlay>
}

type OverlayProps = {
  isOpen: boolean
  children?: ReactNode
}
const Overlay = ({ children, isOpen }: OverlayProps) => {
  const { channel } = useHostContext()
  const commonProps = {
    isOpen,
    dismissStatus: 'disallowed' as const,
  }

  return channel === 'desktop' ? (
    <Modal
      {...commonProps}
      modalStyle={{
        maxWidth: '40rem',
      }}
    >
      <ModalContentStyled>{children}</ModalContentStyled>
    </Modal>
  ) : (
    <Tray
      {...commonProps}
      headerTitle='Depositing'
      shouldShowHeader={false}
      requiresExtraBottomPadding={!isReactNativeApp}
      trayStyle={{
        maxWidth: '77.6rem',
      }}
    >
      <TrayContentStyled>{children}</TrayContentStyled>
    </Tray>
  )
}

// ======
// Styles
// ======

export const TrayContentStyled = styled.div({
  paddingBottom: '4rem', // The tray only has 4rem of spacing while the bottom nav is 5, this is to compensate
})

export const ModalContentStyled = styled.div({
  padding: `${spacing.lgx2} ${spacing.bigx1}`,
})
