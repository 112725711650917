import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { getHostHelpers } from '@mobi/betslip/helpers/hostHelpers'
import type { LinkInfo } from '../../../types'

export const LinkWrapper: React.FC<{
  children: React.ReactNode
  linkInfo?: LinkInfo
}> = ({ linkInfo, children }) => {
  const hostHelpers = getHostHelpers()

  if (!linkInfo) return <span>{children}</span>

  return (
    <LinkStyled
      to={linkInfo.state ? linkInfo : linkInfo.pathname}
      onClick={() => hostHelpers.toggleBetSlipDrawer?.(false)}
    >
      {children}
    </LinkStyled>
  )
}

// ======
// Styles
// ======

const LinkStyled = styled(Link)({
  padding: 0,
  margin: 0,
  color: 'unset',
  textDecoration: 'unset',
  transition: 'opacity 300ms ease',

  ':hover': { opacity: 0.6 },
})
