import { get, post } from '@classic/Foundation/Services/ApiService'
import type {
  WithdrawableResponse,
  WithdrawResponse,
} from '@mobi/account/Areas/Withdraw/Utils/types'

export const getWithdrawable = async (): Promise<WithdrawableResponse> => {
  return await get<WithdrawableResponse>({ url: '/api/account/withdrawable' })
}

interface WithdrawRequest {
  amount: number
}

export const postWithdraw = async (amount: number): Promise<WithdrawResponse> => {
  const request: WithdrawRequest = {
    amount: amount,
  }

  return await post<WithdrawResponse>({
    url: '/api/account/withdraw',
    body: request,
  })
}
