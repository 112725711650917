import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DepositError } from '../../DepositError'
import { SpinnerStandalone } from '@mobi/component-library/Common/Spinner'
import { unwrapErrorMessage } from '@mobi/utils'
import type { PanelProps } from '../../types'
import type { PayPalDepositRequest } from '@mobi/api-types'
import { PaymentSDKButtonContainerStyled } from '../Payment.styles'
import { LoadingContainerStyled } from '../../DepositBody.styles'
import { type DepositRequestWithoutDeviceData } from '../../../Hooks'
import { usePayPalDeposit, useBraintreeClient } from '../../../Hooks'
import { PayPalButtonCaption } from './PayPalButton.styles'

export const payPalButtonCaption = 'You will be redirected to PayPal to complete your deposit.'

export const PayPalButtonWeb = ({
  initialData: { transactionId, clientToken },
  depositAmount,
  isDepositAllowed,
  onStart,
  onFailure,
  onSuccess,
  onDepositing,
  onCancel,
}: PanelProps) => {
  const isInitializing = useRef(false)
  const didInitialize = useRef(false)
  const buttonContainer = useRef<HTMLDivElement | null>(null)
  const transactionDetailsRef = useRef({
    depositAmount,
    transactionId,
  })
  const { isReady, client } = useBraintreeClient(clientToken)
  const [isButtonReady, setButtonReady] = useState(false)
  const { init, deposit } = usePayPalDeposit({ braintreeClient: client })

  const onPayPalConfirmed = useCallback(
    async (data: DepositRequestWithoutDeviceData<PayPalDepositRequest>) => {
      onDepositing()

      try {
        const { isSuccess, ...errorDetails } = await deposit(data)

        if (isSuccess) {
          onSuccess(transactionDetailsRef.current.depositAmount)
        } else {
          onFailure(DepositError.fromErrorDetails(errorDetails))
        }
      } catch (error) {
        onFailure(DepositError.coerce(error, transactionId))
      }
    },
    [onDepositing, deposit, onSuccess, onFailure, transactionId]
  )

  // Update the transaction details that will be provided to the PayPal SDK
  useEffect(() => {
    transactionDetailsRef.current = {
      depositAmount,
      transactionId,
    }
  }, [depositAmount, transactionId])

  useEffect(() => {
    if (isInitializing.current || didInitialize.current) {
      return
    }

    isInitializing.current = true

    isReady().then(isReady => {
      if (!isReady || didInitialize.current) {
        return
      }

      didInitialize.current = true

      init({
        braintreeClient: client,
        buttonContainer,
        getTransactionDetails: () => transactionDetailsRef.current,
        onInit: () => setButtonReady(true),
        onClick: () => onStart(),
        onSuccess: onPayPalConfirmed,
        onError: error => {
          // FIXME: Find a better, more accurate way to determine whether or not to display the error
          // Errors with an undefined code are likely errors caused by rendering failures within
          // PayPal's SDK. This is currently the best way to determine these kinds of errors

          const errorCode =
            typeof error !== 'string' && 'code' in error && typeof error.code === 'string'
              ? error.code
              : undefined

          const shouldReportError = typeof error === 'string' || errorCode !== undefined

          if (shouldReportError) {
            onFailure(
              new DepositError(
                'paypal_initialization_failed',
                transactionId,
                errorCode,
                unwrapErrorMessage(error)
              )
            )
          } else {
            onCancel?.()
          }
        },
        onCancel: () => onCancel?.(),
      })
        .catch(() => {
          return onFailure(
            new DepositError(
              'paypal_initialization_failed',
              transactionId,
              undefined,
              'Failed to initialize PayPal'
            )
          )
        })
        .finally(() => {
          isInitializing.current = false
        })
    })
  }, [
    client,
    depositAmount,
    deposit,
    transactionId,
    init,
    isReady,
    onCancel,
    onFailure,
    onPayPalConfirmed,
    onStart,
  ])

  return (
    <>
      <div style={{ display: isButtonReady ? 'block' : 'none' }}>
        <PaymentSDKButtonContainerStyled
          id='paypal-container'
          ref={buttonContainer}
          aria-disabled={!isDepositAllowed}
          isDisabled={!isDepositAllowed}
        />
        <PayPalButtonCaption>{payPalButtonCaption}</PayPalButtonCaption>
      </div>
      {isButtonReady ? null : (
        <LoadingContainerStyled>
          <SpinnerStandalone />
        </LoadingContainerStyled>
      )}
    </>
  )
}
