import React from 'react'

import { ListBadgeStyled, ListBadgeInnerStyled } from './ListBadge.styles'

export const ListBadge: React.FC<{
  color: string
  children?: React.ReactNode
  prefix?: string
}> = props => (
  <ListBadgeStyled color={props.color}>
    <ListBadgeInnerStyled prefix={props.prefix || ''}>{props.children}</ListBadgeInnerStyled>
  </ListBadgeStyled>
)
