import { isToteSelection } from '@mobi/betslip/helpers/typeGuards'
import { trackLoadedBetBetslip } from '@classic/Foundation/Analytics/GoogleTagManagerService'
import { Betslip } from '@core/Areas/Betslip/Betslip'
import { state$, BetslipItem } from '@core/Areas/Betslip/driver'
import { AddSinglesToBetslip, RemoveSingleBet, ScrollTo } from '@core/Areas/Betslip/signals'
import { OverlayOpen } from '@core/Components/Overlay'
import { promptUserAndReturnDecision } from '@core/Utils/betting/loadBet/Components/PromptBetslipChange/helpers'
import { buildBaseBetSelection, isSportBetDetails } from '../buildBetItem/buildBaseBetSelection'
import { BetDetails, FOOBetDetails, LoadBetDetails, LoadBetErrors } from '../types'
import { createItemForLegacyBetslip } from '@core/Areas/Betslip/helpers/addToBetslip'
import { getErrorMessage } from './loadBet'
import { zip } from '@mobi/utils/functions/zip'
import { QuickbetSelection } from '@core/Areas/Quickbet/signals'

const enum LocalConstants {
  PlaceBetType = 'Place - Fixed',
}

/** Betslip: handle non-empty betslip, build items and open in Betslip (Multi ONLY) */
export async function addToLegacyBetslip(
  incomingBets: LoadBetDetails[],
  source?: LoadBetSource,
  // pass in existing bet selection to avoid loading from api again
  prebuiltBets?: QuickbetSelection[] | null
): Promise<AddToBetslipReturn> {
  let whatToDoWithExisting = 'emptyBetslip'
  const zipped = zip(incomingBets, prebuiltBets || [])
  // old bet slip cant handle sports
  const bets = zipped.filter(bet => !isSportBetDetails(bet[0])) as [
    BetDetails | FOOBetDetails,
    QuickbetSelection | null,
  ][]

  if (bets.length === 0 && incomingBets.length > 0) {
    throw new Error(LoadBetErrors.NoSportsInLegacy)
  }

  if (hasExistingBetslipItems()) {
    whatToDoWithExisting = await promptUserAndReturnDecision()

    switch (whatToDoWithExisting) {
      case 'cancel':
        return { total: bets.length, success: 0 }
      case 'add':
        break
      case 'replace': {
        removeFixedOddsBetslipItemsLegacy()
        break
      }
    }
  }

  const createBetslipItemResults = await Promise.allSettled<BetslipItem>(
    bets.map(async ([bet, qbSelection]) => {
      const item = qbSelection || (await buildBaseBetSelection(bet))
      const betslipItem = createItemForLegacyBetslip(item)
      betslipItem.multiLegBetType = bet.betType === LocalConstants.PlaceBetType ? 'P' : 'W'
      return betslipItem
    })
  )
  const betsReadyForBetslip = createBetslipItemResults
    .filter(isFulfilled)
    .map(result => result.value)

  if (betsReadyForBetslip.length === 0) {
    const isBettingClosedOnBets = createBetslipItemResults
      .filter(isRejected)
      .map(result => result.reason)
      .some(err => getErrorMessage(err) === LoadBetErrors.BettingClosed)
    throw Error(isBettingClosedOnBets ? LoadBetErrors.BettingClosed : LoadBetErrors.Default)
  }

  AddSinglesToBetslip(betsReadyForBetslip)
  OverlayOpen(Betslip)
  ScrollTo('multi')

  source &&
    trackLoadedBetBetslip({
      source,
      action: whatToDoWithExisting,
      betsToAdd: betsReadyForBetslip.length,
    })

  return { total: incomingBets.length, success: betsReadyForBetslip.length }
}

// =============
// Local Helpers
// =============

function removeFixedOddsBetslipItemsLegacy() {
  state$.take(1).subscribe(state => {
    state.items
      .filter(item => !isToteSelection(item.selection))
      .forEach(item => item && RemoveSingleBet(item))
  })
}

function hasExistingBetslipItems(): boolean {
  let hasBetslipItems = false
  state$.take(1).subscribe(state => {
    hasBetslipItems = state.items.filter(item => !isToteSelection(item.selection)).count() > 0
  })
  return hasBetslipItems
}

function isFulfilled<T>(input: PromiseSettledResult<T>): input is PromiseFulfilledResult<T> {
  return input.status === 'fulfilled'
}
function isRejected<T>(input: PromiseSettledResult<T>): input is PromiseRejectedResult {
  return input.status === 'rejected'
}

// =====
// Types
// =====

interface AddToBetslipReturn {
  total: number
  success: number
}

type LoadBetSource = 'share-bet' | 'rebet' | 'blackbook'
