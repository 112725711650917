import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const loginInitialState: LoginState = {
  isLoginShown: false,
  isKeepMeLoggedIn: false,
  isBiometricsEnabledOnTempPasswordChange: false,
}

const loginSlice = createSlice({
  name: 'login',
  initialState: loginInitialState,
  reducers: {
    setIsLoginShown(
      state,
      {
        payload: {
          shouldShow,
          onLoginSuccessCallback,
          onLoginSuccessRedirectUrl,
          onLoginCancelledRedirectUrl,
        },
      }: PayloadAction<SetIsLoginShown>
    ) {
      state.isLoginShown = shouldShow
      state.onLoginSuccessCallback =
        typeof onLoginSuccessCallback === 'function' ? onLoginSuccessCallback : undefined
      state.onLoginSuccessRedirectUrl = onLoginSuccessRedirectUrl
      state.onLoginCancelledRedirectUrl = onLoginCancelledRedirectUrl
    },

    setIsKeepMeLoggedIn(state, { payload }: PayloadAction<boolean>) {
      state.isKeepMeLoggedIn = payload
    },

    setIsBiometricsEnabledOnTempPasswordChange(state, { payload }: PayloadAction<boolean>) {
      state.isBiometricsEnabledOnTempPasswordChange = payload
    },
  },
})

export const { setIsLoginShown, setIsKeepMeLoggedIn, setIsBiometricsEnabledOnTempPasswordChange } =
  loginSlice.actions

export default persistReducer(
  {
    key: 'login',
    whitelist: ['isKeepMeLoggedIn'],
    storage,
    debug: !PRODUCTION,
  },
  loginSlice.reducer
)

// =====
// Types
// =====

export interface LoginState {
  isLoginShown: boolean
  /** Server sets extended expiry (shown in app only) */
  isKeepMeLoggedIn: boolean
  onLoginSuccessRedirectUrl?: string
  onLoginCancelledRedirectUrl?: string
  onLoginSuccessCallback?(): void
  isBiometricsEnabledOnTempPasswordChange: boolean
}

type SetIsLoginShownOpen = Pick<
  LoginState,
  'onLoginSuccessCallback' | 'onLoginSuccessRedirectUrl' | 'onLoginCancelledRedirectUrl'
>
type SetIsLoginShown =
  | ({
      shouldShow: false
    } & { [P in keyof SetIsLoginShownOpen]: never })
  | ({
      shouldShow: true
    } & SetIsLoginShownOpen)
