import { Observable, IDisposable } from 'rx'
import { FeatureFlags } from '@mobi/settings'
import { state$ as userAccountState$ } from './userAccountDriver'
import { state$ as featureState$ } from '../LaunchDarklyFeatures/driver'
import { fetchAccountBalanceAsync } from './async-signals'
import { iotSubscribeTopics, iotUnsubscribeTopics } from '../PushData'
import { getAccountTopic } from '@mobi/utils/awsiot/topics'
import { queryClient } from '@core/Data/ReactQuery/queryClient'
import { balanceUpdate, payIdUpdate } from '@mobi/account/Utils/iotEvents'

let pushDataSubscription: IDisposable | null = null

export const register = (): void => {
  let accountNumberLoggedIn: number | null = null

  Observable.combineLatest(
    userAccountState$,
    featureState$,
    ({ isLoggedIn, accountNumber }, featureStateRecord) => ({
      isLoggedIn,
      accountNumber,
      isAccountPushFeatureActive: featureStateRecord.features.get(
        FeatureFlags.PUSHDATA_USERINFO.key
      ) as boolean,
    })
  )
    .distinctUntilChanged()
    .subscribe(managePushDataSubscription)

  function managePushDataSubscription({
    isLoggedIn,
    accountNumber,
    isAccountPushFeatureActive,
  }: {
    isLoggedIn: boolean | null
    accountNumber: number | null
    isAccountPushFeatureActive: boolean
  }) {
    // Do initial fetch and set up subscription
    if (isAccountPushFeatureActive && isLoggedIn && !pushDataSubscription) {
      subscribe(accountNumber as number)
    }
    // Unsubscribe and dispose of subscription
    if (!!pushDataSubscription && (!isAccountPushFeatureActive || !isLoggedIn)) {
      unsubscribe()
    }
  }

  function subscribe(accountNumber: number) {
    accountNumberLoggedIn = accountNumber

    const pushEventSubscription = iotSubscribeTopics([getAccountTopic(accountNumber)])
    pushDataSubscription = pushEventSubscription.subscribe(({ payload }) => {
      if (!payload) return
      balanceUpdate(payload, queryClient, () => {
        fetchAccountBalanceAsync()
      })
      payIdUpdate(payload, accountNumber, queryClient)
    })
  }

  function unsubscribe() {
    if (accountNumberLoggedIn === null) {
      return
    }

    iotUnsubscribeTopics([getAccountTopic(accountNumberLoggedIn)])
    if (!!pushDataSubscription && pushDataSubscription.dispose) {
      pushDataSubscription.dispose()
      pushDataSubscription = null
    }
  }
}
