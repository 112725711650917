import React, { type FC, useCallback, type ComponentPropsWithoutRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InitialData, PaymentMethod } from '@mobi/api-types'
import { isReactNativeApp } from '@mobi/web-native-comms/web'
import { PaymentAmount } from '@mobi/component-library/Account/Payment'
import { useHostContext } from '../HostContext'
import type { PaymentMethods } from '@mobi/component-library/Deposit/types'
import {
  selectDepositState,
  setDepositInput,
  verify,
  deposit,
  depositSucceeded,
  depositFailed,
  cancel,
  selectDepositFlow,
  finished,
} from '../Store'
import type { OnFailureParams } from './types'
import { PanelStyled } from './DepositBody.styles'
import { DepositError, InvalidCardAlert } from '.'
import { incrementSuccessCount } from '../Utils'
import { amountInputValidationRegex } from '../../../Utils'
import { track } from '../../../Utils/analytics'
import { PaymentButton } from './Payments'
import { DepositProgress } from './DepositProgress'
import { useToggleControl } from '@mobi/utils'

type Props = {
  accountNumber: number
  initialData: InitialData
  paymentMethod: PaymentMethods
}

export const DepositPanel: FC<Props> = ({ accountNumber, initialData, paymentMethod }) => {
  const dispatch = useDispatch()
  const flow = useSelector(selectDepositFlow)
  const { events } = useHostContext()
  const { depositStatus, depositInput, depositFailure } = useSelector(selectDepositState)
  const { isOpen, open: openTray, close: closeTray } = useToggleControl()

  const inputValue = +depositInput.inputValue

  const onStart = useCallback(() => {
    dispatch(verify())
    openTray()

    track('deposit-started', {
      accountNumber,
      paymentMethod,
      depositVariant: flow,
    })
  }, [dispatch, openTray, accountNumber, paymentMethod, flow])

  const onCancel = useCallback(() => {
    dispatch(cancel())
    closeTray()

    track('deposit_cancel', {
      accountNumber,
      paymentMethod,
      cancelStep: depositStatus,
      depositVariant: flow,
    })
  }, [dispatch, closeTray, accountNumber, paymentMethod, depositStatus, flow])

  const onDepositing = useCallback(() => {
    if (paymentMethod !== 'CreditCard') {
      openTray()
    }
    dispatch(deposit())
  }, [dispatch, openTray, paymentMethod])

  const onSuccess = useCallback(
    (depositAmount: number) => {
      const successfulDepositCount = incrementSuccessCount(accountNumber)

      track('deposit-success', {
        accountNumber,
        paymentMethod,
        depositVariant: flow,
        deposit: {
          amount: depositAmount.toString(),
          isFirstDeposit: successfulDepositCount === 1,
          isSecondDeposit: successfulDepositCount === 2,
        },
      })

      return new Promise<void>(() => {
        events?.onDepositSuccess(flow, depositAmount)
        dispatch(depositSucceeded({ accountNumber }))
      })
    },
    [accountNumber, dispatch, flow, paymentMethod, events]
  )

  const onFailure = useCallback(
    (details: DepositError | OnFailureParams) => {
      track('deposit-error', {
        accountNumber,
        paymentMethod,
        depositVariant: flow,
        errorTrigger: 'Deposit',
        message: details.message,
      })

      dispatch(
        depositFailed({
          ...details,
          displayMessage: details.displayMessage,
        })
      )
    },
    [accountNumber, paymentMethod, flow, dispatch]
  )

  const onDepositInputChanged = useCallback(
    (value: string) => {
      if (amountInputValidationRegex.test(value)) {
        dispatch(setDepositInput({ inputValue: value, isValid: true, amount: +value }))
      } else {
        dispatch(setDepositInput({ inputValue: value, isValid: false }))
      }
    },
    [dispatch]
  )

  const { depositLimits } = initialData

  const helpText =
    inputValue > 0 && inputValue < depositLimits.minimum
      ? `Minimum $${depositLimits.minimum.toFixed(2)}`
      : `Enter custom amount`

  //deposit limit reached regardless of what they enter
  const isDepositLimitReached =
    depositLimits.maximum < depositLimits.minimum || depositLimits.maximum <= 0

  const isDepositingAllowed =
    depositStatus === 'idle' &&
    depositInput.isValid &&
    depositInput.amount >= depositLimits.minimum &&
    depositInput.amount <= depositLimits.maximum &&
    !isDepositLimitReached

  const primaryCard = initialData.paymentMethods.find(card => card.isDefault)

  return (
    <PanelStyled>
      {paymentMethod === 'CreditCard' &&
        primaryCard !== undefined &&
        isCardInvalid(primaryCard) && <InvalidCardAlert card={primaryCard} />}
      <PaymentAmount
        helpText={helpText}
        inputValue={depositInput.inputValue}
        onAmountChange={onDepositInputChanged}
        isDisabled={isDepositLimitReached}
        maximumAmount={depositLimits.maximum}
      />
      <PaymentButton
        isNative={isReactNativeApp}
        paymentMethod={paymentMethod}
        initialData={initialData}
        isDepositAllowed={isDepositingAllowed}
        depositAmount={depositInput.isValid ? depositInput.amount : 0}
        accountNumber={accountNumber}
        onStart={onStart}
        onCancel={onCancel}
        onDepositing={onDepositing}
        onSuccess={onSuccess}
        onFailure={onFailure}
      />

      <DepositProgress
        isOpen={isOpen}
        depositStatus={depositStatus}
        failureMessage={depositFailure?.displayMessage ?? 'An unexpected error has occured'}
        depositAmount={depositInput.isValid ? depositInput.amount : 0}
        onClose={() => {
          dispatch(finished())
          closeTray()
        }}
      />
    </PanelStyled>
  )
}

function isCardInvalid(
  card: PaymentMethod
): card is ComponentPropsWithoutRef<typeof InvalidCardAlert>['card'] {
  return card.isExpired || !card.isDebitCard
}
