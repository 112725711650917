import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AccountBalanceHeader } from './AccountBalanceHeader'
import { DepositHeader, DepositPanel } from '.'
import { BetAccount, InitialData } from '@mobi/api-types'
import * as native from '@mobi/web-native-comms/web'
import { PageStyled } from '../App.styles'
import { PanelStyled, PaymentMethodsStyled } from './DepositBody.styles'
import { PaymentMethods } from '@mobi/component-library/Deposit/Payment'
import { PaymentMethodBlocked } from '../../Deposit/Components/PaymentMethodBlocked'
import {
  selectDepositFlow,
  selectDepositState,
  selectLastSelectedPaymentMethod,
  selectPaymentMethod,
} from '../Store'
import { SelfServeDepositPanels } from '../../Deposit/Components/SelfServeDepositPanels'
import { type PaymentMethodsAll } from '@mobi/component-library/Deposit/types'
import { isSelfServePaymentMethod } from '@mobi/component-library/Deposit/helpers'
import type { AppProps } from '../App'
import { isPaymentRequestApiSupported } from '../Utils/helpers'
import { isApplePayWebSupported } from '../Utils'
import { DepositLimitStatus } from './DepositLimitStatus'
import { track } from '../../../Utils'

type Props = Omit<AppProps, 'flow'> & {
  initialData: InitialData
  betAccountData: BetAccount
}

type PaymentMethodStatuses = Record<PaymentMethodsAll, { available: boolean; blocked: boolean }>

export const DepositBody = ({
  accountNumber,
  accountBalance,
  initialData,
  betAccountData,
  asModal,
  onClose,
}: Props) => {
  const dispatch = useDispatch()
  const flow = useSelector(selectDepositFlow)
  const lastSelectedPaymentMethod = useSelector(selectLastSelectedPaymentMethod(accountNumber))

  const { selectedPaymentMethod, depositInput } = useSelector(selectDepositState)

  const inputValue = +depositInput.inputValue
  const { depositLimits } = initialData

  const showApplePay = native.isReactNativeIos || isApplePayWebSupported()
  const showGooglePay =
    !native.isReactNativeIos && (native.isReactNativeAndroid || isPaymentRequestApiSupported())

  // When deposit is show modally, hide these payment methods
  // as their deposit flow can interrupt the user from
  // resuming their normal workflow
  const showBPay = !asModal
  const showCash = !asModal
  const showScanMyBet = !asModal && native.isReactNativeApp
  const showPayID = !asModal

  const payIdDetailList = betAccountData.PayIdDetailList ?? []
  const isPreCommitmentLimitSet = betAccountData.PreCommitmentMaxDepositFrequency !== null
  const paymentMethodStatuses: PaymentMethodStatuses = {
    CreditCard: { available: true, blocked: betAccountData.CreditCardBlockedInd === 'Y' },
    PayPal: { available: true, blocked: betAccountData.BlockPayPalInd === 'Y' },
    ApplePay: { available: showApplePay, blocked: betAccountData.BlockApplePayInd === 'Y' },
    GooglePay: {
      available: showGooglePay,
      blocked: betAccountData.BlockGooglePayInd === 'Y',
    },
    BPay: { available: showBPay, blocked: false },
    Cash: { available: showCash, blocked: false },
    ScanMyBet: { available: showScanMyBet, blocked: false },
    PayID: { available: showPayID, blocked: betAccountData.BlockEftInd === 'Y' },
  }

  const isAvailable = (paymentMethod: PaymentMethodsAll) =>
    paymentMethodStatuses[paymentMethod].available

  const isBlocked = (paymentMethod: PaymentMethodsAll) =>
    paymentMethodStatuses[paymentMethod].blocked

  const onPaymentMethodClick = useCallback(
    (paymentMethod: PaymentMethodsAll) => {
      dispatch(selectPaymentMethod({ accountNumber, paymentMethod }))

      // If the deposit method has changed, track it in GA
      if (paymentMethod !== selectedPaymentMethod) {
        track('deposit_method_selection', {
          accountNumber,
          paymentMethod,
          depositVariant: flow,
        })
      }
    },
    [accountNumber, dispatch, selectedPaymentMethod, flow]
  )

  useEffect(() => {
    if (selectedPaymentMethod) return

    let paymentMethod: PaymentMethodsAll = 'CreditCard'

    if (lastSelectedPaymentMethod && isAvailable(lastSelectedPaymentMethod)) {
      paymentMethod = lastSelectedPaymentMethod
    }

    dispatch(
      selectPaymentMethod({
        accountNumber,
        paymentMethod,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentMethod])

  if (!selectedPaymentMethod) {
    return null
  }

  return (
    <>
      <DepositHeader title='Deposit' onClose={onClose} />

      {asModal && accountBalance !== undefined && <AccountBalanceHeader amount={accountBalance} />}

      <PageStyled>
        {!isBlocked(selectedPaymentMethod) && selectedPaymentMethod != 'ScanMyBet' && (
          <DepositLimitStatus
            depositAmount={inputValue}
            depositLimits={depositLimits}
            isPreCommitmentLimitSet={isPreCommitmentLimitSet}
            selectedPaymentMethod={selectedPaymentMethod}
          />
        )}
        {(function () {
          const isSelectedPaymentMethodSelfServe = isSelfServePaymentMethod(selectedPaymentMethod)
          const isPaymentMethodBlocked = isBlocked(selectedPaymentMethod)

          if (isSelectedPaymentMethodSelfServe && isPaymentMethodBlocked) {
            return <PaymentMethodBlocked paymentMethod={selectedPaymentMethod} />
          }

          if (isSelectedPaymentMethodSelfServe) {
            return (
              <SelfServeDepositPanels
                accountNumber={accountNumber}
                paymentMethod={selectedPaymentMethod}
                initialData={initialData}
                isPreCommitmentLimitSet={isPreCommitmentLimitSet}
                payIdDetailList={payIdDetailList}
              />
            )
          }

          if (isPaymentMethodBlocked) {
            return (
              <PanelStyled>
                <PaymentMethodBlocked paymentMethod={selectedPaymentMethod} />
              </PanelStyled>
            )
          }

          return (
            <DepositPanel
              accountNumber={accountNumber}
              initialData={initialData}
              paymentMethod={selectedPaymentMethod}
            />
          )
        })()}
        <PaymentMethodsStyled>
          <PaymentMethods
            deviceType='mobile' // TODO detect device type
            selectedPaymentMethod={selectedPaymentMethod}
            showApplePay={isAvailable('ApplePay')}
            showGooglePay={isAvailable('GooglePay')}
            showBPay={isAvailable('BPay')}
            showCash={isAvailable('Cash')}
            showScanMyBet={isAvailable('ScanMyBet')}
            showPayID={isAvailable('PayID')}
            onClick={onPaymentMethodClick}
          />
        </PaymentMethodsStyled>
      </PageStyled>
    </>
  )
}
