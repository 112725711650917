import { ContainerModule, interfaces } from 'inversify'
import {
  StartersReactQueryService,
  FormReactQueryService,
  BetEnquiryService,
} from '../Services/Services'
import { IBetEnquiryService } from '../Services/IBetEnquiryService'
import { IStartersService } from '../Services/IStartersService'

import { LegacyBetAdapter } from '../Model/LegacyBetAdapter'
import { ILegacyBetAdapter } from '../Model/ILegacyBetAdapter'
import { IUrlProvider } from '../Utils/IUrlProvider'
import { UrlProvider } from '../Utils/UrlProvider'
import { IErrorHandler } from '../Utils/IErrorHandler'
import { ErrorHandler } from '../Utils/ErrorHandler'
import { IBettingInformationManager } from '../Model/IBettingInformationManager'
import BettingInformationManager from '../Model/BettingInformationManager'
import { IEntitlementService } from '../Services/IEntitlementService'
import { EntitlementService } from '../Services/EntitlementService'
import { IFormService } from '../Services/IFormService'

const services = new ContainerModule((bind: interfaces.Bind) => {
  bind<ILegacyBetAdapter>('ILegacyBetAdapter').to(LegacyBetAdapter).inSingletonScope()
  bind<IStartersService>('IStartersService').to(StartersReactQueryService).inSingletonScope()
  bind<IBetEnquiryService>('IBetEnquiryService').to(BetEnquiryService).inSingletonScope()
  bind<IUrlProvider>('IUrlProvider').to(UrlProvider)
  bind<IErrorHandler>('IErrorHandler').to(ErrorHandler)
  bind<IBettingInformationManager>('IBettingInformationManager').to(BettingInformationManager)
  bind<IEntitlementService>('IEntitlementService').to(EntitlementService)
  bind<IFormService>('IFormService').to(FormReactQueryService).inSingletonScope()
})

export default services
