import React from 'react'
import { isLoading, isErrored, LoadingInstance, ErroredInstance } from 'rwwa-data-access'
import { connect } from '../../Components/HOCs/connect'
import { observeImmutable } from '../../Components/HOCs'
import { Spinner, DesktopSpinner } from '@mobi/component-library/Common/Spinner'
import { ErrorMessage, SuccessMessage } from '../../Components/Messages'
import { MainContainer } from '../../Components/Containers'
import { ContentHeader } from '../../Components/ContentHeader'
import { ButtonBlock } from '@mobi/component-library/Common/Buttons'
import { ComplexPasswordObserve } from '../Password'
import {
  ChangePasswordRepo,
  ChangePasswordKey,
  AccountDetails,
} from '../../Data/Account/changePassword'
import {
  ChangePasswordTitleLabelStyled,
  ChangePasswordContainerStyled,
} from './ChangePassword.styles'
import { CurrentPassword } from './Components/CurrentPassword'
import {
  state$ as changePasswordState$,
  ChangePasswordSubmit,
  ChangePasswordStateRecord,
  UpdateCurrentPassword,
  Reset,
} from './driver'
import { useBiometricsStatus } from '@mobi/account/Areas/Verification/Biometrics/Hooks/useBiometricsStatus'
import { useSelector } from 'react-redux'
import { getIsBiometricsEnabledOnTempPasswordChange } from '../Login/Store/selectors'

export function ChangePassword({ isDesktop }: { isDesktop?: boolean }) {
  return <ConnectChangePassword changePassword={ChangePasswordKey} isDesktop={isDesktop} />
}

export const ConnectChangePassword = connect({ changePassword: ChangePasswordRepo })<{
  isDesktop?: boolean
}>(ChangePasswordLoader)

export function ChangePasswordLoader({
  changePassword,
  isDesktop,
}: {
  changePassword: typeof LoadingInstance | typeof ErroredInstance | AccountDetails
  isDesktop?: boolean
}) {
  if (isLoading(changePassword)) {
    return isDesktop ? <DesktopSpinner /> : <Spinner />
  }

  if (isErrored(changePassword)) {
    return <ErrorMessage>An error occurred while loading change password.</ErrorMessage>
  }

  const ChangePasswordComponent = () => {
    React.useEffect(() => {
      return () => Reset()
    })
    const props = { accountNumber: changePassword.accountNumber as string }
    return <ChangePasswordViewObserve {...props} isDesktop={isDesktop} />
  }

  return <ChangePasswordComponent />
}

interface ChangePasswordViewProps {
  isDesktop?: boolean
  isChangeTemporaryPassword?: boolean
  accountNumber: string
  isChangePasswordSuccess: boolean
  isChangePasswordSubmitEnabled: boolean
  validationError: string | false
}

export const ChangePasswordViewObserve = observeImmutable<
  ChangePasswordStateRecord,
  { accountNumber: string; isDesktop?: boolean; isChangeTemporaryPassword?: boolean }
>(changePasswordState$, ({ record, ...props }) => {
  const { isChangePasswordSuccess, isChangePasswordSubmitEnabled, validationError } = record

  return (
    <ChangePasswordView
      {...props}
      isChangePasswordSuccess={isChangePasswordSuccess}
      isChangePasswordSubmitEnabled={isChangePasswordSubmitEnabled}
      validationError={validationError}
    />
  )
})

let changePasswordForm: HTMLFormElement

export function ChangePasswordView({
  isDesktop,
  accountNumber,
  isChangeTemporaryPassword,
  isChangePasswordSuccess,
  isChangePasswordSubmitEnabled,
  validationError,
}: ChangePasswordViewProps) {
  const { isBiometricsEnabled } = useBiometricsStatus()
  const isBiometricsEnabledOnTempPasswordChange = useSelector(
    getIsBiometricsEnabledOnTempPasswordChange
  )

  const onChangeCurrent = (event: React.FormEvent<HTMLInputElement>) => {
    UpdateCurrentPassword(event.currentTarget.value)
  }
  const changePasswordSubmit = () =>
    ChangePasswordSubmit({
      accountNumber,
      form: changePasswordForm,
      isBiometricsEnabledByUser: isBiometricsEnabled || isBiometricsEnabledOnTempPasswordChange,
      isDesktop,
      isChangeTemporaryPassword,
    })
  const onFormClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  return (
    <MainContainer data-tid-changepassword-main-container=''>
      {!isDesktop && <ContentHeader title='Change Password' data-tid-page-title='' />}
      {isChangePasswordSuccess ? (
        <SuccessMessage>Successfully changed password</SuccessMessage>
      ) : null}
      {!isChangePasswordSuccess && validationError ? (
        <ErrorMessage>{validationError}</ErrorMessage>
      ) : null}
      <ChangePasswordContainerStyled>
        <ChangePasswordTitleLabelStyled>Account: {accountNumber}</ChangePasswordTitleLabelStyled>
        <form
          ref={(el: HTMLFormElement) => (changePasswordForm = el)}
          onClick={onFormClick}
          id='changePasswordForm'
        >
          <fieldset>
            {!isChangeTemporaryPassword && (
              <CurrentPassword labelName='Current Password*' onChange={onChangeCurrent} />
            )}
            <ComplexPasswordObserve shouldScrollIntoView={!isDesktop} />
          </fieldset>
          <ButtonBlock
            onClick={changePasswordSubmit}
            disabled={!isChangePasswordSubmitEnabled}
            form='changePasswordForm'
          >
            Change Password
          </ButtonBlock>
        </form>
      </ChangePasswordContainerStyled>
    </MainContainer>
  )
}
