import React from 'react'
import { PlanSeq } from '@mobi/api-types'
import { Banner } from '@mobi/component-library/Common/V2/Banner'
import type { Ticket, TicketStatus } from '@core/Areas/AccountActivity/types'
import type { TicketLayoutType } from '../../helpers/legTypes'
import {
  FavouriteNumbers,
  Multi,
  SameRaceMulti,
  SingleSingle,
  SingleMultiple,
  Sports,
  Tipping,
} from './Components'

export const BetInfo = React.memo<{
  ticket: Ticket
  status: TicketStatus
  ticketLayoutType: TicketLayoutType
}>(
  ({ ticket, status, ticketLayoutType }) => {
    if (PlanSeq.FavouriteNumbers === ticket.planSeq) {
      return <FavouriteNumbers ticket={ticket} status={status} />
    }

    if (ticketLayoutType === 'same-race-multi') {
      return <SameRaceMulti ticket={ticket} status={status} />
    }

    if (ticketLayoutType === 'single-single') {
      return <SingleSingle ticket={ticket} status={status} />
    }

    if (ticketLayoutType === 'single-multiple') {
      return <SingleMultiple ticket={ticket} status={status} />
    }

    if (ticketLayoutType === 'multi') {
      return <Multi ticket={ticket} status={status} />
    }

    if (ticketLayoutType === 'sports') {
      return <Sports ticket={ticket} status={status} />
    }

    if (ticketLayoutType == 'tipping') {
      return <Tipping ticket={ticket} status={status} />
    }

    return <Banner text='Unable to match selection info' type='neutral' />
  },
  (prev, next) => prev.ticket.ticketSequence === next.ticket.ticketSequence
)
