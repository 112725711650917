import React from 'react'
import styled from '@emotion/styled'
import { Currency } from '@mobi/utils/money'
import { spacing } from '@mobi/component-library/Theme/Common'
import type { BetSlipItem } from '@mobi/betslip/types'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { isValidMultiInvestmentForLegs } from '@mobi/betslip/helpers/state'
import { ComboInvestment } from './Components/ComboInvestment'
import { SummaryLine } from '@mobi/betslip/Components/Common/SummaryLine'
import {
  calclulateCombinedMultiInvestment,
  calculateMultiProjectedPay,
} from '@mobi/betslip/helpers/calculator/multis'

export const ComboMultis: React.FC<{
  isDisabled: boolean
  selectedMultiItems: BetSlipItem[]
}> = ({ isDisabled, selectedMultiItems }) => {
  const multiInvestment = useAppSelector(state => state.betslip.bets.multiInvestment)

  const legCount = selectedMultiItems.length
  const shouldEnableSingles = isValidMultiInvestmentForLegs(multiInvestment, legCount)
  const totalProjPay = calculateMultiProjectedPay(selectedMultiItems, multiInvestment)

  return (
    <WrapperStyled>
      <ComboInvestment
        multiInvestmentKey='f1'
        isDisabled={isDisabled || !shouldEnableSingles}
        comboFieldType='MultiComboSingles'
        label='Singles'
        legCount={legCount}
      />

      {legCount > 2 && (
        <ComboInvestment
          multiInvestmentKey='f2'
          isDisabled={isDisabled}
          comboFieldType='MultiComboDoubles'
          label='Doubles'
          legCount={legCount}
        />
      )}

      {legCount > 3 && (
        <ComboInvestment
          multiInvestmentKey='f3'
          isDisabled={isDisabled}
          comboFieldType='MultiComboTrebles'
          label='Trebles'
          legCount={legCount}
        />
      )}

      {legCount > 4 && (
        <ComboInvestment
          multiInvestmentKey='f4'
          isDisabled={isDisabled}
          comboFieldType='MultiComboPick4'
          label='Pick 4'
          legCount={legCount}
        />
      )}

      {legCount > 5 && (
        <ComboInvestment
          multiInvestmentKey='f5'
          isDisabled={isDisabled}
          comboFieldType='MultiComboPick5'
          label='Pick 5'
          legCount={legCount}
        />
      )}

      <div>
        <SummaryLine
          left='Total Multi Cost'
          right={new Currency(
            calclulateCombinedMultiInvestment(selectedMultiItems, multiInvestment)
          ).format()}
        />

        <SummaryLine left='Total Projected Payout' right={new Currency(totalProjPay).format()} />
      </div>
    </WrapperStyled>
  )
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.sm,
})
