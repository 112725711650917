import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import {
  trackVerificationBannerLoaded,
  trackVerificationVerifyNowButtonClicked,
} from '@core/Areas/Account/Verification/analytics'
import { AppRoutes } from '@core/AppRoutes'
import { Banner, Button } from '@mobi/component-library/Common/V2'
import { state$ } from '@core/State/UserAccount/userAccountDriver'
import { useObservableProperties } from '@core/Utils/hooks'
import { useBetAccount } from '@mobi/account/Areas/Deposit/Hooks'
import { getFromRepo } from '@core/Utils/repository'
import { BetAccountKey, BetAccountRepo } from '@core/Data/Account/betAccount'

export const VerificationBanner = () => {
  const history = useHistory()
  const { isLoggedIn } = useObservableProperties(state$, ['isLoggedIn'], {
    isLoggedIn: false,
  })

  const {
    isError,
    isLoading,
    data: betAccount,
  } = useBetAccount({
    enabled: isLoggedIn ?? false,
    queryFn: () => getFromRepo(BetAccountRepo, BetAccountKey),
  })

  // isVerified can be undefined, meaning we don't know
  // whether the user is verified by means of the ID
  const isVerified = betAccount?.IsIDVerified
  const accountNumber = betAccount?.BetAccountNumber
  const isUnverified = isVerified === false
  const { pathname } = useLocation()
  const isSuppressedRoute = [
    AppRoutes.SignUp,
    AppRoutes.CompletedSignUp,
    AppRoutes.Verification,
  ].some(path => pathname.startsWith(path))

  const showBanner = !isLoading && !isError && !!isLoggedIn && isUnverified && !isSuppressedRoute

  useEffect(() => {
    if (showBanner && accountNumber !== undefined) {
      trackVerificationBannerLoaded({
        accountNumber,
        isVerified: !isUnverified,
      })
    }
  }, [showBanner, accountNumber, isUnverified])

  if (!showBanner || accountNumber === undefined) {
    return null
  }

  const button = (
    <Button
      onClick={() => {
        history.push(`${AppRoutes.Verification}`)
        trackVerificationVerifyNowButtonClicked({
          accountNumber,
        })
      }}
      color='secondary_outline'
      size='sm'
      role='link'
    >
      Verify now
    </Button>
  )

  return (
    <Banner
      role='status'
      type='error'
      text='To access our full experience, please verify your account.'
      title={`To verify your account, tap the "Verify now" button or dial +61 1300 36 36 88`}
      button={button}
    />
  )
}
