import React from 'react'
import styled from '@emotion/styled'
import { colors, radius, spacing } from '@mobi/component-library/Theme/Common'
import { Icon } from '@mobi/component-library/Common/Icon'
import { RacingTitle } from '@mobi/betslip/Components/Common/BetCard/Components/EventDetails/Components/RacingTitle'
import { getIconTypeFromMeetingCode } from '@core/Areas/Quickbet/helpers/IconTypeHelper'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import {
  getNumberFromString,
  getSingleTicketTitle,
  shouldUseFallbackIcon,
  TicketLayoutType,
} from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { SilkFallback } from '@core/Areas/AccountActivity/Components/Ticket/Components/Common'
import { getRaceLink, meetingIdToContestType } from '@core/Areas/Racing/helpers'
import { SilkImageContained } from '@core/Components/SilkImage/SilkImageContained'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'
import { CountdownStatus } from './Components/CountdownStatus'
import { RunnerNumber } from '@core/Areas/RaceCard/Components/RunnerNumber'
import { RaceCodesLowerCase } from '@core/Areas/Racing/Types/MeetingInformation'

export const Multi: React.FC<{ ticket: Ticket; ticketLayoutType: TicketLayoutType }> = ({
  ticket,
  ticketLayoutType,
}) => {
  const isTipping = ticketLayoutType === 'tipping'

  return (
    <WrapperStyled>
      {ticket.legs.map(leg => {
        const shouldHideSilk = shouldUseFallbackIcon(leg, ticket.planSeq)

        const shouldProvideLink = !!(leg.fixtureId && leg.fixtureDate && leg.raceNumber)

        const runnerCode = meetingIdToContestType(leg.fixtureId, true)
        const { title, runnerNumber } = getSingleTicketTitle(leg, ticket.planSeq, true)

        const runnerNameForBlackbook =
          runnerNumber &&
          runnerCode &&
          leg.selections[0]?.selectionDetails[0]?.acceptorDetails[0]?.name
        const shouldRenderBlackbookNumber = runnerCode && runnerNameForBlackbook && runnerNumber

        const linkInfo = shouldProvideLink
          ? {
              pathname: getRaceLink({
                meetingDate: leg.fixtureDate,
                meetingId: leg.fixtureId,
                raceNumber: leg.raceNumber,
                isFOO: false,
              }),
            }
          : undefined

        const selectionSubHeading = (
          leg.eventNameLine2 ||
          leg.selections[0].header ||
          leg.betType
        )?.replace('-Fixed', '')

        return (
          <div key={leg.header} data-testid='Multi.Details.Leg'>
            <BetCard.EventDetails
              iconComponent={
                <Icon
                  size='1.6rem'
                  type={
                    getIconTypeFromMeetingCode(
                      leg.eventType || leg.fixtureId?.charAt(leg.fixtureId.length - 1)
                    ) || 'tab'
                  }
                />
              }
              heading={
                isTipping ? (
                  leg.eventName
                ) : leg.raceNumber && leg.meetingName ? (
                  <RacingTitle
                    races={[{ raceNumber: leg.raceNumber }]}
                    meetingName={leg.meetingName}
                    isMultipleRaceSelection={false}
                  />
                ) : (
                  `${leg.sportName ? leg.sportName + ' ' : ''}${ticket.legs[0].eventName}`
                )
              }
              statusComponent={<CountdownStatus multiLeg={leg} />}
              linkInfoPrimary={linkInfo}
            />

            <BetCard.SelectionInfo
              heading={
                <>
                  {shouldRenderBlackbookNumber ? (
                    <>
                      <RunnerNumber
                        starterType={runnerCode}
                        starterName={runnerNameForBlackbook}
                        starterNumber={runnerNumber}
                      />
                      <span>&nbsp;-&nbsp;</span>
                    </>
                  ) : runnerNumber ? (
                    `${runnerNumber} - `
                  ) : (
                    ''
                  )}
                  {title}
                </>
              }
              subHeading={selectionSubHeading}
              priceWin={getStarterPrice(leg)}
              iconComponent={
                shouldHideSilk ? (
                  <SilkFallback contestType={runnerCode?.toLowerCase() as RaceCodesLowerCase} />
                ) : (
                  <SilkImageContained
                    fixtureDate={leg.fixtureDate}
                    fixtureId={leg.fixtureId}
                    raceNumber={leg.selections[0].raceNumber || leg.raceNumber}
                    acceptorNumber={leg.selections[0].selectionDetails[0].acceptorDetails[0].number}
                  />
                )
              }
              linkInfo={linkInfo}
            />
          </div>
        )
      })}
    </WrapperStyled>
  )
}

// =============
// Local Helpers
// =============

function getStarterPrice(leg: Ticket['legs'][0]) {
  const isPlace = leg.betType?.toLowerCase().includes('place')
  const returnRate = isPlace ? leg.return?.split(',')[1] ?? leg.return : leg.return
  return returnRate ? getNumberFromString(returnRate) : undefined
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.smx1,

  '> div': {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.smx1,
    padding: spacing.smx1,
    border: `1px solid ${colors.neutral[200]}`,
    borderRadius: radius.lgx1,
  },
})
