import React from 'react'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'
import { Icon } from '@mobi/component-library/Common/Icon'
import type { Ticket, TicketStatus } from '@core/Areas/AccountActivity/types'
import { CountdownStatus } from '../Common/CountdownStatus'

export const Sports: React.FC<{ ticket: Ticket; status: TicketStatus }> = ({ ticket, status }) => {
  const leg = ticket.legs[0]
  const selection = leg.selections[0].selectionDetails[0]

  return (
    <>
      <BetCard.EventDetails
        heading={leg.eventNameLine2}
        subHeading={leg.eventName}
        statusComponent={<CountdownStatus ticket={ticket} status={status} />}
        iconComponent={<Icon size='1.6rem' type='tab' />}
      />

      <BetCard.SelectionInfo
        heading={selection.acceptorDetails[0].name}
        subHeading={leg.betType || ticket.productName}
        priceWin={leg.odds}
        iconComponent={
          <div
            style={{ width: '2.8rem', height: '2.8rem', borderRadius: '50%', background: 'red' }}
          />
        }
      />
    </>
  )
}
