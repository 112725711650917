import React from 'react'
import { BetCard } from '@mobi/betslip/Components/Common/BetCard'
import type { NextSkyRace } from '@core/Areas/RaceCardSky/types'
import type { Ticket } from '@core/Areas/AccountActivity/types'
import { getTicketLayoutType } from '@core/Areas/AccountActivity/Components/Ticket/helpers'
import { MultiTicket } from './Components/MultiTicket'
import { GeneralTicket } from './Components/GeneralTicket'
import { TicketDisplayContext } from '@core/Areas/AccountActivity/context'

export const SkyPendingTicket: React.FC<{ ticket: Ticket; nextSkyRaces: NextSkyRace[] }> = ({
  ticket,
  nextSkyRaces,
}) => {
  const ticketType = getTicketLayoutType(ticket)
  const isMulti = ticketType === 'multi'

  return (
    <TicketDisplayContext.Provider
      value={{ type: 'pending-bets-drawer', data: { isSkyRaceMatch: false } }}
    >
      <BetCard.Container>
        {isMulti ? (
          <MultiTicket ticket={ticket} nextSkyRaces={nextSkyRaces} />
        ) : (
          <GeneralTicket ticket={ticket} nextSkyRaces={nextSkyRaces} ticketType={ticketType} />
        )}
      </BetCard.Container>
    </TicketDisplayContext.Provider>
  )
}
